import React, {PureComponent} from 'react';
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import {Button} from "reactstrap";
import {bindActionCreators} from "redux";
import * as poolActions from "../redux/actions/poolActions";
import {connect} from "react-redux";
import * as loginUserActions from "../redux/actions/loginUserActions";
//import { UserManager } from "oidc-cli";
import { Redirect } from "react-router-dom";
import { userManager } from 'oidc-react';

 const  oidcConfig = {

   authority: "https://test.turkuazgo.com/im",
   scope: "openid profile",
   clientId: "tys",
   response_type: "code",
   response_mode:"fragment",
   redirectUri:
     process.env.NODE_ENV === "development"
       ? "https://localhost:5001/#!/callback"
       : "",
 };

 
class sessionTimeOutPopup extends PureComponent
{

   constructor(props)
   {      
      super(props);
      this.state = {
         timer: 0,
         message: "",
         redirect:false,
         finish: false
      }

   //   debugger
   //    const auth = useAuth();
   //    if (auth && auth.userData) {
   //      return (
   //        <div>
   //          <strong>Logged in! 🎉</strong><br />
   //          <button onClick={() => auth.signOut()}>Log out!</button>
   //        </div>
   //      );
   //    }
   //    return <div>Not logged in! Try to refresh to be redirected to Google.</div>;
      // let ths = this;
      // this.popupTimer = setInterval(function ()
      // {
      //    // var dif = (new Date() - new Date(localStorage.getItem('loginTime')));
      //    // var dif = Math.round((dif / 1000));
      //    let text = "Talep Yönetim Sistemine Yönlendirmeniz Yapılıyor . . . ";

      //    if (dif >= 39)
      //    {

      //       // // ths.setState({
      //       // //    message: "Talep Yönetim Sistemine Yönlendirmeniz Yapılıyor . . . ",
      //       // //    finish: true
      //       // // });

      //       // // clearInterval(ths.popupTimer);
      //       // // // ths.closeWindow();
      //    }

      //    // if (dif < 39 && ths.state.finish === false)
      //    // {
      //    //    ths.setState({message: text})
      //    // }

      // }, 1000);
   }


//   SigninAsync = () => new Promise((resolve) =>
//    {
//       userManager.signinRedirectCallback((data) =>
//       {
//          this.setState({redirect:true})

//          resolve(data);
//       });
//    })

//     testButton(){
//       this.SigninAsync().then(data =>
//          {
//          })
//     }

   componentWillUnmount()
   {
      
      //clearInterval(this.popupTimer);
   }

// //   componentDidMount(){
// //    debugger
// //    this.SigninAsync()
// //   }

   // continueSession()
   // {
   //    this.props.actions.getPoolCount().then(() =>
   //    {
   //       window.close();
   //    });
   // }

   render()
   {    
      if (this.state.redirect === true) {
         return <Redirect push to="/main" />;
       } 
       else{
      return (
         
         <>         

            <Card>
               <CardHeader>Task4Team Yönlendirme Bildirimi</CardHeader>
               <CardBody>
                  Merhaba, <br/>
                  Talep Yönetim Sistemine Yönlendirmeniz Yapılıyor . . . 
                  {this.state.message}
               </CardBody>
               <CardFooter>
                  Otomatik olarak yönlendirme gerçekleştirilmedi ise butona tıklayarak ilerlebilirsiniz .
                  {/*   disabled={this.state.finish}*/}
                  {/*   onClick={() => this.closeWindow()}*/}
                  {/*   color={'info'}*/}
                  {/*   style={{float: 'right',}}>*/}
                  {/*   Oturumu Kapat*/}
                  {/*</Button>*/}
                  
                  <Button
                     onClick={() => this.LoggedIn()}
                     color={'success'}
                     style={{float: 'right'}}>
                     Devam Et
                  </Button>
               </CardFooter>
            </Card>
         </>
      );
   }
}
}


function mapStateToProps(state)
{
   return {
      loginUser: state.loginReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         clearLoginUser: bindActionCreators(loginUserActions.clearLoginUser, dispatch),
         getPoolCount: bindActionCreators(poolActions.getPoolCount, dispatch),
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(sessionTimeOutPopup);
