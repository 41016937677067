import React from 'react';
import { useAuth } from 'oidc-react';
import DogusLogin from "../../User/CustomLogin/Dogus";

import { HashRouter, Route, Switch ,Redirect} from "react-router-dom";
import { callAPI,callApiPost } from "../../../components/Axios";
import notify from "devextreme/ui/notify";


const tempArr =[]
const LoggedIn = () => {
  
  const auth = useAuth();
  tempArr.push(auth)

  if(tempArr.length >3){
     let user =auth.userData
    auth.userManager.clearStaleState()
    localStorage.removeItem("isExternal");

    let formData = new FormData();
      formData.append('jsonText', JSON.stringify(user));
      var url = '/api/Login/TurkuazLogin'
      callAPI(
        callApiPost,
        url,
        formData,
         (res) => {
          if (res.data.Token !== undefined && res.data.Token !== "") {
            localStorage.setItem("token", res.data.Token);
        
            
            window.location.href =  window.location.origin+"/#!"+"/login";
          
          } else {
            notify("Kullanıcı Adı Veya Şifre Hatalı.", "error", 3000);
          }
      
        })

  }
  auth.isLoading=true;
  if (tempArr.length >3) {
    return (
      <HashRouter hashType={"hashbang"}>
   <Route>
         <Redirect push to="/login" />;
   </Route>
        </HashRouter>  
      
    );
  }
  return <div>Not logged in! Try to refresh to be redirected to Google.</div>
};

export default LoggedIn;
