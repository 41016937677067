import React, {PureComponent} from 'react';
import {Button, Card, CardBody, CardFooter, Col, FormGroup, Label, Row} from "reactstrap";
import * as BB from "../../../BBComponent";
import success from "../../../../assets/img/success.gif";
import notify from "devextreme/ui/notify";
import TaskModel from "../../../../models/Task";
import {post} from "axios";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {TableTask} from "../../../custom";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import Back from '../../../../assets/left-arrow.png'
import {connect} from "react-redux";
import TextBox from "devextreme-react/text-box";
import {Dropdown} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import List from "devextreme-react/list";
import * as taskActions from "../../../../redux/actions/taskActions";
import { htmlToText } from 'html-to-text';
import { CheckBox } from 'devextreme-react';

class RecurringTaskCreate extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         periodicTasks: [],
         isTaskCreateInFlipped: false,
         isTaskCreateFlipped: false,
         taskCreateLoading: false,
         selectedTaskCreateTemplateID: undefined,
         enableShowTemplateButton: true,
         enableChoosePeriod: undefined,
         days:undefined,
         months:undefined,
         monthDays:undefined,
         visibleViewer: false,
         templateJson: undefined,
         selectedTaskDescription: '',
         selectedID: undefined,
          selectedEmail: '',
          templateName: ''
      };
      let { t } = this.props;
      this.languages = {
         lütfenGerekliAlanlarıDoldurunuz: t("lütfenGerekliAlanlarıDoldurunuz"),
         PeriyodikTalepoluşturmaişleminizbaşarıylasistemekaydedilmiştir: t("PeriyodikTalepoluşturmaişleminizbaşarıylasistemekaydedilmiştir"),
         periyodikTalepOluşturmadaHata: t("periyodikTalepOluşturmadaHata"),
         lütfenGerekliAlanlarıDoldurunuz: t("lütfenGerekliAlanlarıDoldurunuz"),
         periyodiktalepolustur:t("periyodiktalepolustur"),
         Herhangibirveriyok:t("Herhangibirveriyok")
      }
      this.GetMyPeriodicTask();
   }

   GetMyPeriodicTask()
   {
      //periodicTasks
      BB.FetchJSON('api/Task/GetMyRecurringTaskCreate').then(data =>
      {
         this.setState({periodicTasks: data});
      });
   }

    handleChange(key, value) {
        if (key === 'selectedTaskCreateTemplateID') {
            if (value !== undefined) {
                BB.FetchJSON('api/DashBoard/GetTemplateJson?ID=' + value).then(data => {
                    const storeData = JSON.parse(data[0].TemplateJson);
                    this.setState({ templateJson: storeData });
                    this.setState({ selectedTaskDescription: storeData.selectedTaskDescription })
                    this.setState({ selectedID: value })
                });

                fetch('api/Dashboard/GetTemplateName?ID=' + value, { method: 'GET' })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState({ templateName: data, enableShowTemplateButton: false, enableChoosePeriod: true })
                    });
            }
        }
      //      this.setState({enableShowTemplateButton: false, enableChoosePeriod: true});         
      //}

      this.setState({[key]: value});
   }

   addPeriodicTask = () =>
   {
      this.setState({taskCreateLoading: true});
      this.props.actions.isLoading(true);
      let nullCheck = true;

      if(this.state.selectedTaskCreateFrequency == 1){
         if (this.state.selectedTaskCreateDays === undefined || this.state.selectedTaskCreateTime === undefined) {
            nullCheck = false;
         }
      }else if(this.state.selectedTaskCreateFrequency == 2){
         if(this.state.selectedTaskCreateMonths === undefined || this.state.selectedTaskCreateMonthDays === undefined || this.state.selectedTaskCreateTime === undefined){
            nullCheck = false;
         }
      }

      if (!nullCheck)
      {
         this.props.actions.isLoading(false);
         notify(this.languages.lütfenGerekliAlanlarıDoldurunuz, 'warning', 3000);
      }
      else
      {
         let item = new TaskModel();

         item.CategoryID = this.state.templateJson.selectedCategory;
         item.CreateDate = new Date();
         item.DegreeOfUrgency = this.state.templateJson.selectedDegreeOfUrency;
         item.Description =htmlToText(this.state.templateJson.selectedTaskDescription, {tags: {'img': {format: 'skip'}}}).replaceAll('//', '/');
         item.NotificationType = this.state.templateJson.selectedNotificationType;
         item.TaskNumber = 0;
         item.TaskTypeID = 'fb3a1946-fecd-e811-80f6-005056b839bb';
         item.UrgencyDescription = this.state.templateJson.selectedDegreeOfUrencyDescription;
         item.CreateUserID = this.state.templateJson.selectedEmployee;
         item.IsDepartmanNotification = this.state.templateJson.isDepartmanNotification;
         item.ExpectedEndDate = this.state.templateJson.selectedDueClosedDate;
         item.CustomControlForms = null;
          item.HTMLDescription = this.state.templateJson.selectedTaskDescription;
          item.TaskTemplateViewer = this.state.selectedTaskCreateTemplateID;
          let formData = new FormData();
         formData.append('jsonText', JSON.stringify(item));
         formData.append('days', JSON.stringify(this.state.selectedTaskCreateDays));
         formData.append('hours', JSON.stringify(new Date(this.state.selectedTaskCreateTime).getHours()));
         formData.append('minutes', JSON.stringify(new Date(this.state.selectedTaskCreateTime).getMinutes()));
         formData.append('months', JSON.stringify(this.state.selectedTaskCreateMonths));
         formData.append('monthDays',JSON.stringify(this.state.selectedTaskCreateMonthDays))
         formData.append('taskFrequency',JSON.stringify(this.state.selectedTaskCreateFrequency))
         post('api/Task/recurringTaskCreate', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {  
            formData = new FormData();
            if (res.data)
            {
               this.setState({
                  selectedTaskCreateDays: undefined,
                  selectedTaskCreateTime: undefined,
                  selectedTaskCreateTemplateID: undefined,
                  selectedTaskCreateFrequency: undefined,
                  taskCreateLoading: false,
                  isTaskCreateFlipped: true,
                  enableShowTemplateButton: true,
                  enableChoosePeriod: false,
                  showModal: false,
                  selectedTaskCreateMonths: undefined,
                  selectedTaskMonthDays: undefined,
                  selectedTaskCreateMonthDays: undefined
               })

               this.props.actions.isLoading(false);
               this.GetMyPeriodicTask();
               this.setState({isTaskCreateFlipped: false, showModal: false})
               notify(this.languages.PeriyodikTalepoluşturmaişleminizbaşarıylasistemekaydedilmiştir, "success", 4000);
            }
            else
            {

               notify(this.languages.periyodikTalepOluşturmadaHata, "error", 4000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   modalVisible = () =>
   {
      this.setState({showModal: !this.state.showModal})
      if (this.state.showModal)
      {
         this.setState({visibleViewer: false})
      }
   }

   render()
   {
       const t = this.props.useTranslation;
       const { selectedTaskCreateTemplateID, templateJson, templateName } = this.state;
      this.state.days =  [
         {ID: 1, Name: t('pazartesi')},
         {ID: 2, Name: t('sali')},
         {ID: 3, Name: t('carsamba')},
         {ID: 4, Name: t('persembe')},
         {ID: 5, Name: t('cuma')},
         {ID: 6, Name: t('cumartesi')},
         {ID: 7, Name: t('pazar')}];

         this.state.months =  [
            {ID: 1, Name: t('ocak')},
            {ID: 2, Name: t('subat')},
            {ID: 3, Name: t('mart')},
            {ID: 4, Name: t('nisan')},
            {ID: 5, Name: t('mayis')},
            {ID: 6, Name: t('haziran')},
            {ID: 7, Name: t('temmuz')},
            {ID: 8, Name: t('agustos')},
            {ID: 9, Name: t('eylul')},
            {ID: 10, Name: t('ekim')},
            {ID: 11, Name: t('kasim')},
            {ID: 12, Name: t('aralik')}];

         this.state.monthDays = [
            {ID: 1, Name: "1"},
            {ID: 5, Name: "5"},
            {ID: 10, Name: "10"},
            {ID: 15, Name: "15"},
            {ID: 20, Name: "20"},
            {ID: 25, Name: "25"}];
      return (
         <>
            <TableTask
               title={t("periyodikTalepler")}
               description={t("periyodikTaleplerDesc")}
               data={this.state.periodicTasks}
               type='button'
               handleClick={() => this.setState({showModal: true})}
               event={this}
               buttonVisible={true}
               minHeight={true}
               noDataText={t("Herhangibirveriyok")}
            />
            <Modal
               size={'lg'}
               style={{zIndex: 1}}
               centered={true}
               isOpen={this.state.showModal}
            >
               <ModalHeader>
                  {this.state.visibleViewer && (
                     <img onClick={() => this.setState({visibleViewer: false})} src={Back}
                          style={{height: 20, cursor: 'pointer', marginRight: 10}}/>
                  )}
                  {this.state.visibleViewer ? 'Önizleme' : this.languages.periyodiktalepolustur}
               </ModalHeader>
               <ModalBody className='pt-0'>
                  {
                     this.state.visibleViewer ? (
                        <div>
                           <TaskTemplateViewer
                              loginuser={this.props.loginuser}
                              useTranslation={t}
                              templateJson={templateJson}
                              templateName={templateName}
                              event={this}
                              state={this.state}
                           />
                        </div>
                     ) : (
                        this.props.myTaskCreateTemplates.length > 0 ?
                           (
                              <div style={{marginTop: 10}}>
                                 <Row>
                                    <Col xl={6}>
                                       {BB.DxDropDownWithJSON('selectedTaskCreateTemplateID', t("Talepoluşturmakiçinşablonseçiniz"), this, this.props.myTaskCreateTemplates)}
                                    </Col>
                                    <Col xl={6}>
                                       {BB.DxDropDownWithJSON('selectedTaskCreateFrequency', t("PeriyotSeçiniz"), this,
                                          [{
                                             ID: 1,
                                             Name: t("gunluk")
                                          },
                                          {
                                             ID: 2,
                                             Name: t("aylik")
                                          }], !this.state.enableChoosePeriod)}
                                    </Col>
                                 </Row>
                                 {(() => {
                                    if (this.state.selectedTaskCreateFrequency == 1) {    //1 ise günlük 2 ise aylık
                                       return(
                                          <Row>
                                             <Col xl={6}>
                                                {BB.DxMultipleDropdownTaskCreateTemplate('selectedTaskCreateDays', t("GünSeciniz"), this, this.state.days, !this.state.enableChoosePeriod)}
                                             </Col>
                                             <Col>
                                                {BB.TimeSelectWithLabel('selectedTaskCreateTime', t("SaatSeciniz"), this, !this.state.enableChoosePeriod)}
                                             </Col>
                                          </Row>
                                       )
                                    }else if(this.state.selectedTaskCreateFrequency == 2){
                                       return(
                                          <Row>
                                             <Col>
                                                {BB.DxMultipleDropdownTaskCreateTemplate('selectedTaskCreateMonths', t("aySeciniz"), this, this.state.months, !this.state.enableChoosePeriod)}
                                             </Col>
                                             <Col>
                                               {BB.DxMultipleCheckBox('selectedTaskCreateMonthDays',t('GünSeciniz'),this.state.monthDays,this)}
                                             </Col>
                                             <Col>
                                                {BB.TimeSelectWithLabel('selectedTaskCreateTime', t("SaatSeciniz"), this, !this.state.enableChoosePeriod)}
                                             </Col>
                                          </Row>
                                       )
                                    }
                                 })()}
                                 <Row>
                                    <Col>
                                       <Button
                                          color={'success'}
                                          className='float-right m-1'
                                          onClick={() =>
                                          {
                                             this.addPeriodicTask();
                                          }}>
                                          {t("kaydet")}
                                       </Button>

                                       <Button
                                          className='float-right m-1'
                                          disabled={selectedTaskCreateTemplateID === undefined}
                                          onClick={() => this.setState({visibleViewer: true})}
                                          color={'warning'}>
                                          {t("önizleme")}
                                       </Button>
                                       <Button
                                          className='float-right m-1'
                                          onClick={() => this.setState({
                                             showModal: false,
                                             selectedTaskCreateTime: undefined,
                                             selectedTaskCreateFrequency: undefined,
                                             selectedTaskCreateTemplateID: undefined,
                                             selectedTaskCreateDays: undefined,
                                             selectedTaskCreateMonths: undefined,
                                             selectedTaskCreateMonthDays: undefined
                                          })}>
                                         {t("vazgecveKapat")}
                                       </Button>
                                    </Col>
                                 </Row>
                              </div>
                           ) : (
                              <div>
                                 <h5 className='dashboard-info-h5'>{t("sablonBulunamadı")}</h5>

                                 <Row>
                                    <Col>
                                       <Button
                                          className='float-right m-1'
                                          onClick={() => this.setState({
                                             showModal: false,
                                             selectedTaskCreateTime: undefined,
                                             selectedTaskCreateFrequency: undefined,
                                             selectedTaskCreateTemplateID: undefined,
                                             selectedTaskCreateDays: undefined,
                                             selectedTaskCreateMonths: undefined,
                                             selectedTaskCreateMonthDays: undefined
                                          })}>
                                         {t("vazgecveKapat")}
                                       </Button>
                                    </Col>
                                 </Row>
                              </div>
                           )
                     )
                  }
               </ModalBody>
            </Modal>
         </>
      );
   }
}

class TaskTemplateViewer extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         userList: []
      }
   }

   componentDidMount()
   {
      const storeData = this.props.templateJson;
      this.setState(storeData);
   }

   handleChange(key, value)
   {
      this.setState({[key]: value});
   }

   render() {
      const {
         selectedCompanyName,
         userList,
         selectedEmployeeName,
         selectedEmployeeJobName,
         selectedEmployeeDepartment,
         selectedEmployeeEmail,
         selectedDegreeOfUrency,
         selectedMainTaskID,
         isAcil,
         showExpectedEndDate,
         showEffect,
         effectList,
         mainTaskList,
         visibleWatcher,
         parentCategories,
         childCategory1Arr,
         childCategory2Arr,
         childCategory3Arr,
         childCategory4Arr,
         childCategory5Arr,
         selectedParentCategory
      } = this.state;
      const t = this.props.useTranslation;
      let event = this;
      let state = this.state;
       this.setState({ templateName: this.props.templateName })
      return (
         <>
            <Col xs="12" sm="12" lg={12} className='p-0'>
               <Card className="border-warning">
                  <CardBody>
                     <Row>
                        <Col lg="12">
                           <Row>
                              <Col lg={8}>
                                 {/*{BB.DxDropDownWithJSON('selectedEmployeeName', t('isim'), event, userList, true)}*/}
                                 {BB.DxTextBox('selectedEmployeeName', t('isim'), event, true)}
                              </Col>
                              <Col lg={4}>
                                 {BB.DxDropDownWithJSON('selectedMainTaskID', t('iştekrarıno'), event, mainTaskList, true)}
                              </Col>
                           </Row>

                           <Row style={{marginTop: -5, marginBottom: 15}}>
                              <Col>
                                 <a className="text-muted">
                                    İş Ünvanı
                                 </a> : {selectedEmployeeJobName} {'  |  '}
                                 <a className="text-muted">
                                    Departman
                                 </a> : {selectedEmployeeDepartment} {'  |  '}
                                 <a className="text-muted">
                                    E-posta
                                 </a> : {selectedEmployeeEmail}
                              </Col>
                           </Row>

                           <Row>
                              <Col lg={8}>
                                 <FormGroup onClick={() => this.setState({selectCategory: true})}>
                                    <Label>
                                       <strong>Kategori*</strong>
                                    </Label>
                                    <TextBox placeholder={'Lütfen seçiniz'} readOnly={true}
                                             value={this.state.selectedCategoryName}/>
                                 </FormGroup>
                              </Col>
                              <Col lg={4}>
                                 <Row>
                                    <Label style={{marginLeft: 15}}><strong>Aciliyet
                                       Derecesi</strong></Label>
                                 </Row>
                                 {
                                    selectedDegreeOfUrency === 0
                                       ? <Button
                                          style={{backgroundColor: "black", color: "white"}}>Düşük</Button>
                                       : selectedDegreeOfUrency === 1
                                          ? <Button style={{
                                             backgroundColor: "yellow",
                                             color: "black"
                                          }}>Orta</Button>
                                          : selectedDegreeOfUrency === 2
                                             ? <Button style={{
                                                backgroundColor: "red",
                                                color: "white"
                                             }}>Yüksek</Button>
                                             : null
                                 }
                              </Col>
                           </Row>

                           <Modal isOpen={this.state.selectCategory} style={{marginTop: 250}}>
                              <ModalHeader toggle={() => this.setState({
                                 selectCategory: false,
                              })}>
                                 Kategori Seçimi
                              </ModalHeader>
                              <ModalBody>
                                 <FormGroup>
                                    <Label>
                                       <strong>Ana Kategori</strong>
                                    </Label>
                                    <div style={{width: 300}}>
                                       <Dropdown
                                          disabled={true}
                                          placeholder='Lütfen Seçiniz'
                                          search
                                          defaultValue={selectedParentCategory}
                                          options={parentCategories}
                                          onChange={(e, a) => this.categoryRender('selectedParentCategory', a.value)}
                                       />
                                    </div>

                                 </FormGroup>

                                 {childCategory1Arr &&
                                 <FormGroup>
                                    <Label>
                                       <strong>Alt Kategori</strong>
                                    </Label>
                                    <br/>
                                    <Dropdown
                                       disabled={true}
                                       placeholder='Lütfen Seçiniz'
                                       search
                                       defaultValue={this.state.selectedChildCategory1}
                                       options={childCategory1Arr}
                                       onChange={(e, a) => this.categoryRender('selectedChildCategory1', a.value)}
                                    />
                                 </FormGroup>
                                 }

                                 {childCategory2Arr &&
                                 <FormGroup>
                                    <Label>
                                       <strong>Alt Kategori</strong>
                                    </Label>
                                    <br/>
                                    <Dropdown
                                       disabled={true}
                                       placeholder='Lütfen Seçiniz'
                                       search
                                       defaultValue={this.state.selectedChildCategory2}
                                       options={childCategory2Arr}
                                       onChange={(e, a) => this.categoryRender('selectedChildCategory2', a.value)}
                                    />
                                 </FormGroup>
                                 }

                                 {childCategory3Arr &&
                                 <FormGroup>
                                    <Label>
                                       <strong>Alt Kategori</strong>
                                    </Label>
                                    <br/>
                                    <Dropdown
                                       disabled={true}
                                       placeholder='Lütfen Seçiniz'
                                       search
                                       defaultValue={this.state.selectedChildCategory3}
                                       options={childCategory3Arr}
                                       onChange={(e, a) => this.categoryRender('selectedChildCategory3', a.value)}
                                    />
                                 </FormGroup>
                                 }

                                 {childCategory4Arr &&
                                 <FormGroup>
                                    <Label>
                                       <strong>Alt Kategori</strong>
                                    </Label>
                                    <br/>
                                    <Dropdown
                                       disabled={true}
                                       placeholder='Lütfen Seçiniz'
                                       search
                                       defaultValue={this.state.selectedChildCategory4}
                                       options={childCategory4Arr}
                                       onChange={(e, a) => this.categoryRender('selectedChildCategory4', a.value)}
                                    />
                                 </FormGroup>
                                 }

                                 {childCategory5Arr &&
                                 <FormGroup>
                                    <Label>
                                       <strong>Alt Kategori</strong>
                                    </Label>
                                    <br/>
                                    <Dropdown
                                       disabled={true}
                                       placeholder='Lütfen Seçiniz'
                                       search
                                       defaultValue={this.state.selectedChildCategory5}
                                       options={childCategory5Arr}
                                       onChange={(e, a) => this.categoryRender('selectedChildCategory5', a.value)}
                                    />
                                 </FormGroup>
                                 }

                              </ModalBody>
                           </Modal>


                           {showExpectedEndDate === true &&
                           <Row>
                              <Col></Col>
                              <Col></Col>
                              <Col>
                                 {BB.DateSelectWithLabel('selectedDueClosedDate', t('tahminikapanmatarihi'), event, t('lütfenseçiniz'), true)}
                              </Col>
                           </Row>
                           }

                           {/*Ek alanlar*/}
                           {/*<div dangerouslySetInnerHTML={{__html: customElements}}/>*/}

                           {/*Talep açıklaması*/}
                                  {/*BB.DxHtmlEditorViewer('selectedTaskDescription', t('açıklamazorunlu'), this.state.selectedTaskDescription)*/}
                               
                                  {BB.DxHtmlEditor2(
                                      "selectedTaskDescription",
                                      t("açıklamazorunlu"),
                                      this.props.state.selectedTaskDescription
                                  )}


                           {isAcil === true && BB.TextAreaWithLabel('selectedDegreeOfUrencyDescription', t('aciliyetaçıklaması'), t('aciliyethakkındabirşeyleryaz'), 1, event, '#fff', true)}

                           {showEffect && BB.DxDropDownWithJSON('selectedEffect', t('etki'), event, effectList, true)}

                           <Row>
                              <Col>
                                 {BB.MultipleFileWithLabelIsDisable('selectedTaskFiles', t('ekdosya'), event)}
                              </Col>
                              <Col>
                              </Col>
                              <Col>
                                 <div style={{float: 'right', marginRight: 30,}}
                                      onClick={() => this.setState({visibleWatcher: true})}
                                 >
                                    <Label style={{cursor: 'pointer'}}>
                                       <strong>
                                          <u>{this.state.selectedWatcherUserList ? 'İzleyiciler' + ' (' + this.state.selectedWatcherUserList.length + ')' : 'İzleyiciler' + ' (0)'}</u>
                                       </strong>
                                    </Label>
                                 </div>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </CardBody>
                  <CardFooter>
                     <div dangerouslySetInnerHTML={{__html: state.categoryConfirmations}}/>
                  </CardFooter>
               </Card>
            </Col>


            <Modal isOpen={visibleWatcher} centered={true} size={'l'}>
               <ModalHeader toggle={() => this.setState({visibleWatcher: false})}>
                  İzleyiciler
               </ModalHeader>
               <ModalBody>
                  <Col>
                     <List
                        keyExpr={"ID"}
                        displayExpr={"Name"}
                        dataSource={this.state.selectedWatcherUserList}
                        height={220}
                        allowItemDeleting={false}
                        itemDeleteMode={"static"}
                        showSelectionControls={true}
                     />
                  </Col>
               </ModalBody>
            </Modal>
         </>
      );
   }
}

function mapStateToProps(state)
{
   return {
      loginuser: state.loginReducer,
      myTaskCreateTemplates: state.myTaskCreateTemplatesReducer
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
         getMyTaskCreateTemplates: bindActionCreators(taskActions.getMyTaskCreateTemplates, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RecurringTaskCreate)))
