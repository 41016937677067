import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import * as BB from '../../../components/BBComponent'
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class Company extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         companyID: '',
         redirect: false,
         redirectUrl: ''
      }
      let { t } = this.props;
   }

   componentDidMount()
   {
      BB.FetchJSON('api/Yonetim/Companies/GetDataCompany').then(res =>
      {
         this.setState({gridData: res.Data});
      })
      //this.dataget();
   }
   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               color={'info'}
               outline
               className='mr-1'
               onClick={() =>
               {
                  this.setState({companyID: cellData.data.ID, redirect: true, redirectUrl: '/Yonetim/Departments'});
               }}>
               {t("departmanlar")}
            </Button>
            <Button
               color={'info'}
               outline
               className='mr-1'
               onClick={() =>
               {
                  this.setState({companyID: cellData.data.ID, redirect: true, redirectUrl: '/Yonetim/CompanyUser'});
               }}
            >{t("kullanicilar")}
            </Button>
            <Button
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({companyID: cellData.data.ID, redirect: true, redirectUrl: '/Yonetim/CompaniesEdit'});
               }}>
               {t("düzenle")}
            </Button>

         </div>
      );
   }
   render()
   {
      const {gridData, redirectUrl, companyID} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {CompanyID: companyID}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({
                              redirect: true,
                              redirectUrl: '/Yonetim/CompaniesCreateCompany'
                           })}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Kurum Listesi"/>

                           <Column
                              dataField="Name"
                              caption={t("firmaismi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={300}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         );
      }
   }
}

export default withTranslation()(Company);
