import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import ScrollView from 'devextreme-react/scroll-view';
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class PackageAuthority extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         treeData: [],
         rolePackageDatas: [],
         rolePackagesDatas: [],
         categoryRolePackageDatas: [],
         selectedRolePackageID: undefined,
         selectedRolePackageName: undefined,
         newRolePackage: undefined,
         selectedCategoryRolePackageID: undefined,
         newCategoryRolePackageID: undefined,
         editRolePackageRowVisible: false,
         addRolePackageRowVisible: false,
         addCategoryRolePackageRowVisible: false
      }
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let url = 'api/Yonetim/RolePackage/GetCategories';
      let urlRolePackage = 'api/Yonetim/RolePackage/GetRolePackage';
      let urlRolePackages = 'api/Yonetim/RolePackage/GetRolePackages';

      BB.FetchJSON(url).then(data =>
      {
         this.setState({treeData: data, isLoading: false})
      });

      BB.FetchJSON(urlRolePackage).then(data =>
      {
         this.setState({rolePackageDatas: data.Data, isLoading: false})
      });

      BB.FetchJSON(urlRolePackages).then(data =>
      {
         this.setState({rolePackagesDatas: data.Data, isLoading: false})
      });
   }

   actionRolePackage(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.setRolePackageRow(cellData)}>
               {t("düzenle")}
            </Button>
         </div>
      );
   }

   setRolePackageRow(cellData)
   {
      this.setState(
         {
            editRolePackageRowVisible: true,
            selectedRolePackageID: cellData.data.ID,
            selectedRolePackageName: cellData.data.Name,
         });
   }

   addNewRolePackage()
   {
      this.setState(
         {
            addRolePackageRowVisible: true
         });


      if (this.state.newRolePackage === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            Name: this.state.newRolePackage
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/RolePackage/AddRolePackage', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  newRolePackage: undefined,
                  selectedCategoryRolePackageID: undefined,
                  newCategoryRolePackageID: undefined,
                  editRolePackageRowVisible: false,
                  addRolePackageRowVisible: false,
                  addCategoryRolePackageRowVisible: false
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  newRolePackage: undefined,
                  selectedCategoryRolePackageID: undefined,
                  newCategoryRolePackageID: undefined,
                  editRolePackageRowVisible: false,
                  addRolePackageRowVisible: false,
                  addCategoryRolePackageRowVisible: false
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   setRolePackage()
   {
      if (this.state.selectedRolePackageName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.setState(
            {
               editRolePackageRowVisible: true
            });


         if (this.state.newRolePackage === '')
         {
            notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
         }
         else
         {
            this.props.actions.isLoading(true);

            let entity = {
               ID: this.state.selectedRolePackageID,
               Name: this.state.selectedRolePackageName
            }

            const formData = new FormData();
            formData.append('jsonText', JSON.stringify(entity));

            post('api/Yonetim/RolePackage/AddRolePackage', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.setState({
                     selectedRolePackageName: undefined,
                     newRolePackage: undefined,
                     selectedCategoryRolePackageID: undefined,
                     newCategoryRolePackageID: undefined,
                     editRolePackageRowVisible: false,
                     addRolePackageRowVisible: false,
                     addCategoryRolePackageRowVisible: false
                  }, this.getDatas());
                  this.props.actions.isLoading(false);
                  notify(t("islemBasarili"), "success", 1000);
               }
               else
               {
                  this.setState({
                     selectedRolePackageName: undefined,
                     newRolePackage: undefined,
                     selectedCategoryRolePackageID: undefined,
                     newCategoryRolePackageID: undefined,
                     editRolePackageRowVisible: false,
                     addRolePackageRowVisible: false,
                     addCategoryRolePackageRowVisible: false
                  }, this.getDatas());
                  this.props.actions.isLoading(false);
                  notify(t("islemBasarisiz") + res.Message, "warning", 1000);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      }
   }

   actionButtonToCategoryRolePackage(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.deleteCategoryRolePackage(cellData)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   addNewRoleCategoryRolePackage()
   {
      if (this.state.newCategoryRolePackageID === '' || this.state.selectedRolePackageID === '' ||
         this.state.newCategoryRolePackageID === undefined || this.state.selectedRolePackageID === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            CategoryID: this.state.selectedRolePackageID,
            RolePackageID: this.state.newCategoryRolePackageID
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/RolePackage/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  newRolePackage: undefined,
                  selectedCategoryRolePackageID: undefined,
                  newCategoryRolePackageID: undefined,
                  editRolePackageRowVisible: false,
                  addRolePackageRowVisible: false,
                  addCategoryRolePackageRowVisible: false
               }, this.getCategoryRolePackages());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  newRolePackage: undefined,
                  selectedCategoryRolePackageID: undefined,
                  newCategoryRolePackageID: undefined,
                  editRolePackageRowVisible: false,
                  addRolePackageRowVisible: false,
                  addCategoryRolePackageRowVisible: false
               }, this.getCategoryRolePackages());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   deleteCategoryRolePackage(cellData)
   {
      BB.askYesNo(t("Onaylıyormusunuz?"), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {

            this.state.selectedCategoryRolePackageID = cellData.data.ID;

            this.props.actions.isLoading(true);

            const formData = new FormData();

            formData.append('id', this.state.selectedCategoryRolePackageID);

            post('api/Yonetim/RolePackage/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {

                  this.setState({
                     selectedRolePackageName: undefined,
                     newRolePackage: undefined,
                     selectedCategoryRolePackageID: undefined,
                     newCategoryRolePackageID: undefined,
                     editRolePackageRowVisible: false,
                     addRolePackageRowVisible: false,
                     addCategoryRolePackageRowVisible: false
                  }, this.getCategoryRolePackages());
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     selectedRolePackageName: undefined,
                     newRolePackage: undefined,
                     selectedCategoryRolePackageID: undefined,
                     newCategoryRolePackageID: undefined,
                     editRolePackageRowVisible: false,
                     addRolePackageRowVisible: false,
                     addCategoryRolePackageRowVisible: false
                  }, this.getCategoryRolePackages());
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   getCategoryRolePackages()
   {
      let urlCategoryRolePackage = 'api/Yonetim/RolePackage/GetData?id=' + this.state.selectedRolePackageID;

      BB.FetchJSON(urlCategoryRolePackage).then(data =>
      {
         this.setState({categoryRolePackageDatas: data.Data, isLoading: false})
      });
   }

   handleChange(id, value)
   {
      if (id === "selectedRolePackageID")
      {

         this.setState({[id]: value});
         this.getCategoryRolePackages();
      }
      else
      {
         this.setState({[id]: value});
      }
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>
                     <Row>
                        <Col lg="4">
                           <Row>
                              <Col lg="12" style={{marginLeft: 10, height: '78vh'}}>
                                 <ScrollView>
                                    {BB.DxTreeViewToAll('selectedRolePackageID', '', this, this.state.treeData)}
                                 </ScrollView>
                              </Col>
                           </Row>
                        </Col>
                        <Col style={{marginRight: 24}}>
                           <Button
                              color={'success'}
                              outline
                              onClick={() => this.setState({
                                 addRolePackageRowVisible: true

                              })}
                              style={{float: 'left', position: 'absolute', zIndex: 1}}
                              type="button">{t("yenikategori")}</Button>
                           <DataGrid
                              id="gridContainer"
                              dataSource={this.state.rolePackageDatas}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <SearchPanel visible={true} width={100}/>
                              <Export enabled={true} fileName=""/>

                              <Column
                                 dataField="Name"
                                 caption={t("yetkikategorisi")}
                                 minWidth={100}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'UserName'}
                                 caption={t("ekleyenkullanici")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'CreateDate'}
                                 caption={t("eklemetarihi")}
                                 minWidth={80}/>
                              <Column
                                 dataField="İşlemler"
                                 caption={t("islemler")}
                                 width={200}
                                 cellRender={(cellData) => this.actionRolePackage(cellData)}
                              />
                           </DataGrid>
                           <Button
                              color={'success'}
                              outline
                              onClick={() => this.setState({
                                 addCategoryRolePackageRowVisible: true,
                                 newCategoryRolePackageID: ''
                              })}
                              style={{float: 'left', position: 'absolute', zIndex: 1}}
                              type="button">{t("yeniyetki")}</Button>
                           <DataGrid
                              id="gridContainer"
                              dataSource={this.state.categoryRolePackageDatas}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <SearchPanel visible={true} width={100}/>
                              <Export enabled={true} fileName=""/>

                              <Column
                                 dataField="RolePackageName"
                                 caption={t("kategori")}
                                 minWidth={100}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'CategoryName'}
                                 caption={t("yetkikategorisi")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField="İşlemler"
                                 caption={t("islemler")}
                                 width={200}
                                 cellRender={(cellData) => this.actionButtonToCategoryRolePackage(cellData)}
                              />
                           </DataGrid>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Col>

            {/*Yetki Kategorisi Ekleme, Popup*/}
            <Popup
               visible={this.state.addRolePackageRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("yetkikategorisiekle")}
               width={metrics.popupMinWidth}
               height={230}
            >
               <ScrollView width='100%' height='100%'>
                  <form>

                     {BB.InputWithLabel('newRolePackage',t("adizorunlu"), 'text', this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addRolePackageRowVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.addNewRolePackage()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </form>
               </ScrollView>

            </Popup>

            {/*Yetkisi Kategori Düzenleme, Popup*/}
            <Popup
               visible={this.state.editRolePackageRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("kategoriduzenleme")}
               width={metrics.popupMinWidth}
               height={230}
            >
               <ScrollView width='100%' height='100%'>
                  <form>

                     {BB.InputWithLabel('selectedRolePackageName',t("adizorunlu"), 'text', this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({editRolePackageRowVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.setRolePackage()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </form>
               </ScrollView>
            </Popup>

            {/*Yetki Ekleme, Popup*/}
            <Popup
               visible={this.state.addCategoryRolePackageRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("yetkiekle")}
               width={metrics.popupMinWidth}
               height={230}
            >
               <ScrollView width='100%' height='100%'>
                  <form>

                     {BB.DxDropDownWithJSON('newCategoryRolePackageID', t("yetkikategorisi"), this, this.state.rolePackagesDatas)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addCategoryRolePackageRowVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.addNewRoleCategoryRolePackage()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </form>
               </ScrollView>

            </Popup>

         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(PackageAuthority)
);

