import React from "react";
import {ReactComponent as EmptyIcon} from '../../../assets/img/brand/empty.svg';
import './styles.scss';

const Empty=({title})=>{
    return(
        <div className='dashboard-info'>
            <EmptyIcon/>
            <h6 className='dashboard-info-h5'>{title}</h6>
        </div>
    )
}
export default Empty;
