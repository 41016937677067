import React from "react";
import {FormGroup, Label, Modal, ModalBody, ModalHeader,Button,Input,InputGroup,ModalFooter, ListGroup, ListGroupItem} from "reactstrap";
import TextBox from "devextreme-react/text-box";
import {Dropdown} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as BB from "../../BBComponent";


const getFilterCategories = (UserID, catTerm) => new Promise((resolve) =>
{
  
   let url = UserID !== null && '/api/Task/GetPoolCategoriesTree?UserID=' + UserID  +'&keywords=' + catTerm;

   BB.FetchJSON(url).then(data =>
   {
      resolve(data)
      
   });
   
});



const CategoryFilter=({state,event, edit,t})=> {

 


    const categoryFilter=(e) => {

      
        const {categoryTerm}=event.state;
        const catTerm=categoryTerm.toLowerCase();

        
    
        if(catTerm.length>2) {
         getFilterCategories(event.state.selectedEmployee, catTerm).then(data=>
            {
               event.setState({filteredCategory:data})
            })
        }
       
     }

     
    const setModalOpen=() =>{
        event.setState({
          modalOpen: !event.state.modalOpen, filteredCategory:[]
        });
      }

      const onChangeCategoryInput=(e)=> {
      
    
        event.setState({categoryTerm: e.target.value}, categoryFilter);
          
            
      
     }

   

      

      
    const onClickFilter=(filter)=> {

      let arr=[...event.state.selectedCategory,filter.value]
      if(event.state.selectedCategory.includes(filter.value)){
         arr=arr.filter(x=> x!==filter.value)
      }
      event.setState({selectedCategoryName:filter.text, selectedCategory:arr})
      //event.handleChange('selectedCategory', filter.value)

    }
      
    return (
        <>
             
                            
                            <Button
                              style={{marginLeft: 20, marginRight: 20}}
                              color="primary"
                              type="button"
                              onClick={() => setModalOpen()}
                            >
                              {t("kategoriEkle")}
                            </Button>
                            <Modal
                              toggle={() => setModalOpen()}
                              isOpen={state.modalOpen}
                              style={{marginTop:'250px'}}
                              autoFocus={false}
                            >
                               <div className=" modal-header">
                                <h5
                                  className=" modal-title"
                                  id="exampleModalLabel"
                                >
                                  {t("kategoriArama")}
                                </h5>
                                <button
                                  aria-label="Close"
                                  className=" close"
                                  type="button"
                                  onClick={() => setModalOpen()}
                                >
                                  <span aria-hidden={true}>×</span>
                                </button>
                              </div>
                              <ModalBody>
                                <InputGroup>
                                  <Input className="border border-dark shadow-sm" style={{border:'5px'}} type="text" autoFocus={true} name={state.categoryTerm} onChange={onChangeCategoryInput} placeholder={t("aradiginizKategoriyiYaziniz")}/>
                                 
                                </InputGroup>
                                
                                <ListGroup style={{overflowY:'scroll', height:'200px'}}>

                                   {
                                     event.state.filteredCategory.map(filter=>(
                                      <ListGroupItem><div><Input onChange={()=>onClickFilter(filter)} key={filter.key} type="checkbox" className="mr-1"/></div><div className="ml-2">{filter.text}</div></ListGroupItem>
                                     ))
                                   }
                                   
                                   
                                   
                                  </ListGroup>
                                  </ModalBody>
                              <ModalFooter>
                              <Button color="primary" type="button" onClick={()=>{setModalOpen() ; event.edit()}}>
                                  {t("sec")}
                                </Button>
                              </ModalFooter>
                            </Modal>
                             

                              
        </>
    )
}

function mapStateToProps(state)
{
   return {
      filterCategories: state.filterCategoryReducer
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         getFilterCategories: bindActionCreators(categoryActions.getFilterCategories, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CategoryFilter)))
