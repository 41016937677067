import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import ScrollView from 'devextreme-react/scroll-view';
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class MenuManagement extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         treeData: [],
         authorityGroupData: [],
         categoryRoleMenuDatas: [],
         selectedRoleMenuID: undefined,
         selectedRolePackageName: undefined,
         selectedCategoryRoleMenuID: undefined,
         newCategoryRoleMenuID: undefined,
         addVisible: false
      }
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let url = '/api/Yonetim/RoleMenu/GetDataMenu';
      BB.FetchJSON(url).then(data =>
      {
         this.setState({treeData: data.Data, authorityGroupData: data.ViewBag.Roles, isLoading: false})
      });
   }

   actionButton(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.deleteCategoryRoleMenu(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   add()
   {
      if (this.state.newCategoryRoleMenuID === '' || this.state.selectedRoleMenuID === '' ||
         this.state.newCategoryRoleMenuID === undefined || this.state.selectedRoleMenuID === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            RoleID: this.state.newCategoryRoleMenuID,
            MenuID: this.state.selectedRoleMenuID
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/RoleMenu/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  selectedCategoryRoleMenuID: undefined,
                  newCategoryRoleMenuID: undefined,
                  addVisible: false
               }, this.getCategoryRoleMenus());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  selectedRolePackageName: undefined,
                  selectedCategoryRoleMenuID: undefined,
                  newCategoryRoleMenuID: undefined,
                  addVisible: false
               }, this.getCategoryRoleMenus());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   deleteCategoryRoleMenu(ID)
   {
      BB.askYesNo(t("onaylıyorMusunuz?"), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.props.actions.isLoading(true);
            const formData = new FormData();

            formData.append('id', ID);

            post('api/Yonetim/RoleMenu/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.setState({
                     selectedRolePackageName: undefined,
                     newCategoryRoleMenuID: undefined,
                     addVisible: false
                  }, this.getCategoryRoleMenus());
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     selectedRolePackageName: undefined,
                     newCategoryRoleMenuID: undefined,
                     addVisible: false
                  }, this.getCategoryRoleMenus());
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   getCategoryRoleMenus()
   {
      let URL = 'api/Yonetim/RoleMenu/GetData?ID=' + this.state.selectedRoleMenuID;
      BB.FetchJSON(URL).then(data =>
      {
         this.setState({categoryRoleMenuDatas: data.Data, isLoading: false})
      });
   }

   handleChange(id, value)
   {
      if (id === "selectedRoleMenuID")
      {
         this.setState({[id]: value});
         this.getCategoryRoleMenus();
      }
      else
      {
         this.setState({[id]: value});
      }
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>
                     <Row>
                        <Col lg="4">
                           <Row>
                              <Col lg="12" style={{marginLeft: 0, height: '78vh', overflowY: 'hidden'}}>
                                 <ScrollView width='100%' height='100%'>
                                    {BB.DxTreeViewToAll('selectedRoleMenuID', '', this, this.state.treeData)}
                                 </ScrollView>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg="8" style={{paddingLeft: 20, paddingRight: 30}}>
                           <Button
                              color={'success'}
                              outline
                              onClick={() => this.setState({
                                 addVisible: true,
                                 newCategoryRoleMenuID: ''
                              })}
                              style={{float: 'left', position: 'absolute', zIndex: 1}}
                              type="button">{t("yeni")}</Button>
                           <DataGrid
                              id="gridContainer"
                              dataSource={this.state.categoryRoleMenuDatas}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <SearchPanel visible={true} width={100}/>
                              <Export enabled={true} fileName=""/>

                              <Column
                                 dataField={'CategoryName'}
                                 caption={t("menu")}
                                 minWidth={100}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'RoleName'}
                                 caption={t("yetkigrubu")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField="İşlemler"
                                 caption={t("islemler")}
                                 width={200}
                                 cellRender={(cellData) => this.actionButton(cellData)}
                              />
                           </DataGrid>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Col>
            <Popup
               visible={this.state.addVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("yetkiekle")}
               width={metrics.popupMinWidth}
               height={230}
            >
               <ScrollView>
                  {BB.DxDropDownWithJSON('newCategoryRoleMenuID', t("yetkigrubu"), this, this.state.authorityGroupData)}
                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.add()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </ScrollView>
            </Popup>
         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(MenuManagement)
);

