import React from "react";
import cs from 'classnames'
import './styles.scss'

const CustomCard = ({children, cardStyle, bodyStyle, isTaskDetail}) =>
{
   return (
      <div
         className={cs('card', 'custom-card', 'custom-card-height', cardStyle, bodyStyle && ('h-100'))}
           style={{marginTop: isTaskDetail === true ? -10 : 0,}}
      >
         <div className={cs('card-body', bodyStyle)}

         >
            {children}
         </div>
      </div>
   )
}
export default CustomCard;
