import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {textarea} from "reactstrap";

const TaskViewDescription = ({t, event}) =>
{
    return (
        <Modal
            style={{zIndex: 1}}
            centered={true}
            isOpen={event.state.visibleDescription}
        >
            <ModalHeader toggle={() => event.setState({visibleDescription: false})}>Açıklama</ModalHeader>
            <ModalBody>
                        <textarea
                            ref={(textarea) => event.textArea = textarea}
                            defaultValue={event.state.selectedDescription}
                            rows={15}
                            readOnly={true}
                            className="form-control"
                        />
            </ModalBody>

        </Modal>
    )
}

export default TaskViewDescription;
