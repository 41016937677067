import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row} from "reactstrap";
import notify from "devextreme/ui/notify";
import { callAPI, callApiGet } from "../../../../../components/Axios";


const changeDegreeOfUrency = (event) =>
{
   event.setState({visibleAciliyet: false});

   let url = '/api/TaskDetail/UpdateDegreeOfUrgency?TaskID=' + event.state.taskID + '&DegreeOfUrgency=' + event.state.selectedDegreeOfUrency + '&Description=' + event.state.selectedDegreeOfUrencyDesc;




   //var url = "/api/User/GetUserList?CompanyID=" + value;
    callAPI(
      callApiGet,
      url,
      {},
       (res) => {
        if (res?.data) {
        
         if (res.data.Status)
         {
            event.setState({selectedDegreeOfUrency: 0, selectedDegreeOfUrencyDesc: ''})
            notify(event.languages.talebinAciliyetiBasariylaDegistirildi, "success", 2000);
         }
         
         else
         {
            notify(res.data.Message, "error", 2000);
         }
         event.getData();
        }
   },
      true
 );


}

const TaskChangeUrgencyModal = ({t, event}) =>
{
   return (
      <Modal
         centered={true}
         isOpen={event.state.visibleAciliyet}
      >
         <ModalHeader>
            {t('aciliyetdeğiştir')}
         </ModalHeader>

         <ModalBody>
            {BB.DxDropDownWithURL('selectedDegreeOfUrency', t('aciliyetDurumunuSeciniz'), event, 'api/TaskDetail/GetDegreeOfUrgencies', t('lütfenseçiniz'))}
            {BB.TextAreaWithLabel('selectedDegreeOfUrencyDesc', t('açıklama'), '', 5, event)}
            <Row style={{float: 'right'}}>
               <Button
                  color={'info'} onClick={() => event.setState({visibleAciliyet: false})}>
                  {t('vazgec')}
               </Button>
               <Button
                  color={'success'}
                  style={{marginLeft: 20, marginRight: 15}}
                  onClick={() => changeDegreeOfUrency(event)}>
                  {t('degistir')}
               </Button>
            </Row>
         </ModalBody>

      </Modal>
   )
}

export default TaskChangeUrgencyModal;
