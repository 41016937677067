import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import { AuthProvider } from "oidc-react";
import { useAuth } from "oidc-react";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import configureStore from "./redux/reducers/configureStore";
const oidcConfig = {
  onSignIn: async (user) => {
    //alert('You just signed in, congratz! Check out the console!');
    window.location.hash = "";
  },
  authority: "https://test.turkuazgo.com/im",
  clientId:
    "tys",
  responseType: "id_token token",
  redirectUri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5001/"
      : "https://cobraz.github.io/example-oidc-react",
};
if (process.env.NODE_ENV !== "development") console.log = () => {};

function saveToLocalStorage(state) {
  // if(JSON.parse(localStorage.getItem('storageState')) !== true) {
  //    localStorage.clear()
  // }
  // try
  // {
  //    const serializedState = JSON.stringify(state);
  //    localStorage.setItem('state', serializedState);
  // }
  // catch (e)
  // {
  //    // console.log(e);
  // }
}


const store = configureStore();

store.subscribe(() => saveToLocalStorage(store.getState()));



ReactDOM.render(
    <Provider store={store}>
     <App />

    </Provider>
    , document.getElementById('root')
);
//serviceWorker.register();
