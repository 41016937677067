import * as actionTypes from './actionTypes'

export function setMainCompany(User)
{
   return {type: actionTypes.SET_MAIN_COMPANIES, payload: User}
}

export function getMainCompanyDetail()
{
   return function (dispatch)
   {
      let url = 'api/Login/GetMainCompanies';

      return fetch(url)
      .then(res => res.json())
      .then(result =>
      {
         dispatch(setMainCompany(result))
      });
   }
}
