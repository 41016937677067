import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row} from "reactstrap";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Popup} from "devextreme-react/popup";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import {post} from "axios";
import TreeView from "devextreme-react/tree-view";
import DropDownBox from 'devextreme-react/drop-down-box';
import {Redirect} from 'react-router-dom';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class SurveyEdit extends Component
{

   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         editRowVisible: false,
         addRowVisible: false,
         selectedID: '',
         selectedName: '',
         selectedCompany: '',
         selectedCategory: [],
         selectedIsActive: true,
         selectedOptionLength: 0,
         surveyID: this.props.location.state ? this.props.location.state.SurveyID : null,

      }
      this.treeView = null;
      this.treeDataSource = BB.makeAsyncDataSource('/api/Yonetim/Survey/GetCategory')
   }

   componentDidMount()
   {
      if (this.state.surveyID != null)
      {
         this.getSurvey();
         this.getQuestion();
      }

   }

   handleChange(id, value)
   {
      this.setState({[id]: value});

      if (id === 'selectedIsActive')
      {
         this.setState({selectedIsActive: !this.state.selectedIsActive})
      }
   }

   add()
   {
      if ((this.state.selectedName === undefined || this.state.selectedName === '') && (this.state.selectedOption1 === undefined || this.state.selectedOption1 === '') && (this.state.selectedOption2 === undefined || this.state.selectedOption2 === '') && (this.state.selectedOption2 === undefined || this.state.selectedOption2 === '') && (this.state.selectedOption3 === undefined || this.state.selectedOption3 === '') && (this.state.selectedOption4 === undefined || this.state.selectedOption4 === ''))
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            SurveyID: this.state.selectedID,
            Question: this.state.selectedQuestion,
            Option1: this.state.selectedOption1,
            Option2: this.state.selectedOption2,
            Option3: this.state.selectedOption3,
            Option4: this.state.selectedOption4
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/Survey/AddQuestion', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               }, this.getQuestion());
               this.props.actions.isLoading(false);
               notify(t("soruolusturuldu"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               });
               notify(t("soruolusturmadahata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   edit(ID)
   {
      if ((this.state.selectedName === undefined || this.state.selectedName === '') && (this.state.selectedOption1 === undefined || this.state.selectedOption1 === '') && (this.state.selectedOption2 === undefined || this.state.selectedOption2 === '') && (this.state.selectedOption2 === undefined || this.state.selectedOption2 === '') && (this.state.selectedOption3 === undefined || this.state.selectedOption3 === '') && (this.state.selectedOption4 === undefined || this.state.selectedOption4 === ''))
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ID: this.state.selectedID,
            Question: this.state.selectedQuestion,
            Option1: this.state.selectedOption1,
            Option2: this.state.selectedOption2,
            Option3: this.state.selectedOption3,
            Option4: this.state.selectedOption4
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', entity.ID);

         post('api/Yonetim/Survey/EditQuestion', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               }, this.getQuestion());
               this.props.actions.isLoading(false);
               notify(t("soruduzenlendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               });
               notify(t("soruduzenlemedehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   delete(ID)
   {
      this.props.actions.isLoading(true);

      fetch('api/Yonetim/Survey/DeleteQuestion?ID=' + ID, {method: 'GET'})
      .then((response) => response.json())
      .then((res) =>
      {
         if (res.MessageStatus === true)
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''}, this.getQuestion());
            this.props.actions.isLoading(false);
            notify(t("sorubasariylasilindi"), "success", 1000);
         }
         else
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
            notify(t("sorusilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   getQuestion()
   {
      BB.FetchJSON('api/Yonetim/Survey/GetQuestion?ID=' + this.state.surveyID).then(data =>
      {
         this.setState({gridData: data.Data})
         this.props.actions.isLoading(false);
      });
   }

   getSurvey()
   {
      BB.FetchJSON('api/Yonetim/Survey/GetSurvey?ID=' + this.state.surveyID).then(data =>
      {
         if(data) {
            this.setState({
               selectedID: data.Data[0].ID,
               selectedName: data.Data[0].Name,
               selectedSurveyQuestionCount: data.Data[0].SurveyQuestionCount,
               selectedCompany: data.Data[0].CompanyID === '' ? "Tümü" : data.Data[0].CompanyID,
               selectedCategory: data.Data[0].Category === '' ? "Tümü" : data.Data[0].Category,
               selectedIsActive: data.Data[0].IsActive
            })
         }
         this.props.actions.isLoading(false);
      });
   }

   editSurvey()
   {
      if ((this.state.selectedName === undefined || this.state.selectedName === '') || (this.state.selectedCompany === undefined || this.state.selectedCompany === '') || (this.state.selectedCategory === undefined || this.state.selectedCategory === ''))
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            Name: this.state.selectedName,
            CompanyID: this.state.selectedCompany,
            CategoryID: this.state.selectedCategory,
            IsActive: this.state.selectedIsActive,
            EditID: this.state.selectedID,
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', entity.EditID);
         formData.append('CategoryIDs', entity.CategoryID);

         post('api/Yonetim/Survey/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               }, this.getSurvey());
               this.props.actions.isLoading(false);
               notify(t("anketguncellendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               });
               notify(t("anketguncellemedehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   renderMultipleDropdown()
   {
      return (
         <FormGroup>
            <Label>
               <strong>{t("kategori")}</strong>
            </Label>
            <DropDownBox
               value={this.state.selectedCategory}
               valueExpr="ID"
               displayExpr="Name"
               placeholder={t("lutfenSeciniz")}
               showClearButton={true}
               dataSource={this.treeDataSource}
               onValueChanged={(e) =>
               {
                  let treeView = (e.component.selectItem && e.component) || (this.treeView && this.treeView.instance);

                  if (treeView)
                  {
                     if (e.value === null)
                     {
                        treeView.unselectAll();
                     }
                     else
                     {
                        let values = e.value || this.state.treeBoxValue;

                        values && values.forEach(function (value)
                        {
                           treeView.selectItem(value);
                        });
                     }
                  }

                  if (e.value !== undefined)
                  {
                     this.setState({
                        selectedCategory: e.value
                     });
                  }
               }}
               contentRender={(e) =>
               {
                  return (
                     <TreeView
                        dataSource={this.treeDataSource}
                        ref={(ref) => this.treeView = ref}
                        dataStructure="plain"
                        keyExpr="ID"
                        parentIdExpr="ParentID"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        selectNodesRecursive={true}
                        displayExpr="Name"
                        selectByClick={true}
                        onContentReady={this.syncTreeViewSelection}
                        onItemSelectionChanged={e1 =>
                        {
                           this.setState({selectedCategory: e1.component.getSelectedNodeKeys()});

                        }}
                     />
                  );
               }}
            />
         </FormGroup>
      );
   }

   actionButton = (cellData) =>
   {

      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({
                     editRowVisible: true,
                     selectedID: cellData.data.ID,
                     selectedQuestion: cellData.data.Question,
                     selectedOption1: cellData.data.Option1,
                     selectedOption2: cellData.data.Option2,
                     selectedOption3: cellData.data.Option3,
                     selectedOption4: cellData.data.Option4
                  });
               }}>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('Onaylıyormusunuz?'), t("sectiginizbaglantisilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      if (this.state.surveyID === null)
      {
         return <Redirect to="/Yonetim/Survey"/>
      }
      return (
         <div className="animated fadeIn">
            <Row>
               <Col>
                  <Card>
                     <CardHeader>{t("anketdetaybilgileri")}</CardHeader>
                     <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                           <Row>
                              <Col lg='6'> {this.renderMultipleDropdown()}</Col>
                              <Col
                                 lg='6'> {BB.DxDropDownWithURL('selectedCompany', t("kurum"), this, '/api/Yonetim/Survey/GetCompany', t("kurum"))}</Col>
                           </Row>
                           <Row>
                              <Col
                                 lg='6'> {BB.InputWithLabel('selectedName', t("anketAdi"), 'text', this, true, t("anketAdi"))}</Col>
                              <Col lg='6'> {BB.CheckBoxWithLabel('selectedIsActive', t("aktif"), this)}</Col>
                           </Row>
                           <Row>
                              <Col lg='6'> </Col>
                              <Col lg='6'>
                                 <Button
                                    onClick={() => this.editSurvey()}
                                    color={'primary'}
                                    style={{marginLeft: 20, marginRight: 0, float: 'right'}}>
                                    {t("kaydet")}
                                 </Button>
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>

                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({
                              addRowVisible: true,
                              selectedQuestion: '',
                              selectedOption1: '',
                              selectedOption2: '',
                              selectedOption3: '',
                              selectedOption4: ''
                           })}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Anket Listesi"/>
                           <Column
                              dataField={'ID'}
                              visible={false}/>
                           <Column
                              dataField={'Question'}
                              caption={t("soru")}
                              minWidth={120}
                              allowFiltering={true}/>
                           <Column
                              dataField={'Option1'}
                              caption={t("sikbir")}
                              minWidth={80}
                              allowFiltering={true}/>
                           <Column
                              dataField="Option2"
                              caption={t("sikiki")}
                              minWidth={80}
                              allowFiltering={true}/>
                           <Column
                              dataField={'Option3'}
                              caption={t("sikuc")}
                              minWidth={80}/>

                           <Column
                              dataField={'Option4'}
                              caption={t("sikdort")}
                              minWidth={80}
                              allowFiltering={true}/>
                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={250}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>

                  {/*Yeni Satır Ekleme Popup*/}
                  <Popup
                     visible={this.state.addRowVisible}
                     dragEnabled={false}
                     showTitle={true}
                     showCloseButton={false}
                     title={t("yenisorutanimi")}
                     width={400}
                     height={550}
                  >
                     <form>
                        {BB.InputWithLabel('selectedQuestion', t("soru"), 'text', this, true, "Soru")}
                        {BB.InputWithLabel('selectedOption1', t("sikbir"), 'text', this, true, t("sikbir"))}
                        {BB.InputWithLabel('selectedOption2', t("sikiki"), 'text', this, true, t("sikiki"))}
                        {BB.InputWithLabel('selectedOption3', t("sikuc"), 'text', this, true, t("sikuc"))}
                        {BB.InputWithLabel('selectedOption4', t("sikdort"), 'text', this, true, t("sikdort"))}

                        <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                           <Button
                              color={'info'}
                              onClick={() => this.setState({
                                 addRowVisible: false,
                              })}>
                              {t("vazgec")}
                           </Button>
                           <Button
                              onClick={() => this.add()}
                              color={'success'}
                              style={{marginLeft: 20, marginRight: 0}}>
                              {t("olustur")}
                           </Button>
                        </Row>
                     </form>
                  </Popup>

                  {/*Düzenle Popup*/}
                  <Popup
                     visible={this.state.editRowVisible}
                     dragEnabled={false}
                     showTitle={true}
                     showCloseButton={false}
                     title={t("soruduzenle")}
                     width={400}
                     height={550}
                  >

                        {BB.InputWithLabel('selectedQuestion', t("soru"), 'text', this, true, "Soru")}
                        {BB.InputWithLabel('selectedOption1', t("sikbir"), 'text', this, true, t("sikbir"))}
                        {BB.InputWithLabel('selectedOption2', t("sikiki"), 'text', this, true, t("sikiki"))}
                        {BB.InputWithLabel('selectedOption3', t("sikuc"), 'text', this, true, t("sikuc"))}
                        {BB.InputWithLabel('selectedOption4', t("sikdort"), 'text', this, true, t("sikdort"))}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                        <Button
                           color={'info'}
                           onClick={() => this.setState({
                              editRowVisible: false,
                           })}>
                           {t("kapat")}
                        </Button>

                        <Button
                           onClick={() => this.edit()}
                           color={'primary'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </Popup>
               </Col>
            </Row>
         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SurveyEdit));
