import React from "react";
import './styles.scss'
import {Badge, CustomCard, Empty, LabelGroup} from "../index";
import {Link} from "react-router-dom";
import * as BB from '../../BBComponent';

const {htmlToText} = require('html-to-text');

const TableTaskAssigned = ({ title, description, data = [], handleDetail, handleDelete, t }) =>
{
   return (
       <CustomCard cardStyle='mg-b-20'>
           <LabelGroup
               title={title}
               description={description}
           />
           {
               data.length > 0 ? (
                   <div className="table-responsive tasks" style={{ height: 350, overflowY: 'scroll' }}>
                       <table className="table card-table table-vcenter text-nowrap mb-0  border">
                           <thead>
                               <tr>
                                   <th className="wd-lg-10p text-center hidden-mobile">{t("talepno")}</th>
                                   {window.innerWidth >= 768 ? (
                                       <>
                                           <th className="wd-lg-20p">{t("durum")}</th>
                                           <th className="wd-lg-12p">{t("kategori")}</th>
                                           <th className="wd-lg-15p">{t("GörevAtayanKullanıcı")}</th>
                                           <th className="wd-lg-40p">{t("GörevAçıklaması")}</th>
                                       </>
                                   ) : null}
                                   
                               </tr>
                           </thead>
                           <tbody>
                               {
                                   data.map((item, inx) => {
                                       let a = htmlToText(item.Description).replaceAll('//', '/');
                                       let str = a.length > 0 ? a.split('[')[0] : '';
                                       let end = a.length > 0 ? a.split(']').length > 0 ? a.split(']')[1] : '' : '';
                                       return (
                                           <tr key={inx}>
                                               <td className="text-center">
                                                   <Link to={'/TaskDetail?' + item.TaskID}>
                                                       {item.TaskNumber}
                                                   </Link>
                                               </td>
                                               {window.innerWidth >= 768 ? (
                                                   <>
                                                       <td>
                                                           <Badge title={item.StatusName} status={item.StatusName} />
                                                       </td>
                                                       <td className="font-weight-semibold d-flex">
                                                           <span className="mt-1">{item.CategoryName}</span>
                                                       </td>
                                                       <td className="text-nowrap">
                                                           <span className="mt-1">{item.CreateUserName}</span>
                                                       </td>
                                                      

                                                       <td
                                                           className={`${item.DegreeOfUrgency === 0 ? 'text-primary' : (item.DegreeOfUrgency === 1 ? 'text-warning' : 'text-danger')}`}>
                                                           {str.toString().substr(0, 80)}
                                                       </td>
                                                   </>
                                               ) : null}
                                           </tr>
                                       )
                                   })
                               }
                           </tbody>
                       </table>
                   </div>
               ) : (
                   <Empty title={t("Herhangibirveriyok")} />
               )
           }
       </CustomCard>
   )
}

export default TableTaskAssigned;
