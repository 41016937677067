import React from "react";
import {CustomCard, Empty, LabelGroup} from "../index";
import './styles.scss'
import * as BB from "../../BBComponent";
import notify from "devextreme/ui/notify";
import USER from "../../../assets/user.png";

const TableTask = ({
                      title,
                      description,
                      data,
                      buttonVisible,
                      selectData,
                      handleClick,
                      type,
                      event,
                      handleChange,
                      props,
                      handleDetail,
                      base64Img,
                      taskID,
                      minHeight = false,
                      noDataText
                   }) =>
{
   return (
      <CustomCard cardStyle='card-dashboard-calendar pb-0' bodyStyle={minHeight ? 'card-height' : ''}>
         <LabelGroup title={title} description={description} buttonVisible={buttonVisible}
                     handleClick={handleClick}/>
         {
            data.length > 0 ? (
               <div className='table-task-div'>
                  <table className="table  m-b-0 transcations mt-2">
                     <tbody>
                     {
                                   data.map((item, inx) => (
                           <tr key={inx}>
                              <td className="wd-5p">
                                 <div className="main-img-user avatar-md">
                                    {/*<img alt="avatar" className="rounded-circle mr-3" src='https://www.sonax.com.tr/wp-core/assets/storefront/images/placeholder-image.jpg'/>*/}
                                    <img alt="avatar" className="rounded-circle mr-3"
                                         src={item.Photo ? item.Photo !== undefined ? "data:image/png;base64, " + item.Photo : USER : USER}/>
                                 </div>
                              </td>
                              <td>
                                 <div className="d-flex align-middle ml-3">
                                    <div className="d-inline-block">
                                       {!item.SourceUser ?
                                          <h6 className="mb-1">{item.ResponsibleUser}</h6>
                                          :
                                          <h6 className="mb-1"> Görevi oluşturan : {item.SourceUser}</h6>
                                       }
                                       {item.SourceUser ?
                                          <p className="mb-0 tx-11 text-muted">
                                             {new Date(item.StartDate).toLocaleDateString()} - {new Date(item.EndDate).toLocaleDateString()}
                                          </p>
                                          :
                                          null
                                       }
                                          <p className="mb-0 tx-13 text-muted">({item.TaskCreateTemplateName}) - Açıklama :  {item.Description}</p>

                                    </div>
                                 </div>
                              </td>
                              <td className="text-right">
                                 <div className="d-inline-block">
                                    {
                                       type === 'button' ? (
                                          <button className='btn btn-danger taskButton mb-1' onClick={
                                             () => BB.askYesNo('Zamanlanmış Görev İptali', 'İptal etmek istediğinize eminmisiniz?', 'Evet', 'Hayır').then((res) =>
                                             {
                                                if (res === true)
                                                {
                                                   BB.FetchJSON('api/Task/DeleteRecurringTask?JobID=' + item.JobID).then(result =>
                                                   {
                                                      if (result === true)
                                                      {
                                                         event.GetMyPeriodicTask();
                                                         notify('Zamanlanmış Görev Başarıyla Silinmiştir.', 'success', 2000);
                                                      }
                                                   })
                                                }
                                             })
                                          }>
                                             İptal Et
                                          </button>
                                       ) : (
                                          <div className='d-flex align-items-center'>

                                             {!taskID &&

                                             <button
                                                className='ml-1 btn btn-success taskButton mb-1'
                                                style={{backgroundColor: '#2FC19A'}}
                                                onClick={() =>
                                                {
                                                   props.redirectToTask(item.TaskID);
                                                }}
                                             >
                                                Talebe Git
                                             </button>

                                             }

                                             <button
                                                className='ml-2 btn btn-danger taskButton mb-1 mr-2'
                                                onClick={() => handleDetail(item.ID)}
                                             >
                                                Hareketler
                                             </button>
                                          </div>
                                       )
                                    }

                                 </div>
                              </td>
                           </tr>

                        ))
                     }
                     </tbody>
                  </table>
               </div>
            ) : (
               <Empty title={noDataText}/>
            )
         }
      </CustomCard>
   )
}

export default TableTask;

{/**/
}
