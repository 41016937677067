import * as actionTypes from '../actions/actionTypes'
import * as models from '../../models/LoginUserModel'

export default function loginReducer(state = models.LoginUser, action)
{
   switch (action.type)
   {
      case actionTypes.SET_LOGIN_USER:
         return action.payload
      default:
         return state
   }
}
