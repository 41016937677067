import * as actionTypes from '../actions/actionTypes'

export default function getWatcherTasksReducer(state = {}, action)
{
    switch (action.type)
    {
        case actionTypes.GET_WATCHER_TASKS:
            return action.payload
        default:
            return state
    }
}
