import React, { Component } from "react";
import { connect } from "react-redux";
import * as BB from "../../../components/BBComponent";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import * as mainActions from "../../../redux/actions/mainActions";
import { Switch } from "devextreme-react/switch";
import { CustomCard, LabelGroup, SliderItem } from "../../../components/custom";

import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import Modal from "reactstrap/es/Modal";
import Jobs from "../../../components/user/main/wigdet/Jobs";
import RecurringTaskCreate from "../../../components/user/main/wigdet/RecurringTaskCreate";
import LastCreatedTasks from "../../../components/user/main/wigdet/LastCreatedTasks";
import TaskAssignedRequests from "../../../components/user/main/wigdet/TaskAssignedRequests";
import Notice from "../../../components/user/main/wigdet/Notice";
import notify from "devextreme/ui/notify";

import Task from "../../../assets/task.png";
import Task1 from "../../../assets/tasks-1.png";
import Task2 from "../../../assets/task2.png";
import Task3 from "../../../assets/friend-request.png";
import MainWelcomeWidget from "../../../components/custom/MainWelcomeWidget/MainWelcomeWidget";
import FastTaskCreate from "../../../components/user/main/wigdet/FastTaskCreate";
import * as taskActions from "../../../redux/actions/taskActions";
import { getWatcherTasks } from "../../../redux/actions/getWatcherTasksActions";
import { addMyTaskCount } from "../../../redux/actions/mainActions";
import jwt_decode from "jwt-decode";

import {
    callAPI,
    callApiGet,
    callApiPost,
    callApiPut,
} from "../../../components/Axios";
import { t } from "i18next";

class Main extends Component {

    constructor(props) {

        super(props);

        this.state = {
            width: 0,
            height: 0,

            confirmTaskCount: 0,
            ownTaskCount: 0,
            slaTaskCount: 0,
            assignmentCount: 0,
            myTaskCount: "0/0",

            taskCount: [],
            taskStatus: [],
            poolTask: [],

            reminderVisible: false,
            reminderVisibleUpdate: false,

            selectedReminderDesc: "",
            selectedReminderDate: undefined,
            notesAndReminder: [],
            reminderVisibleModal: true,

            selectedReminderDescEdit: "",
            selectedReminderDateEdit: undefined,
            selectedReminderEditID: undefined,

            loginUserTaskCounts: 0,
            selectedLoginUser: {},
            poolCount: 0,
            // ownConfirmTaskCount:0
        };
        let { t } = this.props;

        this.languages = {
            TalepYönetimSistemineHoşgeldiniz: t("TalepYönetimSistemineHoşgeldiniz"),
            Merhaba: t("Merhaba"),
            aciklamaAlaniBosBirakilmaz: t("aciklamaAlaniBosBirakilmaz"),
            eklemeIslemiBasariliBirSekildeGerceklestirildi: t("eklemeIslemiBasariliBirSekildeGerceklestirildi"),
            guncellemeIslemiBasariliBirSekildeGerceklesti: t("guncellemeIslemiBasariliBirSekildeGerceklesti"),
            not: t("not"),
            animsatici: t("animsatici")
        }

        this.props.actions.isLoading(true);
    }

    async componentDidMount() {
        this.getOwnConfirmTaskCount();
        console.log("scaleee", window.innerWidth);
        await Promise.all([
            await this.GetAllTaskCount()
        ]);

    }

    async GetAllTaskCount() {
        var token = localStorage.getItem("token");
        if (token) {
            let user = jwt_decode(token);
            if (user && new Date(user.exp * 1000) > new Date()) {
                await this.GetMyNotesAndReminder();
                await this.getSLATaskCount();
                await this.getAssignmentCount();
                await this.props.actions.getMyTaskCreateTemplates();
                await this.getTaskCounts();
                await this.getPoolCount();
            }
        }
    }

    async getSLATaskCount() {

        callAPI(
            callApiGet,
            `api/Main/GetSLATaskCount`,
            {},
            async (res) => {
                if (res?.data) {

                    this.setState({ slaTaskCount: res?.data.Data.strValue });

                    this.props.actions.isLoading(false);
                }
            },
            true
        );
    }
    async getAssignmentCount() {

        callAPI(
            callApiGet,
            `api/Main/GetAssignmentCount`,
            {},
            async (res) => {
                if (res?.data) {

                    this.setState({ assignmentCount: res?.data.Data.strValue });

                    this.props.actions.isLoading(false);
                }
            },
            true
        );
    }

    getOwnConfirmTaskCount() {
        callAPI(
            callApiGet,
            `api/Main/GetConfirmTaskCount`,
            {},
            (res) => {
                if (res?.data) {

                    this.setState({ ownTaskCount: res?.data.Data.strValue });
                    this.props.actions.isLoading(false);
                }
            },
            true
        );
    }

    async getTaskCounts() {
        callAPI(
            callApiGet,
            `api/Main/GetMyOwnTaskCount`,
            {},
            async (res) => {
                if (res?.data) {

                    this.setState({ loginUserTaskCounts: res?.data.Data.strValue });
                    this.props.actions.isLoading(false);
                }
            },
            true
        );
        //await BB.FetchJSON("api/Main/GetMyOwnTaskCount").then(data =>
        //{
        //   this.setState({loginUserTaskCounts: data})
        //   this.props.actions.isLoading(false)
        //});
    }

    handleChange = (id, value) => {
        this.setState({ [id]: value });
    };

    CardChartUrl = (url) => {
        this.props.history.push(url);
    };

    valueChanged = (e) => {
        this.setState({
            reminderVisible: e.value,
        });
    };

    valueChangedModal = (e) => {
        this.setState({
            reminderVisibleModal: e.value,
        });
    };

    addReminder = () => {
        const { selectedReminderDate, selectedReminderDesc, reminderVisible } =
            this.state;
        let date = "";
        let time = "";
        let newDate = "";
        if (reminderVisible) {
            if (
                selectedReminderDate === undefined ||
                selectedReminderDate === null ||
                selectedReminderDate === ""
            ) {
                return notify(t("tarihalanibosbirakilamaz"), "error", 2000);
            }
            if (new Date(selectedReminderDate) < new Date()) {
                return notify(
                    t("hatirlatmatarihibugundendahakucukolamaz"),
                    "error",
                    2000
                );
            }
            date = selectedReminderDate.toLocaleDateString();
            time = selectedReminderDate.toLocaleTimeString();
        }

        newDate =
            date.split(".")[2] + "-" + date.split(".")[1] + "-" + date.split(".")[0];

        if (
            selectedReminderDesc === undefined ||
            selectedReminderDesc === null ||
            selectedReminderDesc === ""
        ) {
            return notify(t("aciklamaAlaniBosBirakilmaz"), "error", 2000);
        }

        let categoryType = reminderVisible ? 1 : 0;

        BB.FetchJSON(
            "api/Main/AddOrUpdateMyNotesAndReminder?description=" +
            selectedReminderDesc +
            "&type=" +
            categoryType.toString() +
            "&datetime=" +
            newDate +
            " " +
            time
        )
            .then((res) => {
                if (true) {
                    notify(
                        t("eklemeIslemiBasariliBirSekildeGerceklestirildi"),
                        "success",
                        3000
                    );
                    this.GetMyNotesAndReminder();
                } else {
                    notify("Ekleme işlemi başarısız.", "error", 3000);
                }

                this.setState({
                    selectedReminderDesc: "",
                    selectedReminderDate: undefined,
                });
            })
            .catch((err) => {
            });
    };

    editReminder = () => {
        const {
            reminderVisibleModal,
            selectedReminderDateEdit,
            selectedReminderDescEdit,
            selectedReminderEditID,
        } = this.state;
        let date = "";
        let time = "";
        if (
            selectedReminderEditID === undefined ||
            selectedReminderEditID === null ||
            selectedReminderEditID === ""
        ) {
            return notify(t("beklenmedikbirhataolustu"), "error", 2000);
        }

        if (reminderVisibleModal) {
            if (
                selectedReminderDateEdit === undefined ||
                selectedReminderDateEdit === null ||
                selectedReminderDateEdit === ""
            ) {
                return notify(t("tarihalanibosbirakilamaz"), "error", 2000);
            }
            if (new Date(selectedReminderDateEdit) < new Date()) {
                return notify(
                    t("hatirlatmatarihibugundendahakucukolamaz"),
                    "error",
                    2000
                );
            }
            date = new Date(selectedReminderDateEdit).toLocaleDateString();
            time = new Date(selectedReminderDateEdit).toLocaleTimeString();
        }

        if (
            selectedReminderDescEdit === undefined ||
            selectedReminderDescEdit === null ||
            selectedReminderDescEdit === ""
        ) {
            return notify(t("aciklamaAlaniBosBirakilmaz"), "error", 2000);
        }
        this.props.actions.isLoading(true);
        let categoryType = reminderVisibleModal ? 1 : 0;

        BB.FetchJSON(
            "api/Main/AddOrUpdateMyNotesAndReminder?description=" +
            selectedReminderDescEdit +
            "&ID=" +
            selectedReminderEditID +
            "&type=" +
            categoryType.toString() +
            "&datetime=" +
            date +
            " " +
            time
        )
            .then((res) => {
                if (true) {
                    notify(
                        t("guncellemeIslemiBasariliBirSekildeGerceklesti"),
                        "success",
                        3000
                    );
                    this.GetMyNotesAndReminder();
                } else {
                    notify(t("guncellemeIslemiBasariliBirSekildeGerceklesti"), "error", 3000);
                }
                this.props.actions.isLoading(false);
                this.setState({
                    reminderVisibleUpdate: false,
                    selectedReminderDescEdit: "",
                    selectedReminderDateEdit: undefined,
                    selectedReminderEditID: undefined,
                });
            })
            .catch((err) => {
            });
    };

    GetMyNotesAndReminder() {
        BB.FetchJSON("api/Main/GetMyNotesAndReminder").then((data) => {
            this.setState({ notesAndReminder: data });
        });
    }

    MyNotesAndReminderUpdate = (item) => {
        this.setState({
            reminderVisibleUpdate: true,
            reminderVisibleModal: item.Type === 1 ? true : false,
            selectedReminderEditID: item.ID,
            selectedReminderDateEdit: item.RunTime,
            selectedReminderDescEdit: item.Description,
        });

        let reminderVisibleModal = item.Type === 1 ? true : false;
        let categoryType = reminderVisibleModal ? 1 : 0;
        let date = new Date(item.RunTime).toLocaleDateString();
        let time = new Date(item.RunTime).toLocaleTimeString();

        // BB.FetchJSON('api/Main/AddOrUpdateMyNotesAndReminder?description=' + item.Description + '&ID=' + item.ID + '&type=' + categoryType.toString() + '&datetime=' + date + ' ' + time)
        // .then(res =>
        // {
        //    if (true)
        //    {
        //       notify('Güncelleme işlemi başarılı bir şekilde gerçekleşti.', 'success', 3000);
        //       this.GetMyNotesAndReminder();
        //    }
        //    else
        //    {
        //       notify('Güncelleme işlemi başarısız.', 'error', 3000);
        //    }
        //    this.props.actions.isLoading(false);
        //    this.setState({
        //       reminderVisibleUpdate: false,
        //       selectedReminderDescEdit: '',
        //       selectedReminderDateEdit: undefined,
        //       selectedReminderEditID: undefined
        //    });
        // })
        // .catch(err =>
        // {
        // })
    };
    async getPoolCount() {
        callAPI(
            callApiGet,
            `api/Pool/GetTaskCountInMyPool`,
            {},
            async (res) => {
                if (res?.data) {
                    this.setState({ poolCount: res?.data });
                }
            },
            true
        );
    }

    MyNotesAndReminderDelete(ID) {
        BB.askYesNo(
            t("onaylıyorMusunuz"),
            t("sectiginizmesajsilinecektir"),
            t("evet"),
            t("hayır")
        ).then((res) => {
            if (res) {
                BB.FetchJSON("api/Main/DeleteMyNotesAndReminder?ID=" + ID)
                    .then((res) => {
                        if (true) {
                            notify(
                                t("silmeislemibasariylagerceklesti"),
                                "success",
                                3000
                            );
                            this.GetMyNotesAndReminder();
                        } else {
                            notify("Silme işlemi başarısız.", "error", 3000);
                        }
                    })
                    .catch((err) => {
                    });
            }
        });
    }
    render() {
        var token = localStorage.getItem("token");
        var user = jwt_decode(token);
        const { t } = this.props;
        let isManager = user.IsManager == "True";
        let isLastUser = user.IsLastUser == "True";
        let className = isLastUser
            ? "col-sm-12 col-md-12 col-lg-12 col-xl-12"
            : isManager
                ? "col-sm-12 col-md-6 col-lg-6 col-xl-4"
                : "col-sm-12 col-md-6 col-lg-6 col-xl-6";

        return (
            <>
                <Row>
                    <div className="col-sm-12 col-lg-12 col-xl-8">
                        {
                            <Row>
                                <div className={"col-sm-12 col-lg-12 col-xl-12"}>
                                    <MainWelcomeWidget
                                        title={t("Merhaba") + " " + user.Name + " " + user.SurName}
                                        description={
                                            user.Subdomain &&
                                            (user.Subdomain.includes("dohas")
                                                ? "Doğuş Otomotiv"
                                                : "") +
                                            " " +
                                            t("TalepYönetimSistemineHoşgeldiniz")
                                        }
                                        imgUri={user.CompanyLogo}
                                    />
                                </div>
                            </Row>
                        }

                        {
                            <Row>
                                {this.state.ownTaskCount > 0 && (
                                    <div className={className}>
                                        <SliderItem
                                            onClick={() => this.props.history.push("/WaitingConfirm")}
                                            title={t("onaybekleyentalepler")}
                                            subTitle={this.state.ownTaskCount}
                                            imgUri={Task1}
                                        />
                                    </div>
                                )}
                                {!isLastUser ? (
                                    <>
                                        <div className={className}>
                                            <SliderItem
                                                onClick={() => this.props.history.push("/TaskPool")}
                                                title={t("havuzdakitalepsayısı")}
                                                subTitle={this.state.poolCount}
                                                imgUri={Task}
                                            />
                                        </div>

                                        <div className={className}>
                                            <SliderItem
                                                onClick={() => this.props.history.push("/TaskTracking")}
                                                title={t("cozumlemeyibekleyentalepsayısı")}
                                                subTitle={this.state.slaTaskCount}
                                                imgUri={Task2}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className={className}>
                                        <SliderItem
                                            onClick={() =>
                                                this.props.history.push("/TaskTracking?WatcherTasks")
                                            }
                                            title={t("bilgimdeolantalepler")}
                                            subTitle={this.props.watcherTasks.length}
                                            imgUri={Task}
                                        />
                                    </div>
                                )}

                                <div className={className}>
                                    <SliderItem
                                        // this.props.history.push(`${'/satis/satis-detaylari/'}${data.alisSatisNo}`)
                                        onClick={() =>
                                            this.props.history.push("/TaskTracking?OwnTasks")
                                        }
                                        title={t("cozdugumTalepSayisi")}
                                        description={t("TümTaleplerimSüreciTamamlanmışTaleplerim")}
                                        subTitle={this.state.loginUserTaskCounts}
                                        imgUri={Task3}
                                    />
                                </div>   
                                <div className={className}>
                                    <SliderItem
                                        onClick={() => this.props.history.push("/TaskTracking?TaskAssignment")}
                                        title={t("görevAtananTalepler").toUpperCase()}
                                        subTitle={this.state.assignmentCount}
                                        imgUri={Task2}
                                    />
                                </div>
                            </Row>
                        }
                        <Row className="mb-3 pb-2">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Jobs />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <RecurringTaskCreate useTranslation={t} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-12">
                                <LastCreatedTasks useTranslation={t} props={this.props} />
                            </div>
                        </Row>
                        {/*<Row>*/}
                        {/*    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-12">*/}
                        {/*        <TaskAssignedRequests useTranslation={t} props={this.props} />*/}
                        {/*    </div>*/}
                        {/*</Row>*/}
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-4 mt-xl-4">
                        <Notice useTranslation={t} />
                        <FastTaskCreate useTranslation={t} />
                        <CustomCard>
                            <div className="border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                                <div className="label-custom">
                                    <label className="main-content-label mb-2 pt-1">
                                        {this.state.reminderVisible
                                            ? t("animsaticiEkle")
                                            : t("notekle")}
                                    </label>
                                    <span className="d-block tx-12 mb-2 text-muted">
                                        {t("Günlükgörevler")}
                                    </span>
                                </div>
                                <div className="ml-auto show">
                                    <div className="d-flex align-items-center">
                                        <span className="switch-label-custom">{t("animsat")}</span>
                                        <Switch
                                            defaultValue={this.state.reminderVisible}
                                            onValueChanged={this.valueChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.state.reminderVisible &&
                                BB.DateTimeSelectWithLabelMain(
                                    "selectedReminderDate",
                                    t("tarih"),
                                    this
                                )}
                            {BB.InputWithLabel(
                                "selectedReminderDesc",
                                t("note"),
                                "text",
                                this,
                                t("notunuzuBurayayaziniz")
                            )}
                            <button
                                type="submit"
                                className="btn btn-default todo-button"
                                onClick={this.addReminder}
                            ></button>
                        </CustomCard>
                        <CustomCard>
                            <LabelGroup
                                title={t("anımsatıcılarveNotlar")}
                                description={t("anımsatıcıVeNotlarAçıklama")}
                            />
                            <ul className=" list-group list-group-flush">
                                {this.state.notesAndReminder.map((item, inx) => {
                                    return (
                                        <li className="list-group-item" key={`key` + inx}>
                                            <div
                                                className={`todo-indicator ${item.Type === 1 ? "bg-warning" : "bg-success"
                                                    }`}
                                            ></div>
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left">
                                                        <div
                                                            className={`widget-heading ${item.IsConfirm === true &&
                                                                "text-muted text-decoration-line-through"
                                                                }`}
                                                        >
                                                            {item.Description}
                                                        </div>
                                                        <div className="widget-subheading">
                                                            <div>
                                                                <div
                                                                    className={`badge badge-pill  ml-0 ${item.Type === 1
                                                                        ? "badge-warning"
                                                                        : "badge-success"
                                                                        }`}
                                                                >
                                                                    {item.Type === 1 ? t("animsatici") : t("not")}
                                                                </div>
                                                                <span className="badge badge-pill badge-danger ml-2">
                                                                    {item.Type === 1 &&
                                                                        new Date(
                                                                            item.RunTime
                                                                        ).toLocaleDateString() +
                                                                        " " +
                                                                        new Date(
                                                                            item.RunTime
                                                                        ).toLocaleTimeString()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right d-flex">
                                                        <button
                                                            className="border-0 btn-transition btn btn-outline-warning"
                                                            onClick={() =>
                                                                this.MyNotesAndReminderUpdate(item)
                                                            }
                                                        >
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                        <button
                                                            className="border-0 btn-transition btn btn-outline-danger"
                                                            onClick={() =>
                                                                this.MyNotesAndReminderDelete(item.ID)
                                                            }
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </CustomCard>
                    </div>
                </Row>

                <Modal
                    style={{ zIndex: 1 }}
                    centered={true}
                    isOpen={this.state.reminderVisibleUpdate}
                >
                    <ModalHeader
                        toggle={() =>
                            this.setState({
                                reminderVisibleUpdate: false,
                                selectedReminderEditID: undefined,
                                selectedReminderDateEdit: undefined,
                                selectedReminderDescEdit: undefined,
                            })
                        }
                    >
                        {t("düzenle")}
                    </ModalHeader>
                    <ModalBody className="pt-0">
                        <div style={{ marginTop: 10 }}>
                            <Row>
                                <Col xl={12}>
                                    <div className="float-right d-flex align-items-center">
                                        <span className="switch-label-custom">{t("animsat")}</span>
                                        <Switch
                                            defaultValue={this.state.reminderVisibleModal}
                                            onValueChanged={this.valueChangedModal}
                                        />
                                    </div>
                                </Col>
                                <Col xl={12}>
                                    {this.state.reminderVisibleModal &&
                                        BB.DateTimeSelectWithLabelMain(
                                            "selectedReminderDateEdit",
                                            t("tarih"),
                                            this
                                        )}
                                </Col>
                                <Col xl={12}>
                                    {BB.InputWithLabel(
                                        "selectedReminderDescEdit",
                                        t("açıklama"),
                                        "text",
                                        this,
                                        t("aciklamagiriniz")
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        color={"success"}
                                        className="float-right m-1"
                                        onClick={() => {
                                            this.editReminder();
                                        }}
                                    >
                                        {t("kaydet")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        loginuser: state.loginReducer,
        poolTasks: state.poolReducer,
        poolTasksCount: state.poolCountReducer,
        ownTasks: state.ownTaskReducer,
        ownConfirm: state.ownConfirmReducer,
        slaTaskCount: state.slaTaskCountReducer,
        ownTaskCount: state.ownTaskCountReducer,
        ownConfirmTaskCount: state.ownConfirmTaskCountReducer,
        watcherTasks: state.getWatcherTasksReducer,
        taskAssigned: state.getTaskAssignedReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
            // getSLATaskCount: bindActionCreators(mainActions.getSLATaskCount, dispatch),
            getOwnTaskCount: bindActionCreators(
                mainActions.getOwnCreatedTaskCount,
                dispatch
            ),
            // getOwnConfirmTaskCount: bindActionCreators(mainActions.getOwnConfirmTaskCount, dispatch),
            getMyTaskCreateTemplates: bindActionCreators(
                taskActions.getMyTaskCreateTemplates,
                dispatch
            ),
            getWatcherTasks: bindActionCreators(getWatcherTasks, dispatch),
        },
    };
}

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Main)
);
