import * as actionTypes from '../actions/actionTypes'

export default function menuReducer(state = {}, action)
{
   switch (action.type)
   {
      case actionTypes.SET_MENUS:
         return action.payload
      default:
         return state
   }
}
