import React from "react";
import './styles.scss'

// status:danger-warning-primary

const Badge=({title,status})=>{
    return(
        <span className={`badge badge-pill ${status==='danger'?'badge-danger-light':(status==='warning'?'badge-warning-light ':'badge-primary-light ')}`}>{title}</span>
    )
}
export default Badge;