import React from "react";
import CustomCard from "../Card/CustomCard";
import './styles.scss'

const MainWelcomeWidget = ({title, imgUri, description}) => {
    return (
        <div className='mt-lg-4'>
            <CustomCard cardStyle={'card-box bg-primary'} bodyStyle={'p-4'}>
                <div className="row align-items-center">
                    <div className="offset-xl-4 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg ">
                        <h4 className="d-flex  mb-3">
                            <span className="font-weight-bold text-black ">{title}</span>
                        </h4>
                        <p className="tx-black-7 mb-1">{description}
                        </p>
                    </div>
                    <img
                        src={imgUri} alt={title}/>
                </div>
            </CustomCard>
        </div>
    )
}
export default MainWelcomeWidget;
