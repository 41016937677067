import * as actionTypes from './actionTypes'
import * as BB from "../../components/BBComponent";
import { callAPI, callApiGet ,callApiPost} from "../../components/Axios";

export function addAllTasks(allTasks)
{
   return {type: actionTypes.SET_POOL_DATA, payload: allTasks}
}

export function setPoolCount(count)
{
   return {type: actionTypes.SET_POOL_COUNT, payload: count}
}

export function getAllPoolTask(loginUserID,poolID)
{
   return async function (dispatch)
   {

      let filter = {
         TaskNumber: null,
         UserID: loginUserID,
         StatusID: null,
         DegreeOfUrgency: null,
         CompanyID: null,
         CreateDateStart: null,
         CreateDateEnd: null,
         TerminationDateStart: null,
         TerminationDateEnd: null,
         PoolID: null,
         ExpectedEndDate: null,
         IsDepartmentTasks: false,
         IsFinishedTasks: false,
         IsAllTasks: false,
         IsPoolTasks: true,
         IsMyTasks: false,
         IsSLA: false,
         rejectTask:false,
         confirmTasks:false,
         selectedPool:poolID
      }

      callAPI(
         callApiPost,
         'api/TaskTracking/GetGridData',
         filter,
         async (res) => {
            dispatch(addAllTasks(res.data));

          })


      // let url = 'api/TaskTracking/GetGridData';

      // await fetch(url, {
      //    method: 'POST',
      //    headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      //    body: JSON.stringify(filter)
      // })
      // .then((response) => response.json())
      // .then((data) =>
      // {
      //    debugger
      //    //localStorage.setItem('loginTime', new Date());
        
      //    dispatch(addAllTasks(data));
      // })
      // .catch((error) =>
      // {
      //    console.error('Error:', error);
      // });
   }
}

export function getPoolCount()
{
   return function (dispatch)
   {

      return callAPI(
         callApiGet,
         'api/Pool/GetTaskCountInMyPool',
         {},
         async (result) => {
            dispatch(setPoolCount(result));
         },
         true
       );


      // let url = 'api/Pool/GetTaskCountInMyPool';

      // return BB.FetchTEXT(url)
      // .then(result =>
      // {
      //    dispatch(setPoolCount(result));
      // });
   }
}
