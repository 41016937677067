import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import 'semantic-ui-css/semantic.min.css'
import ReactDataGrid from "react-data-grid";

const SheetModal = ({event, t}) =>
{
   return (
      
      <Modal isOpen={event.state.preview2Visible} size={'xl'} centered={true} className={'customModal'}>
         <ModalHeader
            toggle={() => event.setState({preview2Visible: false})}>{event.state.selectedSheetName}</ModalHeader>
         <ModalBody style={{marginRight: 40}}>
            <ReactDataGrid
               columns={event.state.selectedSheet}
               rowGetter={i => event.state.rows[i]}
               rowsCount={event.state.rows.length}
               onGridRowsUpdated={event.onGridRowsUpdated}
               enableCellSelect={true}
            />
         </ModalBody>
         <ModalFooter>
            <Button
               color={'info'}
               onClick={() =>
               {
                  let columns = event.state.selectedSheet;

                  if (columns.length > 0)
                  {
                     let rowData = [];
                     let obj = {};

                     columns.map((item, inx) =>
                     {
                        obj[item.key] = '';

                        if (inx === 0)
                        {
                           rowData.push(obj);
                        }
                     })
                     event.setState({rows: event.state.rows.concat(rowData)});
                  }
               }}
            >
               {t("yeniSatırOlustur")}
            </Button>

            <Button
               onClick={() => event.setState({preview2Visible: false})}
               color={'success'}
            >
               {t("kaydet")}
            </Button>
         </ModalFooter>
      </Modal>
   )
}
export default SheetModal;
