import React, {Component} from 'react';
import {Button, Card, CardBody, Col,} from 'reactstrap';
import DataGrid, {
   Column,
   Export,
   FilterRow,
   GroupPanel,
   HeaderFilter,
   Pager,
   Paging,
   SearchPanel,
   Sorting
} from "devextreme-react/data-grid";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CompanyUserCategory extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         userID: this.props.location.state ? this.props.location.state.UserID : null,
         companyID: this.props.location.state ? this.props.location.state.companyID : null
      }
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      BB.FetchJSON('api/Yonetim/Companies/GetDataRolPackage?ID=' + this.state.userID).then(data =>
      {
         this.setState({gridData: data.Data, isLoading: false})
      });

   }

   actionButton(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.deleteUserCategory(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   deleteUserCategory(id)
   {
      BB.askYesNo('Onaylıyormusunuz?', t("bukategoritaniminikullanicidansilmekistiyormusunuz"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.props.actions.isLoading(true);

            const formData = new FormData();

            formData.append('ID', id);

            post('api/Yonetim/Companies/DeleteConfirm', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.getDatas()
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {
                  this.getDatas()
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   render()
   {
      const {redirectUrl, departmentID, companyID} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {CompanyID: companyID}}}/>
      }
      else
      {
         if (this.state.userID === null)
         {
            return <Redirect to={{pathname: '/Yonetim/Companies', state: {companyID: this.state.companyID}}}/>
         }
         else
         {
            return (
               <div className="animated fadeIn">
                  <Col>
                     <Card>
                        <CardBody>

                           <Button
                              color={'success'}
                              outline
                              onClick={() => this.setState({redirect: true, redirectUrl: '/Yonetim/CompanyUser'})}
                              style={{float: 'left', position: 'absolute', zIndex: 1}}
                              type="button">{t("geri")}</Button>

                           <DataGrid
                              style={{marginTop: 35}}
                              id="gridContainer"
                              dataSource={this.state.gridData}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <Sorting mode="multiple"/>
                              <FilterRow visible={true}/>
                              <HeaderFilter visible={true}/>
                              <GroupPanel visible={true}/>
                              <SearchPanel visible={true}/>
                              <Export enabled={true} fileName=""/>
                              <Column
                                 dataField="CategoryName"
                                 caption={t("kategori")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField="RolePackageName"
                                 caption={t("yetkikategorisi")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField="RoleCategory"
                                 caption={t("rolkategorisi")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'CreateUser'}
                                 caption={t("olusturankullanici")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField="İşlemler"
                                 caption={t("islemler")}
                                 width={200}
                                 cellRender={(cellData) => this.actionButton(cellData)}
                              />
                           </DataGrid>
                        </CardBody>
                     </Card>
                  </Col>

               </div>
            );
         }
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(CompanyUserCategory)
);


