import * as actionTypes from './actionTypes'

export function addMenu(Menus)
{
   return {type: actionTypes.SET_MENUS, payload: Menus}
}

export function getMenus()
{
   return function (dispatch)
   {
      let url = 'api/Common/GetMenu';
      return null;
      // return fetch(url)
      // .then(res => res.json())
      // .then(result =>
      // {
      //    //localStorage.setItem('loginTime', new Date());
      //    dispatch(addMenu(result))
      // });
   }
}

