import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap'
import DataGrid, {Column, Pager, Paging} from "devextreme-react/data-grid";
import CustomInput from '../../../components/admin/CustomInput/CustomInput'
import axios, {post} from "axios";
import notify from "devextreme/ui/notify";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {Redirect} from 'react-router-dom';
import * as BB from "../../../components/BBComponent";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CompanyEdit extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         kurum: '',
         telefon: '',
         adres: '',
         domain: '',
         isActive: undefined,
         changeGridData: [],
         changeGridMultiData: [],
         taskList: [],
         confirmationTypeID: undefined,
         useSurveyID: undefined,
         redirectUrl: '',
         redirect: false,
         info: {
            error: '',
         },
         companyDetails: [],
         depAndJob: [],
         companyID: this.props.location.state ? this.props.location.state.CompanyID : null,
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   componentDidMount()
   {
      if (this.state.companyID !== null)
      {
         this.props.actions.isLoading(true);
         Promise.all([
            axios.get(`api/Yonetim/Companies/GetCompanyDetail?ID=${this.state.companyID}`),
            axios.get(`api/Yonetim/Companies/GetDepAndJob?ID=${this.state.companyID}`)
         ])
         .then(([companyDetailResponse, reposResponse]) =>
         {
            this.setState({
               companyDetails: companyDetailResponse.data,
               taskList: reposResponse.data.Data,
               kurum: companyDetailResponse.data.Name,
               telefon: companyDetailResponse.data.Phone ? companyDetailResponse.data.Phone : '',
               adres: companyDetailResponse.data.Address,
               confirmationTypeID: companyDetailResponse.data.ConfirmationTypeID,
               useSurveyID: companyDetailResponse.data.UseSurveyID,
               isActive: companyDetailResponse.data.IsActive,
               domain: companyDetailResponse.data.LdapDomain,
            });

            this.props.actions.isLoading(false);
         });
      }
      else
      {
         this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})
      }

   }


   handleChange(id, value)
   {
      if (id === 'isActive')
      {
         this.setState({isActive: !this.state.isActive});
      }
      else
      {
         this.setState({[id]: value});
      }

   }

   handleChangeInput = (e) =>
   {
      const {name, value} = e.target;
      this.setState({[name]: value})
   }
   handleSubmit = (e) =>
   {
      const {kurum, telefon, adres} = this.state;
      if (kurum === '' || telefon === '' || adres === '')
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            ID: this.state.companyID,
            Name: this.state.kurum,
            Address: this.state.adres,
            Phone: this.state.telefon,
            IsActive: this.state.isActive,
            ConfirmationTypeID: this.state.confirmationTypeID,
            UseSurveyID: this.state.useSurveyID,
            LdapDomain: this.state.domain,
         }
         let departmans = this.state.taskList;
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('selectedData', JSON.stringify(departmans));
         const URL = `api/Yonetim/Companies/Add`;
         post(URL, formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.props.actions.isLoading(false);
               notify(t("kurumguncellemebasarilibirsekildegerceklestirildi"), "success", 1000);
               this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})
            }
            else
            {

               notify(t("kurumguncellemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }

      e.preventDefault();
   }

   actionButton = () =>
   {
      this.setState({companyID: this.state.companyID, redirect: true, redirectUrl: '/Yonetim/Departments'});
   }

   render()
   {
      const {
         companyDetails,
         taskList,
         kurum,
         domain,
         telefon,
         adres,
         companyID,
         redirectUrl,
      } = this.state;
      const {Confirmations, Survery} = companyDetails
      let ConfirmationsArray = [];
      for (let key in Confirmations)
      {
         ConfirmationsArray.push(Confirmations[key]);
      }
      let SurveryArray = [];
      for (let key in Survery)
      {
         SurveryArray.push(Survery[key]);
      }
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {CompanyID: companyID}}}/>
      }
      return (
         <Row>
            <Col sm='12' md='12' lg='12'>
               <Card>
                  <CardHeader>{t("firmaduzenle")} {kurum}</CardHeader>
                  <CardBody>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col lg='6' sm='12'>
                              <CustomInput type='text' label={t("kurumadi")} placeholder="" name="kurum"
                                           handleChange={this.handleChangeInput} value={kurum} rq='*'/>
                           </Col>
                           <Col lg='6' sm='12'>
                              <CustomInput type='text' label={t("telefon")} placeholder="" name="telefon"
                                           handleChange={this.handleChangeInput} value={telefon} rq='*'/>
                           </Col>
                           <Col lg='12' sm='12'>
                              <CustomInput type='textarea' label={t("adres")} placeholder="" name="adres"
                                           handleChange={this.handleChangeInput} value={adres} rq='*'/>
                           </Col>
                        </Row>
                        <Row>
                           <Col lg='3' sm='12' className='mb-3'>
                              {BB.DxDropDownWithJSON('confirmationTypeID', '', this, ConfirmationsArray)}
                           </Col>
                           <Col lg='3' sm='12' className='mb-3'>
                              {BB.DxDropDownWithJSON('useSurveyID', '', this, SurveryArray)}
                           </Col>
                           <Col>
                              <CustomInput type='text' label="Domain" placeholder="" name="domain"
                                           handleChange={this.handleChangeInput} value={domain} rq=''/>
                           </Col>
                        </Row>
                        <Row>
                           <Col>{BB.CheckBoxWithLabel('isActive', t("aktifmi"), this, false)}</Col>
                        </Row>
                        <DataGrid
                           id="taskListGridData"
                           dataSource={taskList}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                           onRowRemoving={this.onRowRemoving}
                           onRowRemoved={this.onRowRemoved}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <Column
                              dataField="depText"
                              caption={t("departmantanimi")}
                              minWidth={220}
                              allowFiltering={true}/>
                           <Column
                              dataField="jobText"
                              caption={t("gorevtanimi")}
                              minWidth={220}
                              allowFiltering={true}/>
                        </DataGrid>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                           <Button onClick={this.actionButton} color='secondary'
                                   className='float-left  mt-10 mr-10 departmantspan'>{t("departmanvegorevleriduzenle")}</Button>
                           <Button color='success' className='float-right  mt-10' type='submit'>{t("firmayiguncelle")}</Button>
                        </div>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      );

   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()( 
   connect(mapStateToProps, mapDispatchToProps)(CompanyEdit)
);
