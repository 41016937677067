import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class EmailTemplate extends Component
{
   constructor(props)
   {

      super(props);

      this.state = {
         gridData: undefined,
         editRowVisible: false,
         editPoolVisible: false,

         editID: '',
         selectedName: '',
         selectedIsActive: true,
         selectedMasterPoolID: null,
         selectedTemplate: undefined,

         redirect: false,
         poolID: '',
      }

      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/EmailTemplate/GetData').then(data =>
      {
         this.setState({gridData: data});
      })
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   delete(ID)
   {
      BB.FetchJSON('api/Yonetim/EmailTemplate/Delete?id=' + ID).then(res =>
      {
         if (res.Data === true)
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''}, this.getData());
            notify(t("emailtemplatebasariylasilindi"), "success", 1000);
         }
         else
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
            notify(t("emailtemplatesilmehata"), "warning", 2000);
         }
      })
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({redirect: true, selectedTemplate: cellData.data});
               }}>
               {t("düzenle")}
            </Button>

            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizhavuzsilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      if (this.state.redirect === true)
      {
         if (this.state.redirectURL === 'add')
         {
            return <Redirect to={{pathname: '/Yonetim/EmailTemplateEditor'}}/>
         }
         else
         {
            return <Redirect
               to={{pathname: '/Yonetim/EmailTemplateEditor', state: {template: this.state.selectedTemplate}}}/>
         }
      }
      else
      {
         return (
            <div className="animated fadeIn">
               <Col>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({redirect: true, redirectURL: 'add'})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Havuz Listesi"/>

                           <Column
                              dataField="Name"
                              caption={t("havuztanimi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField='Subject'
                              caption={t("konu")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField='CreateDate'
                              caption={t("olusturmaTarihi")}
                              dataType={'date'}
                              minWidth={80}/>
                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={150}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>


               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.editRowVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("yenihavuztanimi")}
                  width={400}
                  height={550}
               >
                  <form>

                     {BB.InputWithLabel('selectedName', t("tanim"), 'text', this)}
                     {BB.DxDropDownWithJSON('selectedMasterPoolID', t("talepHavuuzu"), this, this.state.gridData)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({editRowVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>

               {/*Düzenle Popup*/}
               <Popup
                  visible={this.state.editPoolVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("emailsablonduzenle")}
                  width={'55%'}
                  height={690}
               >


                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                     <Button
                        color={'info'}
                        onClick={() => this.setState({
                           editPoolVisible: false,
                           editID: '',
                           selectedName: '',
                           selectedIsActive: false,
                           selectedMasterPoolID: null,
                        })}>
                        {t("kapat")}
                     </Button>

                     <Button
                        onClick={() => this.edit()}
                        color={'primary'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </Popup>
            </div>
         );
      }
   }
}

export default withTranslation()(EmailTemplate);
