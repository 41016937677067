import * as actionTypes from '../actions/actionTypes'

export default function mainCompanyReducer(state = {}, action)
{
   switch (action.type)
   {
      case actionTypes.SET_MAIN_COMPANIES:
         return action.payload
      default:
         return state
   }
}
