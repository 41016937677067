import React from "react";
import './styles.scss'
import {CustomButton} from "../index";

const LabelGroup = ({title, description, buttonVisible = false, handleClick, isTaskDetail, isSettings}) =>
{
   return (
      <div className="border-bottom-0 pt-0 pl-0 pr-0 d-flex">
         {isTaskDetail === undefined ?
            <div className='label-custom'>
               <label className="main-content-label mb-2 pt-1">{title}</label>
               <span className="d-block tx-12 my-3 text-muted">{description}</span>
            </div>
            : null
         }
         {
            buttonVisible && (
               <div className="ml-auto show">
                  <CustomButton icon='icon-plus' handleClick={handleClick}/>
               </div>
            )
         }

         {
            isSettings && (
               <div className="ml-auto show">
                  <CustomButton icon="cui-cog" handleClick={handleClick}/>
               </div>
            )
         }
      </div>
   )
}

export default LabelGroup;
