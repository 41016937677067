import React from "react";
import {Dropdown} from "semantic-ui-react";

import {FormGroup, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import TextBox from "devextreme-react/text-box";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row} from "reactstrap";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {  callAPI,
    callApiGet,
    callApiPost,
    callApiPut } from "../../../../../components/Axios";





    const getChildCategories = (TaskID, ParentID) => new Promise((resolve) =>
    {
    
       let url = TaskID !== null && '/api/Task/GetCategoriesForChangeTaskCategories?TaskID=' + TaskID + '&ParentID=' + ParentID;
    
    
       callAPI(
          callApiGet,
          url,
          {},
          async (res) => {
            resolve(res?.data)
          },
          true
        );
    
    
      
    
    });

    const categoryRender = (id, value, event) =>
    { 
    
       if (id === 'selectedParentCategory')
       {
    
          event.setState({selectedParentCategory: value, parentCategoryLoading: true, [id]: value});
          let parent = event.state.taskCategories.filter(x => x.key == value)[0];
    
    
          event.setState({"categoryPath":parent.text});
          getChildCategories(event.state.taskID, value).then(data =>
          {
             
             if (data.length > 0)
             {
                event.setState({
                   parentCategoryLoading: false,
                   childCategory1Arr: data,
                   childCategory2Arr: [],
                   childCategory3Arr: [],
                   childCategory4Arr: [],
                   childCategory5Arr: [],
                   childCategory6Arr: []
                })
    
             }
             else
             {
                let sCategory = event.state.taskCategories.filter(x => x.key == value)[0];
                event.handleChange('selectedCategory', value);
                event.setState({
                   parentCategoryLoading: false,
                   selectedCategoryName: sCategory.text,
                   selectCategory: false,
                })
    
             }
          })
       }
       if (id === 'selectedChildCategory1')
       {
          let parentCategoryPath="";
    
          event.setState({childCategory1Loading: true, [id]: value,});
          let parent = event.state.childCategory1Arr.filter(x => x.key == value)[0];
          let s=[];
          parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
          s = parentCategoryPath.split("/");
          
          if(s[2] != null){
             s[1] =parent.text;
          }
          let path = s[0]+"/"+s[1];
    
          
          event.setState({"categoryPath":path});
    
          getChildCategories(event.state.taskID, value).then(data =>
          {
             if (data.length > 0)
             {
                event.setState({
                   childCategory1Loading: false,
                   childCategory2Arr: data,
                   childCategory3Arr: [],
                   childCategory5Arr: [],
                   childCategory4Arr: [],
                   childCategory6Arr: []
                })
             }
             else
             {
                event.handleChange('selectedCategory', value);
                let parentCategoryPath = event.state.categoryPath ;
                event.setState({
                   selectedCategoryName: parentCategoryPath,
                   selectCategory: false,
                   childCategory1Loading: false
                })
             }
          })
       }
       if (id === 'selectedChildCategory2')
       {
          let s=[];
          event.setState({childCategory2Loading: true, [id]: value,});
          let parent = event.state.childCategory2Arr.filter(x => x.key == value)[0];
          let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
          s = parentCategoryPath.split("/");
          if(s[3] != null){
             s[2] =parent.text;
          }
          let path = s[0]+"/"+s[1]+"/"+s[2];
          
          event.setState({"categoryPath":path});
          getChildCategories(event.state.taskID, value).then(data =>
          {
             if (data.length > 0)
             {
                event.setState({
                   childCategory3Arr: data,
                   childCategory4Arr: [],
                   childCategory5Arr: [],
                   childCategory6Arr: [],
                   childCategory2Loading: false
                })
             }
             else
             {
                event.handleChange('selectedCategory', value);
                let parentCategoryPath = event.state.categoryPath ;
                event.setState({
                   selectedCategoryName: parentCategoryPath,
                   selectCategory: false,
                   childCategory2Loading: false
                })
             }
          });
       }
       if (id === 'selectedChildCategory3')
       {
          event.setState({childCategory3Loading: true, [id]: value,});
          let s=[];
          let parent = event.state.childCategory3Arr.filter(x => x.key == value)[0];
          let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
          s = parentCategoryPath.split("/");
          if(s[4] != null){
             s[3] =parent.text;
          }
          let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3];
          
          event.setState({"categoryPath":path});
          getChildCategories(event.state.taskID, value).then(data =>
          {
             if (data.length > 0)
             {
                event.setState({
                   childCategory4Arr: data,
                   childCategory5Arr: [],
                   childCategory6Arr: [],
                   childCategory3Loading: false})
             }
             else
             {
                event.handleChange('selectedCategory', value);
                let parentCategoryPath = event.state.categoryPath ;
                event.setState({
                   selectedCategoryName: parentCategoryPath,
                   selectCategory: false,
                   childCategory3Loading: false
                })
             }
          });
       }
       if (id === 'selectedChildCategory4')
       {  let s=[];
          event.setState({childCategory4Loading: true, [id]: value,});
          let parent = event.state.childCategory4Arr.filter(x => x.key == value)[0];
          let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
          s = parentCategoryPath.split("/");
          if(s[5] != null){
             s[4] =parent.text;
          }
          let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3]+"/"+s[4];
          event.setState({"categoryPath":path});
          getChildCategories(event.state.taskID, value).then(data =>
          {
             if (data.length > 0)
             {
                event.setState({
                   childCategory5Arr: data,
                   childCategory6Arr: [],
                   childCategory4Loading: false
                })
             }
             else
             {
                event.handleChange('selectedCategory', value);
                let parentCategoryPath = event.state.categoryPath ;
                event.setState({
                   selectedCategoryName: parentCategoryPath,
                   selectCategory: false,
                   childCategory4Loading: false
                })
             }
          });
       }
       if (id === 'selectedChildCategory5')
       {
          let s=[];
          event.setState({childCategory5Loading: true, [id]: value,});
          let parent = event.state.childCategory5Arr.filter(x => x.key == value)[0];
          let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
          s = parentCategoryPath.split("/");
          if(s[6] != null){
             s[5] =parent.text;
          }
          let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3]+"/"+s[4]+"/"+s[5];
          event.setState({"categoryPath":path});
          getChildCategories(event.state.taskID, value).then(data =>
          {
             if (data.length > 0)
             {
                event.setState({childCategory6Arr: data, childCategory5Loading: false})
             }
             else
             {
                event.handleChange('selectedCategory', value);
                let parentCategoryPath = event.state.categoryPath ;
                event.setState({
                   selectedCategoryName: parentCategoryPath,
                   selectCategory: false,
                   childCategory5Loading: false
                })
             }
          });
       }
    }


const ChangeCategoryByTask = ({t, event}) =>
{

   return (
      <FormGroup>
       

         <Modal
            isOpen={event.state.changeCategoryModalVisible}
            style={{marginTop: 250}}>
            <ModalHeader
               toggle={() => event.setState({changeCategoryModalVisible: false})}
            >
              {t("kategoriSecimi")}
            </ModalHeader>
            <ModalBody>

               <FormGroup>

                  <div>
                  {BB.DxDropDownWithJSON(
                  "selectedCategoryID",
                  t("havuzKategorileri"),
                  event,
                  event.state.taskCategories
                )}
                  </div>

               </FormGroup>

           


                    <Row style={{float: 'right',marginRight:10}}>
                <Button style={{marginRight:7}}
                        color={'info'} // changeTaskCanceled
                         onClick={() =>event.changeTaskCanceled() }
                        >
                        {t("vazgec")}
               </Button>
               <div>
               <Button

                color={'success'}
               onClick={() =>event.changeCategoryByTask() 
                // event.setState({changeCategoryModalVisible: false })
                
            }


               >{t("onayla")}</Button></div>

                </Row>
            </ModalBody>
         </Modal>
      </FormGroup>)
}

export default ChangeCategoryByTask;
