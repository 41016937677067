import React from "react";
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  InputGroup,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import TextBox from "devextreme-react/text-box";
import { Dropdown } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as BB from "../../BBComponent";
import { callAPI, callApiGet } from "../../../components/Axios";

const getFilterCategories = (UserID, catTerm) =>
  new Promise((resolve) => {


    let url =
    UserID !== null &&
    "/api/Task/GetCategoriesTree?UserID=" + UserID + "&keywords=" + catTerm;
    callAPI(
        callApiGet,
        url,
        {},
        async (data) => {
          resolve(data.data);

    })


    // let url =
    //   UserID !== null &&
    //   "/api/Task/GetCategoriesTree?UserID=" + UserID + "&keywords=" + catTerm;

    // BB.FetchJSON(url).then((data) => {
    //   resolve(data);
    // });

  });

const getFavoriteCategories = () =>
  new Promise((resolve) => {
    let url = "/api/Task/GetFavoriCategories";
    callAPI(
      callApiGet,
      url,
      {},
      async (data) => {
        resolve(data.data);

  })
    // BB.FetchJSON(url).then((data) => {
    //   resolve(data);

    // });
  });

const CategoryFilter = ({ state, event,t }) => {
  const categoryFilter = (e) => {
    const { categoryTerm } = event.state;
    const catTerm = categoryTerm.toLowerCase();

    if (catTerm.length > 2) {
      getFilterCategories(event.state.selectedEmployee, catTerm).then(
        (data) => {
          event.setState({ filteredCategory: data });
        }
      );
    }
  };

  const setModalOpen = () => {
    getFavoriteCategories().then((data) => {
      event.setState({ favoriCategory: data });
    });
    event.setState({
      modalOpen: !event.state.modalOpen,
      filteredCategory: [],
    });
  };

  const onChangeCategoryInput = (e) => {
    event.setState({ isFavori: false });
    event.setState({ categoryTerm: e.target.value }, categoryFilter);
  };

  const onClickFilter = (filter) => {
    //value ID
    event.setState({
      selectedCategoryName: filter.text,
      selectedCategory: filter.value,
    });
    event.handleChange("selectedCategory", filter.value);
  };

  const onClickFav = (fav) => {
    event.setState({
      selectedCategoryName: fav.Text,
      selectedCategory: fav.ID,
    });
    event.handleChange("selectedCategory", fav.ID); 
    
  };
   
  return (
    
    <>
      <Button
        style={{ marginTop: "10px" }}
        color="primary"
        type="button"
        onClick={() => setModalOpen()}
      >
        <i className="fa fa-eye"></i>
      </Button>

      <Modal
        toggle={() => setModalOpen()}
        isOpen={state.modalOpen}
        style={{ marginTop: "250px" }}
        autoFocus={false}
      >
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            {t("kategoriArama")}
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <InputGroup>
            <Input
              className="border border-dark shadow-sm"
              style={{ border: "5px" }}
              type="text"
              autoFocus={true}
              name={state.categoryTerm}
              onChange={onChangeCategoryInput}
              placeholder={t("aradiginizKategoriyiYaziniz")}
            />
            {/* <InputGroupAddon addonType="append">
                                    <Button color="secondary">
                                      Ara
                                    </Button>
                                  </InputGroupAddon> */}
          </InputGroup>

          <ListGroup style={{ overflowY: "scroll", height: "200px" }}> 
             {event.state.isFavori ? event.state.favoriCategory.map((fav) => (

                  <ListGroupItem
                    active={
                      fav.Text === state.selectedCategoryName ? true : false
                    }
                    onClick={() => onClickFav(fav)}
                    key={fav.ID}
                  >
                    {fav.Text}
                  </ListGroupItem>
                ))
                : <> {event.state.filteredCategory.map(filter=>(
                  <ListGroupItem active={filter.text===state.selectedCategoryName?true:false} onClick={()=>onClickFilter(filter)} key={filter.key}>{filter.text}</ListGroupItem>
                 ))}  </>
             }
            {/*  {
                                     event.state.filteredCategory.map(filter=>(
                                      <ListGroupItem active={filter.text===state.selectedCategoryName?true:false} onClick={()=>onClickFilter(filter)} key={filter.key}>{filter.text}</ListGroupItem>
                                     ))
                                   } */}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          {/* <Button
                                  color="secondary"
                                  type="button"
                                  onClick={() => this.setModalOpen()}
                                >
                                  Close
                                </Button> */}
          <Button color="primary" type="button" onClick={setModalOpen}>
            {t("sec")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    filterCategories: state.filterCategoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getFilterCategories: bindActionCreators(
        categoryActions.getFilterCategories,
        dispatch
      ),
    },
  };
}

export default withRouter(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CategoryFilter)
  )
);
