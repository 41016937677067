import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row, Input, InputGroup, ListGroup, ListGroupItem, Label} from "reactstrap";
import notify from "devextreme/ui/notify";
import { FormGroup } from "semantic-ui-react";

import {  callAPI,
    callApiGet,
    callApiPost,
    callApiPut } from "../../../../../components/Axios";

const additionalConfirmation = (event,t) =>
{
   

   if (event.state.data.WorkStatusID === 'A235C463-B950-EB11-A2CB-005056B81088')
   {

      return notify(t("talepDurumuSonlandırıldıOlanTaleplerEkOnayaTabiTutulamaz!!"), "error", 2500);
      
   }
   else
   {
      if (event.state.selectedCompanyID === '' || event.state.selectedCompanyID === null)
      {

         return notify(t("kurumAlanıZorunludur!!"), "error", 2500);
      
      }
   }

    let additionalConfirmationTask ={
      TaskID:event.state.taskID,
      ConfirmationUserID:event.state.selectedCompanyUserID,
      Description:event.state.selectedAdditionalConfirmationDescription
   } 
  
   let formData = new FormData();
   formData.append('jsonText', JSON.stringify(additionalConfirmationTask));
   
  

   event.props.actions.isLoading(true);
   const url = `api/TaskDetail/AdditionalConfirmation`;


   callAPI(
    callApiPost,
    url,
    formData,
     (res) => {
      if(res.data==="Sonlandırılan Talep Ek Onaya Gönderilemez"){
         event.props.actions.isLoading(false);
         event.setState({
            visibleAdditionalConfirmation: false,
            selectedCompanyID: undefined,
            selectedCompanyUserID: undefined,
            selectedAdditionalConfirmationDescription:""
            , taskCompaniesTemp: [], companySelectToolListVisible: false, companySelectTool:''
          })
         event.getData();
        return notify(t("sonlandırılanTalepEkOnayaGonderilemez"), "error", 2500);
     

      }
        if(res.data){
            event.props.actions.isLoading(false);
            event.setState({
               visibleAdditionalConfirmation: false,
               selectedCompanyID: undefined,
               selectedCompanyUserID: undefined,
               selectedAdditionalConfirmationDescription:"",
               taskCompaniesTemp: [], companySelectToolListVisible: false, companySelectTool:''
             })
              notify(t("talepEkOnayaGonderilmiştir."), "success", 2500);

             event.getData();

        }
       

    })


}

const AdditionalConfirmationModal = ({t, event}) =>
{  const onClickFilter = (filter) => {
   if (filter.ID !== '') {
       event.setState({ companySelectTool: filter.Name })
       event.handleChange('selectedCompanyID', filter.ID)
      event.setState({ companySelectToolListVisible: false });
   }
}
const companyFilter = (e) => {
   event.setState({ companySelectToolListVisible: true });
   var companies = event.state.companies;
   if (event.state.companySelectTool.length > 2) {
       //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

       //   

       var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(event.state.companySelectTool.toLowerCase()));

       event.setState({ taskCompaniesTemp: filteredCompanies });
       //})
   } else {
       let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(event.state.companySelectTool.toLowerCase())).slice(0,30);
       let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: "Daha fazla Firma için en az 3 karakter girmelisiniz...", ParentID: null };
       tempCompanies = tempCompanies.concat(itemdef);
       event.setState({ taskCompaniesTemp: tempCompanies });
   }

}
   return (
      <Modal
         style={{zIndex: 1}}
         centered={true}
         isOpen={event.state.visibleAdditionalConfirmation}
      >

         <ModalHeader>{t("ekOnayaGönder")}</ModalHeader>
         <ModalBody>
            {/* {BB.DxDropDownWithURL('selectedPoolDevret', t('hedefHavuz'), event, 'api/TaskDetail/GetAllPool', t('lütfenseçiniz'))} */}

            <FormGroup>
            {/* {BB.DxDropDownWithJSON(
                  "selectedCompanyID",
                  "Kurum",
                  event,
                  event.state.companies
                )} */}  
<Label>
                        <strong>{t("kurumListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={event.state.companySelectTool} type="text" autoFocus={true} name={'selectedCompanyID'} onChange={(e) => { event.setState({ companySelectTool: e.target.value }, companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px',display: event.state.companySelectToolListVisible ? 'block' : 'none'}}>
                        {
                            event.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === event.state.selectedCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={() => onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>




                  {BB.DxDropDownWithJSON(
                  "selectedCompanyUserID",
                  t("kurumKullanıcıları"),
                  event,
                  event.state.companyUser
                )}
  </FormGroup>
            {/* {BB.DxDropDownWithURL('selectedPoolUserID', t('hedefKullanici'), event, 'api/Common/GetPoolUsers?poolID=' + event.state.selectedPoolDevret, t('lütfenseçiniz'), !event.state.selectedPoolDevret)} */}
            {BB.TextAreaWithLabel('selectedAdditionalConfirmationDescription', t('açıklama'), t('açıklama'), 5, event)}
            <Row style={{float: 'right'}}>
               <Button
                  color={'info'}
                  onClick={() => event.setState({
                    visibleAdditionalConfirmation: false,
                    selectedCompanyID: undefined,
                    selectedCompanyUserID: undefined,
                    selectedAdditionalConfirmationDescription:"",
                     taskCompaniesTemp: [], companySelectToolListVisible: false, companySelectTool:'' 

                  })}>
                  {t('vazgec')}
               </Button>
               <Button
                  color={'success'}
                  disabled={!event.state.selectedCompanyID}
                  style={{marginLeft: 20, marginRight: 15}}
                  onClick={() => additionalConfirmation(event,t)}>
                  {t("gonder")}

               </Button>
               
            </Row>
         </ModalBody>

      </Modal>
   )
}

export default AdditionalConfirmationModal;
