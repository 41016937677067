import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {Button, ModalFooter} from "reactstrap";
import ReactDataGrid from "react-data-grid";
import {post} from "axios";
import notify from "devextreme/ui/notify";

const TaskViewSheet = ({t, event}) =>
{
    const saveSheet = () => {
        const formData = new FormData();

        formData.append('TaskID', event.state.taskID);
        formData.append('customSheetID', event.state.selectedSheetID);
        formData.append('customSheetValues', JSON.stringify(event.state.rows));

        post('api/TaskDetail/AddCustomSheetValues', formData, {headers: {'content-type': 'multipart/form-data'}})
            .then(res =>
            {
                if (res)
                {
                    notify(t("Basarılı!"), 'success', 2000);
                    event.getCustomSheet();
                }
                else
                {
                    notify(t("hata"), 'error', 2000);
                }
            });
    }

    return (

        <Modal
            isOpen={event.state.preview2Visible} size={'xl'}
            centered={true} className={'customModal'}>
            <ModalHeader
                toggle={() => event.setState({preview2Visible: false})}>{event.state.selectedSheetName}</ModalHeader>
            <ModalBody style={{marginRight: 40}}>
                <ReactDataGrid
                    columns={event.state.selectedSheet}
                    rowGetter={i => event.state.rows[i]}
                    rowsCount={event.state.rows.length}
                    onGridRowsUpdated={event.onGridRowsUpdated}
                    enableCellSelect={true}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color={'info'}
                    onClick={() =>
                    {
                        let columns = event.state.selectedSheet;
                        if (columns.length > 0)
                        {
                            let rowData = [];
                            let obj = {};
                            columns.map((item, inx) =>
                            {
                                obj[item.key] = '';
                                if (inx === 0)
                                {
                                    rowData.push(obj);
                                }
                            })
                            event.setState({rows: event.state.rows.concat(rowData)});
                        }
                    }}
                >
                    {t("yeniSatırOlustur")}
                </Button>

                <Button
                    onClick={() => saveSheet()}
                    color={'success'}
                >
                    {t("kaydet")}
                </Button>
            </ModalFooter>

        </Modal>
    )
}

export default TaskViewSheet;

