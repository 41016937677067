import React, {useEffect ,useState} from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import "./i18n";

import "react-form-builder2/dist/app.css";
import { loadMessages, locale } from "devextreme/localization";
import DefaultLayout from "./containers/DefaultLayout";
import SessionTimeOut from "../src/components/sessionTimeOutPopup";
import ForgotPassword from "./views/User/Login/ForgotPassword";
import CustomDashBoard from "./views/User/CustomDashboard/CustomDashboard";
import AbcLogin from "./views/User/CustomLogin/Abc";
import SkodaLogin from "./views/User/CustomLogin/Skoda";
import DogusLogin from "./views/User/CustomLogin/Dogus";
import PasaliLogin from "./views/User/CustomLogin/Pasali";
import AvecLogin from "./views/User/CustomLogin/Avec";
import Login from "./views/User/Login/Login";
import LoggedIn from "./views/User/Test/LoggedIn";
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import trMessages from "devextreme/localization/messages/tr.json";


import MailSurvey from "./views/User/Surveys/MailSurvey";
import { AuthProvider,useAuth } from 'oidc-react';

import { callAPI,callApiPost } from "../src/components/Axios";
import notify from "devextreme/ui/notify";

//Functional Componente Çevrildi SingleSignOn için  . . .


function App() {
 const [isLoading,setIsLoading]=useState(false)
 const [customLogin,setCustomLoging]=useState(true)
 const [isRedirect,setIsRedirect]=useState(false)
 const [customPage]=useState(window.location.host)
 const [isTokenOk,setIsTokenOk]=useState(false)

if(window.location.href.includes("error=access_denied")){
  localStorage.removeItem("isExternal");
  window.location.href =  window.location.origin+"/#!"+"/login";
}
useEffect(() => {
    initMessages()
  },[]);

 const initMessages=()=>{
    loadMessages(trMessages);
    loadMessages(deMessages);
    loadMessages(enMessages);
  }

const token = localStorage.getItem("token");
const isExternal = localStorage.getItem("isExternal");
let oidcConfig={};

if(!token){
   oidcConfig = {
    // onSignIn: (user) => {
  
    //   let formData = new FormData();
    //   formData.append('jsonText', JSON.stringify(user));
    //   var url = '/api/Login/TurkuazLogin'
    //   callAPI(
    //     callApiPost,
    //     url,
    //     formData,
    //      (res) => {
    //       if (res.data.Token !== undefined && res.data.Token !== "") {
    //         localStorage.setItem("token", res.data.Token);
    //         localStorage.setItem("isExternal", null);
            
    //         window.location.href =  window.location.origin+"/#!"+"/main";
          
    //       } else {
    //         notify("Kullanıcı Adı Veya Şifre Hatalı.", "error", 3000);
    //       }
      
    //     })
    // },
    
    
    authority: "https://www.turkuazgo.com/im",
    scope: "openid profile",
    clientId: "tys",
    responseType: "code",
    redirectUri:"https://talep.dohas.com.tr/",
    //http://localhost:5000/ 
    //https://talep.dohas.com.tr/
    //https://test.turkuazgo.com/im
    //https://www.turkuazgo.com/im
  }; //#!/callback
}
if(isExternal!==null){
  return(
    <AuthProvider {...oidcConfig}>
       {/* <HashRouter hashType={"hashbang"}>
        <Route
        exact
        path="/login"
        name="Login Page"
        render={(props) => <DefaultLayout {...props} />}
      /></HashRouter> */}
          <LoggedIn></LoggedIn>

    </AuthProvider>
  )
}

// if (process.env.NODE_ENV === 'development') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }
return (
  <HashRouter hashType={"hashbang"}>
        <React.Suspense>
          <Switch>
            {isLoading ? (
              <>
                <div className="animated fadeIn pt-3 text-center">
                  Yükleniyor...
                </div>
              </>
            ) : customLogin ? (
              customPage.indexOf("abc") > -1 ? (
                <Route
                  exact
                  path="/Login"
                  name="Login Page"
                  render={(props) => <AbcLogin {...props} />}
                />
              ) : customPage.indexOf("skoda") > -1 ? (
                <Route
                  exact
                  path="/Login"
                  name="Login Page"
                  render={(props) => <SkodaLogin {...props} />}
                />
              ) : customPage.indexOf("pasali") > -1 ? (
                <Route
                  exact
                  path="/Login"
                  name="Login Page"
                  render={(props) => <PasaliLogin {...props} />}
                />
              ) : customPage.indexOf("dohas") > -1 ? (
                <Route
                  exact
                  path="/Login"
                  name="Login Page"
                  render={(props) => <DogusLogin {...props} />}
                />
              ) : customPage.indexOf("avecrentacar") > -1 ? (
                <Route
                  exact
                  path="/Login"
                  name="Login Page"
                  render={(props) => <AvecLogin {...props} />}
                />
              ) : (
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  // Portal Login
                  render={(props) => <Login {...props} />}

                  //Dogus Login 
                  //  render={(props) => <DogusLogin {...props} />}

                />
               
              )
            ) : (
              <Route
                exact
                path="/login"
                                  // Portal Login
                render={(props) => <Login {...props} />}

                name="Login Page"
                  //Dogus Login 
                  // render={(props) => <DogusLogin {...props} />}
              />
            )}

            <Route
              exact
              path="/forgotPassword/:id"
              name="Password"
              render={(props) => <ForgotPassword {...props} />}
            />

            <Route
              exact
              path="/customdashboard"
              name="Custom Dashboard"
              render={(props) => <CustomDashBoard {...props} />}
            />
            <Route
              exact
              path="/callback"
              name="Zaman Aşımı"
              render={(props) => <SessionTimeOut {...props} />}
            />

            <Route
              exact
              path="/SessionTimeOut"
              name="Zaman Aşımı"
              render={(props) => <SessionTimeOut {...props} />}
            />

            <Route
              path="/SurveyMail"
              name="Mail Survey"
              render={(props) => <MailSurvey {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>   
    );
  }

  export default App