import React, { Component } from "react";
import { Button } from "reactstrap";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import { connect } from "react-redux";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import Buttons from 'devextreme-react/button';
import { withTranslation } from "react-i18next";
import Query from "devextreme/data/query";
import { callAPI, callApiGet, callApiPost } from "../../../components/Axios";
import jwt_decode from "jwt-decode";
import { t } from "i18next";
let languages = undefined;

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth();
let year = newDate.getFullYear();
let hour = newDate.getHours();
let min = newDate.getMinutes();
const currentDate = new Date(year, month, date, hour, min);
const groups = ["employeeID"];
const views = ["month", "week", "day"];

class Activities extends Component {
  constructor(props) {
    super(props);
    //this.dataGridRef = React.createRef();

    this.onAppointmentFormOpening = this.onAppointmentFormOpening.bind(this);
    let user = null;
   
    let { t } = this.props;

  
    var token = localStorage.getItem("token");

    if (token) {
      user = jwt_decode(token);
    }

    this.state = {
      gridData: [],
      params: undefined,
      editRowVisible: false,
      addRowVisible: false,
      loginUser: user,
      newStartDate: undefined,
      newEndDate: undefined,
      newActivityType: undefined,
      newJobNumber: undefined,
      newMessege: undefined,
      selectedID: undefined,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
      selectedActivityType: undefined,
      selectedJobNumber: undefined,
      selectedMessege: undefined,
      dataScheduler: [],
      timeLineRef: null,
      GetResourceUserData: [],
      selectedCellInfo:undefined
    };
    this.GetResourceUserData();
  }

  componentDidMount() {
    this.getDatas();

    this.getDatasForScheduler();
    this.props.actions.isLoading(false);
  }
  


  getDatas() {
    //GetResourceData
    callAPI(
      callApiGet,
      "/api/Activity/GetData",
      {},
      (res) => {
        if (res.data) {
          this.setState({
            gridData: res.data,
            isLoading: false,
          });
        }
      },
      true
    );

    callAPI(
      callApiGet,
      "/api/Activity/GetProjectTypes",
      {},
      (res) => {
        if (res.data) {
          this.setState({
            params: res.data,
            isLoading: false,
          });
        }
      },
      true
    );
  }

  GetResourceUserData() {
    //GetResourceData
    callAPI(
      callApiGet,
      "/api/Activity/GetResourceUserData",
      {},
      (res) => {
        if (res.data) {
          this.setState({
            GetResourceUserData: res.data,
          });
        }
      },
      true
    );
  }

  getDatasForScheduler() {
    //GetResourceData
    callAPI(
      callApiGet,
      "/api/Activity/GetResourceData",
      {},
      (res) => {
        if (res.data) {
          this.setState({
            dataScheduler: res.data,
          });
        }
      },
      true
    );
  }


  handleChange(id, value) {
    this.setState({ [id]: value });
  }

  isWeekEnd(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

 
  AddFunction=(e)=> {
    //var newtime =  new Date(e.appointmentData.startDate.getHours())


    let entity = {
      StartDate: e.appointmentData.startDate,
      EndDate: e.appointmentData.endDate,
      ParameterID: e.appointmentData.projectId,
      JobNumbers: e.appointmentData.isNo,
      Description: e.appointmentData.text,
    };
    const formData = new FormData();
    formData.append("jsonText", JSON.stringify(entity));
    post("api/Activity/Add2", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.Message=== "İşlem Başarılı.") {
          
          //         this.setState(
          //   {
          //     editRowVisible: false,
          //     addRowVisible: false,
          //     newStartDate: undefined,
          //     newEndDate: undefined,
          //     newActivityType: undefined,
          //     newJobNumber: undefined,
          //     newMessege: undefined,
          //   },
           
          // );
          notify(t("islemBasarili"), "success", 3000);
          this.getDatasForScheduler();  
        } else {
          this.setState(
            {
              editRowVisible: false,
              addRowVisible: false,
              newStartDate: undefined,
              newEndDate: undefined,
              newActivityType: undefined,
              newJobNumber: undefined,
              newMessege: undefined,
            },
            this.getDatasForScheduler()
          );
          notify(res.data.Message, "warning", 1000);
        }
       // this.schedulerInstance.getDataSource().reload();
      })
      .catch((ex) => {});
  }

  updateFunction=(e)=> {
    var projectıd =undefined;
      if (e.newData.projectId && e.oldData.projectId && e.newData.projectId.length === e.oldData.projectId.length){
        projectıd=e.newData.projectId
    }
    else{
      projectıd=e.oldData.projectId

    }

    let entity = {
      StartDate: e.newData.startDate,
      EndDate: e.newData.endDate,
      ParameterID: projectıd,
      JobNumbers: e.newData.isNo,
      Description: e.newData.text,
      ID: e.newData.id,
    };

    const formData = new FormData();
    formData.append("jsonText", JSON.stringify(entity));

    post("api/Activity/Update", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res.data.MessageStatus === true) {
          this.getDatasForScheduler();  

          notify(res.data.Message, "success", 5000);

        } else {
          this.getDatasForScheduler();  

          notify(res.data.Message, "warning", 5000);

        }
      })
      .catch((ex) => {
        //this.props.actions.isLoading(false);
      });

  }

  onAppointmentFormOpening(e) {
    const { form } = e;
    let dataInfo =
      getMovieById(e.appointmentData.id, this.state.dataScheduler) || {};
    form.updateData("startDate",(dataInfo.length == 0?this.state.selectedCellInfo.cellData.startDate:dataInfo.startDate));
    form.updateData("endDate",(dataInfo.length == 0?this.state.selectedCellInfo.cellData.endDate:dataInfo.endDate));
    form.option("items", [
      {
        label: {
          text: t("aktiviteTipi"),
        },
        editorType: "dxSelectBox",
        dataField: "projectName",
        editorOptions: {
          items: this.state.params,
          displayExpr: "Name",
          valueExpr: "ID",
          value: dataInfo.projectId,
          onValueChanged(args) {
            let activityType = getActivityById(args.value);
            form.updateData("projectId", activityType);
          },
        },
      },
      {
        dataField: t("baslangicTarihi"),
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          pickerType: "calendar",
          type: "datetime",
          displayFormat: "dd-MM-yyyy HH:mm",
          value: (dataInfo.length == 0?this.state.selectedCellInfo.cellData.startDate:dataInfo.startDate),
          onValueChanged(args) {
            let startDate = args.value;
            form.updateData("startDate", startDate);
          },
        },
      },
      {
        label: {
          text: t("isNo"),
        },
        name: "isNo",
        editorType: "dxTextBox",
        editorOptions: {
          onValueChanged(args) {
            form.updateData("isNo", args.value);
          },
          readOnly: false,
          value: dataInfo.isNo,
        },
      },
      {
        dataField: t("bitistarihi"),
        editorType: "dxDateBox",
        editorOptions: {
          width: "100%",
          pickerType: "calendar",
          type: "datetime",
          displayFormat: "dd-MM-yyyy HH:mm",
          value:(dataInfo.length == 0?this.state.selectedCellInfo.cellData.endDate:dataInfo.endDate),
          onValueChanged(args) {
            // var sub = args.value.split(":");
            // if(sub[0].includes("00")&&sub[1]==="00"&&sub[2]==="00"){
            //    return notify("Saat 12 Değeri AM olarak işaretlenemez PM olarak değiştiriniz .", "warning", 5000);
            // }
            let endDate = args.value;
            form.updateData("endDate", endDate);
          },
        },
      },
      {
        label: {
          text: t("mesaj"),
        },
        name: "text",
        editorType: "dxTextArea",
        colSpan:2,
        colCount:2,
        editorOptions: {
          onValueChanged(args) {
            form.updateData("text", args.value);
          },
          value: dataInfo.text,
        },
      },
    ]);
  }

  renderAppointmentTooltip = (model) => {
    return (
      <div style={{ height: "10px" }}>
        <i>{model.appointmentData.text} </i>
      </div>
    );
  };

  

  deleteFunction=(e)=> {
    
    const date = new Date(e.appointmentData.createDate);

    var s =new Date(currentDate-date);
    var id = e.appointmentData.id;
    post("/api/Activity/Delete?ID=" + id, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((res) => {
        
        if (res.data.MessageStatus === true) {
          debugger
          notify(t("islemBasarili"), "success", 5000);
          debugger
          this.getDatasForScheduler();  


        } else {
          debugger
          notify(res.data.Message, "warning", 5000);

          this.getDatasForScheduler();  


        }
      })
      .catch((ex) => {
        //this.props.actions.isLoading(false);
      });



    // callAPI(callApiPost, "/api/Activity/Delete?ID=" + id, {}, (res) => {
    //   if (res.data) {
    //     if (res.data.MessageStatus === true) {
    //       this.getDatasForScheduler(); 

    //       notify("Aktivite Silinmiştir . ", "success", 2000);
    //     } else {
    //       this.getDatasForScheduler(); 
    //       notify(res.data.Message, "error", 2000);

    //     }
    //   } else {
    //   }
    // });
  }

  render() {
    const sData = this.state.dataScheduler;
    const sUser = this.state.loginUser;
    let employeData = {
      text: sUser.Name + " " + sUser.SurName,
      id: sUser.ID,
      color: "#56ca85",

      
    };
   
    const getCellInfo = (e) =>{
      this.state.selectedCellInfo = e;
    }
    
    return (
      <Scheduler
      
        //timeZone="UTC"
        dataSource={sData}
        //allowEditingTimeZones={true}
        //dataCellComponent={DataCell}
        //resourceCellComponent={ResourceCell}
        groups={groups}
        ref={this.state.timeLineRef}
        //cellDuration={60}
        views={views}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        height={600}
        //showAllDayPanel={true}
        firstDayOfWeek={0}
        startDayHour={0}
        endDayHour={23}
        //appointmentTooltipRender={this.renderAppointmentTooltip}
        onAppointmentFormOpening={this.onAppointmentFormOpening}
        onAppointmentUpdating={this.updateFunction}
        onAppointmentDeleting={this.deleteFunction}
        onAppointmentAdded={this.AddFunction}
        onCellClick={getCellInfo}
      >
         
        <Resource
          label="Employee"
          fieldExpr="id"
          dataSource={employeData}
          allowMultiple={false}
        />
      </Scheduler>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}
function getMovieById(id, data) {
  if(id===undefined){
    let arry =[]
    return arry;
  }
  else{
      let s = Query(data).filter(["id", id]).toArray()[0];
  return s;

  }

}
function getActivityById(id) {
  return id;
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Activities));