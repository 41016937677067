export default class TaskModel
{
   ApprovalDate: DateTime;
   CategoryID: Guid;
   ClosingDate: DateTime;
   ConfirmingManagerID: Guid;
   CreateDate: DateTime;
   CreateUserID: Guid;
   DegreeOfUrgency: int;
   Description: string;
   HTMLDescription: string;
   EffectID: Guid;
   ExpectedEndDate: DateTime;
   ID: Guid;
   MainTaskID: Guid;
   NotificationType: _NotificationType;
   PoolID: Guid;
   ResponsibleUserID: Guid;
   StatusID: Guid;
   TaskNumber: int;
   TaskTypeID: Guid;
   TerminationDate: DateTime;
   TerminationDescription: string;
   TerminationPatameterID: Guid;
   UpdateDate: DateTime;
   UpdateUserID: Guid;
   UrgencyDescription: string;
   WorkStatusID: Guid;
   WorkUserID: Guid;
   CustomControlForms: Array;
   MailNotification: Array;
   WatcherUsers: Array;
   WatcherGroups: Array;

}

export class _DegreeOfUrgency
{
   Düşük = 1;
   Orta = 2;
   Yüksek = 3;
}

export class _CustomControlForms
{
   ID = String;
   Value = String;
}
