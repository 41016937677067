import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class ManageDashboard extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         groups: [],

         selectedParameterID: undefined,
         newParameterName: undefined,

         editRowVisible: false,
         editID: undefined,

         addNewQuery: false,
         addNewDashboard: false,
         editDashboard: false,
         selectedParentID: undefined,
         selectedName: undefined,
         selectedFlipInterval: 0,
         selectedHigherThan: false,
         selectedChangeBackground: false,
         selectedGroup: undefined,
      }
   }

   componentDidMount()
   {
      this.getDatas();
   }

   getDatas()
   {
      let url = 'api/Yonetim/CustomDashboard/GetData';
      let URL1 = 'api/Yonetim/Group/GetAllGroup';

      BB.FetchJSON(url).then(data =>
      {
         BB.FetchJSON(URL1).then(groups =>
         {
            this.setState({gridData: data.Data, isLoading: false, selectedParentID: undefined, groups: groups});
         });
      });
   }

   getSubParamater(ID)
   {
      let url = 'api/Yonetim/CustomDashboard/DashboardItem?id=' + ID;

      BB.FetchJSON(url).then(data =>
      {
         this.setState({gridData: data.Data, isLoading: false, selectedParentID: ID})
      });
   }

   handleChange(id, value)
   {
      if (id === 'selectedChangeBackground')
      {
         this.setState({[id]: !this.state.selectedChangeBackground});
      }
      else
      {
         if (id === 'selectedHigherThan')
         {
            this.setState({[id]: !this.state.selectedHigherThan});
         }
         else
         {
            this.setState({[id]: value});
         }
      }
   }

   addDashboard()
   {
      if (this.state.selectedName === '')
      {
         notify('Zorunlu alanları doldurunuz!', "warning", 2000);
      }
      else
      {
         let entity = {};

         if (this.state.editID === undefined)
         {
            entity = {Title: this.state.selectedName, FlipInterval: this.state.selectedFlipInterval};
         }
         else
         {
            entity = {
               Title: this.state.selectedName,
               FlipInterval: this.state.selectedFlipInterval,
               ID: this.state.editID
            };
         }

         const formData = new FormData();

         formData.append('jsonText', JSON.stringify(entity));

         if (this.state.selectedGroup !== undefined)
         {
            formData.append('groupID', this.state.selectedGroup);
         }

         post('api/Yonetim/CustomDashboard/AddDashboad', formData, {headers: {'content-type': 'multipart/form-data'}}).then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addNewDashboard: false,
                  editDashboard: false,
                  selectedName: '',
                  selectedFlipInterval: null
               });
               this.getDatas();
               notify(t("islemBasarili"), "success", 4000);

            }
            else
            {
               this.setState({
                  addNewDashboard: false,
                  editDashboard: false,
                  selectedName: '',
                  selectedFlipInterval: null
               });

               notify(t("islemBasarisiz") + res.Message, "warning", 4000);
            }
         })
         .catch(ex =>
         {
            this.setState({
               addNewDashboard: false,
               editNewDashboard: false,
               selectedName: '',
               selectedFlipInterval: null
            });

            notify(t("islemBasarisiz") + ex, "warning", 4000);
         });
      }
   }

   addQuery()
   {
      if (this.state.selectedSubTitle === '')
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         let entity = {
            SubTitle: this.state.selectedSubTitle,
            OptimumValue: this.state.selectedOptimumValue,
            Query: this.state.selectedSubQuery,
            ChangeBackground: this.state.selectedChangeBackground ? this.state.selectedChangeBackground : false,
            HigherThan: this.state.selectedHigherThan ? this.state.selectedHigherThan : false,
            SubQuery: this.state.selectedQuery,
            CustomDashboardID: this.state.selectedParentID,
         };

         const formData = new FormData();

         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/CustomDashboard/AddDashboardItem', formData, {headers: {'content-type': 'multipart/form-data'}}).then(res =>
         {
            if (res.status === 200)
            {
               this.setState({
                  addNewDashboard: false,
                  SubTitle: '',
                  OptimumValue: '',
                  Query: '',
                  ChangeBackground: false,
                  HigherThan: false,
                  SubQuery: '',
                  CustomDashboardID: '',
                  addNewQuery: false,
               });
               this.getDatas()
               notify(t("islemBasarili"), "success", 4000);
            }
            else
            {
               this.setState({addNewDashboard: false,});

               notify(t("islemBasarisiz") + res.Message, "warning", 4000);
            }
         })
         .catch(ex =>
         {
            this.setState({
               addNewDashboard: false,
               addNewQuery: false,
            });
            this.getDatas()
            notify(t("islemBasarisiz") + ex, "warning", 4000);
         });
      }
   }

   editQuery()
   {
      if (this.state.newParameterName === '')
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ParentID: this.state.selectedParentID
            , Name: this.state.newParameterName
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/CustomDashboard/AddDashboardItem', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {

               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   deleteDashboard(ID)
   {
      BB.askYesNo(t('Onaylıyormusunuz?'), t("sectiginizdashboardsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            const formData = new FormData();
            formData.append('id', ID);

            post('api/Yonetim/CustomDashboard/Delete', formData, {headers: {'content-type': 'multipart/form-data'}})
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.getDatas();
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
               }
            })
            .catch(ex =>
            {
               notify(t("islemBasarisiz") + ex, "warning", 4000);
            });
         }
      })
   }

   deleteQuery(ID)
   {
      BB.askYesNo(t('Onaylıyormusunuz?'), t("sectiginizquerysilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.state.selectedParameterID = ID;

            this.props.actions.isLoading(true);

            const formData = new FormData();
            formData.append('id', ID);

            post('api/Yonetim/CustomDashboard/DeleteItem', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === false)
               {
                  this.getSubParamater(ID);
                  notify(t("kayitsilindi"), "success", 1000);
                  this.props.actions.isLoading(false);
                  this.getDatas();
               }
               else
               {
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
                  this.getDatas();
               }
            })
            .catch(ex =>
            {
               notify(t("islemBasarisiz") + ex, "warning", 4000);
            });
         }
      })
   }

   actionButton(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.getSubParamater(cellData.data.ID)}>
               {t("sorgular")}
            </Button>
            <Button
               color={'primary'}
               outline
               style={{marginLeft: 5}}
               onClick={() =>
               {
                  let URL = 'api/Yonetim/CustomDashboard/GetDashboardGroup?DID=' + cellData.data.ID;

                  BB.FetchJSON(URL).then(data =>
                  {
                     if (data && data.length > 0)
                     {
                        this.setState({selectedGroup: data[0].GroupID})
                     }
                  });

                  this.setState({
                     editDashboard: true,
                     editID: cellData.data.ID,
                     selectedName: cellData.data.Name,
                     selectedFlipInterval: cellData.data.FlipInterval
                  });
               }
               }>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() => this.deleteDashboard(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   actionButtonDetail(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               style={{marginLeft: 5}}
               onClick={() => this.setState({
                  editRowVisible: true,
                  selectedParentID: cellData.data.ID,
                  selectedSubTitle: cellData.data.SubTitle,
                  selectedOptimumValue: cellData.data.OptimumValue,
                  selectedChangeBackground: cellData.data.ChangeBackground,
                  selectedHigherThan: cellData.data.HigherThan,
                  selectedSubQuery: cellData.data.SubQuery,
                  selectedQuery: cellData.data.Query,
               })}>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() => this.deleteQuery(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      //Ana dashboardların render edildiği yer
      if (this.state.selectedParentID === undefined)
      {
         return (
            <div className="animated fadeIn">
               <Col>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() =>
                           {
                              this.setState({
                                 addNewDashboard: true,
                                 selectedName: '',
                                 selectedFlipInterval: ''
                              })
                           }}
                           style={{float: 'left', position: 'absolute', zIndex: 1,}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName=""/>
                           <Column
                              dataField="Name"
                              caption={t("adı")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="FlipInterval"
                              caption={t("donusms")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={250}
                              cellRender={(cellData) => this.actionButton(cellData)}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               {/*Dashboard Ekleme*/}
               <Modal
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.addNewDashboard}
               >
                  <ModalHeader>{t("dashboardekle")}</ModalHeader>
                  <ModalBody>
                     {BB.InputWithLabel('selectedName', t("baslik"), 'text', this, '')}
                     {BB.InputWithLabel('selectedFlipInterval', t("donusms"), 'number', this, '')}
                     {BB.DxDropDownWithJSON('selectedGroup', t("goruntulenecegigrubuseciniz"), this, this.state.groups)}
                     <Row style={{float: 'right'}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addNewDashboard: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 15}}
                           onClick={() => this.addDashboard()}
                        >
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>


               {/*Dashboard Düzenleme*/}
               <Modal
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.editDashboard}
               >
                  <ModalHeader>Dashboard Ekle</ModalHeader>
                  <ModalBody>
                     {BB.InputWithLabel('selectedName', t("baslik"), 'text', this, '')}
                     {BB.InputWithLabel('selectedFlipInterval', t("donusms"), 'number', this, '')}
                     {BB.DxDropDownWithJSON('selectedGroup',  t("goruntulenecegigrubuseciniz"), this, this.state.groups)}
                     <Row style={{float: 'right'}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({editDashboard: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 15}}
                           onClick={() => this.addDashboard()}
                        >
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>
            </div>
         );
      }
      else //Detay Kısmının render edildiği yer
      {
         return (
            <div className="animated fadeIn">
               <Col>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() =>
                           {
                              this.getDatas();
                           }}
                           style={{float: 'left', position: 'absolute', zIndex: 1, marginLeft: 60}}
                           type="button"
                        >
                           {t("geri")}
                        </Button>

                        <Button
                           color={'success'}
                           outline
                           onClick={() =>
                           {
                              this.setState({
                                 addNewQuery: true,
                                 selectedSubTitle: '',
                                 selectedOptimumValue: '',
                                 selectedChangeBackground: '',
                                 selectedHigherThan: '',
                                 selectedQuery: '',
                                 selectedSubQuery: ''
                              })
                           }}
                           style={{float: 'left', position: 'absolute', zIndex: 1,}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName=""/>
                           <Column
                              dataField="SubTitle"
                              caption={t("baslik")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="OptimumValue"
                              caption={t("optimumdeger")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="ChangeBackground"
                              caption={t("arkaplanrengideger")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="HigherThan"
                              caption={t("degerbuyukkendegis")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={250}
                              cellRender={(cellData) => this.actionButtonDetail(cellData)}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>


               {/*Query Düzenleme*/}
               <Modal
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.editRowVisible}
               >
                  <ModalHeader>Sorgu Düzenle</ModalHeader>
                  <ModalBody>
                     {BB.InputWithLabel('selectedSubTitle', t("baslik"), 'text', this, '')}
                     {BB.InputWithLabel('selectedOptimumValue', t("optimumdeger"), 'number', this, '')}
                     {BB.CheckBoxWithLabel('selectedChangeBackground', t("arkaplanrengidegistirilsinmi"), this)}
                     {BB.CheckBoxWithLabel('selectedHigherThan', t("optimumdegerdenbuyuksemiarkaplandegissin"), this)}
                     {BB.TextAreaWithLabel('selectedSubQuery', t("anasorgu"), '', 10, this)}
                     {BB.TextAreaWithLabel('selectedQuery', t("altsorgu"), '', 10, this)}
                     <Row style={{float: 'right'}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({editRowVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 15}}
                           onClick={() => this.editQuery()}
                        >
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>


               {/*Query Ekleme*/}
               <Modal
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.addNewQuery}
               >
                  <ModalHeader>{t("sorguekle")}</ModalHeader>
                  <ModalBody>
                     {BB.InputWithLabel('selectedSubTitle', t("baslik"), 'text', this, '')}
                     {BB.InputWithLabel('selectedOptimumValue', t("optimumdeger"), 'number', this, '')}
                     {BB.CheckBoxWithLabel('selectedChangeBackground', t("arkaplanrengidegistirilsinmi"), this)}
                     {BB.CheckBoxWithLabel('selectedHigherThan', t("optimumdegerdenbuyuksemiarkaplandegissin"), this)}
                     {BB.TextAreaWithLabel('selectedSubQuery', t("anasorgu"), '', 10, this)}
                     {BB.TextAreaWithLabel('selectedQuery', t("altsorgu"), '', 10, this)}
                     <Row style={{float: 'right'}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addNewQuery: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 15}}
                           onClick={() => this.addQuery()}
                        >
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>
            </div>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageDashboard));
