import React from "react";
import {FormGroup, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import TextBox from "devextreme-react/text-box";
import {Dropdown} from "semantic-ui-react";
import {bindActionCreators} from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as BB from "../../BBComponent";
import {
   callAPI,
   callApiGet,
   callApiPost,
   callApiPut
 } from "../../../components/Axios";
const getChildCategories = (UserID, ParentID) => new Promise((resolve) =>
{
   let url = UserID !== null && '/api/Task/GetCategories?UserID=' + UserID + '&ParentID=' + ParentID;
   callAPI(
      callApiGet,
      url,
      {},
      async (res) => {
        resolve(res?.data)
      },
      true
    );
});

const categoryRender = (id, value, event) =>
{ 
   if (id === 'selectedParentCategory')
   {
      event.setState({selectedParentCategory: value, parentCategoryLoading: true, [id]: value});
      let parent = event.state.taskCategories.filter(x => x.key === value)[0];

      event.setState({"categoryPath":parent.text});
      getChildCategories(event.state.selectedEmployee, value).then(data =>
      {
         if (data.length > 0)
         {
            event.setState({
               parentCategoryLoading: false,
               childCategory1Arr: data,
               childCategory2Arr: [],
               childCategory3Arr: [],
               childCategory4Arr: [],
               childCategory5Arr: [],
               childCategory6Arr: []
            })

         }
         else
         {
            let sCategory = event.state.taskCategories.filter(x => x.key === value)[0];
            event.handleChange('selectedCategory', value);
            event.setState({
               parentCategoryLoading: false,
               selectedCategoryName: sCategory.text,
               selectCategory: false,
            })

         }
      })
   }
   
   if (id === 'selectedChildCategory1')
   {
       event.setState({ childCategory1Loading: true, [id]: value }, () => {
           let parent = event.state.childCategory1Arr.filter(x => x.key === value)[0];
           let parentCategoryPath = event.state.categoryPath + '/' + parent.text;

           let pathSegments = parentCategoryPath.split("/");
           pathSegments[1] = parent.text;
           let updatedPath = pathSegments.slice(0, 2).join("/");


           event.setState({ "categoryPath": updatedPath });


           getChildCategories(event.state.selectedEmployee, value).then(data => {
               if (data.length > 0) {
                   event.setState({
                       childCategory1Loading: false,
                       childCategory2Arr: data,
                       childCategory3Arr: [],
                       childCategory5Arr: [],
                       childCategory4Arr: [],
                       childCategory6Arr: []
                   });
               }
               else {
                   event.handleChange('selectedCategory', value);
                   event.setState({
                       selectedCategoryName: updatedPath,
                       selectCategory: false,
                       childCategory1Loading: false
                   });
               }
           });
       });
   }
   if (id === 'selectedChildCategory2')
   {
      let s=[];
      event.setState({childCategory2Loading: true, [id]: value,});
      let parent = event.state.childCategory2Arr.filter(x => x.key == value)[0];
      let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
      s = parentCategoryPath.split("/");
      if(s[3] != null){
         s[2] =parent.text;
      }
      let path = s[0]+"/"+s[1]+"/"+s[2];
      event.setState({"categoryPath":path});
      getChildCategories(event.state.selectedEmployee, value).then(data =>
      {
         if (data.length > 0)
         {
            event.setState({
               childCategory3Arr: data,
               childCategory4Arr: [],
               childCategory5Arr: [],
               childCategory6Arr: [],
               childCategory2Loading: false
            })
         }
         else
         {
            event.handleChange('selectedCategory', value);
            let parentCategoryPath = event.state.categoryPath ;
            event.setState({
               selectedCategoryName: parentCategoryPath,
               selectCategory: false,
               childCategory2Loading: false
            })
         }
      });
   }
   if (id === 'selectedChildCategory3')
   {
      event.setState({childCategory3Loading: true, [id]: value,});
      let s=[];
      let parent = event.state.childCategory3Arr.filter(x => x.key == value)[0];
      let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
      s = parentCategoryPath.split("/");
      if(s[4] != null){
         s[3] =parent.text;
      }
      let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3];
      event.setState({"categoryPath":path});
      getChildCategories(event.state.selectedEmployee, value).then(data =>
      {
         if (data.length > 0)
         {
            event.setState({
               childCategory4Arr: data,
               childCategory5Arr: [],
               childCategory6Arr: [],
               childCategory3Loading: false})
         }
         else
         {
            event.handleChange('selectedCategory', value);
            let parentCategoryPath = event.state.categoryPath ;
            event.setState({
               selectedCategoryName: parentCategoryPath,
               selectCategory: false,
               childCategory3Loading: false
            })
         }
      });
   }
   if (id === 'selectedChildCategory4')
   {  let s=[];
      event.setState({childCategory4Loading: true, [id]: value,});
      let parent = event.state.childCategory4Arr.filter(x => x.key == value)[0];
      let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
      s = parentCategoryPath.split("/");
      if(s[5] != null){
         s[4] =parent.text;
      }
      let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3]+"/"+s[4];
      event.setState({"categoryPath":path});
      getChildCategories(event.state.selectedEmployee, value).then(data =>
      {
         if (data.length > 0)
         {
            event.setState({
               childCategory5Arr: data,
               childCategory6Arr: [],
               childCategory4Loading: false
            })
         }
         else
         {
            event.handleChange('selectedCategory', value);
            let parentCategoryPath = event.state.categoryPath ;
            event.setState({
               selectedCategoryName: parentCategoryPath,
               selectCategory: false,
               childCategory4Loading: false
            })
         }
      });
   }
   if (id === 'selectedChildCategory5')
   {
      let s=[];
      event.setState({childCategory5Loading: true, [id]: value,});
      let parent = event.state.childCategory5Arr.filter(x => x.key == value)[0];
      let parentCategoryPath = event.state.categoryPath +'/'+  parent.text;
      s = parentCategoryPath.split("/");
      if(s[6] != null){
         s[5] =parent.text;
      }
      let path = s[0]+"/"+s[1]+"/"+s[2]+"/"+s[3]+"/"+s[4]+"/"+s[5];
      event.setState({"categoryPath":path});
      getChildCategories(event.state.selectedEmployee, value).then(data =>
      {
         if (data.length > 0)
         {
            event.setState({childCategory6Arr: data, childCategory5Loading: false})
         }
         else
         {
            event.handleChange('selectedCategory', value);
            let parentCategoryPath = event.state.categoryPath ;
            event.setState({
               selectedCategoryName: parentCategoryPath,
               selectCategory: false,
               childCategory5Loading: false
            })
         }
      });
   }
}

const CategorySelect = ({state, event,t}) =>
{

   return (
      <FormGroup>
         <Label>
            <strong>{t("kategorizorunlu")}</strong>
         </Label>
         <TextBox
            placeholder={t("LütfenSeçiniz")}
            readOnly={true}
            value={state.selectedCategoryName}/>

         <Modal
            isOpen={state.selectCategory}
            style={{marginTop: 250}}>
            <ModalHeader
               toggle={() => event.setState({selectCategory: false,})}
            >
               {t("kategoriSecimi")}
            </ModalHeader>
            <ModalBody>
               <FormGroup>
                  <Label>
                     <strong>{t("anaKategori")}</strong>
                  </Label>
                  <div>
                     <Dropdown
                        placeholder={t("LütfenSeçiniz")}
                        search
                        fluid
                        loading={state.parentCategoryLoading}
                        defaultValue={state.selectedParentCategory}
                        options={state.taskCategories}
                        onChange={(e, a) => categoryRender('selectedParentCategory', a.value, event)}
                     />
                  </div>

               </FormGroup>

               {state.childCategory1Arr.length > 0 &&
               <FormGroup>
                  <Label>
                     <strong>{t("altKategori")}</strong>
                  </Label>
                  <br/>
                  <Dropdown
                     placeholder={t("LütfenSeçiniz")}
                     search
                     fluid
                     loading={state.childCategory1Loading}
                     defaultValue={state.selectedChildCategory1}
                     options={state.childCategory1Arr}
                     onChange={(e, a) => categoryRender('selectedChildCategory1', a.value, event)}
                  />
               </FormGroup>
               }

               {state.childCategory2Arr.length > 0 &&
               <FormGroup>
                  <Label>
                     <strong>{t("altKategori")}</strong>
                  </Label>
                  <br/>
                  <Dropdown
                     placeholder={t("LütfenSeçiniz")}
                     search
                     fluid
                     loading={state.childCategory2Loading}
                     defaultValue={state.selectedChildCategory2}
                     options={state.childCategory2Arr}
                     onChange={(e, a) => categoryRender('selectedChildCategory2', a.value, event)}
                  />
               </FormGroup>
               }

               {state.childCategory3Arr.length > 0 &&
               <FormGroup>
                  <Label>
                     <strong>{t("altKategori")}</strong>
                  </Label>
                  <br/>
                  <Dropdown
                     placeholder={t("LütfenSeçiniz")}
                     search
                     fluid
                     loading={state.childCategory3Loading}
                     defaultValue={state.selectedChildCategory3}
                     options={state.childCategory3Arr}
                     onChange={(e, a) => categoryRender('selectedChildCategory3', a.value, event)}
                  />
               </FormGroup>
               }

               {state.childCategory4Arr.length > 0 &&
               <FormGroup>
                  <Label>
                     <strong>{t("altKategori")}</strong>
                  </Label>
                  <br/>
                  <Dropdown
                     placeholder={t("LütfenSeçiniz")}
                     search
                     fluid
                     loading={state.childCategory4Loading}
                     defaultValue={state.selectedChildCategory4}
                     options={state.childCategory4Arr}
                     onChange={(e, a) => categoryRender('selectedChildCategory4', a.value, event)}
                  />
               </FormGroup>
               }

               {state.childCategory5Arr.length > 0 &&
               <FormGroup>
                  <Label>
                     <strong>{t("altKategori")}</strong>
                  </Label>
                  <br/>
                  <Dropdown
                     placeholder={t("LütfenSeçiniz")}
                     search
                     fluid
                     defaultValue={state.childCategory5Loading}
                     options={state.childCategory5Arr}
                     onChange={(e, a) => categoryRender('selectedChildCategory5', a.value, event)}
                  />
               </FormGroup>
               }

            </ModalBody>
         </Modal>
      </FormGroup>)
}

function mapStateToProps(state)
{
   return {
      parentCategories: state.parentCategoryReducer,
      childCategories: state.childCategoryReducer
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         getParentCategories: bindActionCreators(categoryActions.getParentCategories, dispatch),
         getChildCategories: bindActionCreators(categoryActions.getChildCategories, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CategorySelect)))
