import * as actionTypes from "../actions/actionTypes";

export function parentCategoryReducer(state = {}, action)
{
   switch (action.type)
   {
      case actionTypes.SET_PARENT_CATEGORIES:
         return action.payload
      default:
         return state
   }
}

export function childCategoryReducer(state = {}, action)
{
   switch (action.type)
   {
      case actionTypes.SET_CHILD_CATEGORIES:
         return action.payload
      default:
         return state
   }
}

export function filterCategoryReducer(state={}, action) {
   switch(action.type) {
      case actionTypes.SET_FILTER_CATEGORIES:
         return action.payload
      default:
         return state
   }
}
