import React, { PureComponent } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from "reactstrap";
import BarLoader from "react-spinners/BarLoader";
import { bindActionCreators } from "redux";
import * as mainCompanyActions from "../../../redux/actions/mainCompanyActions";
import { connect } from "react-redux";
import metrics from "../../../common/metrics";
import { Redirect } from "react-router-dom";
import * as BB from "../../../components/BBComponent";
import task4TeamLogo from "../../../assets/logo-siyah.png";
import notify from "devextreme/ui/notify";
import jwt_decode from "jwt-decode";
import { callAPI, callApiGet } from "../../../components/Axios";
import { AppSwitch } from "@coreui/react";
import { AuthProvider } from "oidc-react";


const oidcConfig = {
    onSignIn: async (user) => {

        window.location.hash = "";
    },
    authority: "https://test.turkuazgo.com/im",
    scope: "openid profile",
    clientId: "tys",
    responseType: "code",
    response_mode: "query",
    redirectUri:
        process.env.NODE_ENV === "development"
            ? "https://localhost:5001/#!/callback"
            : "",
};


class Dogus extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loginLoading: false,
            isLoading: false,
            userEmail: "",
            userPassword: "",
            redirect: false,
            showReset: false,
            resetEmail: "",
            isFlipped: false,
            passwordVisibility: false,
            loginUser: null,
            LogoUrl: "",
            loginIdentity: false,
        };

        const token = localStorage.getItem("token");
        if (token) {
            const user = jwt_decode(token);
            if (user && new Date(user.exp * 1000) > new Date()) {
                this.state.loginUser = user;
                this.state.redirect = true;
            }
        }


        if (!this.state.redirect) {
            this.checkUser = this.checkUser.bind(this);
            this.getMainCompanyDetail = this.getMainCompanyDetail.bind(this);

            this.getMainCompanyDetail();
            sessionStorage.clear();
            localStorage.clear();

        }
    }

    getMainCompanyDetail() {
        callAPI(
            callApiGet,
            "api/Login/GetMainCompanies",
            {},
            async (res) => {
                const result = res?.data;
                this.setState({ LogoUrl: result.LogoUrl });
            },
            true
        );
    }

    loginWithIdentity() {
        localStorage.setItem("isExternal", true);
        window.location.reload();
    }

    checkUser(e) {
        const { userEmail, userPassword } = this.state;
        const isLdapLogin = false;
        const url =
            "api/Login/checkUserLogin?userName=" +
            userEmail +
            "&userPassword=" +
            userPassword +
            "&isLdapLogin=" +
            isLdapLogin;
        this.setState({ loginLoading: true });
        fetch(url)
            .then((res) => res.json())
            .then((response) => {
                if (response.Token !== undefined && response.Token !== "") {
                    localStorage.setItem("token", response.Token);
                    this.setState({ isLoading: false, redirect: true, loginLoading: false });
                    // this.props.actions.getPoolCount().then(() => {
                    //    this.setState({ isLoading: false, redirect: true });
                    // })
                } else {
                    this.setState({ isLoading: false, redirect: false, loginLoading: false });
                    notify("Kullanıcı Adı Veya Şifre Hatalı.", "error", 3000);
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false, redirect: false, loginLoading: false });
                if (error) {
                    this.setState({ isLoading: false, redirect: false, loginLoading: false });
                    notify(error.response?.data, "error", 3000);
                } else {
                    this.setState({ isLoading: false, redirect: false, loginLoading: false });
                    notify("İnternet Baglantınızı Kontrol Ediniz.", "error", 3000);
                }
            });
        e.preventDefault();
    }

    forgotPasswordUser() {
        const { resetEmail } = this.state;
        if (resetEmail) {
            this.setState({ isLoading: true });
            let url = "api/Login/ForgotPassword?Email=" + this.state.resetEmail;
            BB.FetchJSON(url).then((data) => {
                alert(data);
                this.setState({ info: data, isLoading: false, showReset: false });
            });
        } else {
            notify("E-mail adresi boş bırakılamaz.", "warning", 3000);
        }
    }
    samlLogin() {
        window.open('api/Login/SamlLogin', '_self');
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
    };

    render() {
        const { isFlipped, redirect, isLoading, LogoUrl, passwordVisibility, loginIdentity, loginLoading } = this.state
        if (redirect === true) {
            if (metrics.redirectURL !== undefined) {
                if (!metrics.redirectURL === "/") {
                    return <Redirect push to={metrics.redirectURL} />;
                }
                return <Redirect push to="/main" />;
            } else {
                return <Redirect push to="/main" />;
            }
        }


        return (
            <div className="custom-login-page">
                <div className="sweet-loading">
                    <BarLoader
                        width={"auto"}
                        size={10}
                        color={"#24364a"}
                        loading={isLoading}
                    />
                </div>
                <div className="pt-5 pb-3 d-flex justify-content-center">
                    <img
                        className="custom-dogus-logo"
                        src={LogoUrl}
                        alt="Task4Team Logo"
                    />
                </div>
                <div id="login-container" className="custom-login-form">
                    {
                        !isFlipped ? (
                            <div id="login-form">
                                <h2 className="heading" style={{ fontSize: 28 }}>
                                    Talep Yönetim Sistemi
                                </h2>
                                <p className="info-text">
                                    Turkuaz kullanıcı adınız ile giriş yapabilirsiniz.
                                </p>
                                <form onSubmit={(e) => this.checkUser(e)}>
                                    <fieldset className="p-0">
                                        <div className="_control-group">
                                            <div className="_controls">
                                                <Input
                                                    onChange={(event) =>
                                                        this.setState({ userEmail: event.target.value })
                                                    }
                                                    value={this.state.userEmail || ""}
                                                    className="required _special"
                                                    id="UserName"
                                                    name="UserName"
                                                    placeholder="Kullanıcı adı veya E-posta adresiniz"
                                                    size="30"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="control-group">
                                            <div className="controls">
                                                <InputGroup>
                                                    <Input
                                                        onChange={(event) =>
                                                            this.setState({
                                                                userPassword: event.target.value,
                                                            })
                                                        }
                                                        value={this.state.userPassword || ""}
                                                        className="_special"
                                                        id="Password"
                                                        name="Password"
                                                        placeholder="Şifre"
                                                        size="30"
                                                        type={
                                                            passwordVisibility
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        autoComplete="off"
                                                        style={{ height: 40 }}
                                                    />
                                                    <InputGroupAddon
                                                        addonType="prepend"
                                                        className="login-password-right"
                                                    >
                                                        <InputGroupText
                                                            className="custom-password-display"
                                                            onClick={(e) =>
                                                                this.setState({
                                                                    passwordVisibility:
                                                                        !this.state.passwordVisibility,
                                                                })
                                                            }
                                                        >
                                                            <i
                                                                className={
                                                                    passwordVisibility
                                                                        ? "fa fa-eye"
                                                                        : "fa fa-eye-slash"
                                                                }
                                                            />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="d-flex align-items-center mb-3">
                                            <label className="checkbox mb-0 mr-1"> Turkuaz Login</label>
                                            <AppSwitch
                                                onChange={() => {
                                                    this.loginWithIdentity();
                                                }}
                                                className={"float-left"}
                                                variant={"pill"}
                                                label
                                                checked={this.loginIdentity}
                                                color={"success"}
                                                size={"sm"}
                                            />
                                        </div>                                        

                                        {loginIdentity && (<AuthProvider {...oidcConfig} />)}
                                        <div className="_control-group forgot-pass-container mt-0">
                                            <div className="_controls clearfix">
                                                <label className="checkbox pull-left"></label>
                                                <div className="pull-right">
                                                    <a
                                                        id="forgot_password"
                                                        onClick={(e) => this.handleChange(e)}
                                                        data-toggle-dom="#forgot-form, #login-form"
                                                        className="custom-forgot-password"
                                                    >
                                                        Şifremi unuttum?
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="_control-group login-form-submit">
                                            <div className="_controls">
                                                <button
                                                    type={loginLoading ? 'button' : 'submit'}
                                                    className="btn btn-primary btn-login custom-btn-login"
                                                    style={{ backgroundColor: "inherit", width: "100%" }}
                                                >
                                                    {
                                                        loginLoading ? (
                                                            <Spinner
                                                                style={{ width: "1rem", height: "1rem" }}
                                                                color="light"
                                                            />
                                                        ) : ('Giriş Yap')
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>         
                                <button
                                    className="btn btn-primary btn-login custom-btn-login"
                                    style={{
                                        backgroundColor: "inherit",
                                        width: "25%",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                    outline
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.samlLogin();
                                    }}
                                >
                                    <span >Saml ile Giriş</span>
                                </button>
                            </div>) : (
                            <div>
                                <h2 className="heading" style={{ fontSize: 28 }}>
                                    Şifremi Unuttum
                                </h2>
                                <p>
                                    Şifrenizi sıfırlamak için kullanıcı adınızı yazınız. Kullanıcı
                                    adınızı bilmiyorsanız (262) 676 76 75 Turkuaz Destek Hattı ile
                                    görüşebilirsiniz.
                                </p>
                                <fieldset className="p-0">
                                    <div className="control-group forgot-form-input">
                                        <div className="controls">
                                            <Input
                                                onChange={(event) =>
                                                    this.setState({ resetEmail: event.target.value })
                                                }
                                                type="text"
                                                name="email"
                                                id="email"
                                                className="required _special"
                                                placeholder="Kullanıcı adınızı giriniz..."
                                                aria-required="true"
                                            />
                                        </div>
                                    </div>
                                </fieldset>

                                <div
                                    className="control-group button-container d-flex justify-content-end"
                                    style={{ marginTop: 16 }}
                                >
                                    <button
                                        onClick={(e) => this.handleChange(e)}
                                        type="button"
                                        data-toggle-dom="#login-form, #forgot-form"
                                        className="btn custom-reset-buttons custom-cancel-btn"
                                        id="reset-password-cancel"
                                        style={{ marginRight: 8 }}
                                    >
                                        {" "}
                                        İptal
                                    </button>
                                    <button
                                        style={{ marginRight: 0 }}
                                        onClick={() => this.forgotPasswordUser()}
                                        type="submit"
                                        className="btn custom-reset-buttons custom-reset-pass-btn"
                                        id="reset-password-submit"
                                    >
                                        Şifremi yenile
                                    </button>
                                </div>
                            </div>)
                    }
                </div>
                <div className="px-3 py-2 d-flex justify-content-end align-items-end fixed-bottom">
                    <div>
                        <img
                            className="custom-t4t-logo"
                            src={task4TeamLogo}
                            alt="TaskForTeam Logo"
                        />
                        <br></br>
                        <span className="ml-auto">
                            {" "}
                            <i className="fa fa-phone" aria-hidden="true"></i> Destek Hattı
                            : (0262) 676 76 75){" "}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        loginUser: state.loginReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            getMainCompany: bindActionCreators(
                mainCompanyActions.getMainCompanyDetail,
                dispatch
            ),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dogus);
