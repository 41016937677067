import React from 'react';
import Main from '../views/User/Main/Main';
import Activities from '../views/User/Activities/Activities';
import TaskTracking from '../views/User/Task/TastTracking/TaskTracking';
import TaskPool from '../views/User/Task/TaskPool/TaskPool';
import TaskCreate from '../views/User/Task/TaskCreate/TaskCreate';
import TaskConfirm from '../views/User/Task/TaskConfirm/TaskConfirm';
import TaskDetail from '../views/User/Task/TaskDetail/TaskDetail';
import OwnTask from '../views/User/Task/OwnTask/OwnTask';
import SSS from '../views/User/SSS/SSS';
import Surveys from '../views/User/Surveys/Surveys';
import Profile from '../views/User/Profile/Profile';
import callback from '../views/User/CustomLogin/callback'

import CategoryAuthority from '../views/Admin/Authority/CategoryAuthority';
import EffectManagement from '../views/Admin/Authority/EffectManagement';
import GroupAuthority from '../views/Admin/Authority/GroupAuthority';
import MenuManagement from '../views/Admin/Authority/MenuManagement';
import PackageAuthority from '../views/Admin/Authority/PackageAuthority';
import StatusManagement from '../views/Admin/Authority/StatusManagement';
import TaskCategory from '../views/Admin/Category/TaskCategory';
import TaskClosingTemplate from '../views/Admin/Category/TaskClosingTemplate';
import TaskConfirmManagement from '../views/Admin/Category/TaskConfirmManagement';
import TaskCustomControl from '../views/Admin/Category/TaskCustomControl/TaskCustomControl';
import TaskCustomControlAdd from '../views/Admin/Category/TaskCustomControl/TaskCustomControlAdd';
import TaskCustomControlEdit from '../views/Admin/Category/TaskCustomControl/TaskCustomControlEdit';
import TaskMailTemplate from '../views/Admin/Category/EmailTemplate/EmailTemplate';
import TaskMailTemplateEditor from '../views/Admin/Category/EmailTemplate/EmailTemplateEditor';
import TaskEmail from '../views/Admin/Category/TaskEmail';
import Company from '../views/Admin/Company/Company';
import CompanyEdit from '../views/Admin/Company/CompanyEdit';
import CreateCompany from '../views/Admin/Company/CompanyAdd';
import CompanyDepartment from '../views/Admin/Company/CompanyDepartment';
import CompaniesDepartmentJobs from '../views/Admin/Company/CompanyDepartmentJobs';
import CompanyUser from '../views/Admin/Company/CompanyUser';
import CompanyUserCategory from '../views/Admin/Company/CompanyUserCategory';
import Error from '../views/Admin/Error/Error';
import UserProcess from '../views/Admin/UserProcess/UserProcess';

import Parameter from '../views/Admin/Parameter/Parameter';
import Pool from '../views/Admin/Pool/Pool';
import PoolUser from '../views/Admin/Pool/PoolUser';
import Popup from '../views/Admin/Popup/Popup';
import AdminSSS from '../views/Admin/SSS/SSS';
import AdminSssEdit from '../views/Admin/SSS/SssEdit';
import AdminSssAdd from '../views/Admin/SSS/SssAdd';
import Survey from '../views/Admin/Survey/Survey';
import SurveyEdit from '../views/Admin/Survey/SurveyEdit';
import ManageDashboard from '../views/Admin/Authority/ManageDashboard';
import GroupManagement from '../views/Admin/Group/GroupManagement';
import FormDesing from '../views/Admin/FormDesign/FormDesign';
import FormList from '../views/Admin/FormDesign/FormList';
import SheetList from "../views/Admin/SheetDesign/SheetList";
import SheetDesign from "../views/Admin/SheetDesign/SheetDesign";
import MailSurvey from "../views/User/Surveys/MailSurvey";

const routes = [

    //User
    {path: '/main', name: '', component: Main, role: ["Destek", "Son Kullanıcı", "Yönetim"]},
    {path: '/Activities', name: 'Aktivitelerim', component: Activities, role: ["Destek"]},
    {path: '/SSS', name: 'Sıkça sorulan sorular', component: SSS, role: ["Destek", "Son Kullanıcı"]},
    {path: '/Surveys', name: 'Anketlerim', component: Surveys, role: ["Destek", "Son Kullanıcı"]},
    {path: '/TaskTracking', name: 'Talep takibi', component: TaskTracking, role: ["Destek", "Son Kullanıcı"]},
    {path: '/OwnTask', name: 'Talep takibi', component: OwnTask, role: ["Destek"]},
    {path: '/TaskDetail', name: 'Talep detayı', component: TaskDetail, role: ["Destek"]},
    {path: '/WaitingConfirm', name: 'Talep onayı', component: TaskConfirm, role: ["Destek"]},
    {path: '/CreateTask', name: 'Talep oluştur', component: TaskCreate, role: ["Destek", "Son Kullanıcı"]},
    {path: '/TaskPool', name: 'Talep havuzu', component: TaskPool, role: ["Destek"]},
    {path: '/Profile', name: 'Profil', component: Profile, role: ["Destek"]},
    // {path:'/callback',name:'callback', component: callback},


    //Yonetim Routes

    {path: '/Yonetim/RoleCategories', name: 'Kategori Yönetimi', component: CategoryAuthority, role: 'Yönetim'},
    {path: '/Yonetim/FormList', name: 'Form Listesi', component: FormList, role: 'Yönetim'},
    {path: '/Yonetim/FormDesing', name: 'Form Yönetimi', component: FormDesing, role: 'Yönetim'},
    {path: '/Yonetim/Effect', name: 'Etki Yönetimi', component: EffectManagement, role: 'Yönetim'},
    {path: '/Yonetim/ManageDashboard', name: 'Dashboard Yönetimi', component: ManageDashboard, role: 'Yönetim'},
    {path: '/Yonetim/Role', name: 'Grup Yönetimi', component: GroupAuthority, role: 'Yönetim'},
    {path: '/Yonetim/SurveyEdit', name: 'Anket Yönetimi', component: SurveyEdit, role: 'Yönetim'},
    {path: '/Yonetim/RoleMenu', name: 'Menü Yönetimi', component: MenuManagement, role: 'Yönetim'},
    {path: '/Yonetim/RolePackage', name: 'Yetki Paketi Yönetimi', component: PackageAuthority, role: 'Yönetim'},
    {path: '/Yonetim/Status', name: 'Durum Yönetimi', component: StatusManagement, role: 'Yönetim'},
    {path: '/Yonetim/Categories', name: 'Kategori Yönetimi', component: TaskCategory, role: 'Yönetim'},
    {
        path: '/Yonetim/TerminationMessages',
        name: 'Talep Kapatma Şablonları',
        component: TaskClosingTemplate,
        role: 'Yönetim'
    },
    {
        path: '/Yonetim/CategoryConfirmations',
        name: 'Talep Onay Yönetimi',
        component: TaskConfirmManagement,
        role: 'Yönetim'
    },
    {path: '/Yonetim/CustomControls', name: 'Özel Alan Yönetimi', component: TaskCustomControl, role: 'Yönetim'},
    {path: '/Yonetim/CustomControlAdd', name: 'Özel Alan Ekle', component: TaskCustomControlAdd, role: 'Yönetim'},
    {path: '/Yonetim/CustomControlEdit', name: 'Özel Alan Ekle', component: TaskCustomControlEdit, role: 'Yönetim'},
    {path: '/Yonetim/EmailTemplate', name: 'Talep Mail Şablonları', component: TaskMailTemplate, role: 'Yönetim'},
    {
        path: '/Yonetim/EmailTemplateEditor',
        name: 'Talep Mail Şablonu Ekle',
        component: TaskMailTemplateEditor,
        role: 'Yönetim'
    },

    {path: '/Yonetim/Companies', name: 'Kurum', component: Company, role: 'Yönetim'},
    {path: '/Yonetim/CompaniesEdit', name: 'Firma Düzenle', component: CompanyEdit, role: 'Yönetim'},
    {path: '/Yonetim/CompaniesCreateCompany', name: 'Firma Ekle', component: CreateCompany, role: 'Yönetim'},
    {path: '/Yonetim/Departments', name: 'Departman', component: CompanyDepartment, role: 'Yönetim'},
    {path: '/Yonetim/CompaniesDepartmentJobs', name: 'Ünvanlar', component: CompaniesDepartmentJobs, role: 'Yönetim'},
    {path: '/Yonetim/CompanyUser', name: 'Kurum Kullanıcı', component: CompanyUser, role: 'Yönetim'},
    {path: '/Yonetim/Parameter', name: 'Parametre Yönetimi', component: Parameter, role: 'Yönetim'},
    {path: '/Yonetim/Pools', name: 'Havuz Yönetimi', component: Pool, role: 'Yönetim'},
    {path: '/Yonetim/PoolUser', name: 'Havuz Kullanıcıları', component: PoolUser, role: 'Yönetim'},
    {path: '/Yonetim/Popup', name: 'Popup Bilgilendirme', component: Popup, role: 'Yönetim'},
    {path: '/AdminSSS', name: 'Sıkça Sorulan Sorular', component: AdminSSS, role: 'Yönetim'},
    {path: '/AdminSssEdit', name: 'SSS Düzenle', component: AdminSssEdit, role: 'Yönetim'},
    {path: '/AdminSssAdd', name: 'SSS Ekle', component: AdminSssAdd, role: 'Yönetim'},
    {path: '/Yonetim/Survey', name: 'Anket Yönetimi', component: Survey, role: 'Yönetim'},
    {path: '/Yonetim/TaskEmail', name: 'Talep Mailleri', component: TaskEmail, role: 'Yönetim'},
    {
        path: '/Yonetim/CompanyUserCategory',
        name: 'Kullanıcı Kategorileri',
        component: CompanyUserCategory,
        role: 'Yönetim'
    },
    {path: '/Yonetim/GroupManagement', name: 'Grup Yönetimi', component: GroupManagement, role: 'Yönetim'},
    {path: '/Yonetim/SheetList', name: 'Tablo Listesi', component: SheetList, role: 'Yönetim'},
    {path: '/Yonetim/SheetDesing', name: 'Tablo Yönetimi', component: SheetDesign, role: 'Yönetim'},
    {path: '/Yonetim/Error', name: 'Hatalar', component: Error, role: 'Yönetim'},
    {path:'/Yonetim/UserProcess',name:'Kullanıcılar', component: UserProcess,role:'Yonetim'},

];

export default routes;
