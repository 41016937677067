import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import 'semantic-ui-css/semantic.min.css'
import {ReactFormGenerator} from "react-form-builder2";

const FormModal = ({event, t}) =>
{
   
   return (
      <Modal isOpen={event.state.previewVisible} centered={true} className={"modal-lg"}>
         <ModalHeader
            toggle={() => event.setState({previewVisible: false})}> {event.state.selectedFormName}</ModalHeader>
         <ModalBody>
            
            <ReactFormGenerator
               read_only={false}
               form_method="POST"
               hide_actions={false}
               action_name={'Kaydet'}
               back_action={''}
               back_name={'Vazgeç'}
               actionName={'Kaydet'}
               answer_data={event.state.answer_data}
               submitButton={<button type={"submit"} className={"btn btn-primary"}>Kaydet</button>}
               //backButton={<button type={"dismiss"} className={"btn btn-primary"}>Kaydet</button>}

               onSubmit={(e) => event.saveCustomFormValues(e)}
               
               data={event.state.selectedForm}
            />
         </ModalBody>
      </Modal>
   )
}
export default FormModal;
