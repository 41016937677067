import * as actionTypes from "../actions/actionTypes";

export function slaTaskCountReducer(state = 0, action)
{
   switch (action.type)
   {
      case actionTypes.SET_SLA_TASK_COUNT:
         return action.payload
      default:
         return state
   }
}

export function ownTaskCountReducer(state = 0, action)
{
   switch (action.type)
   {
      case actionTypes.SET_OWN_TASK_COUNT:
         return action.payload
      default:
         return state
   }
}

export function ownConfirmTaskCountReducer(state = 0, action)
{
   switch (action.type)
   {
      case actionTypes.SET_OWN_CONFIRM_TASK_COUNT:
         return action.payload
      default:
         return state
   }
}


export function popupMessageReducer(state = [], action)
{
   switch (action.type)
   {
      case actionTypes.SET_POPUP_MESSAGE:
         return action.payload
      default:
         return state
   }
}
