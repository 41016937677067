import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import metrics from "../../../common/metrics";
import { callAPI, callApiGet, callApiPost } from "../../../components/Axios";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class TaskConfirmManagement extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         companies: undefined,
         categories: undefined,
         parameters: [],
         editRowVisible: false,
         addRowVisible: false,

         selectedID: undefined,
         newTaskCompanyID: undefined,
         newCategoryID: undefined,
         newConfirmCompanyID: undefined,
         newUserID: undefined,
         newPreferential: false,
         companyUser:undefined,
         companyUserID :undefined
      }


      this.handleNewPreferentialCheckBox = this.handleNewPreferentialCheckBox.bind(this);
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let url = 'api/Yonetim/CategoryConfirmations/GetData';
      let url1 = 'api/Yonetim/CategoryConfirmations/GetCompanies';
      let url2 = 'api/Yonetim/CategoryConfirmations/GetCategories';
      BB.FetchJSON(url).then(data =>
      {
         debugger
         this.setState({gridData: data.Data, isLoading: false})
      });

      BB.FetchJSON(url1).then(data =>
      {
         this.setState({companies: data.Data, isLoading: false})
      });

      BB.FetchJSON(url2).then(data =>
      {
         this.setState({categories: data.Data, isLoading: false})
      });
   }

   actionButton(cellData)
   {
      return (
         <div>

            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() => this.deleteTaskConfirm(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   handleChange(id, value)
   {
       if(id==="newTaskCompanyID"){
         this.getCompanyUsersWithID(value);
         this.setState({newTaskCompanyID: value});

       }
       else{
         this.setState({[id]: value});

       }
   }


   getCompanyUsersWithID(companyID){
      if(companyID){

      
      let url = 'api/Yonetim/CategoryConfirmations/GetCompanyUsers?companyID=' + companyID;

      callAPI(
         callApiGet,
          url, 
          {},
          (data) => {
            debugger
         this.setState({
            companyUser:data.data.Data
          });
   
       });
   }}


   handleNewPreferentialCheckBox(e)
   {
      this.setState({newPreferential: !this.state.newPreferential});
   }

   addNewTaskConfirm()
   {

      if (this.state.newCategoryID === '' ||
         this.state.newTaskCompanyID === '' ||
         this.state.newConfirmCompanyID === '' ||
         this.state.newUserID === ''
         )
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            CategoryID: this.state.newCategoryID,
            SourceCompanyID: this.state.newTaskCompanyID,
            ConfirmationCompanyID: this.state.newConfirmCompanyID,
            UserID: this.state.newUserID,
            Preferential: this.state.newPreferential,
            SourceUserID:this.state.companyUserID
         };

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/CategoryConfirmations/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedID: undefined,
                  newTaskCompanyID: undefined,
                  newCategoryID: undefined,
                  newConfirmCompanyID: undefined,
                  newUserID: undefined,
                  newPreferential: false
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedID: undefined,
                  newTaskCompanyID: undefined,
                  newCategoryID: undefined,
                  newConfirmCompanyID: undefined,
                  newUserID: undefined,
                  newPreferential: false
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   deleteTaskConfirm(id)
   {
      BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.state.selectedID = id;

            this.props.actions.isLoading(true);

            const formData = new FormData();
            formData.append('id', this.state.selectedID);

            post('api/Yonetim/CategoryConfirmations/DeleteConfirm', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {

                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedID: undefined,
                     newTaskCompanyID: undefined,
                     newCategoryID: undefined,
                     newConfirmCompanyID: undefined,
                     newUserID: undefined,
                     newPreferential: false
                  }, this.getDatas());
                  this.props.actions.isLoading(false);
                  notify(t("mesajsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedID: undefined,
                     newTaskCompanyID: undefined,
                     newCategoryID: undefined,
                     newConfirmCompanyID: undefined,
                     newUserID: undefined,
                     newPreferential: false
                  }, this.getDatas());
                  notify(t("mesajsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>

                     <Button
                        color={'success'}
                        outline
                        onClick={() => this.setState({
                           addRowVisible: true,
                           selectedID: '',
                           newTaskCompanyID: '',
                           newCategoryID: '',
                           newConfirmCompanyID: '',
                           newUserID: '',
                           newPreferential: false
                        })}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button">{t("yeni")}</Button>

                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName=""/>

                        <Column
                           dataField="SourceCompanyName"
                           caption={t("talepsahibifirma")}
                           minWidth={200}
                           allowFiltering={true}/> 
                           <Column
                           dataField={'SourceUserName'}
                           caption={t("talepsahibikullanici")}
                           minWidth={80}/>
                        <Column
                           dataField={'CategoryName'}
                           caption={t("kategori")}
                           minWidth={80}
                           allowFiltering={true}/>
                        <Column
                           dataField={'ConfirmCompanyName'}
                           caption={t("onaysahibifirma")}
                           minWidth={80}/>
                        <Column
                           dataField={'UserName'}
                           caption={t("adisoyadi")}
                           minWidth={80}/>
                        <Column
                           dataField={'Preferential'}
                           caption={t("oncelikli")}
                           minWidth={80}/>
                             
                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={200}
                           cellRender={(cellData) => this.actionButton(cellData)}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Yönetici Onaylı Kategori Ekleme, Popup*/}
            <Popup
               visible={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               title="Yeni Onay Verecek Kullanıcı Ekle"
               width={metrics.popupMinWidth}
               height={520}
            >
               <form>

                  {BB.DxDropDownWithJSON('newTaskCompanyID', t("talepsahibifirma"), this, this.state.companies)}
                  {BB.DxDropDownWithJSON('companyUserID', t("talepsahibikullanici"), this, this.state.companyUser)}

                  {BB.DxDropDownWithJSON('newCategoryID', t("kategori"), this, this.state.categories)}
                  {BB.DxDropDownWithJSON('newConfirmCompanyID', t("onaysahibifirma"), this, this.state.companies)}
                  {BB.DxDropDownWithURL('newUserID', t("kullanıcı"), this, '/api/Yonetim/CategoryConfirmations/GetUsers?companyID=' + this.state.newConfirmCompanyID)}

                  <div className="form-check">
                     <input type="checkbox" className="form-check-input" id="newPreferentialCheckBox"
                            value={this.state.newPreferential} onChange={this.handleNewPreferentialCheckBox}/>
                     <label className="form-check-label" htmlFor="newPreferentialCheckBox">{t("oncelikli")}</label>
                  </div>

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.addNewTaskConfirm()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </form>
            </Popup>

         </div>
      );
   }
}


function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskConfirmManagement));
