import * as actionTypes from '../actions/actionTypes'

export default function surveyReducer(state = {}, action) {
   switch (action.type) {
      case actionTypes.SET_OWN_SURVEYS:
         return action.payload
      default:
         return state
   }
}
