import React from "react";
import './styles.scss'

const CustomButton=({children,icon,handleClick,...otherProps})=>{
    return(
        <button className='btn btn-secondary custom-button' {...otherProps} onClick={handleClick}>
            <i className={icon}/>
        </button>
    )
}
export default CustomButton;
