import React from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import * as BB from "../../../../components/BBComponent";
import CustomInput from '../../../../components/admin/CustomInput/CustomInput'
import CustomButton from '../../../../components/admin/CustomButton/CustomButton'
import {post} from "axios";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

const DATA = [

   {ID: "DosyaYukleme", Name: "DosyaYukleme"},

   {ID: "CheckBox", Name: "CheckBox"},

   {ID: "DosyaIndirme", Name: "DosyaIndirme"},

   {ID: "MetinGirisi", Name: "MetinGirisi"},

   {ID: "IzinSecenek", Name: "IzinSecenek"},

   {ID: "DropDown", Name: "DropDown"},

   {ID: "Tarih", Name: "Tarih"},

   {ID: "Aciklama", Name: "Aciklama"},

   {ID: "CokluMetinGirisi", Name: "CokluMetinGirisi"}

]
export const FileWithLabel = (id, text, event) =>
{
   return (
      <FormGroup>
         <Label htmlFor={id}>
            <strong>{text}</strong>
         </Label>
         <Input
            type='file'
            id={id}
            onChange={(e) => event.handleChange(id, e)}
         />
      </FormGroup>
   );
}

class TaskCustomControlAdd extends React.Component
{

   constructor(props)
   {
      super(props)
      this.state = {
         controlType: DATA,
         categoryList: [],
         isRequired: false,
         isActive: true,
         orderNumber: '',
         values: '',
         optionListVisible: 'none',
         selectedParameter: '00000000-0000-0000-0000-000000000000',
         isClosing: false,
      }

      this.handleRequiredChange = this.handleRequiredChange.bind(this)
      this.handleActiveChange = this.handleActiveChange.bind(this)
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
   }

   componentDidMount()
   {
      //TODO : Gelen data İncelenecek

      // Promise.all([
      //    axios.get(`api/Yonetim/CustomControls/CategoryList`),
      // ])
      // .then(([customCategory]) =>
      // {
      //    this.setState({categoryList: customCategory.data.ViewBag});
      // });


      let url = `api/Yonetim/CustomControls/CategoryList`;

      BB.FetchJSON(url).then(customCategory =>
      {
         this.setState({categoryList: customCategory.ViewBag});
      });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});

      if (id == 'selecteControlType' && value == 'DropDown' || id == 'selectedParameter')
      {
         this.setState({optionListVisible: 'block'});
      }
      else
      {
         this.setState({optionListVisible: 'none'});
      }
   }


   handleInputChange(e)
   {
      const {value, name} = e.target;
      this.setState({[name]: value});
   }

   handleRequiredChange()
   {
      const {isRequired} = this.state
      this.setState({isRequired: !isRequired})
   }

   handleActiveChange()
   {
      const {isActive} = this.state
      this.setState({isActive: !isActive})
   }

   handleIsCloseChange = () =>
   {
      const {isClosing} = this.state
      this.setState({isClosing: !isClosing})
   }

   handleSubmit = (e) =>
   {
      const {
         values,
         orderNumber,
         selectedCategory,
         selecteControlType,
         isRequired,
         isActive,
         selectedParameter,
         isClosing
      } = this.state;
      if (this.state.values === '' || this.state.orderNumber === '')
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();

         let entity = {
            Value: values,
            OrderNumber: orderNumber,
            CategoryID: selectedCategory,
            CustomControlType: selecteControlType,
            IsRequired: isRequired,
            IsActive: isActive,
            DropDownParentID: selectedParameter,
            IsClosing: isClosing
         }
         let SelectedFile = this.state.selectedFile;

         formData.append('jsonText', JSON.stringify(entity));
         formData.append('files', this.state.selectedTaskFiles);

         post('api/Yonetim/CustomControls/add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  values: '',
                  OrderNumber: '',
                  selectedCategory: '',
                  selecteControlType: '',
                  isRequired: false,
                  isActive: false

               });
               this.props.actions.isLoading(false);
               notify(t("ozelalanbasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               notify(t("ozelalaneklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   render()
   {
      const {selecteControlType, isRequired, isActive, values, orderNumber, isClosing} = this.state;
      return (
         <Row>
            <Col>
               <Card>
                  <CardHeader>{t("ozelalanekle")}</CardHeader>
                  <CardBody>
                     <Row>
                        <Col
                           lg='12'> {BB.DxDropDownWithJSON('selectedCategory', t("kategori"), this, this.state.categoryList)}</Col>
                        <Col
                           lg='12'> {BB.DxDropDownWithJSON('selecteControlType', t("kontroltipi"), this, this.state.controlType)}</Col>
                        <Col style={{display: this.state.optionListVisible}}
                             lg='12'> {BB.DxDropDownWithURL('selectedParameter', t("optionliste"), this, "/api/Yonetim/CustomControls/GetOptionParameter", t("optionliste"))}</Col>
                        {
                           selecteControlType === 'DosyaIndirme' && (
                              <Col lg='12'>
                                 {BB.FileWithLabel('selectedTaskFiles', t("dosyaekle"), this)}
                              </Col>
                           )
                        }
                        <Col lg='6'>
                           <CustomInput type='text' label={t("tanimmetni")} placeholder="" name="values" value={values}
                                        handleChange={this.handleInputChange}/>
                        </Col>
                        <Col lg='6'>
                           <CustomInput type='text' label={t("siranumarasi")} placeholder="" name="orderNumber"
                                        value={orderNumber} handleChange={this.handleInputChange}/>
                        </Col>
                        <Col lg='12'>
                           <FormGroup check inline className='mb-1 customRadioButton'>
                              <Input className="form-check-input" type="checkbox" id="inline-radio"
                                     onChange={this.handleRequiredChange} value={isRequired} checked={isRequired}/>
                              <Label className="form-check-label" check htmlFor="inline-radio">{t("zorunluluk")}</Label>
                           </FormGroup>
                        </Col>
                        <Col lg='12'>
                           <FormGroup check inline className='mb-1 customRadioButton'>
                              <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                     onChange={this.handleActiveChange} value={isActive} checked={isActive}/>
                              <Label className="form-check-label" check htmlFor="inline-radio1">{t("aktifpasif")}</Label>
                           </FormGroup>
                        </Col>
                        <Col lg='12'>
                           <FormGroup check inline className='mb-1'>
                              <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                     onChange={this.handleIsCloseChange} value={isClosing} checked={isClosing}/>
                              <Label className="form-check-label" check htmlFor="inline-radio1">{t("talepkapanisiicinozelalan")}</Label>
                           </FormGroup>
                        </Col>
                        <Col lg='12'>
                           <CustomButton
                              color='success' cs='float-right  mt-10' type='submit'
                              handleClick={() => this.handleSubmit()}>{t("ozelalanekle")}</CustomButton>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      )
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskCustomControlAdd));
