import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import notify from "devextreme/ui/notify";
import DataGrid, {Column, Pager, Paging} from "devextreme-react/data-grid";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import * as BB from "../../../../components/BBComponent";
import CustomInput from '../../../../components/admin/CustomInput/CustomInput'
import CustomButton from '../../../../components/admin/CustomButton/CustomButton'
import axios, {post} from "axios";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

const DATA = [

   {ID: "DosyaYukleme", Name: "DosyaYukleme"},

   {ID: "CheckBox", Name: "CheckBox"},

   {ID: "DosyaIndirme", Name: "DosyaIndirme"},

   {ID: "MetinGirisi", Name: "MetinGirisi"},

   {ID: "IzinSecenek", Name: "IzinSecenek"},

   {ID: "Tarih", Name: "Tarih"},

   {ID: "Aciklama", Name: "Aciklama"},

   {ID: "CokluMetinGirisi", Name: "CokluMetinGirisi"}

]

class TaskCustomControlEdit extends React.Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         controlType: DATA,
         categoryList: [],
         isRequired: false,
         isActive: false,
         orderNumber: '',
         values: '',
         document: [],
         customControlID: this.props.location.state ? this.props.location.state.CustomControlID : null,
         categoryDetail: [],
         EditID: '',
         selectedCategory: '',
         selecteControlType: '',
         isClosing: false,
      }

      this.handleChange = this.handleChange.bind(this)
      this.handleInputChange = this.handleInputChange.bind(this)
      this.handleRequiredChange = this.handleRequiredChange.bind(this)
      this.handleActiveChange = this.handleActiveChange.bind(this)
   }

   componentDidMount()
   {
      if (this.state.customControlID !== null)
      {
         Promise.all([
            axios.get(`api/Yonetim/CustomControls/CategoryList`),
            axios.get(`api/Yonetim/CustomControls/GetDataForDocument?ID=${this.state.customControlID}`),
            axios.get(`api/Yonetim/CustomControls/Edit?ID=${this.state.customControlID}`)
         ])
         .then(([customCategory, reposResponse, categoryDetail]) =>
         {
            this.setState({
               categoryList: customCategory.data.ViewBag,
               document: reposResponse.data.Data,
               EditID: categoryDetail.data.Data.ID,
               values: categoryDetail.data.Data.Value,
               orderNumber: categoryDetail.data.Data.OrderNumber,
               isRequired: categoryDetail.data.Data.IsRequired,
               isActive: categoryDetail.data.Data.IsActive,
               selecteControlType: categoryDetail.data.Data.CustomControlType,
               selectedCategory: categoryDetail.data.Data.CategoryID,
               isClosing: categoryDetail.data.Data.IsClosing
            });
         });
      }
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'light'}
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizdokumansilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(this.state.customControlID);
                     }
                  })
               }}>
               <i className='icon-trash icon-delete'></i>
            </Button>
         </div>
      );
   }

   delete(ID)
   {
      this.props.actions.isLoading(false);

      let url = 'api/Yonetim/CustomControls/DeleteDocument?ID=' + ID;

      BB.FetchJSON(url).then(data =>
      {
         if (data.MessageStatus === true)
         {
            this.getData()
            this.props.actions.isLoading(false);
            notify(t("dokumanbasariylasilindi"), "success", 1000);
         }
         else
         {
            notify(t("dokumansilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   getData()
   {
      let url = `api/Yonetim/CustomControls/GetDataForDocument?ID=` + this.state.customControlID;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({document: res.Data});
      });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   handleInputChange(e)
   {
      const {value, name} = e.target;
      this.setState({[name]: value});
   }

   handleRequiredChange()
   {
      const {isRequired} = this.state
      this.setState({isRequired: !isRequired})
   }

   handleActiveChange()
   {
      const {isActive} = this.state
      this.setState({isActive: !isActive})
   }

   handleIsCloseChange = () =>
   {
      const {isClosing} = this.state
      this.setState({isClosing: !isClosing})
   }


   handleSubmit = (e) =>
   {
      const {values, orderNumber, selectedCategory, selecteControlType, isRequired, isActive,isClosing} = this.state;
      if (this.state.values === '' || this.state.orderNumber === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            Value: values,
            OrderNumber: orderNumber,
            CustomControlType: selecteControlType,
            IsRequired: isRequired,
            IsActive: isActive,
            ID: this.state.customControlID,
            CategoryID: selectedCategory,
            IsClosing: isClosing
         }
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', JSON.stringify(this.state.customControlID));
         formData.append('files', this.state.selectedTaskFiles);

         post('api/Yonetim/CustomControls/edit', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.props.actions.isLoading(false);
               this.getData()
               notify(t("ozelalanbasarilibirsekildeguncellendi"), "success", 1000);
            }
            else
            {
               notify(t("ozelalanguncellemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }

   render()
   {
      const {selecteControlType, isRequired, isActive, values, orderNumber, document, isClosing} = this.state;
      return (
         <Row>
            <Col>
               <Card>
                  <CardHeader>{t("kategoriozelalanbilgileri")}</CardHeader>
                  <CardBody>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col
                              lg='12'> {BB.DxDropDownWithJSON('selectedCategory', t("kategori"), this, this.state.categoryList)}</Col>
                           <Col
                              lg='12'> {BB.DxDropDownWithJSON('selecteControlType', t("kontroltipi"), this, this.state.controlType)}</Col>
                           {
                              selecteControlType === 'DosyaIndirme' && (
                                 <Col lg='12'>
                                    {BB.FileWithLabel('selectedTaskFiles', t("dosyaekle"), this)}
                                 </Col>
                              )
                           }
                           <Col lg='6'>
                              <CustomInput type='text' label={t("deger")} placeholder="" name="values" value={values}
                                           handleChange={this.handleInputChange}/>
                           </Col>
                           <Col lg='6'>
                              <CustomInput type='text' label={t("siranumarasi")} placeholder="" name="orderNumber"
                                           value={orderNumber} handleChange={this.handleInputChange}/>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio"
                                        onChange={this.handleRequiredChange} value={isRequired} checked={isRequired}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio">{t("zorunluluk")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                        onChange={this.handleActiveChange} value={isActive} checked={isActive}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio1">{t("aktifpasif")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                        onChange={this.handleIsCloseChange} value={isClosing} checked={isClosing}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio1">{t("talepkapanisiicinozelalan")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <CustomButton color='success' cs='float-right  mt-10'
                                            type='submit'>{t("guncelle")}</CustomButton>
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
               <Card>
                  <CardHeader>{t("baglıdokumanlar")}</CardHeader>
                  <CardBody>
                     <DataGrid
                        id="taskListGridData"
                        dataSource={document}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                        onRowRemoving={this.onRowRemoving}
                        onRowRemoved={this.onRowRemoved}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <Column
                           dataField="Value"
                           caption={t("dokumanbasligi")}
                           minWidth={220}
                           allowFiltering={true}/>
                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={250}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      )
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskCustomControlEdit));
