import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginUserActions from "../../../redux/actions/loginUserActions";
import BarLoader from "react-spinners/BarLoader";
import * as menuActions from "../../../redux/actions/menuActions";
import * as poolActions from "../../../redux/actions/poolActions";
import metrics from "../../../common/metrics";
import * as BB from "../../../components/BBComponent";
import logo from "../../../assets/giris-logo.png";
import background from "../../../assets/tys-background.jpg";
import "../../../scss/login.scss";
import { AppSwitch } from "@coreui/react";
import notify from "devextreme/ui/notify";
import jwt_decode from "jwt-decode";
import {
   callAPI,
   callApiGet
} from "../../../components/Axios";
import axios, { get } from "axios";

//let languages = undefined;

class Login extends Component {
  constructor(props) {
  
    super(props);
    this.state = {
      ID: undefined,
      Email: undefined,
      Password: undefined,
      isLoading: false,
      redirect: false,
      CompanyName: "",
      ForgotEmail: undefined,
      LoginDisplay: "",
      ResetPasswordDisplay: "none",
      info: "",
      btnActive: "none",
      passwordVisible: false,
      isLdapLogin: false,
      showSwitch: false,
      showDomainList: false,
      domainList: [],
      loginUser: null,
    };
    this.getLdap();

    var token = localStorage.getItem("token");
    
    if (token) {
      let user = jwt_decode(token);
      if (user && new Date(user.exp * 1000) > new Date()) {
        this.state.loginUser = user;
        this.state.redirect = true;
      }
    }
   

    if (!this.state.redirect) {
      this.checkUser = this.checkUser.bind(this);
      // this.refreshToken = this.refreshToken.bind(this);
      this.getMainCompanyDetail = this.getMainCompanyDetail.bind(this);
      // this.props.actions.getMainCompany();

      this.getMainCompanyDetail();
      sessionStorage.clear();
      let lS = localStorage ? localStorage.getItem("Popups") : null;
      let localPopups = lS ? JSON.parse(lS) : [];

      sessionStorage.clear();

      localStorage.clear();
      // if (this.state.loginUser !== null) {
      //    this.refreshToken();
      // }
    }
    // this.refreshToken = this.refreshToken.bind(this);
  }

  getMainCompanyDetail() {
    callAPI(
      callApiGet,
      "api/Login/GetMainCompanies",
      {},
      async (res) => {
        const result = res?.data;
        this.setState({ LogoUrl: result.LogoUrl });
      },
      true
    );
  }

  componentDidMount() {
    let s = this.props.history.location.search;

    if (s != "") {
      var lst = s.replace("?", "").split("&");

      if (lst.length > 0) {
        let username = lst[0].split("=")[1];
        let password = lst[1].split("=")[1];
        let companyname = lst[2].split("=")[1];
        this.checkUser3rdPartApp(username, password, companyname);
      }
    }

    sessionStorage.clear();
    localStorage.clear();

    //  if (this.state.loginUser !== null) {
    //          props.history.push('/main')
    //    //    this.refreshToken();
    //     }
  }

  // refreshToken() {
  //    this.setState({ isLoading: true });
  //    axios.post('/api/Users/refresh-token', {})
  //       .then((response) => {
  //          if (response.data.ID !== undefined) {
  //             localStorage.setItem('token', response.data.JwtToken);
  //             this.setState({ isLoading: false, redirect: true });
  //             notify('Oturum Yenilenmiştir. Hoş Geldiniz.', 'success', 2000);
  //          }
  //          else {
  //             this.setState({ isLoading: false })
  //          }
  //       })
  //       .catch((error) => {
  //          this.setState({ isLoading: false, redirect: false });
  //          if (error) {
  //             this.setState({ isLoading: false, redirect: false });
  //             window.location.href = "#!/login";
  //             localStorage.clear();
  //             notify(error.response?.data, 'error', 3000);
  //          } else {
  //             this.setState({ isLoading: false, redirect: false });
  //             notify('İnternet Baglantınızı Kontrol Ediniz.', 'error', 3000);
  //          }
  //       });
  // }

  getLdap() {
    BB.FetchJSON("api/Login/GetLdap").then((res) => {
      if (res) {
        this.setState({ domainList: res, showSwitch: true });
      }
    });
  }

  checkUser3rdPartApp(Username, Password, CompanyName) {
    this.setState({ isLoading: true });

    this.props.actions
      .checkLoginUser3rdPartApp(Username, Password, CompanyName)
      .then(() => {
        if (this.props.loginUser.ID !== undefined) {
          this.props.actions.getMenu().then(() => {
            this.props.actions.getPoolCount().then(() => {
              this.setState({ isLoading: false, redirect: true });
              //localStorage.setItem('loginTime', new Date());
            });
          });
        } else {
          this.setState({ isLoading: false });
        }
      });
  }

  /* checkUser(e) {
      if (this.state.userEmail && this.state.userPassword) {
         this.setState({ isLoading: true });

         axios.post('/api/Users/Authenticate', {
            Username: this.state.userEmail,
            Password: this.state.userPassword,
            IsLdap: this.state.isLdapLogin
         }).then((response) => {
            if (response.data.ID !== undefined) {
               localStorage.setItem('token', response.data.JwtToken);
               this.setState({ isLoading: false, redirect: true });
               // this.props.actions.getPoolCount().then(() => {
               //    this.setState({ isLoading: false, redirect: true });
               // })
            }
            else {
               this.setState({ isLoading: false })
            }
         })
            .catch((error) => {
               this.setState({ isLoading: false, redirect: false });
               if (error) {
                  this.setState({ isLoading: false, redirect: false });
                  notify(error.response?.data, 'error', 3000);
               } else {
                  this.setState({ isLoading: false, redirect: false });

                  notify('İnternet Baglantınızı Kontrol Ediniz.', 'error', 3000);
               }
            });


         // this.props.actions.checkLoginuser(this.state.userEmail, this.state.userPassword, this.state.isLdapLogin).then(() =>
         // this.checkLoginuser(this.state.userEmail, this.state.userPassword, this.state.isLdapLogin)
         //    .then((res) => {
         //       // if (res.ID !== undefined) {
         //       //    // this.props.actions.getMenu().then(() =>
         //       //    // {
         //       //    this.props.actions.getPoolCount().then(() => {
         //       //       this.setState({ isLoading: false, redirect: true });
         //       //    })
         //       //    //});
         //       // }
         //       // else {
         //       //    this.setState({ isLoading: false })
         //       // }
         //    }).catch((ex) => {
         //       // this.setState({ isLoading: false, redirect: false });
         //       // notify('Kullanıcı adı & şifreniz hatalıdır', 'error', 3000);
         //    });
      } else {
         notify('Kullanıcı Adı veya Parola Boş Olamaz.', 'error', 3000);
      }

      e.preventDefault();
   }*/
  // checkLoginuser(userEmail, userPass, isLdapLogin) {
  //    axios.post('/api/Users/Authenticate', {
  //       Username: userEmail,
  //       Password: userPass,
  //       IsLdap: isLdapLogin
  //    })
  //       .then(function (response) {
  //          localStorage.setItem('token', response.data.JwtToken);
  //          return response.data;
  //       })
  //       .catch(function (error) {
  //          localStorage.clear();
  //          Promise.reject(error.response.data);
  //       });
  // }

  checkUser(e) {
    const userEmail = this.state.userEmail;
    const userPass = this.state.userPassword;
    const isLdapLogin = this.state.isLdapLogin;
    let url =
      "api/Login/checkUserLogin?userName=" +
      userEmail +
      "&userPassword=" +
      userPass +
      "&isLdapLogin=" +
      isLdapLogin;
    fetch(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.Token !== undefined && response.Token !== "") {
          localStorage.setItem("token", response.Token);
          this.setState({ isLoading: false, redirect: true });
          // this.props.actions.getPoolCount().then(() => {
          //    this.setState({ isLoading: false, redirect: true });
          // })
        } else {
          this.setState({ isLoading: false, redirect: false });
          notify("Kullanıcı Adı Veya Şifre Hatalı.", "error", 3000);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, redirect: false });
        if (error) {
          this.setState({ isLoading: false, redirect: false });
          notify(error.response?.data, "error", 3000);
        } else {
          this.setState({ isLoading: false, redirect: false });

          notify("İnternet Baglantınızı Kontrol Ediniz.", "error", 3000);
        }
      });
    e.preventDefault();
  }

  forgotPasswordUser() {
    if (this.validateForm()) {
      this.setState({ isLoading: true, btnActive: "none" });
      BB.FetchJSON(
        "api/Login/ForgotPassword?Email=" + this.state.ForgotEmail
      ).then((data) => {
        debugger
        this.setState({ info: data, isLoading: false });
      });
    }
  }

    samlLogin() {
        window.open('api/Login/SamlLogin','_self');           
    }

  forgotPassword() {
    this.setState({
      LoginDisplay: "none",
      ResetPasswordDisplay: "",
      btnActive: "",
    });
  }

  backForgotPassword() {
    this.setState({ LoginDisplay: "", btnActive: "none" });
    this.setState({ ResetPasswordDisplay: "none" });
    }


  validateForm() {
    let errors = "";
    let formIsValid = true;
    if (!this.state.ForgotEmail) {
      formIsValid = false;
      this.setState({ btnActive: "" });
      //errors = languages.lutfenEmailGiriniz;
      errors = "*Lütfen e-mail adresinizi giriniz.";
    }
    if (typeof this.state.ForgotEmail !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.ForgotEmail)) {
        formIsValid = false;
        this.setState({ btnActive: "" });
        errors = "*Lütfen geçerli bir e-mail adresinizi giriniz.";
        //errors = languages.lutfenGecerliEmailGiriniz;
      }
    }
    this.setState({
      info: errors,
    });
    return formIsValid;
  }

  handleChange(id, value) {
    let LdapDomain = this.state.domainList.filter((x) => x.ID == value);

    if (LdapDomain.length > 0 && LdapDomain[0].Value) {
      this.setState({ [id]: value, userEmail: LdapDomain[0].Value + "\\" });
    } else {
      notify(
        "Seçilen domainin gerekli bilgileri girilmemiştir",
        "warning",
        3000
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <>
          <div className="animated fadeIn pt-3 text-center">Yükleniyor...</div>
        </>
      );
    } else {
      if (this.state.redirect === true) {
        if (metrics.redirectURL !== undefined) {
          if (metrics.redirectURL !== "/") {
            return <Redirect push to={metrics.redirectURL} />;
          } else {
            return <Redirect push to="/main" />;
          }
        } else {
          return <Redirect push to="/main" />;
        }
      } else {
        return <Render ths={this} />;
      }
    }
  }
}

export const Render = (ths) => {
  //const { t } = useTranslation();
  const event = ths.ths;
  const state = event.state;

  // languages = {
  //    lutfenEmailGiriniz: t('lutfenEmailGiriniz'),
  //    lutfenGecerliEmailGiriniz: t('lutfenGecerliEmailGiriniz'),
  // }

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <div className="sweet-loading ">
        <BarLoader
          width={"auto"}
          size={10}
          color={"#FF9800"}
          loading={event.state.isLoading}
        />
      </div>

      <div
        className="flex-row align-items-center"
        style={{ marginTop: event.state.isLoading === true ? -5 : 0 }}
      >
        <div className="container-fluid">
          <Row className="login-row">
            <Col md="5  login-area">
              <div className="logo">
                <img
                  src={
                    event.props.mainCompany
                      ? event.props.mainCompany.LogoUrl
                      : logo
                  }
                />
              </div>
              <CardGroup className="align-items-center">
                <Card className="p-4 login-card" style={{ boxShadow: "none" }}>
                  <CardBody>
                    <Form onSubmit={(e) => event.checkUser(e)}>
                      {metrics.redirectURL !== undefined ? (
                        metrics.redirectURL !== "/" ? (
                          // // <p style={{ color: 'red' }}>*{t('onceGirisYapiniz')}</p> : null : null
                          <p style={{ color: "red" }}>
                            *{"Lütfen Önce Giriş Yapınız"}
                          </p>
                        ) : null
                      ) : null}
                      <div className="login-text">
                        <h1 className="login-h1">{"task4team'e "}</h1>
                        <h2 className="login-h2">hoşgeldiniz</h2>
                      </div>
                      <p
                        className="text-muted"
                        style={{ display: event.state.LoginDisplay }}
                      >
                        {state.isLdapLogin === true
                          ? "Etki Alanı\\Kullanıcı Adı"
                          : "Kullanıcı Girişi"}
                        {/* //{state.isLdapLogin === true ? "Etki Alanı\\Kullanıcı Adı" : t('kullaniciGirisi')} */}
                      </p>
                      <p
                        className="text-muted"
                        style={{ display: event.state.ResetPasswordDisplay }}
                      >
                        {"Şifre Yenileme İşlemi"}
                      </p>
                      {/* style={{ display: event.state.ResetPasswordDisplay }}>{t('sifreYenilemeIslemi')}</p> */}

                      <InputGroup
                        className="mb-3"
                        style={{ display: event.state.LoginDisplay }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={"email"}
                          placeholder={
                            state.isLdapLogin === true
                              ? "Etki Alanı\\Kullanıcı Adı"
                              : "Kullanıcı adı veya E-posta adresiniz"
                          }
                          onChange={(event) =>
                            ths.ths.setState({ userEmail: event.target.value })
                          }
                          value={event.state.userEmail || ""}
                          autoFocus
                        />
                      </InputGroup>
                      <p
                        className="text-muted"
                        style={{ display: event.state.LoginDisplay }}
                      >
                        {"Şifre"}
                      </p>
                      {/* style={{ display: event.state.LoginDisplay }}>{t('kullaniciŞifre')}</p> */}

                      <InputGroup
                        className="mb-3 login-password"
                        style={{ display: event.state.LoginDisplay }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={"password"}
                          type={
                            event.state.passwordVisible ? "text" : "password"
                          }
                          placeholder={"Şifre"}
                          // placeholder={t('sifre')}
                          autoComplete="current-password"
                          onChange={(event) =>
                            ths.ths.setState({
                              userPassword: event.target.value,
                            })
                          }
                          value={event.state.userPassword || ""}
                        />
                        <InputGroupAddon
                          addonType="prepend"
                          className="login-password-right"
                          onClick={(e) =>
                            ths.ths.setState({
                              passwordVisible: !event.state.passwordVisible,
                            })
                          }
                        >
                          <InputGroupText>
                            <i
                              className={
                                event.state.passwordVisible
                                  ? "fa fa-eye"
                                  : "fa fa-eye-slash"
                              }
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <InputGroup
                        className="mb-3"
                        style={{ display: event.state.ResetPasswordDisplay }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className={"email"}
                          placeholder="Email"
                          type="email"
                          onChange={(event) =>
                            ths.ths.setState({
                              ForgotEmail: event.target.value,
                            })
                          }
                          value={event.state.ForgotEmail || ""}
                          autoFocus
                        />
                      </InputGroup>
                      <p
                        style={{
                          display: event.state.ResetPasswordDisplay,
                          color: "red",
                        }}
                      >
                        {event.state.info}
                      </p>

                      <Row>
                        <Col xs="12" className="button-groups-submit">
                          {state.isLdapLogin === true &&
                            BB.DxDropDownWithJSON(
                              "selectedDomainName",
                              "Domain",
                              event,
                              event.state.domainList
                            )}

                          <Button
                            color="secondary"
                            className="px-4"
                            block
                            style={{ display: event.state.LoginDisplay }}
                            type="submit"
                          >
                            {"Oturum Aç"}
                          </Button>

                          <Button
                            color="secondary"
                            className="px-4"
                            block
                            style={{ display: event.state.btnActive }}
                            onClick={() => event.forgotPasswordUser()}
                          >
                            {"Gönder"}
                          </Button>
                        </Col>
                        <Col
                          xs="12"
                          className="text-left button-groups-settings"
                        >
                          <Row>
                            <Col lg={6}>
                              <Button
                                color="link"
                                className="px-0"
                                style={{ display: event.state.LoginDisplay }}
                                onClick={() => event.forgotPassword()}
                              >
                                {"Şifremi Unuttum"}
                              </Button>
                              {/* onClick={() => event.forgotPassword()}>{t('sifremiUnuttum')}</Button> */}

                              <Button
                                color="link"
                                className="px-0"
                                style={{
                                  display: event.state.ResetPasswordDisplay,
                                }}
                                onClick={() => event.backForgotPassword()}
                              >
                                {"Geri"}
                              </Button>
                            </Col>
                            <Col lg={6} style={{ marginTop: 6 }}>
                                {event.state.showSwitch == true && (
                                 // <>
                                <Row>
                                  <Col lg={8}>
                                    <p style={{ textAlign: "right" }}>
                                      Ldap İle Giriş
                                    </p>
                                  </Col>
                                  <Col lg={4}>
                                    <div style={{ float: "right" }}>
                                      <AppSwitch
                                        label
                                        checked={state.isLdapLogin}
                                        color={"warning"}
                                        size={"sm"}
                                        onChange={(e) =>
                                          event.setState({
                                            isLdapLogin:
                                              !event.state.isLdapLogin,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                  </Row>
                                //<Button
                                //  color="secondary"
                                //  outline
                                //  className="ripple btn-sm"
                                //  //type="submit"
                                //  onClick={() => event.samlLogin()}
                                //>
                                //  <span style={{ color: "black" }}>Saml ile Giriş</span>
                                //</Button>
                                //  </>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
              <div className="footer-div">
                <span></span>
              </div>
            </Col>
            <Col md="7" className="login-right">
              <div>
                <img
                  src={background}
                  className="img-fluid"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: "100%",
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loginUser: state.loginReducer,
    //menus: state.menuReducer,
    mainCompany: state.mainCompanyReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      checkLoginuser: bindActionCreators(
        loginUserActions.checkLoginUser,
        dispatch
      ),
      checkLoginUser3rdPartApp: bindActionCreators(
        loginUserActions.checkLoginUser3rdPartApp,
        dispatch
      ),
      //getMenu: bindActionCreators(menuActions.getMenus, dispatch),
      getPoolCount: bindActionCreators(poolActions.getPoolCount, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
