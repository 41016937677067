import React, { PureComponent } from 'react';
import { CustomCard, LabelGroup } from "../../../custom";
import * as BB from "../../../BBComponent";
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import Modal from "reactstrap/es/Modal";
import ModalBody from "reactstrap/es/ModalBody";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import TaskModel from "../../../../models/Task";
import notify from "devextreme/ui/notify";
import { post } from "axios";
import * as poolActions from "../../../../redux/actions/poolActions";
import TextBox from "devextreme-react/text-box";
import ModalHeader from "reactstrap/es/ModalHeader";
import { Dropdown } from "semantic-ui-react";
import List from "devextreme-react/list";
import * as taskActions from "../../../../redux/actions/taskActions";
import { myTaskCreateTemplatesReducer } from "../../../../redux/reducers/taskReducer";
import { htmlToText } from 'html-to-text';
import metrics from '../../../../common/metrics';

class FastTaskCreate extends PureComponent {
  constructor(props) {
     super(props);
     this.state = {
        selectedTemplate: undefined,
        showPreview: false,
        templateJson: {},
        templateName: '',
        selectedTaskdes: '',
        selectedID: undefined,
        selectedEmail:''
     };

  }

  handleChange(id, key) {
     if (id === 'selectedTemplate') {
        if (key !== undefined) {
           BB.FetchJSON('api/Dashboard/GetTemplateJson?ID=' + key).then(data => {
              const storeData = JSON.parse(data[0].TemplateJson);
              this.setState({ templateJson: storeData });
              this.setState({ selectedTaskdes: storeData.selectedTaskDescription })
              this.setState({ selectedID: key })
              this.setState({selectedEmail:storeData.selectedEmployeeEmail})
           });
         /*   BB.FetchJSON('api/Dashboard/GetTemplateName?ID=' + key).then(data => {

              this.setState({ templateName: data });

           }) */

           fetch('api/DashBoard/GetTemplateName?ID='+key, {method: 'GET'})
             .then((response) => response.json())
              .then((data) =>
              {
                  
                  this.setState({templateName: data});
               });
        }
     }
     this.setState({ [id]: key });
  }

  deleteTemplate() {
     BB.askYesNo('İlgili şablonu silmek istediğinize eminmisiniz?', 'Şablon Sil', 'Evet', 'Hayır')
        .then((e) => {
           if (e === true) {
              BB.FetchJSON('api/Task/DeleteMyTaskCreateTemplate?TemplateID=' + this.state.selectedTemplate)
                 .then(res => {
                    this.props.actions.getMyTaskCreateTemplates();
                    notify('Talep oluşturma şablonunuz başarıyla silimiştir.', 'success', 2000);
                 })
                 .catch(() => {
                    this.props.actions.getMyTaskCreateTemplates();
                    notify('Talep oluşturma şablonu silmede hata!.', 'error', 2000);
                 })
           }
        })
  }
  editTemplate(id) {

     let item = new TaskModel();
     let formData = new FormData();
     let selectedWatcherUserIDs = [];
     let arr = [];
     // let {selectedTemplate} = this.state;
     //let {selectedTemplate}=this.state

     let tempJson = {}

     BB.FetchJSON('api/Dashboard/GetTemplateJson?ID=' + id).then(templateJson => {
        //this.props.actions.isLoading(true);
        tempJson = JSON.parse(templateJson[0].TemplateJson)
         
        /* item.CategoryID = tempJson.selectedCategory;
        item.CreateDate = new Date();
        item.DegreeOfUrgency = tempJson.selectedDegreeOfUrency;
        item.Description = htmlToText(metrics.selectedTaskdes, { tags: { 'img': { format: 'skip' } } }).replaceAll('//', '/');
        item.NotificationType = tempJson.selectedNotificationType;
        item.TaskNumber = 0;
        item.TaskTypeID = 'fb3a1946-fecd-e811-80f6-005056b839bb';
        item.UrgencyDescription = tempJson.selectedDegreeOfUrencyDescription;
        item.CreateUserID = tempJson.selectedEmployee;
        item.IsDepartmanNotification = false;
        item.ExpectedEndDate = tempJson.selectedDueClosedDate;
        item.MailNotification = tempJson.selectedMailNotifications;
        item.WatcherUsers = selectedWatcherUserIDs;
        item.CustomControlForms = tempJson.CustomControlForms;
        item.HTMLDescription = metrics.selectedTaskdes; */

        tempJson.selectedTaskDescription=htmlToText(metrics.selectedTaskdes, { tags: { 'img': { format: 'skip' } } }).replaceAll('//', '/');
        tempJson.selectedEmployeeEmail=this.props.loginuser.Email;

        formData.append('jsonText', JSON.stringify(tempJson));
        formData.append('templateName', this.state.templateName);
        formData.append('id',id);




        post('api/DashBoard/UpdateTaskCreateTemplate', formData, {
           headers: {
              'content-type': 'multipart/form-data'
           },
        })
           .then(res => {
              //this.props.actions.isLoading(false);
              if (res.data["Issuccess"]) {
                 let ths = this;

                 notify(res.data["ErrMsg"], "success", 2000);
                 
                 setTimeout(function () {
                    ths.props.actions.getAllTaskInPool();
                 }, 2000);
                 this.setState({showPreview:false})
                   ths.props.actions.getMyTaskCreateTemplates();
              }
              else {
                 notify(res.data["ErrMsg"], "error", 2000);
                 //this.props.actions.isLoading(false);
              }
           });
     });

  }

  saveTask() {
     let item = new TaskModel();
     let formData = new FormData();
     let selectedWatcherUserIDs = [];
     let arr = [];
     let { selectedTemplate } = this.state;
     let tempJson = {}

     BB.FetchJSON('api/Dashboard/GetTemplateJson?ID=' + selectedTemplate).then(templateJson => {
        this.props.actions.isLoading(true);
        tempJson = JSON.parse(templateJson[0].TemplateJson)
        item.CategoryID = tempJson.selectedCategory;
        item.CreateDate = new Date();
        item.DegreeOfUrgency = tempJson.selectedDegreeOfUrency;
        item.Description = htmlToText(tempJson.selectedTaskDescription, { tags: { 'img': { format: 'skip' } } }).replaceAll('//', '/');
        item.NotificationType = tempJson.selectedNotificationType;
        item.TaskNumber = 0;
        item.TaskTypeID = 'fb3a1946-fecd-e811-80f6-005056b839bb';
        item.UrgencyDescription = tempJson.selectedDegreeOfUrencyDescription;
        item.CreateUserID = tempJson.selectedEmployee;
        item.IsDepartmanNotification = false;
        item.ExpectedEndDate = tempJson.selectedDueClosedDate;
        item.MailNotification = tempJson.selectedMailNotifications;
        item.WatcherUsers = selectedWatcherUserIDs;
        item.CustomControlForms = tempJson.CustomControlForms;
        item.HTMLDescription = tempJson.selectedTaskDescription;

       
        formData.append('jsonText', JSON.stringify(item));

        if (this.state.selectedFormID !== undefined) {
           formData.append('customFormID', tempJson.selectedFormID);
           formData.append('customFormValues', JSON.stringify(tempJson.answer_data));
        }

        if (this.state.selectedSheetID !== undefined) {
           formData.append('customSheetID', tempJson.selectedSheetID);
           formData.append('customSheetValues', JSON.stringify(tempJson.rows));
        }

        for (let i = 0; i < tempJson.selectedTaskFiles.length; i++) {
           formData.append('files', tempJson.selectedTaskFiles[i])
        }

        post('api/Task/taskCreate', formData, {
           headers: {
              'content-type': 'multipart/form-data'
           },
        })
           .then(res => {
              this.props.actions.isLoading(false);
              if (res.data["Issuccess"]) {
                 let ths = this;

                 notify(res.data["ErrMsg"], "success", 2000);

                 setTimeout(function () {
                    ths.props.actions.getAllTaskInPool();
                 }, 2000);
              }
              else {
                 notify(res.data["ErrMsg"], "error", 2000);
                 this.props.actions.isLoading(false);
              }
           });
     });
  }

  render() {
     const t = this.props.useTranslation;
     const { selectedTemplate, templateJson, templateName } = this.state;
     return (
        <CustomCard cardStyle='card-dashboard-calendar pb-0'>
           <LabelGroup
              title={t("hazırTalepŞablonları")}
              description={t("hazırtalepSablonlariDesc")} />
           <Row>
              <div style={{ marginLeft: 20, width: 250 }}>
                 {BB.DxDropDownWithJSON('selectedTemplate', t("şablonSeçiniz"), this, this.props.myTaskCreateTemplates)}
              </div>
              <div style={{ marginTop: 28, float: 'right', marginLeft: 10 }}>


                 <Button
                    style={{ float: 'right', marginRight: 5 }}
                    disabled={selectedTemplate === undefined}
                    color={'danger'}
                    onClick={() => this.deleteTemplate()}
                 >
                    {t("sablonuSil")}
                 </Button>
                 <Button
                    style={{ float: 'right', marginRight: 5 }}
                    disabled={selectedTemplate === undefined}
                    color={'warning'}
                    onClick={() => this.setState({ showPreview: true })}
                 >
                    {t("önizleme")}
                 </Button>
                 <Button
                    style={{ float: 'right', marginRight: 5 }}
                    onClick={() => this.saveTask()}
                    disabled={selectedTemplate === undefined}
                    color={'success'}
                 >
                  {t("sablonİleTalepOlustur")}
                    
                 </Button>
              </div>
           </Row>

           <Modal
              size={'lg'}
              style={{ zIndex: 1 }}
              centered={true}
              isOpen={this.state.showPreview}
           >
              <ModalBody className='pt-0'>
                 {
                    <div>
                       <TaskTemplateViewer
                          loginuser={this.props.loginuser}
                          ths={this}
                          useTranslation={t}
                          templateJson={templateJson}
                          event={this}
                          state={this.state}
                          templateName={templateName}
                       />
                    </div>
                 }
              </ModalBody>
              <ModalFooter>
                  
                 <Button onClick={() => this.editTemplate(this.state.selectedTemplate)}>
                 {t("ŞablonuDüzenle")}
   
                 </Button>
                 <Button onClick={() => this.setState({ showPreview: false })}>
                                  {t("kapat")}

                 </Button>
              </ModalFooter>
           </Modal>

        </CustomCard>
     );
  }
}

class TaskTemplateViewer extends PureComponent {
  constructor(props) {
     super(props);
     this.state = {
        userList: []
     }
  }



  componentDidMount() {
     const storeData = this.props.templateJson;
     this.setState(storeData);
  }

  handleChange(key, value) {
     this.setState({ [key]: value });
  }

  render() {
     const {
        selectedCompanyName,
        userList,
        selectedEmployeeName,
        selectedEmployeeJobName,
        selectedEmployeeDepartment,
        selectedEmployeeEmail,
        selectedDegreeOfUrency,
        selectedMainTaskID,
        isAcil,
        showExpectedEndDate,
        showEffect,
        effectList,
        mainTaskList,
        visibleWatcher,
        parentCategories,
        childCategory1Arr,
        childCategory2Arr,
        childCategory3Arr,
        childCategory4Arr,
        childCategory5Arr,
        selectedParentCategory,

     } = this.state;
     const t = this.props.useTranslation;
     const tempName = this.props.templateName
     let event = this;
     let state = this.state;
     //const des=this.state.selectedTaskDescription

     this.setState({ templateName: this.props.templateName })

     return (
        <>
           <Col xs="12" sm="12" lg={12} className='p-0'>
              <Card className="border-warning">
                 <CardBody>
                    <Row>
                       <Col lg="12">
                          <Row>
                             <Col lg={4}>
                                {/*{BB.DxDropDownWithJSON('selectedEmployeeName', t('isim'), event, userList, true)}*/}
                                {BB.DxTextBox('selectedEmployeeName', t('isim'), event, true)}
                             </Col>
                             <Col lg={4}>
                                {/*{BB.DxDropDownWithJSON('selectedEmployeeName', t('isim'), event, userList, true)}*/}
                                {/* {BB.DxTextBoxEdit('selectedEmployeeName', t('Şablon Başlığı'), tempName, true)} */}
                                {BB.InputWithLabel('templateName', 'Şablon Başlığı*', 'text', this.props.ths)}
                             </Col>
                             <Col lg={4}>
                                {BB.DxDropDownWithJSON('selectedMainTaskID', t('iştekrarıno'), event, mainTaskList, true)}
                             </Col>
                          </Row>

                          <Row style={{ marginTop: -5, marginBottom: 15 }}>
                             <Col>
                                <a className="text-muted">
                                 {t("işUnvani")}

                                </a> : {selectedEmployeeJobName} {'  |  '}
                                <a className="text-muted">
                                {t("departman")}
                                </a> : {selectedEmployeeDepartment} {'  |  '}
                                <a className="text-muted">
                                  {t("eposta")}

                                </a> : {selectedEmployeeEmail}
                             </Col>
                          </Row>

                          <Row>
                             <Col lg={8}>
                                <FormGroup onClick={() => this.setState({ selectCategory: true })}>
                                   <Label>
                                      <strong>{t("kategori")}*</strong>
                                   </Label>
                                   <TextBox
                                      placeholder={t("lütfenseçiniz")}
                                      readOnly={true}
                                      value={this.state.selectedCategoryName}
                                   />
                                </FormGroup>
                             </Col>
                             <Col lg={4}>
                                <Row>
                                   <Label style={{ marginLeft: 15 }}><strong>{t("aciliyetderecesi")} </strong></Label>
                                     
                                </Row>
                                {
                                   selectedDegreeOfUrency === 0
                                      ? <Button 
                                         style={{ backgroundColor: "black", color: "white" }}>{t("düşük")}</Button>
                                      : selectedDegreeOfUrency === 1
                                         ? <Button style={{
                                            backgroundColor: "yellow",
                                            color: "black"
                                         }}>{t("orta")}</Button>
                                         : selectedDegreeOfUrency === 2
                                            ? <Button style={{
                                               backgroundColor: "red",
                                               color: "white"
                                            }}>{t("yüksek")}</Button>
                                            : null
                                }
                             </Col>
                          </Row>

                          <Modal isOpen={this.state.selectCategory} style={{ marginTop: 250 }}>
                             <ModalHeader toggle={() => this.setState({
                                selectCategory: false,
                             })}>
                                {t("kategoriSecimi")}  
                             </ModalHeader>
                             <ModalBody>
                                <FormGroup>
                                   <Label>
                                      <strong>{t("anaKategori")}</strong>
                                   </Label>
                                   <div style={{ width: 300 }}>
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={selectedParentCategory}
                                         options={parentCategories}
                                         onChange={(e, a) => this.categoryRender('selectedParentCategory', a.value)}
                                      />
                                   </div>

                                </FormGroup>

                                {childCategory1Arr &&
                                   <FormGroup>
                                      <Label>
                                         <strong>{t("altKategori")}</strong>
                                      </Label>
                                      <br />
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={this.state.selectedChildCategory1}
                                         options={childCategory1Arr}
                                         onChange={(e, a) => this.categoryRender('selectedChildCategory1', a.value)}
                                      />
                                   </FormGroup>
                                }

                                {childCategory2Arr &&
                                   <FormGroup>
                                      <Label>
                                         <strong>{t("altKategori")}</strong>
                                      </Label>
                                      <br />
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={this.state.selectedChildCategory2}
                                         options={childCategory2Arr}
                                         onChange={(e, a) => this.categoryRender('selectedChildCategory2', a.value)}
                                      />
                                   </FormGroup>
                                }

                                {childCategory3Arr &&
                                   <FormGroup>
                                      <Label>
                                         <strong>{t("altKategori")}</strong>
                                      </Label>
                                      <br />
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={this.state.selectedChildCategory3}
                                         options={childCategory3Arr}
                                         onChange={(e, a) => this.categoryRender('selectedChildCategory3', a.value)}
                                      />
                                   </FormGroup>
                                }

                                {childCategory4Arr &&
                                   <FormGroup>
                                      <Label>
                                         <strong>{t("altKategori")}</strong>
                                      </Label>
                                      <br />
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={this.state.selectedChildCategory4}
                                         options={childCategory4Arr}
                                         onChange={(e, a) => this.categoryRender('selectedChildCategory4', a.value)}
                                      />
                                   </FormGroup>
                                }

                                {childCategory5Arr &&
                                   <FormGroup>
                                      <Label>
                                         <strong>{t("altKategori")}</strong>
                                      </Label>
                                      <br />
                                      <Dropdown
                                         disabled={true}
                                         placeholder={t("lütfenseçiniz")}
                                         search
                                         defaultValue={this.state.selectedChildCategory5}
                                         options={childCategory5Arr}
                                         onChange={(e, a) => this.categoryRender('selectedChildCategory5', a.value)}
                                      />
                                   </FormGroup>
                                }

                             </ModalBody>
                          </Modal>


                          {showExpectedEndDate === true &&
                             <Row>
                                <Col></Col>
                                <Col></Col>
                                <Col>
                                   {BB.DateSelectWithLabel('selectedDueClosedDate', t('tahminikapanmatarihi'), event, t('lütfenseçiniz'), true)}
                                </Col>
                             </Row>
                          }

                          {/*Ek alanlar*/}
                          {/*<div dangerouslySetInnerHTML={{__html: customElements}}/>*/}

                          {/*Talep açıklaması*/}
                          {/* {BB.DxHtmlEditorViewer('selectedTaskdes', t('açıklamazorunlu'), this.props.ths)} */}
                          {BB.DxHtmlEditor2(
                             "selectedTaskdes",
                             t("açıklamazorunlu"),
                             this.props.ths.state.selectedTaskdes
                          )}

                          {isAcil === true && BB.TextAreaWithLabel('selectedDegreeOfUrencyDescription', t('aciliyetaçıklaması'), t('aciliyethakkındabirşeyleryaz'), 1, event, '#fff', true)}

                          {showEffect && BB.DxDropDownWithJSON('selectedEffect', t('etki'), event, effectList, true)}

                          <Row>
                             <Col>
                                {BB.MultipleFileWithLabelIsDisable('selectedTaskFiles', t('ekdosya'), event)}
                             </Col>
                             <Col>
                             </Col>
                             <Col>
                                <div style={{ float: 'right', marginRight: 30, }}
                                   onClick={() => this.setState({ visibleWatcher: true })}
                                >
                                   <Label style={{ cursor: 'pointer' }}>
                                      <strong>
                                         <u>{this.state.selectedWatcherUserList ? 'İzleyiciler' + ' (' + this.state.selectedWatcherUserList.length + ')' : 'İzleyiciler' + ' (0)'}</u>
                                      </strong>
                                   </Label>
                                </div>
                             </Col>
                          </Row>
                       </Col>

                    </Row>
                 </CardBody>
                 <CardFooter>
                    <div dangerouslySetInnerHTML={{ __html: state.categoryConfirmations }} />
                 </CardFooter>
              </Card>
           </Col>


           <Modal isOpen={visibleWatcher} centered={true} size={'l'}>
              <ModalHeader toggle={() => this.setState({ visibleWatcher: false })}>
                 İzleyiciler
              </ModalHeader>
              <ModalBody>
                 <Col>
                    <List
                       keyExpr={"ID"}
                       displayExpr={"Name"}
                       dataSource={this.state.selectedWatcherUserList}
                       height={220}
                       allowItemDeleting={false}
                       itemDeleteMode={"static"}
                       showSelectionControls={true}
                    />
                 </Col>
              </ModalBody>
           </Modal>
        </>
     );
  }
}

function mapStateToProps(state) {
  return {
     loginuser: state.loginReducer,
     myTaskCreateTemplates: state.myTaskCreateTemplatesReducer
  }
}

function mapDispatchToProps(dispatch) {
  return {
     actions: {
        isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
        getAllTaskInPool: bindActionCreators(poolActions.getPoolCount, dispatch),
        getMyTaskCreateTemplates: bindActionCreators(taskActions.getMyTaskCreateTemplates, dispatch),
     }
  }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FastTaskCreate)))
