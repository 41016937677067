import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row} from "reactstrap";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {  callAPI,
    callApiGet,
    callApiPost,
    callApiPut } from "../../../../../components/Axios";

    
const AdditionalDescription = ({t, event}) =>
{
    return (
        <Modal
            style={{zIndex: 1}}
            centered={true}
            isOpen={event.state.visibleAdditionalDescription}
  >
            {/* <ModalHeader>{t('ekAciklamaEkle')}</ModalHeader> */}
            <ModalHeader>{t('ekAciklamaEkle')}</ModalHeader>
             
            <ModalBody>
                {/* {BB.DxMultipleDropdownWithUrl('selectedWatcherUsers', t('ekbilgilendirme'), event, '/api/User/GetUserList')} */}
                {BB.TextAreaWithLabel('additionalDescription',t("ekAciklama"), '', 5, event)}

                <Row style={{float: 'right'}}>
                    <Button
                        color={'info'}
                        onClick={() => event.setState({visibleAdditionalDescription: false,additionalDescriptionValue:""})}>
                        {t('vazgec')}
                    </Button>
                    <Button
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 15}}
                        onClick={() =>
                        {
                            let isResponsibleUser =
                            event.state.loginUser.ID === event.state.data.ResponsibleUserID;

                          let { taskID, selectedProjectNumber ,additionalDescriptionValue} = event.state;

                         
                          let URL =
                            "api/TaskDetail/ChangeTaskProjectNumber?TaskID=" +
                            taskID +
                            "&ProjectNumber=" +
                            "DT" +
                             "&AdditionalDescription=" + 
                             additionalDescriptionValue
                      +"&hostName="+  window.location.hostname

                          if (isResponsibleUser === true) {
                            BB.FetchJSON(URL).then((res) => {
                              if (res === "DT için uygun kategoriler seçili değildir.") {
                                event.setState({visibleAdditionalDescription: false,additionalDescriptionValue:""})
                                notify(t("DTiçinUygunKategorilerSeciliDegildir."), "warning", 2000);
                              } else if (
                                res ===
                                "Bu Talep İçin Önceden Oluşturulmuş Bir Azure Kaydı Mevcuttur. "
                              ) {
                                event.setState({visibleAdditionalDescription: false,additionalDescriptionValue:""})
                                notify(
                                  t("buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur."),
                                  "warning",
                                  2000
                                );
                              } else if (res === true) {
                                event.setState({visibleAdditionalDescription: false,additionalDescriptionValue:""})
                                notify(t("Basarılı!"), "success", 2000);
                              } else {
                                event.setState({visibleAdditionalDescription: false,additionalDescriptionValue:""})
                                notify(t("birHataAlındı!"), "warning", 2000);
                              }
                            });
                          } else {
                            notify(
                              t("projeNumarasıAlanınıSadeceTalepSorumlusuDegistirebilir!"),
                              "warning",
                              3000
                            );
                          }
                        }}>
                        {t('ekle')}
                    </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default AdditionalDescription;
