import React, {Component} from 'react';
import {Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row,} from 'reactstrap';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as BB from "../../../../components/BBComponent";
import {timeoutPopup} from "../../../../components/BBComponent";
import Swal from 'sweetalert2';
import {bindActionCreators} from "redux";
import * as taskActions from "../../../../redux/actions/taskActions";
import {withRouter} from "react-router-dom";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import notify from "devextreme/ui/notify";
import {CustomCard} from "../../../../components/custom";
import moment, { lang } from 'moment';
import jwt_decode from 'jwt-decode';
import { data } from 'jquery';
import { callAPI, callApiGet, callApiPost } from "../../../../components/Axios";

let languages = undefined;

class TaskConfirm extends Component
{

   constructor(props)
   { const token = localStorage.getItem("token");
   let user = null;
   if (token) {
      user = jwt_decode(token);
   }
      
      super(props);

      this.state = {
         gridData: [],
         update: false,
         showDetail: false,
         showModal: false,
         selectedDate: undefined,
         selectedUserId: undefined,
         otherConfirm: undefined,
         otherTransfer:false,
         showDeleteModal:false,
         showProcurationModal:false,
         otherConfirmCount: '',
         procurationConfirmCount:'',
         myOwnTaskCount:'',
         loginUser:user,
         isProcuration:undefined,

      };

      this.getConfirmation();
      this.getData();
      this.getProcurationConfirmCount();
   }

   getConfirmation()
   {
      BB.FetchJSON('api/OwnConfirmTask/GetConfirmByProcuration').then(res =>
      {
         if (res.length > 0)
         {
            this.setState({
               otherConfirm: res[0],
               selectedUserID: undefined,
               selectedDate: undefined,
            });
         }
         else
         {
            this.setState({
               otherConfirm: undefined,
               selectedUserID: undefined,
               selectedDate: undefined,
            });
         }
      })

     
   }

   getData()
   {
      BB.FetchJSON('api/OwnConfirmTask/GetData').then(data =>
      {   

      data.map(jsonlar => (
         this.setState({isProcuration: jsonlar.IsProcuration})
         ))
         
         this.setState({gridData: data});
      })
      
      this.getOtherConfirmData();
      this.getMyOwnTaskCount();
      
   }

   getOtherConfirmData() {
      BB.FetchTEXT('api/OwnConfirmTask/GetProcurationTaskCount').then(data =>
         {
            this.setState({otherConfirmCount: data});
         })

         
   }

   getMyOwnTaskCount() {
      BB.FetchTEXT('api/OwnConfirmTask/GetMyOwnTaskCount').then(data =>
         {
            this.setState({myOwnTaskCount: data});
         })

         
   }

   getProcurationConfirmCount(){

      callAPI(
         callApiGet,
         'api/OwnConfirmTask/GetConfirmTaskCount',
         {},
          (res) => {
           if (res?.data) {
             
            this.setState({procurationConfirmCount: res?.data});
           }
         },
         true
       );






      // BB.FetchTEXT('api/OwnConfirmTask/GetConfirmTaskCount').then(data =>
      //    {
      //       this.setState({procurationConfirmCount: data});
      //    })
   }

  

   handleChange(key, value)
   {
      let taskID = value;

      if (key === 'confirm')
      {
         BB.FetchJSON('api/DataCheck/ConfirmCheck?TaskID=' + taskID).then(res =>
         {
            if (res === true)
            {
               Swal.fire({
                  title: languages.onayAciklamasiGiriniz,
                  input: 'textarea',
                  inputAttributes: {
                     'aria-label': 'Type your message here'
                  },
                  showCancelButton: true,
                  confirmButtonText: languages.onayVer,
                  cancelButtonText: languages.iptal,
                  showLoaderOnConfirm: true,
                  preConfirm: (login) =>
                  {
                     let url = '/api/OwnConfirmTask/Confirm?id=' + taskID + '&Description=' + login;

                     return fetch(url)
                     .then((response) => response.text())
                     .then((res) =>
                     {
                        return res;
                     })
                     .catch((error) =>
                     {
                        console.error(error);
                     });
                  },
                  allowOutsideClick: () => !Swal.isLoading()
               }).then((result) =>
               {
                  
                  if(result.isConfirmed===true && result.value==="false"){
                     timeoutPopup('Talep Daha Önce Onaylanmıştır !', "warning", "Tamam", 4000);

                  }
                  else if (result.isConfirmed)
                  {
                     this.setState({update: !this.state.update}, this.getData);

                     Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: languages.onayBasariylaVerilmistir,
                        showConfirmButton: false,
                        timer: 1500
                     })
                  }
               })
            }
            else
            {
               timeoutPopup('Talebin durumu değiştiği için bu talep üzerinde işlem yapamazsınız!', "warning", "Tamam", 4000);
            }
         })
      }
      if (key === 'reject')
      {
         BB.FetchJSON('api/DataCheck/CancelCheck?TaskID=' + taskID).then(res =>
         {
            if (res === true)
            {
               Swal.fire({
                  title: "Red açıklaması giriniz",
                  input: 'textarea',
                  inputAttributes: {
                     'aria-label': 'Type your message here'
                  },
                  showCancelButton: true,
                  confirmButtonText: "Reddet",
                  cancelButtonText: languages.vazgec,
                  showLoaderOnConfirm: true,
                  preConfirm: (login) =>
                  {
                     let url = '/api/OwnConfirmTask/Reject?id=' + taskID + '&Description=' + login;

                     return fetch(url)
                     .then((response) => response.text())
                     .then((res) =>
                     {
                        return res;
                     })
                     .catch((error) =>
                     {
                        console.error(error);
                     });
                  },
                  allowOutsideClick: () => !Swal.isLoading()
               }).then((result) =>
               {
                  if(result.isConfirmed===true && result.value==="false"){
                     timeoutPopup('Talep Daha Önce Reddedilmiştir !', "warning", "Tamam", 4000);

                  }
                 else if (result.isConfirmed)
                  {
                     this.setState({update: !this.state.update}, this.getData);

                     Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: "Talep Başarıyla reddedilmiştir",
                        showConfirmButton: false,
                        timer: 1500
                     })
                  }
               })
            }
            else
            {
               timeoutPopup('Talebin durumu değiştiği için bu talep üzerinde işlem yapamazsınız!', "warning", "Tamam", 4000);
            }
         })
      }
      if (key === 'selectedDate' || key === 'selectedUserID')
      {
         this.setState({[key]: value});
      }
      if(key=== 'otherTransfer') {
          this.setState({otherTransfer: true})
      }

      if(key=== 'otherTransferNo') {
         this.setState({otherTransfer: false})
     }
   }

   saveConfirmUser()
   {
      let {selectedDate, selectedUserID} = this.state;

      if (selectedDate !== undefined || selectedUserID !== undefined)
      {
         //let expryDate = new Date(selectedDate).toJSON().slice(0, 10);
         let expryDate=moment(selectedDate).format("YYYY-MM-DD 23:59");
         this.props.actions.isLoading(true)

         BB.FetchJSON('api/OwnConfirmTask/SetConfirmByProcuration?strDate=' + expryDate + '&UserID=' + this.state.selectedUserID + '&otherTransfer=' + this.state.otherTransfer)
         .then(r =>
         {
            if (r === true)
            {
               this.setState({
                  selectedUserID: undefined,
                  selectedDate: undefined,
                  showModal: false
               })
            }
            else
            {
               this.setState({
                  selectedUserID: undefined,
                  selectedDate: undefined,
                  showModal: false
               })
            }
            this.getConfirmation();
            this.getData();
                      this.props.actions.isLoading(false)

         })
      }
      else
      {
         notify('Lütfen zorunlu alanları doldurunuz!', 'error', 2000);
      }
   }

   deleteOtherConfirm()
   {
      this.getOtherConfirmData();
      this.setState({showDeleteModal: !this.state.showDeleteModal})
    /*    BB.askYesNo('Vekaleten onay iptali', 'Vekaleten verdiğiniz onay yetkisi kaldırılacaktır, Eminmisiniz?', 'Evet', 'Vazgeç')
      .then(res =>
      {
         if (res === true)
         {
            BB.FetchJSON('api/OwnConfirmTask/DeleteConfirmByProcuration')
            .then(r =>
            {
               this.getConfirmation();
            })
         }
      })  */
   }

   takeProcurationTasks(){
     
         BB.FetchJSON('api/OwnConfirmTask/TaskTransfer?otherTransfer=' + this.state.otherTransfer)
         .then(r =>
         {
            if (r === true)
            {
               this.setState({
                  selectedUserID: undefined,
                  selectedDate: undefined,
                  showDeleteModal: false
               })
            }
            else
            {
               this.setState({
                  selectedUserID: undefined,
                  selectedDate: undefined,
                  showDeleteModal: false
               })
            }
            this.getConfirmation();
            this.getData();
         })
      }
    
   

   onToolbarPreparing = (e) =>
   {
      
      let ths = this;
      let toolbarItems = e.toolbarOptions.items;

      //Toplam Checkbox
      toolbarItems.unshift({
         location: 'center',
         template: 'totalGroupCount'
      });

      //Toplam Checkbox
      toolbarItems.unshift({
         location: 'before',
         template: 'pageName'
      });

      {
         
         if(this.state.isProcuration===true){

         }

         else if (this.state.loginUser.IsManager === "True" && this.state.otherConfirm === undefined)
          {  
          
             toolbarItems.push({
             widget: 'dxButton', 
             options: {
               onClick: () =>
               {
                  this.setState({showModal: true})
               },
               text: languages.vekaletenOnay,
               width: 200,
               visible:true,
            },
            location: 'after'
         });
     
      }
      }
   }

   toolbarItemRender = (e) =>
   {
      return (
         <div className="informer">
            <h3 className="count">{this.state.gridData.length}</h3>
            <span className="name">{languages.toplamTalepSayisi}</span>
         </div>
      );
   }


   pageNameRender = (e) =>
   {
      return (
         <div className="informer">
            <strong><h5 className="count" style={{marginTop: 7, marginLeft: 5,width: 250}}>{languages.onaybekleyentalepler}</h5></strong>
         </div>
      );
   }

   render()
   {
      
      
      const {t} = this.props;

      languages = {
         onayAciklamasiGiriniz: t('onayAciklamasiGiriniz'),
         onayVer: t('onayVer'),
         iptal: t('iptal'),
         onayBasariylaVerilmistir: t('onayBasariylaVerilmistir'),
         iptalAciklamasiGiriniz: t('iptalAciklamasiGiriniz'),
         vazgec: t('vazgec'),
         talepBasariylaIptalEdilmistir: t('talepBasariylaIptalEdilmistir'),
         onaybekleyentalepler:t('onaybekleyentalepler'),
         toplamTalepSayisi:t('toplamTalepSayisi'),
         bilgi:t("bilgi"),
         vekaletenOnay:t("vekaletenOnay"),
         sonGecerlilikTarihiSeciniz:t("sonGecerlilikTarihiSeciniz"),
         LütfenSeçiniz:t("LütfenSeçiniz"),
         sizinYerinizeOnayVerecekKisiSeciniz:t("sizinYerinizeOnayVerecekKisiSeciniz"),
         kaydet:t("kaydet"),
         vazgec:t("vazgec")
      }
     
      return (
         <>
            <Row>
               <Col xs="12" sm="12">
                  <CustomCard cardStyle='card-dashboard-calendar pb-0'>


                     <CardBody>
                        {BB.DxDataGridWithDetailForConfirm(this.state.gridData, this, this.state.showDetail)}
                     </CardBody>

                     {this.state.otherConfirm !== undefined &&
                     <div>
                        <strong>
                           {languages.bilgi}
                        </strong>
                        {new Date(this.state.otherConfirm.ExpiryDate).toLocaleDateString()} Tarihine kadar sizin
                        yerinize <strong> {this.state.otherConfirm.Name}</strong> onay verecektir

                        <div
                           style={{float: "right", right: 0, color: 'mediumblue', cursor: 'pointer'}}
                           onClick={() => this.deleteOtherConfirm()}
                        >
                           Kaldır
                        </div>
                     </div>
                     }
                  </CustomCard>
               </Col>
            </Row>
            <Modal
               toggle={() => this.setState({showDeleteModal: false})}
               backdrop={true}
               isOpen={this.state.showDeleteModal}
               centered={true}
            >
               <ModalHeader toggle={() => this.setState({showDeleteModal: false})}>
                  Vekaleten Onay İptali
               </ModalHeader>
               <ModalBody>

               <h3 className="count">{t("vekaletenverdiginizonayyetkisikaldirilacaktir")}</h3>
                  {
                     this.state.otherConfirmCount > 0 &&   <h5 className="count">{t("vekaletenonayibekleyen")} {this.state.otherConfirmCount} {t("adetonaylanmamistalepbulunmaktadir")}</h5>
                  }
                  {
                     this.state.otherConfirmCount > 0 && <div className='row'> <div className='col-md-2'> {BB.CheckBoxWithLabel('otherTransfer', t("evet"), this) }</div> <div className='col-md-10'> {BB.CheckBoxWithLabel('otherTransferNo', t("hayır"), this) }</div> </div>
                  }
                  
                  <Button
                     color={'success'}
                     style={{float: 'right'}}
                     onClick={() => this.takeProcurationTasks()}
                  >
                     Evet
                  </Button>

                  <Button
                     color={'primary'}
                     style={{float: 'right', marginRight: 10}}
                     onClick={() => this.setState({
                        selectedDate: undefined,
                        selectedUserID: undefined,
                        showDeleteModal: false
                     })}
                  >
                     {languages.vazgec}
                  </Button>
               </ModalBody>

            </Modal>

           

            <Modal
               toggle={() => this.setState({showModal: false})}
               backdrop={true}
               isOpen={this.state.showModal}
               centered={true}
            >
               <ModalHeader toggle={() => this.setState({showModal: false})}>
                  {languages.vekaletenOnay}
               </ModalHeader>
               <ModalBody>
                  {BB.DateSelectWithLabel('selectedDate', languages.sonGecerlilikTarihiSeciniz, this, languages.LütfenSeçiniz)}
                  {BB.DxDropDownWithURL('selectedUserID', languages.sizinYerinizeOnayVerecekKisiSeciniz, this, 'api/OwnConfirmTask/GetDepartmentUser', languages.LütfenSeçiniz)}
                  {
                     this.state.myOwnTaskCount > 0 &&  <h5 className="count">{t("onayiniziBekleyen")} {this.state.myOwnTaskCount} {t("adetonaylanmamistalep")}</h5>
                  }
                   
                   
                   {
                      this.state.myOwnTaskCount >0 && <div className='row'> <div className='col-md-2'> {BB.CheckBoxWithLabel('otherTransfer', t("evet"), this) }</div> <div className='col-md-10'> {BB.CheckBoxWithLabel('otherTransferNo', t("hayır"), this) }</div> </div>
                   }
                   
                    
                   {
                      this.state.procurationConfirmCount > 0 && <h5 style={{color:'red'}}>{t("vekaletenonayibekleyen")} {this.state.procurationConfirmCount} {t("adetonaylanmamistalepbulunmaktadirdegerlendiriniz")}</h5>
                   }
                   
                  <Button
                     color={'success'}
                     style={{float: 'right'}}
                     onClick={() => this.saveConfirmUser()}
                  >
                     {languages.kaydet}
                  </Button>

                  <Button
                     color={'primary'}
                     style={{float: 'right', marginRight: 10}}
                     onClick={() => this.setState({
                        selectedDate: undefined,
                        selectedUserID: undefined,
                        showModal: false
                     })}
                  >
                     {languages.vazgec}
                  </Button>
               </ModalBody>

            </Modal>
         </>
      );
   }
}

function mapStateToProps(state)
{
   return {
      
      loginUser: state.loginReducer,
      ownConfirm: state.ownConfirmReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         // getOwnConfirm: bindActionCreators(taskActions.getOwnConfirmTask, dispatch),
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskConfirm)))


