import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Col, Label, Row} from "reactstrap";
import {AppSwitch} from "@coreui/react";
import notify from "devextreme/ui/notify";

const TaskChangeProcessStatus = ({t, event}) =>
{
   const changeStatus = (e) =>
   {
      e.preventDefault();

      event.props.actions.isLoading(true);

      let url = '';
      let taskID = event.props.location.state ? event.props.location.state.taskID : event.state.data.ID;

      if (event.state.selectedStatus === '')
      {
         notify(event.languages.durumAlaniZorunludur, "error", 1500);
         //event.props.actions.isLoading(false);
         return;
      }

      if (event.state.selectedEndDate !== null)
      {
         let date = event.state.selectedEndDate.toLocaleDateString();
         let newDate = date.split('.')[2] + '-' + date.split('.')[1] + '-' + date.split('.')[0] + ' ' + event.state.selectedEndDate.toLocaleTimeString();
         let changeStatus = event.state.selectedLastStatus === null ? false : event.state.selectedLastStatus === 1;

         url = 'api/TaskDetail/UpdateStatus?TaskID=' + taskID + '&StatusID=' + event.state.selectedStatus + '&eDate=' + newDate + '&ChangeStatus=' + changeStatus;
      }
      else
      {
         url = 'api/TaskDetail/UpdateStatus?TaskID=' + taskID + '&StatusID=' + event.state.selectedStatus;
      }
       
      BB.FetchJSON(url).then(data =>
      {
         if(data.Status){

            notify(event.languages.talepDurumDegistirmeBasarili, "success", 2000);
   
            event.setState({selectedStatus: '', visibleDurumDegistir: false});
            event.getData();
            event.GetProccessStatus();
            event.props.actions.isLoading(false);
         }
         else{
            notify(data.Message, "error", 2000);
            event.setState({selectedStatus: '', visibleDurumDegistir: false});
            event.getData();
            event.GetProccessStatus();
            event.props.actions.isLoading(false);
         }
         
      });

   }
   return (
      <Modal
         style={{zIndex: 1}}
         centered={true}
         isOpen={event.state.visibleDurumDegistir}
      >
         <ModalHeader toggle={() => event.setState({visibleDurumDegistir: false})}>
            {t("islemDurumuDegistir")}
         </ModalHeader>
         <ModalBody >
            <form onSubmit={e => changeStatus(e)}>

               <Row>
                  <Col xl={10} >
                     {BB.DxDropDownWithJSON('selectedStatus', t('talepDurumu'), event, event.state.status)}
                  </Col>
                  <Col xl={2}>
                     <Row>
                        <Label>
                           <strong>{t("zamanla")}</strong>
                        </Label>
                     </Row>
                     <Row style={{marginTop: 10, marginLeft: -5}}>
                        <AppSwitch
                           checked={event.state.selectedTimed}
                           className={'float-right mb-0'}
                           label
                           color={'info'}
                           size={'sm'}
                           onChange={e => event.setState({selectedTimed: !event.state.selectedTimed})}
                        />
                     </Row>
                  </Col>
               </Row>

               {event.state.selectedTimed === true ?
                  <Row>
                     <Col>
                        {BB.DateTimeSelectWithLabel('selectedEndDate', t("suTariheKadarDurumuDegistir"), event, event.state.status)}
                     </Col>
                     <Col>
                        {BB.DxDropDownWithJSON('selectedLastStatus', t("YenidenIslemeAlınsınmı?"), event,
                           [
                              {
                                 ID: 1,
                                 Name: t("evet")
                              },
                              {
                                 ID: 2,
                                 Name: t("hayır")
                              }
                           ])}
                     </Col>
                  </Row>
                  : null
               }


               <Row style={{float: 'right', marginRight: -15, marginTop: 10}}>
                  <Button
                     color={'info'}
                     onClick={() => event.setState({visibleDurumDegistir: false})}>
                     {t('vazgec')}
                  </Button>
                  <Button
                     color={'success'}
                     style={{marginLeft: 20, marginRight: 15}}
                     type={'submit'}>
                     {t('guncelle')}
                  </Button>
               </Row>
            </form>
         </ModalBody>
      </Modal>
   )
}

export default TaskChangeProcessStatus;
