import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {AppSwitch} from '@coreui/react'
import {useTranslation} from "react-i18next";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import * as BB from '../components/BBComponent';
import notify from "devextreme/ui/notify";
import {
   callAPI,
   callApiGet,
   callApiPost,
   callApiPut
} from "../components/Axios";

const propTypes = {
   children: PropTypes.node,
};
const defaultProps = {};

class DefaultAside extends Component
{
   constructor(props)
   {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
         activeTab: '1',
         departmentTasks: [],
         redirect: false,
         taskID: undefined,
         allowTaskNotification: false,
         allowPoolNotificaiton: false,
         allowLoginNotification: false,
         allowConfirmNotification: false
      };

      /*
      BB.FetchJSON('api/Common/GetMyNotificationSettings').then(notificationSettings =>
      {
         this.setState({
            allowTaskNotification: notificationSettings.AllowTaskNotify,
            allowPoolNotificaiton: notificationSettings.AllowPoolNotify,
            allowLoginNotification: notificationSettings.AllowLoginNotify,
            allowConfirmNotification: notificationSettings.AllowConfirmNotify,
         });
      });*/

     
      let url = 'api/Common/GetMyNotificationSettings';
      callAPI(
         callApiGet,
        url,
         {},
         async (res) => {
            if (res?.data) {
               if(!res || !res.data )
               return;
               const notificationSettings = res?.data;
               this.setState({
                  allowTaskNotification: notificationSettings.AllowTaskNotify,
                  allowPoolNotificaiton: notificationSettings.AllowPoolNotify,
                  allowLoginNotification: notificationSettings.AllowLoginNotify,
                  allowConfirmNotification: notificationSettings.AllowConfirmNotify,
               });
  
            }
         },
         true
   );

   }

   toggle(tab)
   {
      if (this.state.activeTab !== tab)
      {
         this.setState({
            activeTab: tab,
         });
      }
   }

   changeNotifySettings()
   {
      let {allowTaskNotification, allowPoolNotificaiton, allowLoginNotification, allowConfirmNotification} = this.state;

      BB.FetchJSON('api/Common/SetMyNotificationSettings?allowTaskNotification=' + allowTaskNotification + '&allowPoolNotificaiton=' + allowPoolNotificaiton + '&allowLoginNotification=' + allowLoginNotification + '&allowConfirmNotification=' + allowConfirmNotification)
      .then(res =>
      {
         if (res)
         {
            notify('Bildirim ayarlarınız başarıyla güncellendi!', 'success', 4000);
         }
      });
   }

   render()
   {
      return (
         <Render
            ths={this}
         />
      );
   }
}

export const Render = (ths) =>
{
   const {t} = useTranslation();
   const state = ths.ths.state;
   const event = ths.ths;

   return (
      <>
         <Nav tabs>
            <NavItem>
               <NavLink className={classNames({active: state.activeTab === '1'})}
                        onClick={() =>
                        {
                           event.toggle('1');
                        }}>
                  <i className="icon-list"/>
               </NavLink>
            </NavItem>
         </Nav>

         <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1" className="p-3">
               <h6>{t('ayarlar')}</h6>
               <div className="aside-options">
                  <div className="clearfix mt-4">
                     <small><b>{t('talepbildirimleri')}</b></small>
                     <AppSwitch
                        onChange={() =>
                        {
                           event.setState({allowTaskNotification: !state.allowTaskNotification});
                           setTimeout(function ()
                           {
                              event.changeNotifySettings();
                           }, 150);
                        }}
                        className={'float-right'}
                        variant={'pill'}
                        label
                        color={'success'}
                        checked={state.allowTaskNotification}
                        size={'sm'}/>

                  </div>
                  <div>
                     <small className="text-muted">
                        {t('talepbildirimleriaçıklama')}
                     </small>
                  </div>
               </div>

               <div className="aside-options">
                  <div className="clearfix mt-4">
                     <small><b>{t('havuzbildirim')}</b></small>
                     <AppSwitch
                        onChange={() =>
                        {
                           event.setState({allowPoolNotificaiton: !state.allowPoolNotificaiton});
                           setTimeout(function ()
                           {
                              event.changeNotifySettings();
                           }, 150);
                        }}
                        className={'float-right'}
                        variant={'pill'}
                        label
                        color={'success'}
                        checked={state.allowPoolNotificaiton}
                        size={'sm'}/>
                  </div>
                  <div>
                     <small className="text-muted">
                        {t('havuzbildirimleriaciklama')}
                     </small>
                  </div>
               </div>

               <div className="aside-options">
                  <div className="clearfix mt-4">
                     <small><b>{t('girisbildirimleri')}</b></small>
                     <AppSwitch
                        onChange={() =>
                        {
                           event.setState({allowLoginNotification: !state.allowLoginNotification});
                           setTimeout(function ()
                           {
                              event.changeNotifySettings();
                           }, 150);
                        }}
                        className={'float-right'}
                        variant={'pill'}
                        label
                        checked={state.allowLoginNotification}
                        color={'success'}
                        size={'sm'}/>
                  </div>
                  <div>
                     <small className="text-muted">
                        {t('girisbildirimleriaciklama')}
                     </small>
                  </div>
               </div>

               <div className="aside-options">
                  <div className="clearfix mt-4">
                     <small><b>{t('onaybildirimleri')}</b></small>
                     <AppSwitch
                        onChange={() =>
                        {
                           event.setState({allowConfirmNotification: !state.allowConfirmNotification});
                           setTimeout(function ()
                           {
                              event.changeNotifySettings();
                           }, 150);
                        }}
                        className={'float-right'}
                        variant={'pill'}
                        label
                        checked={state.allowConfirmNotification}
                        color={'success'}
                        size={'sm'}/>
                  </div>
                  <div>
                     <small className="text-muted">
                        {t('onaybildirimleriaciklama')}
                     </small>
                  </div>
               </div>

               <hr/>
               {
                  /*
                  <h6>{t('sistemdili')}</h6>
                  <LanguageSelector/>
                  */
               }
            </TabPane>
         </TabContent>
      </>
   );
}

DefaultAside.propTypes = propTypes;
DefaultAside.defaultProps = defaultProps;

export default DefaultAside;
