import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row,Label, Input, InputGroup, ListGroup, ListGroupItem} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import { FormGroup } from "semantic-ui-react";
import * as BB from "../../../components/BBComponent";
import {Redirect} from "react-router-dom";
import metrics from "../../../common/metrics";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class PoolUser extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {

         gridData: undefined,
         companyList: [],
         companyUserList: [],
         selectedPoolUsers: [],
         editRowVisible: false,
         poolID: this.props.location.state ? this.props.location.state.PoolID : null,
         companySelectToolListVisible:false,
         companySelectTool:'',
         taskCompaniesTemp: [],
         
   }
}

   componentDidMount()
   {
      this.getData();
   }

    onClickFilter  (filter)  {
      if (filter.ID !== '') {
          this.setState({ companySelectTool: filter.Name })
          this.handleChange('selectedCompanyID', filter.ID)
          this.setState({ companySelectToolListVisible: false });
      }
  }

   getData()
   {
      BB.FetchJSON('api/Yonetim/Pools/GetPoolUsers?id=' + this.state.poolID).then(data =>
      {
         let arr = [];
         if (data && data.length > 0)
         {
            for (let i = 0; i < data.length; i++)
            {
               arr.push(data[i].ID);
            }
         }
         this.setState({gridData: data, isLoading: false})
      });

      BB.FetchJSON('api/Yonetim/Pools/GetCompanies').then(data =>
      {
         this.setState({companyList: data, isLoading: false})
      })
   }

   handleChange(id, value)
   {
      if (id === 'selectedCompanyID')
      {
         BB.FetchJSON('api/Yonetim/Pools/GetCompanyUsers?CompanyID=' + value).then(data =>
         {
            this.setState({companyUserList: data, isLoading: false})
         })
      }
      this.setState({[id]: value});
   }

   addNew()
   {
      let arr = [];

      for (let i = 0; i < this.state.selectedPoolUsers.length; i++)
      {
         arr.push({UserID: this.state.selectedPoolUsers[i], PoolID: this.state.poolID})
      }

      const formData = new FormData();
      formData.append('poolUserList', JSON.stringify(arr));
      formData.append('PoolID', this.state.poolID)

      post('api/Yonetim/Pools/AddUserToPool', formData, {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         if (res.data === true)
         {
            this.setState({
               companyList: [],
               companyUserList: [],
               editRowVisible: false,
               selectedPoolUsers: []
            }, this.getData());
            notify(t("havuzkullanicilaribasariyladuzenlendi"), "success", 1000);
         }
         else
         {
            this.setState({
               companyList: [],
               companyUserList: [],
               editRowVisible: false,
               selectedPoolUsers: []
            });
            notify(t("havuzkullanicilariduzenlemehata") + res.Message, "warning", 1000);
         }
      });
   }
    companyFilter  (e){
      this.setState({ companySelectToolListVisible: true });
      var companies = this.state.companyList
      if (this.state.companySelectTool.length > 2) {
          //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

          //   

          var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase()));

          this.setState({ taskCompaniesTemp: filteredCompanies });
          //})
      } else {
          let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase())).slice(0,30);
          let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: t("dahafazlafirmaicinenazuckaraktergirmelisiniz"), ParentID: null };
          tempCompanies = tempCompanies.concat(itemdef);
          this.setState({ taskCompaniesTemp: tempCompanies });
      }

  }
   actionButton(cellData)
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t("secilikullanicihavuzdansilinecektir"),t("onaylıyorMusunuz?"), t("evet"), t("vazgec"))
                  .then(r =>
                  {
                     if (r)
                     {
                        BB.FetchJSON('api/Yonetim/Pools/DeleteUserInPool?ID=' + cellData.data.ID + '&PoolID=' + this.state.poolID).then(data =>
                        {
                           this.getData();
                        })
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      if (this.state.poolID === null)
      {
         return <Redirect to={{pathname: '/Yonetim/Pools'}}/>
      }
      else
      {
         return (
            <div className="animated fadeIn">
               <Col>
                  <Card>
                     <CardBody>

                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({poolID: null})}
                           style={{float: 'left', position: 'absolute', zIndex: 1,}}
                           type="button">{t("geri")}</Button>

                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({editRowVisible: true})}
                           style={{float: 'left', position: 'absolute', zIndex: 1, left: 75}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Havuz Listesi"/>
                           <Column
                              dataField="Name"
                              caption={t("havuztanimi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField={'Deparment'}
                              caption={t("departman")}
                              minWidth={80}
                              allowFiltering={true}/>
                           <Column
                              dataField={'Job'}
                              caption={t("görev")}
                              minWidth={80}/>
                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={100}
                              cellRender={(cellData) => this.actionButton(cellData)}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               {/*Yeni Satır Ekleme Popup*/}
               <Modal
                  isOpen={this.state.editRowVisible}
                  dragEnabled={false}
                  showTitle={true}
                  width={metrics.popupMinWidth}
                  height={320}
               >
                  <ModalHeader>
                     {t("yenihavuzkullanicisi")}
                  </ModalHeader>
                  <ModalBody>
                     
                     <form>
                     <FormGroup>
                    <Label>
                        <strong>{t("firmaListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={this.state.companySelectTool} type="text" autoFocus={true} name={'selectedCreateUserCompanyID'} onChange={(e) => { this.setState({ companySelectTool: e.target.value }, this.companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px', display: this.state.companySelectToolListVisible ? 'block' : 'none' }}>
                        {
                            this.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === this.state.selectedCreateUserCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={()=>this.onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {/* {BB.DxSelectBoxWithJSON('selectedCreateUserCompanyID', 'Kurum Seçiniz', event, event.state.taskCompanies)}*/}
                    {BB.DxMultipleDropdown('selectedPoolUsers', t("firmakullanicilari"), this, this.state.companyUserList)}

                </FormGroup>
                        {/* {BB.DxDropDownWithJSON('selectedCompanyID', 'Firma', this, this.state.companyList)} */}
                        

                        <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                           <Button
                              color={'info'}
                              onClick={() => this.setState({editRowVisible: false})}>
                              {t("vazgec")}
                           </Button>
                           <Button
                              onClick={() => this.addNew()}
                              color={'success'}
                              style={{marginLeft: 20, marginRight: 0}}>
                              {t("olustur")}
                           </Button>
                        </Row>
                     </form>
                  </ModalBody>

               </Modal>

               {/*Düzenle Popup*/}
               <Modal
                  isOpen={this.state.editPoolVisible}
                  dragEnabled={false}
                  showTitle={true}
                  width={metrics.popupMinWidth}
                  height={320}
               >
                  <ModalHeader>
                     {t("havuzkullaniciduzenle")}
                  </ModalHeader>
                  <ModalBody>
                     {BB.InputWithLabel('selectedName', t("tanim"), 'text', this)}
                     {BB.DxDropDownWithURL('selectedMasterPool', t("talepHavuuzu"), this, 'api/Yonetim/Pools/GetCategoryes')}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                        <Button
                           color={'info'}
                           onClick={() => this.setState({editPoolVisible: false})}>
                           {t("kapat")}
                        </Button>

                        <Button
                           onClick={() => this.addNew()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("kaydet")}
                        </Button>
                     </Row>
                  </ModalBody>

               </Modal>
            </div>
         );
      }
   }

}

export default withTranslation()(PoolUser);
