import * as actionTypes from './actionTypes'

export function addAllSurveys(allTasks) {
    return {type: actionTypes.SET_OWN_SURVEYS, payload: allTasks}
}

export function getAllSurveys() {
    return function (dispatch) {

        let url = '/api/TaskSurvey/GetData';

        return fetch(url)
            .then(res => res.json())
            .then(result => {
                //localStorage.setItem('loginTime', new Date());
                dispatch(addAllSurveys(result))
            });
    }
}

