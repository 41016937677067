import * as actionTypes from "./actionTypes";
import {
    callAPI,
    callApiGet
 } from "../../components/Axios";
 
export function setWatcherTasks(Data) {
  return { type: actionTypes.GET_WATCHER_TASKS, payload: Data };
}
export function getWatcherTasks() {
  return function (dispatch) {
    return callAPI(
      callApiGet,
      "api/DashBoard/GetMyWatcherTasks",
      {},
      async (res) => {
        dispatch(setWatcherTasks(res.data));
      },
      true
    );
  };
}
