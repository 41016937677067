import React, {Component} from 'react';
import axios from "axios";
import {
   Button,
   Card,
   CardBody,
   CardGroup,
   Col,
   Container,
   Form,
   Input,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
   Row
} from 'reactstrap';
//import {useTranslation} from "react-i18next";
import logo from '../../../assets/img/brand/task4team-logo-white.png'

let languages = undefined;

class ForgotPassword extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         info: undefined,
         password: '',
         passwordRepeat: '',
         hashUrl: '',
         isLoading: 0,
         result: '',
         passwordVisible: false,
         passwordRepeatVisible: false,
      }
      this.handleSubmit = this.handleSubmit.bind(this)
   }

   componentDidMount()
   {
      this.setState({hashUrl: this.props.match.params.id})
   }

   validateForm()
   {
      let errors = "";
      let formIsValid = true;
      if (!this.state.password && !this.state.passwordRepeat)
      {
         formIsValid = false;
         errors = languages.bosBirakilamaz;
      }

      if (this.state.password.length < 3)
      {
         formIsValid = false;
         this.setState({result: languages.enAzAltiKarakterGiriniz})
      }
      this.setState({
         info: errors
      });
      return formIsValid;
   }

   handleSubmit(e)
   {
      const {password, passwordRepeat, hashUrl} = this.state;
      if (this.validateForm())
      {
         if (password == passwordRepeat)
         {
            this.setState({isLoading: 1})
            const URL = `api/Login/ChangePasswordHash`;
            axios(URL, {
               method: 'POST',
               headers: {
                  'content-type': 'application/json',
               },
               data: {UserHash: hashUrl.toString(), Password: password.toString()},
            })
            .then(response =>
            {
               this.setState({isLoading: 0, result: response.data})
               if (response.data === languages.sifreBasariylaDegistirildi)
               {
                  setTimeout(() =>
                  {
                     return this.props.history.push('/login')
                  }, 2000);
               }
            })
            .catch(error =>
            {
               throw error;
            });
         }
         else
         {
            this.setState({result: languages.sifrelerUyusmuyor})
         }
      }
      e.preventDefault();
   }

   render()
   {
      return (
         <Render ths={this}/>
      );
   }
}

export const Render = (ths) =>
{
  // const {t} = useTranslation();
   const event = ths.ths;
   const {isLoading} = ths.ths.state;

   languages = {
      bosBirakilamaz: ('*Bu alan boş bırakılamaz'),
      enAzAltiKarakterGiriniz: ('En az 4 karakter giriniz.'),
      sifreBasariylaDegistirildi: ('Şifreniz Başarı ile Değiştirilmiştir..'),
      sifrelerUyusmuyor: ('Şifreler birbiri ile uyuşmuyor')
   }
   return (
      <>
         <div className="app flex-row align-items-center">
            <Container>
               <Row className="justify-content-center">
                  <Col md="6">
                     <CardGroup>
                        <Card className="p-4 login-card " style={{ height: "400px"}}>
                           <CardBody className='forgotPassword-card'>
                              <Form onSubmit={event.handleSubmit}>
                                 <h2 className="my-4">Şifre Değiştir</h2>
                                 <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                       <InputGroupText>
                                          <i className="icon-lock"/>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                       type={event.state.passwordVisible ? 'text' : 'password'}
                                       placeholder={('Şifre')}
                                       onChange={(event) => ths.ths.setState({password: event.target.value})}
                                       value={event.state.password || ''}
                                       autoFocus
                                       autoComplete="current-password"
                                    />
                                    <InputGroupAddon addonType="prepend"
                                                     className='login-password-right forgotPasswordSpan'
                                                     onClick={(e) => ths.ths.setState({passwordVisible: !event.state.passwordVisible})}>
                                       <InputGroupText>
                                          <i className={event.state.passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                 </InputGroup>
                                 {
                                    event.state.info !== undefined &&
                                    <p  style={{color: 'red'}}>{event.state.info}</p>
                                 }
                                 <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                       <InputGroupText>
                                          <i className="icon-lock"/>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                       type={event.state.passwordRepeatVisible ? 'text' : 'password'}
                                       placeholder={('Şifre Tekrarı')}
                                       autoComplete="current-password"
                                       onChange={(event) => ths.ths.setState({passwordRepeat: event.target.value})}
                                       value={event.state.passwordRepeat || ''}
                                    />
                                    <InputGroupAddon addonType="prepend"
                                                     className='login-password-right forgotPasswordSpan'
                                                     onClick={(e) => ths.ths.setState({passwordRepeatVisible: !event.state.passwordRepeatVisible})}>
                                       <InputGroupText>
                                          <i className={event.state.passwordRepeatVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                 </InputGroup>
                                 
                                 {
                                    
                                    event.state.info !== undefined &&
                                    <p  style={{color: 'green'}}>{event.state.info}</p>
                                 }
                                 {
                                    event.state.result !== '' && event.state.result !=="Token kullanım hakkınız tükendi.Lütfen Tekrar Deneyiniz."&&
                                    <p style={{color: 'green'}}>{event.state.result}</p>
                                 }
                                  {
                                    event.state.result === "Token kullanım hakkınız tükendi.Lütfen Tekrar Deneyiniz." &&
                                    <p style={{color: 'red'}}>{event.state.result}</p>
                                 }
                                 <div className='button-groups-submit'>
                                    <Button type='submit' color='secondary'
                                            className="mt-2">{isLoading === 0 ? ('Gönder') : ('Gönderiliyor')}</Button>
                                 </div>
                              </Form>
                           </CardBody>
                        </Card>

                     </CardGroup>
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   );
}

export default ForgotPassword;
