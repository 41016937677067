import React from 'react';
import {TagBox} from 'devextreme-react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import notify from "devextreme/ui/notify";
import DataGrid, {Column, Pager, Paging} from "devextreme-react/data-grid";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

const DATA = [{ID: "_blank", Name: "Yeni Sekmede Aç"}, {ID: "_self", Name: "Olduğu Sayfada Aç"}]
export const FileWithLabel = (id, text, event) =>
{
   return (
      <FormGroup>
         <Label htmlFor={id}>
            <strong>{text}</strong>
         </Label>
         <Input
            type='file'
            id={id}
            onChange={(e) => event.handleChange(id, e)}
         />
      </FormGroup>
   );
}

class SssEdit extends React.Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         sssID: this.props.location.state ? this.props.location.state.SssID : null,
         sssData: '',
         title: '',
         targetList: DATA,
         roleList: [],
         selectedRoleList: [],
         document: [],
         toogle: false
      }
      this.onValueChanged = this.onValueChanged.bind(this);
      this.handleLinkChange = this.handleLinkChange.bind(this);
      this.handleActiveChange = this.handleActiveChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleFile = this.handleFile.bind(this)
      this.handleChange = this.handleChange.bind(this)
   }

   componentDidMount()
   {
      if (this.state.sssID !== null)
      {
         this.getDatas();

      }
   }

   getDatas()
   {
      let URL = `api/Yonetim/AdminSSS/Edit?ID=${this.state.sssID}`;
      BB.FetchJSON(URL).then(res =>
      {
         this.findCommonElement(res.ViewBag.Roles, res.Data.Roles)
         this.setState({
            sssData: res.Data,
            title: res.Data.Title,
            orderNumber: res.Data.OrderNumber,
            content: res.Data.Body,
            isLink: res.Data.IsLink,
            isActive: res.Data.IsActive,
            roleList: res.ViewBag.Roles,
            selectedTargetID: res.Data.LinkTargetType,
            document: res.Data.Documents
         })
      });

   }

   onValueChanged(e)
   {
      const newValues = e.value;
      this.setState({selectedRoleList: newValues})
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   //Radio button
   handleLinkChange()
   {
      const {isLink} = this.state
      this.setState({isLink: !isLink})
   }

   handleActiveChange()
   {
      const {isActive} = this.state
      this.setState({isActive: !isActive})
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'light'}
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizdokumansilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               <i className='icon-trash icon-delete'></i>
            </Button>
         </div>
      );
   }

   delete(ID)
   {
      this.props.actions.isLoading(false);

      let url = 'api/Yonetim/AdminSSS/DeleteDocument?ID=' + ID;

      BB.FetchJSON(url).then(data =>
      {
         if (data.MessageStatus === true)
         {
            this.getData()
            this.props.actions.isLoading(false);
            notify(t("dokumanbasariylasilindi"), "success", 1000);
         }
         else
         {
            notify(t("dokumansilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   getData()
   {
      let url = `api/Yonetim/AdminSSS/GetDataForDocument?ID=` + this.state.sssID;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({document: res.Data});
      });
   }

   handleFile = (e) =>
   {
      this.setState({selectedSssFiles: e.target.files[0]})
   }

   handleSubmit = (e) =>
   {
      const {title, orderNumber, content, selectedRoleList, selectedSssFiles, isLink, isActive, selectedTargetID} = this.state;
      if (orderNumber === '' || orderNumber === undefined || title === undefined || title === '' || selectedRoleList.length == 0)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            Title: title,
            Body: content,
            OrderNumber: orderNumber,
            IsLink: isLink,
            IsActive: isActive,
            LinkTargetType: selectedTargetID
         }
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('file', selectedSssFiles);
         formData.append('RoleIDs', selectedRoleList);
         formData.append('EditID', this.state.sssID);
         post('api/Yonetim/AdminSSS/Edit', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.getDatas();
               this.props.actions.isLoading(false);
               notify(t("duzenlemeislemibasarili"), "success", 1000);
            }
            else
            {
               notify(t("duzenlemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }

   findCommonElement(array1, array2)
   {
      var data = [];
      for (let i = 0; i < array1.length; i++)
      {
         for (let j = 0; j < array2.length; j++)
         {
            if (array1[i].ID === array2[j].RoleID)
            {
               data.push(array1[i].ID)
            }
         }
      }
      this.setState({selectedRoleList: data, toogle: true})
   }

   render()
   {

      const {isLink, isActive, document, selectedRoleList, toogle} = this.state;
      let arr = selectedRoleList;
      return (
         <Row>
            <Col>
               <Card>
                  <CardHeader>{t("sssduzenleme")}</CardHeader>
                  <CardBody>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col lg='6'>
                              {BB.InputWithLabel('title', t("baslik"), 'text', this)}
                           </Col>
                           <Col lg='6'>
                              {BB.InputWithLabel('orderNumber', t("siranumarasi"), 'number', this)}
                           </Col>
                           <Col lg='12'>
                              {BB.TextAreaWithLabel('content', t("icerik"), t("icerik"), 5, this)}
                           </Col>
                           <Col lg='6'>
                              {BB.DxDropDownWithJSON('selectedTargetID', t("linksayfasi"), this, this.state.targetList)}
                           </Col>
                           <Col lg='6'>
                              <label><strong>{t("yetkigrubu")}</strong></label>
                              {
                                 toogle ?
                                    <TagBox
                                       items={this.state.roleList}
                                       defaultValue={arr}
                                       showSelectionControls={true}
                                       maxDisplayedTags={3}
                                       displayExpr="Name"
                                       valueExpr="ID"
                                       selectAllMode="allPages"
                                       onValueChanged={this.onValueChanged}
                                    />
                                    : null
                              }

                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio"
                                        onChange={this.handleLinkChange} value={isLink} checked={isLink}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio">Link</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                        onChange={this.handleActiveChange} value={isActive} checked={isActive}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio1">{t("aktifpasif")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='3' style={{paddingTop: 10}}>
                              <FormGroup>
                                 <Label htmlFor='selectedSssFiles'>
                                    <strong>{t("dosyaekle")}</strong>
                                 </Label>
                                 <Input
                                    type='file'
                                    id='selectedSssFiles'
                                    onChange={this.handleFile}
                                 />
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <Button color='success' className='float-right  mt-10' type='submit'>{t("guncelle")}</Button>
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
               <Card>
                  <CardHeader>{t("baglıdokumanlar")}</CardHeader>
                  <CardBody>
                     <DataGrid
                        id="taskListGridData"
                        dataSource={document}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                        onRowRemoving={this.onRowRemoving}
                        onRowRemoved={this.onRowRemoved}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <Column
                           dataField="DocumentTitle"
                           caption="Döküman Başlığı"
                           minWidth={220}
                           allowFiltering={true}/>
                        <Column
                           dataField="İşlemler"
                           caption="İşlemler"
                           width={250}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      )
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SssEdit));
