import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class TaskClosingTemplate extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         parameters: [],
         editRowVisible: false,
         addRowVisible: false,
         selectedMessageID: undefined,
         selectedParamID: undefined,
         selectedTitle: undefined,
         selectedMessage: undefined,
         newParamID: undefined,
         newTitle: undefined,
         newMessage: undefined
      }
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let url = 'api/Yonetim/TerminationMessages/GetData';
      let url1 = 'api/Yonetim/TerminationMessages/GetParameters';

      BB.FetchJSON(url).then(data =>
      {
         this.setState({gridData: data, isLoading: false})
      });

      BB.FetchJSON(url1).then(data =>
      {
         this.setState({parameters: data, isLoading: false})
      });
   }

   actionButton(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.setState({
                  editRowVisible: true,
                  selectedMessageID: cellData.data.ID,
                  selectedParamID: cellData.data.Parameter,
                  selectedTitle: cellData.data.Title,
                  selectedMessage: cellData.data.Message
               })}>
               {t("düzenle")}
            </Button>

            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() => this.deleteMessage(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   addNewMessage()
   {

      if (this.state.newParamID === '' || this.state.newTitle === '' || this.state.newMessage === ''
         || this.state.newParamID === undefined || this.state.newTitle === undefined || this.state.newMessage === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ParameterID: this.state.newParamID,
            Title: this.state.newTitle,
            Message: this.state.newMessage
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('id', null);

         post('api/Yonetim/TerminationMessages/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedMessageID: undefined,
                  selectedParamID: undefined,
                  selectedTitle: undefined,
                  selectedMessage: undefined,
                  newParamID: undefined,
                  newTitle: undefined,
                  newMessage: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {

               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedMessageID: undefined,
                  selectedParamID: undefined,
                  selectedTitle: undefined,
                  selectedMessage: undefined,
                  newParamID: undefined,
                  newTitle: undefined,
                  newMessage: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   setMessage()
   {

      if (this.state.selectedParamID === '' || this.state.selectedTitle === '' || this.state.selectedMessage === ''
         || this.state.selectedParamID === undefined || this.state.selectedTitle === undefined || this.state.selectedMessage === undefined)
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let paramID = this.state.parameters.filter(x => x.Name === this.state.selectedParamID);

         if (paramID.length === 0)
         {
            paramID = this.state.selectedParamID;
         }
         else
         {
            paramID = this.state.parameters.filter(x => x.Name === this.state.selectedParamID)[0].ID;
         }

         let entity = {
            ID: this.state.selectedMessageID,
            ParameterID: paramID,
            Title: this.state.selectedTitle,
            Message: this.state.selectedMessage
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('id', entity.ID);

         post('api/Yonetim/TerminationMessages/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedMessageID: undefined,
                  selectedParamID: undefined,
                  selectedTitle: undefined,
                  selectedMessage: undefined,
                  newParamID: undefined,
                  newTitle: undefined,
                  newMessage: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedMessageID: undefined,
                  selectedParamID: undefined,
                  selectedTitle: undefined,
                  selectedMessage: undefined,
                  newParamID: undefined,
                  newTitle: undefined,
                  newMessage: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   deleteMessage(id)
   {
      BB.askYesNo(t("onaylıyorMusunuz?"), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.state.selectedMessageID = id;

            this.props.actions.isLoading(true);

            const formData = new FormData();
            formData.append('id', this.state.selectedMessageID);

            post('api/Yonetim/TerminationMessages/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {

                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedMessageID: undefined,
                     selectedParamID: undefined,
                     selectedTitle: undefined,
                     selectedMessage: undefined,
                     newParamID: undefined,
                     newTitle: undefined,
                     newMessage: undefined
                  }, this.getDatas());
                  this.props.actions.isLoading(false);
                  notify(t("mesajsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedMessageID: undefined,
                     selectedParamID: undefined,
                     selectedTitle: undefined,
                     selectedMessage: undefined,
                     newParamID: undefined,
                     newTitle: undefined,
                     newMessage: undefined
                  }, this.getDatas());
                  notify(t("mesajsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>

                     <Button
                        color={'success'}
                        outline
                        onClick={() => this.setState({
                           addRowVisible: true,
                           newParamID: '',
                           newTitle: '',
                           newMessage: ''
                        })}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button">{t("yeni")}</Button>

                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName=""/>
                        <Paging />

                        <Column
                           dataField="Parameter"
                           caption={t("parametre")}
                           minWidth={200}
                           allowFiltering={true}/>
                        <Column
                           dataField={'Title'}
                           caption={t("baslik")}
                           minWidth={80}
                           allowFiltering={true}/>
                        <Column
                           dataField={'Message'}
                           caption={t("mesaj")}
                           minWidth={80}/>
                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={200}
                           cellRender={(cellData) => this.actionButton(cellData)}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Mesaj Düzenleme, Popup*/}
            <Popup
               visible={this.state.editRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("mesajduzenleme")}
               width={metrics.popupMinWidth}
               height={520}
            >
               <form>

                  {BB.DxDropDownWithJSON('selectedParamID', t("parametre"), this, this.state.parameters)}
                  {BB.InputWithLabel('selectedTitle', t("baslik"), 'text', this)}
                  {BB.TextAreaWithLabel('selectedMessage', t("mesaj"), t("mesaj"), 9, this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({editRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.setMessage()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </form>
            </Popup>

            {/*Mesaj Ekleme, Popup*/}
            <Popup
               visible={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("mesajekleme")}
               width={metrics.popupMinWidth}
               height={520}
            >
               <form>

                  {BB.DxDropDownWithJSON('newParamID', t("parametre"), this, this.state.parameters)}
                  {BB.InputWithLabel('newTitle', t("baslik"), 'text', this)}
                  {BB.TextAreaWithLabel('newMessage', t("mesaj"), t("mesaj"), 9, this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.addNewMessage()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </form>
            </Popup>

         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskClosingTemplate));
