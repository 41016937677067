import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Row,} from 'reactstrap';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import {Popup} from 'devextreme-react/popup';
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';


let oldParentIDList = [];

class Parameter extends Component
{

   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         parentIDIndex: -1,
         editRowVisible: false,
         addRowVisible: false,
         selectedParentID: undefined,
         selectedParameterID: undefined,
         selectedParameterName: undefined,
         newParameterName: undefined,
      }
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let url = 'api/Yonetim/Parameter/GetData?ID=' + this.state.selectedParentID;

      BB.FetchJSON(url).then(data =>
      {
         this.setState({gridData: data, isLoading: false})
      });
   }

   actionButton(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.getSubParamater(cellData.data.ID)}>
               {t("altparametreler")}
            </Button>
            <Button
               color={'primary'}
               outline
               style={{marginLeft: 5}}
               onClick={() => this.setState({
                  editRowVisible: true,
                  selectedParameterID: cellData.data.ID,
                  selectedParameterName: cellData.data.Name
               })}>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'primary'}
               outline
               onClick={() => this.deleteParameter(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   getSubParamater(ID)
   {
      oldParentIDList.push(ID);
      this.state.parentIDIndex = this.state.parentIDIndex + 1;
      this.state.selectedParentID = ID;
      this.state.selectedParameterID = undefined;
      this.state.selectedParameterName = undefined;
      this.state.newParameterName = undefined;
      this.getDatas();
   }

   getBefore()
   {
      if (this.state.parentIDIndex > -1)
      {
         this.state.parentIDIndex = this.state.parentIDIndex - 1;
         this.state.selectedParentID = oldParentIDList[this.state.parentIDIndex];
         oldParentIDList.pop(oldParentIDList[oldParentIDList.length - 1]);
         this.state.selectedParameterID = undefined;
         this.state.selectedParameterName = undefined;
         this.state.newParameterName = undefined;
         this.getDatas();
      }
      else
      {
         if (this.state.parentIDIndex === -1)
         {
            this.state.parentIDIndex = -1;
            this.state.selectedParentID = undefined;
            this.state.selectedParameterID = undefined;
            this.state.selectedParameterName = undefined;
            this.state.newParameterName = undefined;
            oldParentIDList = [];
            this.getDatas();
         }
      }
   }

   addParameter()
   {
      if (this.state.newParameterName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ParentID: this.state.selectedParentID
            , Name: this.state.newParameterName
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/Parameter/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {

               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }
   }

   setParameter()
   {
      if (this.state.newParameterName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ID: this.state.selectedParameterID
            , Name: this.state.selectedParameterName
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));

         post('api/Yonetim/Parameter/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {

               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedParameterName: undefined,
                  newParameterName: undefined
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
            }
         })
         .catch(ex =>
         {
            this.props.actions.isLoading(false);
         });
      }

   }

   deleteParameter(ID)
   {
      BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.state.selectedParameterID = ID;

            this.props.actions.isLoading(true);

            const formData = new FormData();
            formData.append('ID', this.state.selectedParameterID);

            post('api/Yonetim/Parameter/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedParameterName: undefined,
                     newParameterName: undefined
                  }, this.getDatas());
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     editRowVisible: false,
                     addRowVisible: false,
                     selectedParameterName: undefined,
                     newParameterName: undefined
                  }, this.getDatas());
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>

                     {
                        

                           <Button

                              color={'success'}
                              outline
                              onClick={() => this.setState({addRowVisible: true})}
                             


                              style={{float: 'left', position: 'absolute', zIndex: 1, marginLeft: 60}}
                              type="button">
                                 {t("yeni")}</Button>
                           
                     }

                     <Button
                        color={'primary'}
                        outline
                        onClick={() => this.getBefore()}

                        style={{float: 'left', position: 'absolute', zIndex: 1,}}
                        type="button">
                           {t("geri")}
                        
                        </Button>

                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName=""/>
                        <Column
                           dataField="Name"
                           caption={t("adı")}
                           minWidth={100}
                           allowFiltering={true}/>
                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={280}
                           cellRender={(cellData) => this.actionButton(cellData)}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Parametre Düzenleme, Popup*/}
            <Popup
               visible={this.state.editRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("parametreduzenleme")}
               width={metrics.popupMinWidth}
               height={220}
               showCloseButton={false}
            >
               <form>

                  {BB.InputWithLabel('selectedParameterName', t("mesaj"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({editRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.setParameter()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </form>
            </Popup>

            {/*Parametre Ekleme, Popup*/}
            <Popup
               visible={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("parametreekleme")}
               width={metrics.popupMinWidth}
               height={220}
               showCloseButton={false}
            >
               <form>

                  {BB.InputWithLabel('newParameterName', t("mesaj"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.addParameter()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </form>
            </Popup>

         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Parameter));
