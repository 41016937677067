import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom'
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown} from 'reactstrap';
import {AppAsideToggler, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import logo from '../assets/img/brand/task4team.png'
import sygnet from '../assets/img/brand/task4team-brand.png'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";

import * as BB from '../components/BBComponent'
import notify from 'devextreme/ui/notify';
import Swr from "../components/swr/swr";
import jwt_decode from 'jwt-decode';
import {
   callAPI,
   callApiGet,
} from "../components/Axios";

class DefaultHeader extends Component
{
   constructor(props)
   {
      super(props);
      const token = localStorage.getItem("token");
      let user = null;
      if (token) {
         user = jwt_decode(token);
      }
      this.state = {
         taskCount: 0,
         getMessage: [],
         redirect: false,
         redirectUrl: '',
         taskNumber: null,
         redirectDetail: false,
         taskID: undefined,
         addClass: false,
         loginUser: user,
         poolCount:0,
         hubConnection: null,
         connection:null,
         Notifications: [],

      }
   }

   componentDidMount()
   {
      
      if (this.state.loginUser !== null)
      {
         // this.getPopupMessageData();
        // this.getPoolCount();
      }
   }


   // async getPoolCount(){
   //    callAPI(
   //       callApiGet,
   //       `api/Pool/GetTaskCountInMyPool`,
   //       {},
   //       async (res) => {
   //          if (res?.data) {
   //            this.setState({poolCount: res?.data})
   //          }
   //       },
   //       true
   // );
   // }

   // getPopupMessageData()
   // {

   //    let data = this.props.popupMessage;

   //    if (data)
   //    {
   //       this.setState({getMessage: data.Message})
   //    }
   // }

   render()
   {
      const {t} = this.props;
      let uName = this.state.loginUser.Name;
      let sName = this.state.loginUser.SurName;
      let showName = uName ? uName.substr(0, 1) + sName.substr(0, 1) : "";

      let boxClass = ["search"];

      if (this.state.addClass)
      {
         boxClass.push('search--visible');
      }

      return (
         <>
            <AppSidebarToggler className="d-lg-none" display="md" mobile/>
            <Link to={'/main'}>
               <AppNavbarBrand
                  tag={'div'}
                  full={{src: logo, width: 135, height: 'auto', alt: 'BB'}}
                  minimized={{src: sygnet, width: 'auto', height: 25, alt: 'BB'}}

               />
            </Link>
            <AppSidebarToggler className="d-md-down-none" display="lg"/>
            <Nav className="d-md-down-none" navbar>
               <NavItem className="px-3">
                  <NavLink to="/main" className="nav-link">{t('anasayfa')}</NavLink>
               </NavItem>
               <NavItem className="px-3">
                  <NavLink to="/customdashboard" target="_blank" className="nav-link"
                  >{t('dashboard')}</NavLink>
               </NavItem>

               {this.state.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb'
                  ?
                  <NavItem className="px-3">
                     <NavLink to="/TaskPool" className="nav-link">
                        {/* <Badge
                           pill color="danger"
                           style={{marginLeft: 20}}>{this.state.poolCount}
                        </Badge> */}
                        {t('havuz')}
                     </NavLink>
                  </NavItem>
                  :
                  null
               }
            </Nav>
            {/* <Nav className="ml-auto" navbar>
               
            </Nav> */}
            <Nav className="ml-auto" navbar>
               {  //this.state.loginUser.AllowSearchTask === "True" &&
                   this.props.history.location.pathname.indexOf('TaskDetail') <= 1 ?
                     <div className='search-task'>
                        <div className={boxClass.join(' ')}>
                           <input type="text"
                                  onChange={(e) => this.setState({taskNumber: e.target.value})}
                                  className="search__input" placeholder={t("talepAra")}
                                  onKeyDown={(e) =>
                                  {
                                     if (e.key === 'Enter')
                                     {


                                       callAPI(
                                          callApiGet,
                                          'api/TaskDetail/GetTaskIDBYTaskNumber?TaskNumber=' + this.state.taskNumber,
                                          {},
                                           (res) => {
                                              
                                            if (res?.data) {

                                             this.props.history.push('/TaskDetail?' + res?.data);

                                            } 
                                            
                                            else  
                                            {
                                               if(this.state.loginUser.AllowSearchTask === "True")
                                               {
                                                 notify('İlgili talep numarası sistemde bulunamadı', "error", 5000);
                                               }
                                               else{
                                                 notify('İlgili talep numarası için yetkiniz bulunamadı', "error", 5000);
                                               }
                                            }

                                          },
                                          true
                                        );


                                     }
                                  }}
                           />
                           <button
                              onClick={() => this.setState({addClass: !this.state.addClass})}
                              className="search__btn btn"

                           >
                              <i className={`icon-magnifier`}/>
                           </button>
                        </div>
                     </div>
                     :
                     null
               }

               <Swr
                  props={this.props}
               />

               <UncontrolledDropdown nav direction="down">

                  <DropdownToggle nav>
                     <div className={'img-avatar'}
                          style={{
                             background: '#2F2E41',
                             height: 35,
                             textAlign: 'center',
                             width: 35,
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                          }}>
                        <strong style={{fontSize: 17, color: 'white',}}>{showName}</strong>
                     </div>
                  </DropdownToggle>
                  <DropdownMenu right>
                     <DropdownItem header tag="div"
                                   className="text-center"><strong>{t('hesabim')}</strong></DropdownItem>
                     <Link to='/Profile'> <DropdownItem><i className="fa fa-user"/>{t('profil')}
                     </DropdownItem></Link>
                     <DropdownItem onClick={e => this.props.onLogout(e)}> <i
                        className="fa fa-lock"/>{t("cikisYap")}
                     </DropdownItem>
                  </DropdownMenu>
               </UncontrolledDropdown>
            </Nav>
            <AppAsideToggler className="d-md-down-none"/>
         </>
      );
   }
}

function mapStateToProps(state)
{
   return {
      poolTasks: state.poolCountReducer,
      loginUser: state.loginReducer,
      popupmessage: state.popupMessageReducer
      
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps)(DefaultHeader)))
