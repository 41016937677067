import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Popup} from "devextreme-react/popup";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

let backArr = [];

class GroupAuthority extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,

         editRowVisible: false,
         addRowVisible: false,

         selectedName: undefined,
         selectedID: undefined,
      }
   }

   componentDidMount()
   {
      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/Role/GetData').then(data =>
      {
         this.setState({gridData: data});
      });
   }

   add()
   {
      if (this.state.selectedName === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         BB.FetchJSON('api/Yonetim/Role/Add?Name=' + this.state.selectedName + '&EditID=null').then(res =>
         {
            if (res.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: undefined,
               });
               this.getData(this.state.selectedParentID);
               notify(t("yetkigrubuolusturuldu"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: undefined,
               });
               notify(t("yetkigrubuolusturmahata") + res.Message, "warning", 1000);
            }
         });
      }
   }

   edit()
   {
      if (this.state.selectedName === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         BB.FetchJSON('api/Yonetim/Role/Add?Name=' + this.state.selectedName + '&EditID=' + this.state.selectedID).then(res =>
         {
            if (res.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: undefined,
                  selectedID: undefined
               });
               this.getData(this.state.selectedParentID);
               notify(t("yetkigrububasariyladuzenlendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: undefined,
                  selectedID: undefined
               });
               notify(t("yetkigrubuduzenlemehata") + res.Message, "warning", 1000);
            }
         });
      }
   }

   handleChange(id, value)
   {

      this.setState({[id]: value});

   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() => this.setState({
                  editRowVisible: true,
                  selectedID: cellData.data.ID,
                  selectedName: cellData.data.Name,
                  selectedIsSLA: cellData.data.ForSLA,
               })}
            >{t("düzenle")}</Button>
         </div>
      );
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>
                     <Button
                        color={'success'}
                        outline
                        onClick={() => this.setState({addRowVisible: true})}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button">
                        {/*<i className="icon-check"/>{' '}*/}
                        {t("yeni")}</Button>

                     {backArr.length !== 0 ?

                        <Button
                           color={'primary'}
                           outline
                           onClick={() =>
                           {
                              let backID = null;

                              backID = backArr[backArr.length - 2];

                              backArr.pop();

                              this.getData(backID);
                           }}
                           style={{float: 'left', position: 'absolute', zIndex: 10, left: 100}}
                           type="button">
                           {/*<i className="icon-arrow-left"/>{' '}*/}
                           {t("geri")}</Button>
                        :
                        null
                     }


                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName="Durum Listesi"/>

                        <Column
                           dataField="Name"
                           caption={t("adı")}
                           minWidth={200}
                           allowFiltering={true}/>

                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={150}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Yeni Satır Ekleme Popup*/}
            <Popup
               visible={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title="Yeni Durum Tanımı"
               width={metrics.popupMinWidth}
               height={220}
            >
               <form>

                  {BB.InputWithLabel('selectedName', t("durumtanimi"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addRowVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.add()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("olustur")}
                     </Button>
                  </Row>
               </form>
            </Popup>

            {/*Düzenle Popup*/}
            <Popup
               visible={this.state.editRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title={t("kategoriduzenle")}
               width={metrics.popupMinWidth}
               height={220}
            >

               {BB.InputWithLabel('selectedName', t("durumtanimi"), 'text', this)}

               <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                  <Button
                     color={'info'}
                     onClick={() => this.setState({
                        editRowVisible: false,
                        selectedID: '',
                        selectedName: '',
                     })}>
                     {t("kapat")}
                  </Button>

                  <Button
                     onClick={() => this.edit()}
                     color={'primary'}
                     style={{marginLeft: 20, marginRight: 0}}>
                     {t("kaydet")}
                  </Button>
               </Row>
            </Popup>
         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(GroupAuthority)
);
