import React, {PureComponent} from 'react';
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import CardFooter from "reactstrap/es/CardFooter";
import {Button} from "reactstrap";
import * as BB from '../../../components/BBComponent'
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import ReactDataGrid from "react-data-grid";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class SheetDesign extends PureComponent
{
   constructor(props)
   {
      super(props);

      this.state = {
         taskCategories: [],

         selectedID: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.ID : null : null,
         selectedName: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.Name : null : null,
         columns: this.props.location.state ? this.props.location.state.selectedForm ? JSON.parse(this.props.location.state.selectedForm.JsonForm) : [] : [],
         selectedCategoryID: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.CategoryID : undefined : undefined,
         selectedIsRequired: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.IsRequired : null : null,

         redirect: false,
         previewVisible: false,
         selectedColumnCaption: undefined,

         rows: []
      }

      this.getCategories();
   }


   getCategories = () =>
   {
      BB.FetchJSON('/api/Yonetim/CustomForm/GetAllCategories').then(data => {
         this.setState({taskCategories: data})
      });
   }

   handleChange(id, value)
   {
      if (id === "selectedIsRequired")
      {
         this.setState({[id]: !this.state.selectedIsRequired})
      }
      else
      {
         this.setState({[id]: value});
      }
   }

   saveForm()
   {
      let formData = new FormData();
      let {selectedCategoryID, columns, selectedID, selectedName, selectedIsRequired} = this.state;

      formData.append('Name', selectedName);
      formData.append('EditID', selectedID);
      formData.append('CatID', selectedCategoryID);
      formData.append('IsRequired', selectedIsRequired);
      formData.append('JsonBody', JSON.stringify(columns));

      post('api/Yonetim/CustomSheet/AddOrUpdateCustomSheet', formData, {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         if (res.data === true)
         {
            notify(t("Basarılı!"), 'success', 2000);
            this.setState({redirect: true});
         }
         else
         {
            notify(t("hata"), 'error', 2000);
         }
      });

   }

   addColumn()
   {
      let item = {
         key: this.state.selectedColumnCaption.toLowerCase(),
         name: this.state.selectedColumnCaption,
         editable: true
      };

      let hasColumn = this.state.columns.filter(x => x.name === this.state.selectedColumnCaption);

      if (hasColumn.length === 0)
      {
         this.setState({columns: this.state.columns.concat(item), selectedColumnCaption: undefined});
      }
      else
      {
         notify(t("aynibaslikilesadecebiradetsutunekleebilirsiniz"), 'warning', 2000);
      }
   }

   removeColumn()
   {
      let columns = this.state.columns;
      let newList = columns.filter(x => x.name !== this.state.selectedColumnCaption);

      if (newList.length > 0)
      {
         this.setState({columns: newList});
         notify(t("sutunsilinmistir"), 'success', 2000);
      }
   }

   onGridRowsUpdated = ({fromRow, toRow, updated}) =>
   {
      this.setState(state =>
      {
         const rows = state.rows.slice();
         for (let i = fromRow; i <= toRow; i++)
         {
            rows[i] = {...rows[i], ...updated};
         }
         return {rows};
      });
   }

   render()
   {

      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: '/Yonetim/SheetList'}}/>
      }
      else
      {

         return (
            <Row>
               <Col xl={12}>
                  <Card>
                     <CardBody>
                        <Row>
                           <Col xl={2}>
                              <Button
                                 color={'success'}
                                 outline
                                 block
                                 onClick={() => this.setState({redirect: true})}
                                 style={{float: 'left', position: 'absolute', zIndex: 1, width: 100}}
                                 type="button">
                                 {t("geri")}
                              </Button>

                              <Card style={{marginTop: 45}}>
                                 <CardBody>
                                    {BB.InputWithLabel('selectedName', t("tablotanimi"), 'text', this)}
                                    {BB.DxDropDownWithJSONWithPlaceHolder('selectedCategoryID', t("kategoriseciniz"), this, this.state.taskCategories, t("LütfenSeçiniz"))}
                                    {BB.CheckBoxWithLabel('selectedIsRequired', t("zorunluolsunmu"), this)}
                                 </CardBody>
                              </Card>
                           </Col>
                           <Col xl={8}>
                              <ReactDataGrid
                                 columns={this.state.columns}
                                 rowGetter={i => this.state.rows[i]}
                                 rowsCount={this.state.rows.length}
                                 onGridRowsUpdated={this.onGridRowsUpdated}
                                 enableCellSelect={true}
                              />
                           </Col>
                           <Col xl={2}>
                              <div style={{marginLeft: 20}}>
                                 <Col>
                                    {BB.InputWithLabel('selectedColumnCaption', t("kolonadigiriniz"), 'text', this)}

                                    <Button
                                       style={{float: "right"}}
                                       color={'info'}
                                       onClick={() => this.addColumn()}
                                    >
                                       {t("kaydet")}
                                    </Button>

                                    <Button
                                       style={{float: "right", marginRight: 10}}
                                       color={'warning'}
                                       onClick={() => this.removeColumn()}
                                    >
                                       {t("sil")}
                                    </Button>
                                 </Col>
                              </div>
                           </Col>
                        </Row>
                     </CardBody>
                     <CardFooter>
                        <Button
                           color={'info'}
                           onClick={() => this.saveForm()}
                        >
                           {t("kaydet")}
                        </Button>

                     </CardFooter>
                  </Card>
               </Col>
            </Row>
         );
      }
   }
}

export default withTranslation()(SheetDesign);
