import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import { Button, Col, Row } from "reactstrap";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import {
    callAPI,
    callApiGet,
    callApiPost,
    callApiPut
} from "../../../../../components/Axios";
import { List } from "devextreme-react";

const TaskAddWatcher = ({ t, event }) => {
    return (
        <Modal
            style={{ zIndex: 1 }}
            centered={true}
            isOpen={event.state.visibleWatcher}
        >
            <ModalHeader>{t('izleyiciEkle')}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={8}>
                        {BB.DxMultipleDropdown('selectedWatcherUserID', t("kullaniciSeciniz"), event, event.state.watcherList)}
                    </Col>
                    <Col lg={4}>
                        <Button
                            color={'light'}
                            block
                            style={{ marginTop: 27, float: 'right' }}
                            onClick={() => {
                                let selectedUser = [];
                                let AllUser = event.state.watcherList;
                                let addingList = [];

                                event.state.selectedWatcherUserID.map((item, i) => {
                                    selectedUser.push(AllUser.filter(x => x.ID == item)[0])
                                });

                                selectedUser.map(sUser => {
                                    let hasList = false;

                                    event.state.selectedWatcherUserList.map(item => {
                                        if (item.ID === sUser.ID) {
                                            hasList = true;
                                        }
                                    })

                                    if (hasList === false) {
                                        addingList.push(sUser);
                                    }
                                })

                                event.setState({ selectedWatcherUserList: event.state.selectedWatcherUserList.concat(addingList), })
                            }}
                        >
                            {t("listeyeEkle")}
                        </Button>
                    </Col>
                </Row>

                <Col>
                    <List
                        keyExpr={"ID"}
                        displayExpr={"Name"}
                        dataSource={event.state.selectedWatcherUserList}
                        height={110}
                        allowItemDeleting={true}
                        itemDeleteMode={"static"}
                        showSelectionControls={true}
                    />
                </Col>
                <Col>
                    <Row>
                        <Col lg={8}>
                            {BB.DxMultipleDropdown('selectedWatcherGroupsID', "İzleyici Grubu Seçiniz", event, event.state.oldGroupUsers)}
                        </Col>
                        <Col lg={4}>
                            <Button
                                color={'light'}
                                block
                                style={{ marginTop: 27, float: 'right' }}
                                onClick={() => {
                                    // event.GetWatcherGroupUsers(event.state.selectedWatcherGroups.concat(addingList));
                                    event.GetWatcherGroupUsers(event.state.selectedWatcherGroupsID);
                                }}
                            >
                                {t("listeyeEkle")}
                            </Button>
                        </Col>
                    </Row>
                    <List
                        keyExpr={"ID"}
                        displayExpr={"Name"}
                        dataSource={event.state.selectedWatcherGroups}
                        height={110}
                        allowItemDeleting={false}
                        itemDeleteMode={"static"}
                        showSelectionControls={true}
                    />
                </Col>

                <Row style={{ float: 'right' }}>
                    <Button
                        color={'info'}
                        onClick={() => {

                            event.setState({
                                visibleWatcher: false,
                                selectedWatcherUsers: [],
                                selectedWatcherGroupsID: [],
                                selectedWatcherGroups: [],
                                selectedWatcherUserID: undefined,
                                selectedWatcherUserList:[]
                            })
                        event.GetWatcherGroups()
                        }
                        }>
                        {t('vazgec')}
                    </Button>
                    <Button
                        disabled={event.state.selectedWatcherUserList.length === 0 && event.state.selectedWatcherGroups.length === 0}
                        color={'success'}
                        style={{ marginLeft: 20, marginRight: 15 }}
                        onClick={() => {
                            const formData = new FormData();
                            let usersCache = [];
                            event.state.selectedWatcherUserList.map(user=>{
                                usersCache.push(user.ID);
                            });
                            formData.append('userList', usersCache);
                            formData.append('TaskID', event.state.taskID);
                            formData.append('watcherGroups', event.state.selectedWatcherGroupsID);



                            callAPI(
                                callApiPost,
                                'api/TaskDetail/addWatcherUsers',
                                formData,
                                async (res) => {

                                    if (res.data) {
                                        if (res.data.warningMessage != undefined) {
                                            notify(res.data.warningMessage, 'warning', 2000);
                                            event.setState({
                                                visibleWatcher: false,
                                                selectedWatcherUsers: [],
                                                selectedWatcherGroupsID: [],
                                                selectedWatcherGroups: [],
                                                treeView: undefined
                                            })
                                        } else {
                                            notify(t("izleyiciBasarıylaEklendi"), 'success', 2000);
                                            event.GetWatcherUsers(event.state.taskID);
                                            event.setState({
                                                visibleWatcher: false,
                                                selectedWatcherUsers: [],
                                                selectedWatcherGroupsID: [],
                                                selectedWatcherGroups: [],
                                                treeView: undefined
                                            })
                                        }

                                    }
                                    else {
                                        notify(t("izleyiciEklerkenHata!"), 'warning', 2000);
                                        event.setState({
                                            visibleWatcher: false,
                                            selectedWatcherUsers: [],
                                            selectedWatcherGroupsID: [],
                                            selectedWatcherGroups: []
                                        })
                                    }
                                   
                                })
                            }}
                    >
                        {t('ekle')}
                    </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default TaskAddWatcher;
