import React, {Component} from 'react';
import {post} from "axios";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import notify from "devextreme/ui/notify";
import {Popup} from 'devextreme-react/popup';
import {bindActionCreators} from "redux";
import * as BB from '../../../components/BBComponent'
import {connect} from "react-redux";

import * as loadingActions from "../../../redux/actions/loadingActions";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class GroupManagement extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],

         addPopupVisible: false,
         editPopupVisible: false,

         editID: '',

         selectedName: '',
      }
      this.getDatas();
   }

   componentDidMount()
   {
      this.props.actions.isLoading(true);
   }

   getDatas()
   {
      let URL = 'api/Yonetim/Group/GetAllGroup';
      BB.FetchJSON(URL).then(data =>
      {
         this.setState({gridData: data})
         this.props.actions.isLoading(false);
      });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginRight: 10}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({editID: cellData.data.ID, selectedName: cellData.data.Name, editPopupVisible: true});
               }}>
               {t("düzenle")}
            </Button>
         </div>
      );
   }


   add()
   {
      if (this.state.selectedName === undefined || this.state.selectedName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            Name: this.state.selectedName,
            IsActive: true,
         }
         const formData = new FormData();

         formData.append('jsonText', JSON.stringify(entity));

         if (this.state.editID !== '' || this.state.editID !== undefined)
         {
            formData.append('EditID', this.state.editID);
         }

         post('api/Yonetim/Group/AddOrUpdate', formData, {headers: {'content-type': 'multipart/form-data'},})
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addPopupVisible: false,
                  editPopupVisible: false,
                  selectedName: '',
                  editID: ''
               }, this.getDatas());
               this.props.actions.isLoading(false);

               if (this.state.editID !== '' || this.state.editID !== undefined)
               {
                  notify(t("duzenlemeislemibasarili"), "success", 1000);
               }
               else
               {
                  notify(t("eklemebasarilibirsekildeeklendi"), "success", 1000);
               }
            }
            else
            {
               this.setState({addPopupVisible: false});

               if (this.state.editID !== '' || this.state.editID !== undefined)
               {
                  notify(t("duzenlemeislemindehata"), "error", 1000);
               }
               else
               {
                  notify(t("eklemeislemihatali"), "error", 1000);
               }

               this.props.actions.isLoading(false);
            }
         });
      }
   }

   render()
   {
      const {gridData} = this.state;
      return (
         <Row>
            <Col md='12' sm='12' lg='12'>
               <Card>
                  <CardBody>
                     <Button
                        color={'success'}
                        outline
                        onClick={() => this.setState({
                           addPopupVisible: true
                        })}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button">{t("yeni")}</Button>

                     <DataGrid
                        id="gridContainer"
                        dataSource={gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName=""/>
                        <Column
                           dataField="Name"
                           caption={t("gruptanimi")}
                           minWidth={200}
                           allowFiltering={true}/>
                        <Column
                           dataField="Düzenle"
                           caption={t("islemler")}
                           width={100}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>

            </Col>

            {/*Yeni Satır Ekleme Popup*/}
            <Popup
               visible={this.state.addPopupVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title={t("grupyonetimietkiekle")}
               width={metrics.popupMinWidth}
               height={230}
            >
               <form>
                  {BB.InputWithLabel('selectedName', t("grupadi"), 'text', this)}
                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addPopupVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.add()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("olustur")}
                     </Button>
                  </Row>
               </form>
            </Popup>

            {/*Yeni Satır Edit Popup*/}
            <Popup
               visible={this.state.editPopupVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title="Grup Yönetimi - Etki Düzenle"
               width={metrics.popupMinWidth}
               height={230}
            >
               <form>
                  {BB.InputWithLabel('selectedName', 'Grup Adı', 'text', this)}
                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({editPopupVisible: false})}>
                        {t("vazgec")}
                     </Button>
                     <Button
                        onClick={() => this.add()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("olustur")}
                     </Button>
                  </Row>
               </form>
            </Popup>
         </Row>

      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GroupManagement));

