import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row} from "reactstrap";
import notify from "devextreme/ui/notify";
import {post} from "axios";

const taskTransfer = (event,t) =>
{
   

   if (event.state.data.WorkStatusID === '60c4bf22-c512-eb11-a2cb-005056b81088')
   {

      return notify(event.languages.talepDurumuBeklemedeOlanHavuzaGonderilmez, "error", 1500);
      
   }
   else
   {
      if (event.state.selectedPoolDevret === '' || event.state.selectedPoolDevret === null)
      {

         return notify(event.languages.havuzAlaniZorunludur, "error", 1500);
      
      }
   }
   const url = `api/TaskDetail/transfer`;

   const formData = new FormData();

   formData.append('TaskID', event.state.taskID);
   formData.append('TargetPoolID', event.state.selectedPoolDevret);
   formData.append('Description', event.state.selectedDescriptionDevret);
   formData.append('TargetUserID', event.state.selectedPoolUserID);

   const config = {
      headers: {
         'content-type': 'multipart/form-data'
      },
   };
   event.props.actions.isLoading(true);
   post(url, formData, config).then(res =>
   {
      if (res.data === "Success")
      {
         let ths = event;
         notify(event.languages.talepBasariylaDevredildi, "success", 2000);
         //event.props.actions.getPoolTaskCount();
         event.props.actions.isLoading(false);
         setTimeout(function ()
         {
            if (ths.props.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb')
            {
               ths.props.history.push('/TaskTracking');
            }
            else
            {
               ths.props.history.push('/OwnTask');
            }
         }, 500)
      }
      else if ("Talep Aynı Kişiye Devir Edilemez."){
         
         notify(t("talepAynıKisiyeDevirEdilemez"), "error", 2000); 
         event.props.actions.isLoading(false);
      }
      else if ("Sonlandırılan Talep Devir Edilemez"){
         notify(t("sonlandırılanTalepDevirEdilemez"), "error", 2000); 
         event.props.actions.isLoading(false);
      }
      else
      {        
          event.props.actions.isLoading(false);

         notify(res.data, "error", 2000);
      }
   });
}

const TaskTransferModal = ({t, event}) =>
{
   return (
      <Modal
         style={{zIndex: 1}}
         centered={true}
         isOpen={event.state.visibleDevret}
      >
         <ModalHeader>{t('talepDevret')}</ModalHeader>
         <ModalBody>
            {BB.DxDropDownWithURL('selectedPoolDevret', t('hedefHavuz'), event, 'api/TaskDetail/GetAllPool', t('lütfenseçiniz'))}
            {BB.DxDropDownWithURL('selectedPoolUserID', t('hedefKullanici'), event, 'api/Common/GetPoolUsers?poolID=' + event.state.selectedPoolDevret, t('lütfenseçiniz'), !event.state.selectedPoolDevret)}
            {BB.TextAreaWithLabel('selectedDescriptionDevret', t('açıklama'), t('açıklama'), 5, event)}
            <Row style={{float: 'right'}}>
               <Button
                  color={'info'}
                  onClick={() => event.setState({
                     visibleDevret: false,
                     selectedPoolDevret: undefined,
                     selectedPoolUserID: undefined,
                  })}>
                  {t('vazgec')}
               </Button>
               <Button
                  color={'success'}
                  disabled={!event.state.selectedPoolDevret}
                  style={{marginLeft: 20, marginRight: 15}}
                  onClick={() => taskTransfer(event,t)}>
                  {t('devret')}

               </Button>
               
            </Row>
         </ModalBody>

      </Modal>
   )
}

export default TaskTransferModal;
