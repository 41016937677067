import * as actionTypes from "./actionTypes";
import {
    callAPI,
    callApiGet
} from "../../components/Axios";
export function setTaskAssigned(Data) {
    return { type: actionTypes.GET_TASK_ASSIGNED, payload: Data };
}
export function getTaskAssigned() {
    return function (dispatch) {
        return callAPI(
            callApiGet,
            "api/DashBoard/GetTaskAssigned",
            {},
            async (res) => {
                dispatch(setTaskAssigned(res.data));
            },
            true
        );
    };
}