import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row} from "reactstrap";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {  callAPI,
    callApiGet,
    callApiPost,
    callApiPut } from "../../../../../components/Axios";


const TakeOverUser = ({t, event}) =>
{
    return (
        <Modal
            style={{zIndex: 1}}
            centered={true}
             isOpen={event.state.takeOverUserVisible}
        >
            <ModalHeader>{t("talepSorumlusuDegistir")}</ModalHeader>
            <ModalBody>


              {BB.DxDropDownWithJSON(
                  "selectedOverUserId",
                  t("havuzKullanıcıları"),
                  event,
                  event.state.poolUsers2
                )}

              {/* {BB.TextAreaWithLabel('selectedMessage', 'Mesaj', 'Mesaj', 9, event)}     */}

                <Row style={{float: 'right',marginRight:10}}>
                <Button style={{marginRight:7}}
                        color={'info'}
                         onClick={() => event.setState({takeOverUserVisible: false, selectedOverUserId:undefined })}
                        >
                        {t("vazgec")}
               </Button>
               <div>
               <Button

                color={'success'}
               onClick={() => event.changeResponsibleUserByTask()
            }


               >{t("onayla")}</Button></div>

                </Row>

               </ModalBody>
               </Modal>
    )
}

export default TakeOverUser;
