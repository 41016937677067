import React, {PureComponent} from 'react';
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import {Button} from "reactstrap";
import {bindActionCreators} from "redux";
import * as poolActions from "../../../redux/actions/poolActions";
import {connect} from "react-redux";
import * as loginUserActions from "../../../redux/actions/loginUserActions";

class callback extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         timer: 0,
         message: "",
         finish: false
      }

      let ths = this;
      this.popupTimer = setInterval(function ()
      {
         var dif = (new Date() - new Date(localStorage.getItem('loginTime')));
         var dif = Math.round((dif / 1000));
         let text = "Task4Team üzerinde uzun süre işlem yapmadığınız için " + (39 - dif).toString() + " saniye sonra oturum kapatılacaktır";


         if (dif >= 39)
         {

            ths.setState({
               message: "Task4Team üzerinde uzun süre işlem yapmadığınız için oturum kapatılmıştır",
               finish: true
            });

            clearInterval(ths.popupTimer);
            // ths.closeWindow();
         }

         if (dif < 39 && ths.state.finish === false)
         {
            ths.setState({message: text})
         }

      }, 1000);
   }

   componentWillUnmount()
   {
      clearInterval(this.popupTimer);
   }

   continueSession()
   {
      this.props.actions.getPoolCount().then(() =>
      {
         window.close();
      });
   }

   render()
   {
      return (
         <>
            <Card>
               <CardHeader>Task4Team Zaman aşım bildirimi</CardHeader>
               <CardBody>
                  Merhaba,<br/>
                  {this.state.message}
               </CardBody>
               <CardFooter>
                  {/*<Button*/}
                  {/*   disabled={this.state.finish}*/}
                  {/*   onClick={() => this.closeWindow()}*/}
                  {/*   color={'info'}*/}
                  {/*   style={{float: 'right',}}>*/}
                  {/*   Oturumu Kapat*/}
                  {/*</Button>*/}
                  <Button
                     disabled={this.state.finish}
                     onClick={() => this.continueSession()}
                     color={'success'}
                     style={{float: 'right'}}>
                     Devam Et
                  </Button>
               </CardFooter>
            </Card>
         </>
      );
   }
}


function mapStateToProps(state)
{
   return {
      loginUser: state.loginReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         clearLoginUser: bindActionCreators(loginUserActions.clearLoginUser, dispatch),
         getPoolCount: bindActionCreators(poolActions.getPoolCount, dispatch),
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(callback);
