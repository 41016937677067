import { data } from "jquery";
import * as actionTypes from "./actionTypes";
// import {SET_MY_TASK_CREATE_TEMPLATE} from "./actionTypes";
import { callAPI, callApiGet, callApiPost } from "../../components/Axios";

export function addAllTasks(allTasks) {
  return { type: actionTypes.SET_TASK_TRACKING_DATA, payload: allTasks };
}

// export function addOwnConfirmTasks(allTasks)
// {
//    return {type: actionTypes.SET_OWN_CONFIRM_DATA, payload: allTasks}
// }

export function addMyTaskCreateTemplates(allTasks) {
  return { type: actionTypes.SET_MY_TASK_CREATE_TEMPLATE, payload: allTasks };
}

export function getAllTask(filter) {
  return function (dispatch) {
    return callAPI(
      callApiPost,
      "api/TaskTracking/GetGridData",
      filter,
      async (result) => {
        dispatch(addAllTasks(result.data));
      },
      true
    );
  };
}

// export function getOwnConfirmTask()
// {
//    return function (dispatch)
//    {
//       let url = 'api/OwnConfirmTask/GetData';

//       return fetch(url)
//       .then(res => res.json())
//       .then(result =>
//       {
//          localStorage.setItem('loginTime', new Date());
//          dispatch(addOwnConfirmTasks(result))
//       });
//    }
// }

export function getMyTaskCreateTemplates() {

  return function (dispatch) {
    return callAPI(
      callApiGet,
      "api/DashBoard/GetMyTaskCreateTemplate",
      {},
      async (result) => {
        dispatch(addMyTaskCreateTemplates(result.data));
      },
      true
    );
  };
}