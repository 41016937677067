import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row,Label, Input, InputGroup, ListGroup, ListGroupItem} from 'reactstrap';
import { FormGroup } from "semantic-ui-react";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Popup} from "devextreme-react/popup";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import {post} from "axios";
import TreeView from "devextreme-react/tree-view";
import DropDownBox from 'devextreme-react/drop-down-box';
import {Redirect} from 'react-router-dom';
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class Survey extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         redirect: false,
         redirectUrl: '',
         gridData: undefined,
         editRowVisible: false,
         addRowVisible: false,
         selectedID: '',
         selectedName: '',
         selectedCompany: '',
         selectedCategory: [],
         selectedActive: true,
         companySelectToolListVisible:false,
         companySelectTool:'',
         taskCompaniesTemp: [],
         

      }
      this.treeView = null;
      this.treeDataSource = BB.makeAsyncDataSource('/api/Yonetim/Survey/GetCategory')
   }

   componentDidMount()
   {
      this.getData();
   }

   getData()
   { BB.FetchJSON('api/Yonetim/Survey/GetCompany').then(datacomp =>
      {
      
      BB.FetchJSON('api/Yonetim/Survey/GetData').then(data =>
      {
         this.setState({gridData: data.Data,companyList:datacomp})
         this.props.actions.isLoading(false);
      });
   });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});

      if (id === 'selectedActive')
      {
         this.setState({selectedActive: !this.state.selectedActive})
      }
   }

   add()
   {
      if ((this.state.selectedName === undefined || this.state.selectedName === '') || (this.state.selectedCompany === undefined || this.state.selectedCompany === '') || (this.state.selectedCategory === undefined || this.state.selectedCategory === ''))
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            Name: this.state.selectedName,
            CompanyID: this.state.selectedCompany,
            CategoryID: this.state.selectedCategory,
            IsActive: this.state.selectedActive,
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', null)
         formData.append('CategoryIDs', entity.CategoryID);

         post('api/Yonetim/Survey/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("anketolusturuldu"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               });
               notify(t("anketolusturmahata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   delete(ID)
   {
      this.props.actions.isLoading(true);

      fetch('api/Yonetim/Survey/Delete?ID=' + ID, {method: 'GET'})
      .then((response) => response.json())
      .then((res) =>
      {
         if (res.MessageStatus === true)
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''}, this.getData());
            this.props.actions.isLoading(false);
            notify(t("anketbasariylasilindi"), "success", 1000);
         }
         else
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
            notify(t("anketsilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {

                  this.setState({
                     redirect: true,
                     redirectUrl: '/Yonetim/SurveyEdit',
                     selectedID: cellData.data.ID
                  });
               }}>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizanketsilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }
   onClickFilter  (filter)  {
      if (filter.ID !== '') {
          this.setState({ companySelectTool: filter.Name })
          this.handleChange('selectedCompanyID', filter.ID)
          this.setState({ companySelectToolListVisible: false });
          this.setState({ selectedCompany: filter.ID });

          
      }
  } 
  companyFilter  (e){
   this.setState({ companySelectToolListVisible: true });
   var companies = this.state.companyList
   if (this.state.companySelectTool.length > 2) {
       //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

       //   

       var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase()));

       this.setState({ taskCompaniesTemp: filteredCompanies });
       //})
   } else {
       let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase())).slice(0,30);
       let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: t("dahafazlafirmaicinenazuckaraktergirmelisiniz"), ParentID: null };
       tempCompanies = tempCompanies.concat(itemdef);
       this.setState({ taskCompaniesTemp: tempCompanies });
   }

}
   renderMultipleDropdown()
   {
      return (
         <FormGroup>
            <Label>
               <strong>{t("kategori")}</strong>
            </Label>
            <DropDownBox
               value={this.state.selectedCategory}
               valueExpr="ID"
               displayExpr="Name"
               placeholder={t("lutfenSeciniz")}
               showClearButton={true}
               dataSource={this.treeDataSource}
               onValueChanged={(e) =>
               {
                  let treeView = (e.component.selectItem && e.component) || (this.treeView && this.treeView.instance);

                  if (treeView)
                  {
                     if (e.value === null)
                     {
                        treeView.unselectAll();
                     }
                     else
                     {
                        let values = e.value || this.state.treeBoxValue;

                        values && values.forEach(function (value)
                        {
                           treeView.selectItem(value);
                        });
                     }
                  }

                  if (e.value !== undefined)
                  {
                     this.setState({
                        selectedCategory: e.value
                     });
                  }
               }}
               contentRender={(e) =>
               {
                  return (
                     <TreeView
                        dataSource={this.treeDataSource}
                        ref={(ref) => this.treeView = ref}
                        dataStructure="plain"
                        keyExpr="ID"
                        parentIdExpr="ParentID"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        selectNodesRecursive={true}
                        displayExpr="Name"
                        selectByClick={true}
                        onContentReady={this.syncTreeViewSelection}
                        onItemSelectionChanged={e1 =>
                        {
                           this.setState({selectedCategory: e1.component.getSelectedNodeKeys()});

                        }}
                     />
                  );
               }}
            />
         </FormGroup>
      );
   }

   render()
   {
      if (this.state.redirect === true)
      {
         return <Redirect push to={{pathname: this.state.redirectUrl, state: {SurveyID: this.state.selectedID}}}/>
      }
      else
      {
         return (
            <div className="animated fadeIn">
               <Col>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({
                              addRowVisible: true,
                              selectedID: '',
                              selectedName: '',
                              selectedCompany: '',
                              selectedCategory: [],
                              selectedActive: true
                           })}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Anket Listesi"/>
                           <Column
                              dataField={'ID'}
                              visible={false}/>
                           <Column
                              dataField={'Name'}
                              caption={t("adı")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField={'SurveyQuestionCount'}
                              caption={t("sorusayisi")}
                              minWidth={100}
                              allowFiltering={true}/>
                           <Column
                              dataField="Company"
                              caption={t("kurum")}
                              minWidth={50}
                              allowFiltering={true}/>
                           <Column
                              dataField={'IsActive'}
                              caption={t("aktif")}
                              minWidth={80}/>

                           <Column
                              dataField="İşlemler"
                              caption={t("islemler")}
                              width={150}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.addRowVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("yeniankettanimi")}
                  width={metrics.popupMinWidth}
                  height={420}
               >
                  <form>
                     {BB.InputWithLabel('selectedName', t("anketismi"), 'text', this)}
                     <FormGroup>
                    <Label>
                        <strong>{t("firmaListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={this.state.companySelectTool} type="text" autoFocus={true} name={'selectedCompany'} onChange={(e) => { this.setState({ companySelectTool: e.target.value  }, this.companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px', display: this.state.companySelectToolListVisible ? 'block' : 'none' }}>
                        {
                            this.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === this.state.selectedCreateUserCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={()=>this.onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {/* {BB.DxSelectBoxWithJSON('selectedCreateUserCompanyID', 'Kurum Seçiniz', event, event.state.taskCompanies)}*/}
                    {/* {BB.DxMultipleDropdown('selectedPoolUsers', 'Firma Kullanıcıları', this, this.state.companyUserList)} */}

                </FormGroup> 
                     {/* {BB.DxDropDownWithURL('selectedCompany', 'Kurum', this, "/api/Yonetim/Survey/GetCompany", "Kurum")} */}
      
                     {this.renderMultipleDropdown()}

                     {BB.CheckBoxWithLabel('selectedActive', t("aktif"), this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({
                              addRowVisible: false,
                           })}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
            </div>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Survey));
