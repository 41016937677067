import React, { PureComponent } from "react";
import { Col, Input, Row, AppSwitch } from "reactstrap";
import BarLoader from "react-spinners/BarLoader";
import { bindActionCreators } from "redux";
import * as loginUserActions from "../../../redux/actions/loginUserActions";
import * as menuActions from "../../../redux/actions/menuActions";
import * as poolActions from "../../../redux/actions/poolActions";
import { connect } from "react-redux";
import metrics from "../../../common/metrics";
import { Redirect } from "react-router-dom";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import jwt_decode from "jwt-decode";
import { callAPI, callApiGet } from "../../../components/Axios";

class Avec extends PureComponent {
    constructor() {

        super();
        this.state = {
            isLoading: false,
            userEmail: "",
            userPassword: "",
            redirect: false,
            showReset: false,
            resetEmail: "",
            isLdap: true,
            loginUser: null,
        };

        var token = localStorage.getItem("token");
        if (token) {
            let user = jwt_decode(token);
            if (user && new Date(user.exp * 1000) > new Date()) {
                this.state.loginUser = user;
                this.state.redirect = true;
            }
        }

        if (!this.state.redirect) {
            this.checkUser = this.checkUser.bind(this);
            // this.refreshToken = this.refreshToken.bind(this);
            this.getMainCompanyDetail = this.getMainCompanyDetail.bind(this);
            // this.props.actions.getMainCompany();

            this.getMainCompanyDetail();
            sessionStorage.clear();
            let lS = localStorage ? localStorage.getItem("Popups") : null;
            let localPopups = lS ? JSON.parse(lS) : [];

            sessionStorage.clear();

            localStorage.clear();
        }
    }
    // componentDidMount()
    // {
    //    sessionStorage.clear();
    //    let lS = localStorage ? localStorage.getItem('Popups') : null;
    //    let localPopups = lS ? JSON.parse(lS) : [];

    //    //localStorage.removeItem("state");

    //    // localStorage.setItem('Popups', JSON.stringify(localPopups));
    //    // localStorage.setItem('loginTime', new Date());
    //    // if (this.state.loginUser !== null) {
    //    //    this.refreshToken();
    //    // }
    //    sessionStorage.clear();
    //    localStorage.clear();
    // }
    getMainCompanyDetail() {
        callAPI(
            callApiGet,
            "api/Login/GetMainCompanies",
            {},
            async (res) => {
                const result = res?.data;
                this.setState({ LogoUrl: result.LogoUrl });
            },
            true
        );
    }

    checkUser(e) {
        const userEmail = this.state.userEmail;
        const userPass = this.state.userPassword;
        let isLdapLogin = this.state.isLdap;

        let ldapCheck = userEmail.substring(0, 4);

        if (ldapCheck !== "avek") {
            isLdapLogin = false;
        }



        let url =
            "api/Login/checkUserLogin?userName=" +
            userEmail +
            "&userPassword=" +
            userPass +
            "&isLdapLogin=" +
            isLdapLogin;
        fetch(url)
            .then((res) => res.json())
            .then((response) => {
                if (response.Token !== undefined && response.Token !== "") {
                    localStorage.setItem("token", response.Token);
                    this.setState({ isLoading: false, redirect: true });
                } else {
                    this.setState({ isLoading: false, redirect: false });
                    notify("Kullanıcı Adı Veya Şifre Hatalı.", "error", 3000);
                }
            })
            .catch((error) => {
                this.setState({ isLoading: false, redirect: false });
                if (error) {
                    this.setState({ isLoading: false, redirect: false });
                    notify(error.response?.data, "error", 3000);
                } else {
                    this.setState({ isLoading: false, redirect: false });

                    notify("İnternet Baglantınızı Kontrol Ediniz.", "error", 3000);
                }
            });
        e.preventDefault();
    }

    forgotPasswordUser() {
        this.setState({ isLoading: true });

        let url = "api/Login/ForgotPassword?Email=" + this.state.resetEmail;

        BB.FetchJSON(url).then((data) => {
            alert(data);
            this.setState({ info: data, isLoading: false, showReset: false });
        });
    }

    render() {
        if (this.state.redirect === true) {
            if (metrics.redirectURL !== undefined) {
                if (!metrics.redirectURL === "/") {
                    return <Redirect push to={metrics.redirectURL} />;
                } else {
                    return <Redirect push to="/main" />;
                }
            } else {
                return <Redirect push to="/main" />;
            }
        } else {
            return (
                <div style={{ backgroundColor: "white" }}>
                    <div className="sweet-loading">
                        <BarLoader
                            width={"auto"}
                            size={10}
                            color={"#21BFD4"}
                            loading={this.state.isLoading}
                        />
                    </div>
                    <Row style={{ backgroundColor: "white", height: 70 }}>
                        <Col>
                            <img
                                src="https://content.r9cdn.net/rimg/provider-logos/cars/h/aveccarrental.png?crop=false&width=120&height=60&fallback=default1.png&_v=de0234ff6b44b5a3384ec55dd52ea83d"
                                style={{ width: 140, marginLeft: 100 }}
                                alt="Logo"
                                data-type="logo"
                            />
                            <h5 style={{ position: "absolute", left: 270, top: 16 }}>
                                Talep Yönetim Sistemi
                            </h5>
                        </Col>
                    </Row>
                    <nav
                        className="_page-tabs _action-bar"
                        style={{ backgroundColor: "#21BFD4" }}
                    >
                        <div className="_action-bar-wrapper">
                            <div className="_nav-link" id="_page-tabs">
                                <a
                                    className="uppercase"
                                    style={{
                                        marginLeft: 100,
                                        backgroundColor: "#1AA0B5",
                                        border: "1px solid #000000", 
                                        
                                        borderRadius: "5px"
                                    }}
                                    href="#"
                                >
                                    Ana Sayfa
                                </a>
                            </div>
                            <div id="new-ticket-button"></div>
                        </div>
                    </nav>
                    <section id="login-container" className="content-form">
                        <div style={{ marginLeft: 100 }} className="login-form">
                            <div id="login-form">
                                <h2 className="heading">Giriş Yap</h2>
                                <p className="info-text">
                                    Bilgisayar kullanıcı adınız ve şifreniz ile giriş
                                    yapabilirsiniz. <br /> Örn: "avek\kullanıcıadı"{" "}
                                </p>
                                <fieldset>
                                    <div className="_control-group">
                                        <div className="_controls">
                                            <Input
                                                onChange={(event) =>
                                                    this.setState({ userEmail: event.target.value })
                                                }
                                                value={this.state.userEmail}
                                                className="required _special"
                                                id="UserName"
                                                name="UserName"
                                                placeholder="Kullanıcı adı veya E-posta adresiniz"
                                                size="30"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="control-group">
                                        <div className="controls">
                                            <Input
                                                onChange={(event) =>
                                                    this.setState({ userPassword: event.target.value })
                                                }
                                                value={this.state.userPassword || ""}
                                                className="_special"
                                                id="Password"
                                                name="Password"
                                                placeholder="Şifre"
                                                size="30"
                                                type="password"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="_control-group forgot-pass-container">
                                        <div className="_controls clearfix">
                                            <label className="checkbox pull-left"></label>
                                            <div className="pull-right">
                                                <a
                                                    id="forgot_password"
                                                    onClick={() => this.setState({ showReset: true })}
                                                    data-toggle-dom="#forgot-form, #login-form"
                                                >
                                                    Şifremi unuttum?
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_control-group login-form-submit">
                                        <div className="_controls">
                                            <button
                                                onClick={(e) => this.checkUser(e)}
                                                className="btn btn-primary btn-login"
                                                style={{ backgroundColor: "#21BFD4" }}
                                            >
                                                Giriş Yap
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="login-right-container">
                            {this.state.showReset ? (
                                <div style={{ marginLeft: 20, marginRight: 20 }}>
                                    <h2 className="heading">Şifremi Unuttum</h2>
                                    <p>
                                        Bize e-posta adresinizi verin ve şifrenizi sıfırlamak için
                                        talimatları size e-posta ile göndereceğiz.
                                    </p>
                                    <fieldset>
                                        <div className="control-group forgot-form-input">
                                            <div className="controls">
                                                <Input
                                                    onChange={(event) =>
                                                        this.setState({ resetEmail: event.target.value })
                                                    }
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    className="required special"
                                                    placeholder="E-mail adresiniz"
                                                    aria-required="true"
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div
                                        className="control-group button-container"
                                        style={{ marginTop: 10 }}
                                    >
                                        <button
                                            onClick={() => this.forgotPasswordUser()}
                                            type="submit"
                                            className="btn btn-primary"
                                            id="reset-password-submit"
                                            style={{ backgroundColor: "#21BFD4" }}
                                        >
                                            Şifremi yenile
                                        </button>
                                        <button
                                            onClick={() =>
                                                this.setState({ showReset: false, resetEmail: "" })
                                            }
                                            type="button"
                                            data-toggle-dom="#login-form, #forgot-form"
                                            className="btn"
                                            id="reset-password-cancel"
                                        >
                                            {" "}
                                            İptal
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </section>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        loginUser: state.loginReducer,
        menus: state.menuReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            checkLoginuser: bindActionCreators(
                loginUserActions.checkLoginUser,
                dispatch
            ),
            getMenu: bindActionCreators(menuActions.getMenus, dispatch),
            getPoolCount: bindActionCreators(poolActions.getPoolCount, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Avec);
