import React, { useState } from "react";
import { CustomCard, Empty, LabelGroup } from "../index";
import "./styles.scss";
import { post } from "axios";

import * as BB from "../../BBComponent";
import notify from "devextreme/ui/notify";
import USER from "../../../assets/user.png";
import {
  Nav,

  TabContent,
  TabPane,
  Col,

} from "reactstrap";

const getFavoriteCategories = () =>
  new Promise((resolve) => {
    let url = "/api/Task/GetFavoriCategories";

    BB.FetchJSON(url).then((data) => {
      resolve(data);
    });
  });

const TableTaskTabs = ({
  title,
  description,
  data,
  data1,
  buttonVisible,
  selectData,
  handleClick,
  type,
  event,
  handleChange,
  isTaskDetail,
  props,
  handleDetail,
  base64Img,
  taskID,
  minHeight = false,
  t,
}) => {
  
  const setModalOpen = () => {
    getFavoriteCategories().then((data) => {
      event.setState({ favoriCategory: data });
    });
    event.setState({
      modalOpen: !event.state.modalOpen,
      filteredCategory: [],
    });
  };

  const onClickCreateFastTask = () => {
    var text = event.state.selectedCommentDescription;
    var categoryID = event.state.selectedCategory;
    var htmlDescription = "<p>" + text + "</p>";
    if (text.length === 0) {

      return notify(t("açıklamaAlanıZorunludur"), "error", 3000);

    }
    if (categoryID===undefined) {
      return notify(t("kategoriSeçilmesiZorunludur"), "error", 3000);
    }
    let taskDto = {
      Description: JSON.stringify(text),
      CategoryID: categoryID,
      HTMLDescription: htmlDescription,
      
    };
    

    const formData = new FormData();

    formData.append("jsonText", JSON.stringify(taskDto));
    formData.append("IsFastTask", true);

    // formData.append('CategoryID',categoryID);
    event.props.actions.isLoading(true);

    post("api/Task/TaskCreate", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      ////
      .then((res) => {
        if (res.data.Issuccess === true) {
          event.props.actions.isLoading(false);
          event.setState({
            selectedCommentDescription: "",
            selectedCategory: "",
          });
          notify(res.data.ErrMsg, "success", 3000);
        } else {
          event.props.actions.isLoading(false);
          notify(t("talepOluşturulamadı") + res.data.ErrMsg, "warning", 3000);
        }
      })
      .catch((ex) => {
        event.props.actions.isLoading(false);
      });
  };


  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let style = {};

  return (
    <CustomCard bodyStyle={"card-height"} isTaskDetail={isTaskDetail}>
      <LabelGroup
        title={t("hızlıTalepOluştur")}
        
        description={
           t("hizliTalepOlusturAciklama")
          // "*Bu adımdan sadece en sık kullandığınız 10 kategori üzerinden talep oluşturabilirsiniz. Ayrıca doldurulması zorunlu alan içeren kategoriler için Yeni Talep Oluştur adımı kullanılarak  talep oluşturulmasını rica ederiz."
        }
        // buttonVisible={true}
        handleClick={handleClick}
        isTaskDetail={isTaskDetail}
      />

      <div className="table-task-div">
        <Nav
          tabs
          style={{
            position: "absolute",
            top: 19,
            right: 71,
          }}
        ></Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {
              <Col>
                <div>
                  {BB.DxDropDownWithJSONFav(
                    "selectedCategory",
                    t("kategori")
                    ,
                    event,
                    event.state.favoriCategory
                  )}
                  {BB.TextAreaWithLabel2(
                    "selectedCommentDescription",
                    t("açıklama"),
                    "",
                    5,
                    event
                  )}

                  <button
                    className=" btn btn-success taskButton2 "
                    onClick={() => onClickCreateFastTask()}
                  >
                    {t("oluştur")}
                  </button>
                </div>
              </Col>
            }
          </TabPane>

          <TabPane tabId="2">
            {data1 && data1.length > 0 ? (
              <table className="table  m-b-0 transcations mt-2">
                <tbody>
                  {data1.map((item, inx) => (
                    <tr key={inx}>
                      <td className="wd-5p">
                        <div className="main-img-user avatar-md">
                          {/*<img alt="avatar" className="rounded-circle mr-3" src='https://www.sonax.com.tr/wp-core/assets/storefront/images/placeholder-image.jpg'/>*/}
                          <img
                            alt="avatar"
                            className="rounded-circle mr-3"
                            src={
                              item.Photo
                                ? item.Photo !== undefined
                                  ? "data:image/png;base64, " + item.Photo
                                  : USER
                                : USER
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-middle ml-3">
                          <div className="d-inline-block">
                            {!item.SourceUser ? (
                              <h6 className="mb-1">{item.ResponsibleUser}</h6>
                            ) : (
                              <h6 className="mb-1">
                                {" "}
                                Atanan kişi : {item.TargetUser}
                              </h6>
                            )}
                            {item.SourceUser ? (
                              <p className="mb-0 tx-11 text-muted">
                                {new Date(item.StartDate).toLocaleDateString()}{" "}
                                - {new Date(item.EndDate).toLocaleDateString()}
                              </p>
                            ) : null}
                            <p className="mb-0 tx-13 text-muted">
                              Açıklama : {item.Description}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <div className="d-inline-block">
                          {type === "button" ? (
                            <button
                              className="btn btn-danger taskButton mb-1"
                              onClick={() =>
                                BB.askYesNo(
                                  "Zamanlanmış Görev İptali",
                                  "İptal etmek istediğinize eminmisiniz?",
                                  "Evet",
                                  "Hayır"
                                ).then((res) => {
                                  if (res === true) {
                                    BB.FetchJSON(
                                      "api/Task/DeleteRecurringTask?JobID=" +
                                        item.JobID
                                    ).then((result) => {
                                      if (result === true) {
                                        event.GetMyPeriodicTask();
                                        notify(
                                          "Zamanlanmış Görev Başarıyla Silinmiştir.",
                                          "success",
                                          2000
                                        );
                                      }
                                    });
                                  }
                                })
                              }
                            >
                              İptal Et
                            </button>
                          ) : (
                            <div className="d-flex align-items-center">
                              {!taskID && (
                                <button
                                  className="ml-1 btn btn-success taskButton mb-1"
                                  style={{ backgroundColor: "#2FC19A" }}
                                  onClick={() => {
                                    props.redirectToTask(item.TaskID);
                                  }}
                                >
                                  Talebe Git
                                </button>
                              )}

                              <button
                                className="ml-2 btn btn-danger taskButton mb-1 mr-2"
                                onClick={() => handleDetail(item.ID)}
                              >
                                Hareketler
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Empty title={t("Herhangibirveriyok")} />
            )}
          </TabPane>
        </TabContent>
      </div>
    </CustomCard>
  );
};

export default TableTaskTabs;

{
  /**/
}
