import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {ReactFormGenerator} from "react-form-builder2";
import {post} from "axios";
import notify from "devextreme/ui/notify";

const TaskViewForm = ({t, event}) =>
{
    const saveCustomFormValues = (values) => {
        
        const formData = new FormData();
        formData.append('TaskID', event.state.taskID);
        formData.append('FormID', event.state.selectedFormID);
        formData.append('Values', JSON.stringify(values));
debugger
        post('api/TaskDetail/AddCustomFormValues', formData, {headers: {'content-type': 'multipart/form-data'}})
            .then(res =>
            {
                if (res)
                {
                    notify(t("Basarılı!"), 'success', 2000);
                    event.getData();
                }
                else
                {
                    notify(t("hata"), 'error', 2000);
                }
                event.setState({previewVisible: false});
            });
    }
    
    return (
    
        <Modal
            isOpen={event.state.previewVisible}
            centered={true}
            className={"modal-lg"}
        >
            <ModalHeader toggle={() => event.setState({previewVisible: false})}>
                {event.state.selectedFormName}
            </ModalHeader>
            <ModalBody>
                <ReactFormGenerator
                    read_only={false}
                    form_method="POST"
                    hide_actions={false}
                    action_name={'Kaydet'}
                    back_action={''}
                    back_name={'Vazgeç'}
                    actionName={'Kaydet'}
                    answer_data={event.state.answer_data}
                    onSubmit={(e) => saveCustomFormValues(e)}
                    data={event.state.selectedForm}
                    submitButton={<button type={"submit"} className={"btn btn-primary"}>{t("kaydet")}</button>}
                    //dismissButton={<button type={"submit"} className={"btn btn-primary"}>Kaydet</button>}

                />
            </ModalBody>
        </Modal>
    )
}

export default TaskViewForm;
