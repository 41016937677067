import React from "react";
import CustomCard from "../Card/CustomCard";
import './styles.scss'

const SliderItem = ({title, description, subTitle, imgUri, onClick}) =>
{
   return (
      <CustomCard>
         <div className="d-flex no-block align-items-center" onClick={onClick}
              style={{cursor: onClick ? 'pointer' : null}}>
            <div>
               <span className="tx-13 mb-3" style={{fontWeight:'bold'}}>{title}</span>
               <span className="d-block tx-12 mb-2 text-muted" style={{fontSize:'12px'}}>{description}</span>
               <h3 className="m-0 mt-2 font-weight-bold">{subTitle}</h3>
            </div>
            <div className="ml-auto mt-auto">
               <img src={imgUri} className="wd-30 hd-30 mr-2" alt={title} height={40}/>
            </div>
         </div>
      </CustomCard>
   )
}

export default SliderItem;
