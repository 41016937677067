// import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend'
// import {initReactI18next} from 'react-i18next'

// i18n
//    .use(Backend)
//    .use(initReactI18next)
//    .init({
//       lng: 'tr',
//       backend: {
//          loadPath: '/assets/i18n/translations/{{lng}}.json'
//       },
//       fallbackLng: {
//          default: ['tr-TR'],
//       },
//       debug: false,
//       interpolation: {
//          escapeValue: false,
//          formatSeparator: ','
//       },
//       react: {
//          wait: true,
//          useSuspense: false
//       }
//    })

// export default i18n

// import i18n from 'i18next'
// import Backend from 'i18next-xhr-backend'
// import { initReactI18next } from 'react-i18next'

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     lng: 'tr',
//     backend: {
//       /* translation file path */
//       loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
//     },
//     fallbackLng: {
//                default: ['tr-TR'],
//              },
//     debug: true,
//     /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
//     ns: ['translations'],
//     defaultNS: 'translations',
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false,
//       formatSeparator: ','
//     },
//     react: {
//       wait: true
//     }
//   })

// export default i18n

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


import translationEN from "../src/Resources/translationEn.json";
import translationTR from "../src/Resources/translationTR.json";

const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  }
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: document.cookie.indexOf("en-EN")>-1?"en":"tr",
    fallbackLng: document.cookie.indexOf("en-EN")>-1?"en":"tr",   
     debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
