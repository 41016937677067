import React from 'react';
import {Button} from 'reactstrap'
import cn from 'classnames';
import './CustomButton.scss'

const CustomCard=({children,handleClick,cs,color,type})=>{
    return(
        <Button onClick={handleClick} className={cn(cs,'custom-button')} color={color} type={type}>
            {children}
        </Button>
    )
}
export default CustomCard;