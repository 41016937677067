import React, { createRef, PureComponent } from 'react';
import CustomParameters from "./CustomParameters";
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import CardBody from "reactstrap/es/CardBody";
import * as BB from '../../../../components/BBComponent'
import EmailEditor from 'react-email-editor';
import blank from './blank.json';
import { post } from "axios";
import notify from "devextreme/ui/notify";
import { Redirect } from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';


class EmailTemplateEditor extends PureComponent {
    constructor(props) {
        super(props);

        this.emailEditorRef = createRef();
        this.state = {
            selectedCompanyTemplates: [],
            modal: false,

            lastLoadTime: null,
            selectedCompanyID: undefined,
            selectedTemplateID: undefined,

            selectedID: this.props.location.state ? this.props.location.state.template ? this.props.location.state.template.ID : '00000000-0000-0000-0000-000000000000' : '00000000-0000-0000-0000-000000000000',
            selectedName: this.props.location.state ? this.props.location.state.template ? this.props.location.state.template.Name : null : null,
            selectedSubject: this.props.location.state ? this.props.location.state.template ? this.props.location.state.template.Subject : null : null,
            selectedHtmlBody: this.props.location.state ? this.props.location.state.template ? this.props.location.state.template.Body : null : null,
            selectedJsonBody: this.props.location.state ? this.props.location.state.template ? JSON.parse(this.props.location.state.template.JsonBody) : null : null,
        }
    }

    loadEditor(jsonData) {
        const currentTime = new Date().getTime();
        const loadThreshold = 100; // 0,1 saniye
        const { lastLoadTime } = this.state;
        if (!lastLoadTime || (currentTime - lastLoadTime) >= loadThreshold) {
            let { selectedJsonBody } = this.state;
            if (jsonData !== undefined) {
                if (this.emailEditorRef.current !== null && this.emailEditorRef.current.editor) {
                    this.emailEditorRef.current.editor.loadDesign(jsonData);
                } else {
                    setTimeout(() => {
                        this.emailEditorRef.current.editor.loadDesign(jsonData);
                    }, 3000);
                }
            } else {
                if (this.emailEditorRef.current !== null && this.emailEditorRef.current.editor) {
                    this.emailEditorRef.current.editor.loadDesign(selectedJsonBody);
                } else {
                    setTimeout(() => {
                        this.emailEditorRef.current.editor.loadDesign(selectedJsonBody);
                    }, 3000);
                }
            }
            this.setState({ lastLoadTime: currentTime });
        }
    }

    handleChange(id, value) {
        let url = 'api/Yonetim/EmailTemplate/GetList?CompanyID=' + value;
        id === 'selectedCompanyID' && BB.FetchJSON(url).then(res => {
            this.setState({ selectedCompanyTemplates: res.Data })
        });

        this.setState({ [id]: value });
    }

    fillEditorJsonBody() {
        let item = this.state.selectedCompanyTemplates.filter(x => x.ID === this.state.selectedTemplateID);
        if (item[0].JsonBody) {
            this.loadEditor(JSON.parse(item[0].JsonBody));
        }
        else {
            this.loadEditor(blank);
        }

        this.setState({ modal: false });
    }

    sendTestMail() {
        this.getEditorData().then(data => null);
    }

    saveDesign() {
        const formData = new FormData();
        let { selectedID, selectedName, selectedSubject } = this.state;

        BB.askYesNo(t("sablonukaydet"), t("yenisablonukaydetmekistediginizeeminmisiniz"), t("evet"), t("vazgec"))
            .then(res => {
                if (res) {
                    this.getEditorData().then(data => {
                        if (selectedName && selectedSubject) {
                            let item = {
                                ID: selectedID,
                                Name: selectedName,
                                Subject: selectedSubject,
                                Body: data.html,
                                JsonBody: JSON.stringify(data.design),
                            }
                            formData.append('jsonText', JSON.stringify(item));
                            post('api/Yonetim/EmailTemplate/Add', formData, { headers: { 'content-type': 'multipart/form-data' } }).then(res => {
                                if (res.status === 200) {
                                    notify(t("emailtemplatebasariylakaydedildi"), "success", 3000);
                                    this.setState({ turnBack: true });
                                }
                            });
                        }
                        else {
                            notify(t("lutfentanimvekonugiriniz"), 'warning', 3000);
                        }
                    })
                }
            })
    }

    getEditorData = () => new Promise((resolve) => {
        this.emailEditorRef.current.editor.exportHtml((data) => {
            resolve(data);
        });
    })

    render() {
        if (this.state.turnBack) {
            return <Redirect to={{ pathname: '/Yonetim/EmailTemplate' }} />
        }
        else {
            return (
                <div className='bg-white p-4'>
                    <Row>
                        <Col>
                            <Button
                                style={{ marginBottom: 10 }}
                                color={'success'}
                                onClick={() => this.setState({ turnBack: true })}
                            >{t("geri")}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3}>
                            <Card>
                                <CardBody>
                                    {BB.InputWithLabel('selectedName', t("sablontanimizorunlu"), 'text', this)}
                                    {BB.InputWithLabel('selectedSubject', t("mailkonusubasligi"), 'text', this)}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CustomParameters />
                                </CardBody>
                            </Card>
                            <Button
                                block
                                color={'warning'}
                                onClick={() => this.setState({ modal: true })}
                            >
                                {t("baskasablondangetir")}
                            </Button>
                            <Modal isOpen={this.state.modal}>

                                <ModalHeader>{t("sablonkopyala")}</ModalHeader>

                                <ModalBody>
                                    {BB.DxDropDownWithURL('selectedCompanyID', t("firma"), this, '/api/Common/GetMainCompanyDropDownList', t("lutfenfirmaseciniz"), false)}
                                    {BB.DxDropDownWithJSON('selectedTemplateID', t("emailsablonu"), this, this.state.selectedCompanyTemplates)}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => this.setState({ modal: false })}>{t("iptal")}</Button> {' '}
                                    <Button color="primary" onClick={() => this.fillEditorJsonBody()}>{t("yukle")}</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                        <Col xl={9}>
                            <EmailEditor
                                ref={this.emailEditorRef}
                                minHeight={window.screen.height - 200}
                                onLoad={() => this.loadEditor()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='float-right mt-4'>
                            <Button
                                className='float-right m-1'
                                color='success'
                                onClick={() => this.saveDesign()}>
                                {t("tasarimikaydet")}
                            </Button>
                            <Button
                                className='float-right m-1'
                                color='primary'
                                onClick={() => this.sendTestMail()}>
                                {t("testmailigonder")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

export default withTranslation()(EmailTemplateEditor);
