import * as actionTypes from '../actions/actionTypes'
import taskInitialState from "../initialData/taskInitialState";

export function taskReducer(state = taskInitialState.allTasks, action)
{
   switch (action.type)
   {
      case actionTypes.SET_TASK_TRACKING_DATA:
         return action.payload
      default:
         return state
   }
}

export function ownTaskReducer(state = taskInitialState.ownTasks, action)
{
   switch (action.type)
   {
      case actionTypes.SET_OWN_TASK_DATA:
         return action.payload
      default:
         return state
   }
}

// export function ownConfirmReducer(state = taskInitialState.ownConfirm, action)
// {
//    switch (action.type)
//    {
//       case actionTypes.SET_OWN_CONFIRM_DATA:
//          return action.payload
//       default:
//          return state
//    }
// }

export function myTaskCreateTemplatesReducer(state = [], action)
{
   switch (action.type)
   {
      case actionTypes.SET_MY_TASK_CREATE_TEMPLATE:
         return action.payload
      default:
         return state
   }
}

