import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row,Label, Input, InputGroup, ListGroup, ListGroupItem} from 'reactstrap';
import { FormGroup } from "semantic-ui-react";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import {post} from "axios";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class TaskEmail extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         editRowVisible: false,
         addRowVisible: false,
         selectedID: '',
         companyList: [],

         selectedTemplate: '',
         selectedCompany: '',
         selectedCategory: [],
         selectedStatu: '',
         selectedSendToTaskCreator: false,
         selectedToList: '',
         selectedToCC: '',
         selectedToBCC: '',
         categoryList: [],
         TaskEmailStatus : [],
         TaskEmailTemplate:[],
         companySelectToolListVisible:false,
         companySelectTool:'',
         taskCompaniesTemp: [],
      }
      this.treeView = null;
      this.treeDataSource = BB.makeAsyncDataSource('/api/Yonetim/TaskEmail/GetCategory');
   }

   componentDidMount()
   {
      this.getData();
   }

   getData()
   { BB.FetchJSON('api/Yonetim/TaskEmail/GetCompany').then(datacomp =>
      {
     
      BB.FetchJSON('api/Yonetim/TaskEmail/GetData').then(data =>
      {
         BB.FetchJSON('/api/Yonetim/TaskEmail/GetCategory').then(categoryList =>
         {
            BB.FetchJSON('/api/Yonetim/TaskEmail/GetStatus').then(statusList =>
            {
               BB.FetchJSON('/api/Yonetim/TaskEmail/GetTemplate').then(templateList =>
                  {
                     this.setState({gridData: data.Data, categoryList: categoryList,TaskEmailStatus : statusList,TaskEmailTemplate : templateList,companyList:datacomp})
                     this.props.actions.isLoading(false);
                  });
            });
         });
      });
   })
   }

   add()
   {
      if ((this.state.selectedTemplate === undefined || this.state.selectedTemplate) === '' && (this.state.selectedStatu === undefined || this.state.selectedStatu === ''))
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            EmailTemplateID: this.state.selectedTemplate,
            CompanyID: this.state.selectedCompany,
            CategoryID: this.state.selectedCategory,
            StatusID: this.state.selectedStatu,
            SendToTaskCreator: this.state.selectedSendToTaskCreator,
            EmailList: this.state.selectedToList,
            CCEmailList: this.state.selectedToCC,
            BCCEmailList: this.state.selectedToBCC
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', null)
         formData.append('CategoryIDs', entity.CategoryID);

         post('api/Yonetim/TaskEmail/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("baglantıolusturuldu"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedMasterPoolID: null
               });
               notify(t("baglantıolusturmahata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }
   onClickFilter  (filter)  {
      if (filter.ID !== '') {
          this.setState({ companySelectTool: filter.Name })
          this.handleChange('selectedCompanyID', filter.ID)
          this.setState({ companySelectToolListVisible: false });
          this.setState({ selectedCompany: filter.ID });

          
      }
  } 
  companyFilter  (e){
   this.setState({ companySelectToolListVisible: true });
   var companies = this.state.companyList
   if (this.state.companySelectTool.length > 2) {
       //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

       //   

       var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase()));

       this.setState({ taskCompaniesTemp: filteredCompanies });
       //})
   } else {
       let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase())).slice(0,30);
       let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: t("dahafazlafirmaicinenazuckaraktergirmelisiniz"), ParentID: null };
       tempCompanies = tempCompanies.concat(itemdef);
       this.setState({ taskCompaniesTemp: tempCompanies });
   }

}
   edit()
   {
      if ((this.state.selectedTemplate === undefined || this.state.selectedTemplate) === '' && (this.state.selectedStatu === undefined || this.state.selectedStatu === ''))
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            ID: this.state.selectedID,
            EmailTemplateID: this.state.selectedTemplate,
            CompanyID: this.state.selectedCompany,
            CategoryID: this.state.selectedCategory,
            StatusID: this.state.selectedStatu,
            SendToTaskCreator: this.state.selectedSendToTaskCreator,
            EmailList: this.state.selectedToList,
            CCEmailList: this.state.selectedToCC,
            BCCEmailList: this.state.selectedToBCC
         }

         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', entity.ID);
         formData.append('CategoryIDs', entity.CategoryID);

         post('api/Yonetim/TaskEmail/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  editPoolVisible: false,
                  selectedName: ''
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("baglantiguncellendi"), "success", 1000);
            }
            else
            {
               this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
               notify(t("baglantiguncellemedehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   delete(ID)
   {
      this.props.actions.isLoading(true);

      fetch('api/Yonetim/TaskEmail/Delete?ID=' + ID, {method: 'GET'})
      .then((response) => response.json())
      .then((res) =>
      {
         if (res.MessageStatus === true)
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''}, this.getData());
            this.props.actions.isLoading(false);
            notify(t("baglantibasariylasilindi"), "success", 1000);
         }
         else
         {
            this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
            notify(t("baglantisilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
       if (id === 'selectedSendToTaskCreator')
       {
          this.setState({selectedSendToTaskCreator: !this.state.selectedSendToTaskCreator})
       }
   }

   actionButton = (cellData) =>
   {

      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({
                     editRowVisible: true,
                     selectedID: cellData.data.ID,
                     selectedTemplate: cellData.data.TemplateID,
                     // selectedCompany: cellData.data.CompanyID,
                     selectedStatu: cellData.data.StatusID,
                     selectedCategory: cellData.data.Category,
                     selectedSendToTaskCreator: cellData.data.SendToTaskCreator,
                     selectedToList: cellData.data.EmailList ? cellData.data.EmailList : '',
                     selectedToCC: cellData.data.CCEmailList ? cellData.data.CCEmailList : '',
                     selectedToBCC: cellData.data.BCCEmailList ? cellData.data.BCCEmailList : '',
                  });
               }}>
               {t("düzenle")}
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizbaglantisilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>
                     <Button
                        color={'success'}
                        outline
                        onClick={() => this.setState({
                           addRowVisible: true,
                           selectedTemplate: '',
                           selectedCompany: '',
                           selectedCategory: [],
                           selectedStatu: '',
                           selectedSendToTaskCreator: false,
                           selectedToList: '',
                           selectedToCC: '',
                           selectedToBCC: '',
                        })}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button">{t("yeni")}</Button>

                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName="Talep Mail Listesi"/>

                        <Column
                           dataField={'ID'}
                           visible={false}/>
                        <Column
                           dataField={'StatusName'}
                           caption={t("durum")}
                           sortOrder={'asc'}
                           minWidth={100}
                           allowFiltering={true}/>
                        <Column
                           dataField={'TemplateName'}
                           caption={t("sablon")}
                           minWidth={100}
                           allowFiltering={true}/>
                        <Column
                           dataField="CompanyName"
                           caption={t("kurum")}
                           minWidth={50}
                           allowFiltering={true}/>
                        <Column
                           dataField={'EmailList'}
                           caption="To"
                           minWidth={80}/>

                        <Column
                           dataField={'CCEmailList'}
                           caption="CC"
                           minWidth={80}
                           allowFiltering={true}/>
                        <Column
                           dataField={'SendToTaskCreator'}
                           caption={t("sahibinibilgilendir")}
                           minWidth={50}
                           allowFiltering={true}/>
                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={250}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Yeni Satır Ekleme Popup*/}
            <Modal
               isOpen={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               width={metrics.popupMinWidth}
               height={660}
            >
               <ModalHeader>{t("yenibaglantitanimi")}</ModalHeader>
               <ModalBody>
                  <form>
                     {BB.DxDropDownWithURL('selectedTemplate', t("emailsablonu"), this, "/api/Yonetim/TaskEmail/GetTemplate", t("emailsablonu"))}

                     {/* {BB.DxDropDownWithURL('selectedCompany', 'Kurum', this, "/api/Yonetim/TaskEmail/GetCompany", "Kurum")} */}
                     <FormGroup>
                    <Label>
                        <strong>{t("firmaListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={this.state.companySelectTool} type="text" autoFocus={true} name={'selectedCompany'} onChange={(e) => { this.setState({ companySelectTool: e.target.value  }, this.companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px', display: this.state.companySelectToolListVisible ? 'block' : 'none' }}>
                        {
                            this.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === this.state.selectedCreateUserCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={()=>this.onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {/* {BB.DxSelectBoxWithJSON('selectedCreateUserCompanyID', 'Kurum Seçiniz', event, event.state.taskCompanies)}*/}
                    {/* {BB.DxMultipleDropdown('selectedPoolUsers', 'Firma Kullanıcıları', this, this.state.companyUserList)} */}

                </FormGroup>

                     {BB.DxMultipleDropdown('selectedCategory', t("kategori"), this, this.state.categoryList)}

                     {BB.DxDropDownWithURL('selectedStatu', t("durum"), this, "/api/Yonetim/TaskEmail/GetStatus", t("durum"))}
                     {BB.CheckBoxWithLabel('selectedSendToTaskCreator', t("sahibinibilgilendir"), this)}
                     {BB.InputWithLabel('selectedToList', t("tolistesi"), 'text', this)}
                     {BB.InputWithLabel('selectedToCC', t("cclistesi"), 'text', this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({
                              addRowVisible: false,
                           })}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </ModalBody>

            </Modal>

            {/*Düzenle Popup*/}
            <Modal
               isOpen={this.state.editRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               width={metrics.popupMinWidth}
               height={660}
            >
               <ModalHeader>{t("kategoriduzenle")}</ModalHeader>
               <ModalBody>
                  {/* {BB.DxDropDownWithURL('selectedTemplate', 'Mail Şablonu', this, "/api/Yonetim/TaskEmail/GetTemplate", "Mail Şablonu")} */}
                  {BB.DxDropDownWithURL2('selectedTemplate', t("emailsablonu"), this, this.state.TaskEmailTemplate, t("durumseciniz"))}
                  
                  {BB.DxDropDownWithURL('selectedCompany', t("kurum"), this, "/api/Yonetim/TaskEmail/GetCompany", t("kurum"))}

                  {BB.DxMultipleDropdown('selectedCategory', t("kategori"), this, this.state.categoryList)}

                  {BB.DxDropDownWithURL2('selectedStatu', t("durum"), this, this.state.TaskEmailStatus, t("durumseciniz"))}

                  {BB.CheckBoxWithLabel('selectedSendToTaskCreator', t("sahibinibilgilendir"), this)}
                  {BB.InputWithLabel('selectedToList', t("tolistesi"), 'text', this)}
                  {BB.InputWithLabel('selectedToCC', t("cclistesi"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                     <Button
                        color={'info'}
                        onClick={() => this.setState({
                           editRowVisible: false,
                        })}>
                        {t("kapat")}
                     </Button>

                     <Button
                        onClick={() => this.edit()}
                        color={'primary'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </ModalBody>

            </Modal>
         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskEmail));
