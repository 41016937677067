import * as actionTypes from '../actions/actionTypes'

export default function getTaskAssignedReducer(state = {}, action)
{
    switch (action.type)
    {
        case actionTypes.GET_TASK_ASSIGNED:
            return action.payload
        default:
            return state
    }
}
