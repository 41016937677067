import React, {Component} from 'react';
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {Button} from "reactstrap";

let answers = [];

class MailSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridData: undefined,
            QuestionsAll: [],
            selectedTaskID: '',
            selectedTaskSurveyID: '',
            loading: false,
            DbNameURL: '',
            UserIDURL: '',
            SurveyIDURL: '',
        };
        this.answer = this.answer.bind(this);
    }

    componentDidMount() {

        const urlData = this.props.location.search.replace('?', '').split('&');
        this.setState({
            DbNameURL: urlData[0].split('=')[1],
            UserIDURL: urlData[1].split('=')[1],
            SurveyIDURL: urlData[2].split('=')[1]
        })

        this.getData(urlData[0].split('=')[1], urlData[1].split('=')[1], urlData[2].split('=')[1]);
    }

    getData(DbName, UserID, SurveyID) {
        BB.FetchJSON(`api/TaskSurvey/GetDataForMail?DbName=${DbName}&UserID=${UserID}&SurveyID=${SurveyID}`, true).then(data => {
            if (data !== null) {
                this.setState({gridData: data[0]});
                this.answer(this.state.gridData.ID);
                this.setState({
                    selectedTaskID: this.state.gridData.TaskNumber.toString(),
                    selectedTaskSurveyID: this.state.gridData.ID
                });
            } else {
                this.setState({gridData: null});
            }
        });
    }

    answer(ID) {
        this.setState({loading: true});
        BB.FetchJSON('api/TaskSurvey/TaskAnswer?ID=' + ID).then(data => {
            var questions = [];

            for (var i = 0; i < data.Survey.SurveyQuestion.length; i++) {
                answers.push({
                    q: i,
                    a: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option
                });
                questions.push({
                    "Question": data.Survey.SurveyQuestion[i].Question,
                    "Answer1": data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
                    "Answer2": data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
                    "Answer3": data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
                    "Answer4": data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
                })
            }
            this.setState({QuestionsAll: questions, loading: false})

        });
    }

    changeSelectionPriority(e) {
        let q = e.target.id.slice(0, 1);
        let a = e.target.value;
        answers.map((item, index) => {
            if (q == item.q) {
                answers.splice(index, 1);
            }
        });
        answers.push({
            q: parseInt(q),
            a: a
        });

    }

    answerSave() {
        this.setState({loading: true})
        const formData = new FormData();
        formData.append('jsonText', JSON.stringify(answers));
        formData.append('TaskSurveyID', this.state.selectedTaskSurveyID);
        formData.append('TaskID', this.state.selectedTaskID);

        post('api/TaskSurvey/TaskAnswer', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
        })
            .then(res => {
                if (res.data.MessageStatus === true) {
                    this.setState({
                        loading: false,
                    });
                    notify('Anket Tamamlandı!', "success", 1000);
                    this.getData(this.state.DbNameURL, this.state.UserIDURL, this.state.SurveyIDURL);
                } else {
                    this.setState({
                        loading: false,
                    });
                    notify('Anket Oluşturma Hata! : ' + res.Message, "warning", 1000);
                }
            });
    }

    render() {
        return (
            <>
                {
                    this.state.loading
                        ?
                        <div className="loading-hourglass-container">
                            <div className="loading-hourglass"></div>
                        </div>
                        :
                        this.state.gridData === null
                            ?
                            <div className="mail-survey-container">
                                <h2>Anket tamamlanmıştır!</h2>
                            </div>
                            :
                            <div className="mail-survey">
                                <h2>{`${this.state.selectedTaskID} numaralı talep anketi`}</h2>

                                <div className="survey-list">
                                    {
                                        this.state.QuestionsAll.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <p>
                                                        <div className="survey-index">{index + 1}</div>
                                                        {item.Question}
                                                    </p>
                                                    {/*<RadioGroup id={index} items={answer} defaultValue={answer[0]} layout="horizontal"*/}
                                                    {/*            onValueChanged={event.changeSelectionPriority}/>*/}
                                                    <div className="rating">
                                                        <input type="radio" id={`${index}-star4`}
                                                               name={`${index}-rating`} value={item.Answer1}
                                                               onChange={this.changeSelectionPriority}/>
                                                        <label className="star" htmlFor={`${index}-star4`}
                                                               title={item.Answer1} aria-hidden="true"> </label>
                                                        <input type="radio" id={`${index}-star3`}
                                                               name={`${index}-rating`} value={item.Answer2}
                                                               onChange={this.changeSelectionPriority}/>
                                                        <label className="star" htmlFor={`${index}-star3`}
                                                               title={item.Answer2} aria-hidden="true"> </label>
                                                        <input type="radio" id={`${index}-star2`}
                                                               name={`${index}-rating`} value={item.Answer3}
                                                               onChange={this.changeSelectionPriority}/>
                                                        <label className="star" htmlFor={`${index}-star2`}
                                                               title={item.Answer3} aria-hidden="true"> </label>
                                                        <input type="radio" id={`${index}-star1`}
                                                               name={`${index}-rating`} value={item.Answer4}
                                                               onChange={this.changeSelectionPriority}/>
                                                        <label className="star" htmlFor={`${index}-star1`}
                                                               title={item.Answer4} aria-hidden="true"> </label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <Button
                                    onClick={() => this.answerSave()}
                                    color={'primary'}
                                    style={{marginLeft: 20, marginRight: 0}}>
                                    Kaydet
                                </Button>
                            </div>
                }
            </>

        );
    }
}

export default MailSurvey;
