import React, {Component} from 'react';
import {CardBody, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import {useTranslation} from "react-i18next";
import * as BB from '../../../components/BBComponent'
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as activityActions from "../../../redux/actions/activityActions";
import Accordion from 'devextreme-react/accordion';
import {ReactComponent as Folder} from '../../../assets/img/brand/folder.svg';
import {CustomCard, LabelGroup} from "../../../components/custom";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

class SSS extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: [],
         collapsible: false,
         searchField: ''
      };
      this.onHandleChange = this.onHandleChange.bind(this);
   }

   componentDidMount()
   {
      this.getData();
   }

   getData()
   {
      let URL = 'api/SSS/GetData';
      BB.FetchJSON(URL).then(data =>
      {
         this.setState({gridData: data})
      });
   }

   onHandleChange = (e) =>
   {
      this.setState({searchField: e.target.value})
   }

   downloadTaskDocument(URL, fileName)
   {
      //TODO : Buradaki methotta incelenecek

      this.props.actions.isLoading(true);

      // fetch(URL).then(response =>
      // {
      //    this.props.actions.isLoading(false);
      //
      //    response.blob().then(blob =>
      //    {
      //
      //       let url = window.URL.createObjectURL(blob);
      //       let a = document.createElement('a');
      //
      //       a.href = url;
      //       a.download = fileName;
      //       a.click();
      //    });
      //    // window.location.href = response.url;
      // });


      BB.FetchJSON(URL).then(response =>
      {
         this.props.actions.isLoading(false);

         response.blob().then(blob =>
         {

            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            a.href = url;
            a.download = fileName;
            a.click();
         });
      });
   }

   render()
   {
      return (
         <Render
            ths={this}
         />
      );
   }
}

export const Render = (ths) =>
{
   const {t} = useTranslation();
   const event = ths.ths;
   const filteredSSS = event.state.gridData.filter(item =>
   {
      const query = event.state.searchField.toLowerCase();
      return (
         item.Title.toLowerCase().indexOf(query) >= 0 ||
         item.Body.toLowerCase().indexOf(query) >= 0
      )
   });
   return (
      <div>
         <Row>
            <Col xs="12" sm="12">
               <CustomCard cardStyle='card-dashboard-calendar pb-0'>
                  <LabelGroup title={t('SSS')}
                              description={''}/>

                  <CardBody>
                     <FormGroup>
                        <InputGroup>
                           <Input type="text"
                                  name="search-field"
                                  placeholder={t('ara')}
                                  autoComplete="name"
                                  onChange={event.onHandleChange}
                           />
                           <InputGroupAddon addonType="append">
                              <InputGroupText><i className="icon-magnifier"></i></InputGroupText>
                           </InputGroupAddon>
                        </InputGroup>
                     </FormGroup>
                     <Accordion
                        dataSource={filteredSSS}
                        collapsible={event.state.collapsible}
                        itemTitleRender={CustomTitle}
                        itemRender={CustomItem}
                     />
                  </CardBody>
               </CustomCard>
            </Col>
         </Row>
      </div>
   );
};

export const CustomTitle = (data) =>
{
   return (
      <h6>{data.Title}</h6>
   );
}

export const CustomItem = (data) =>
{
   return (
      <Row>
         <Col>
            <div>
               <p>
                  <span>{data.Body}</span>
               </p>
               <p>
                  <span>{data.IsActive}</span>
                  <span>{data.IsLink}</span>
               </p>
            </div>
            <div>
               <div style={{display: 'flex'}}>
                  {
                     data.SssDocument.map((item, inx) => (
                        <div key={inx} className='folder-content'>
                           <Folder/>
                           <a href={item.FilePath} download>{item.DocumentTitle}</a>
                        </div>
                     ))
                  }

               </div>
            </div>
         </Col>
      </Row>
   );
}

function mapStateToProps(state)
{
   return {
      allActivities: state.activityReducer
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         getAllActivities: bindActionCreators(activityActions.getAllActivities, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(SSS)
 );


