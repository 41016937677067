import React, { Component } from "react";
import { Button, Card, CardBody, Col, Row, TextBox } from "reactstrap";
import DataGrid, {
  Column,
  StateStoring,
  Export,
  ColumnChooser,
  FilterRow,
  FilterPanel,
  Pager,
  HeaderFilter,
  Sorting,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as BB from "../../../components/BBComponent";
import ScrollView from "devextreme-react/scroll-view";
import metrics from "../../../common/metrics";
import * as loadingActions from "../../../redux/actions/loadingActions";
import TagBox from "devextreme-react/tag-box";
import { Popup } from "devextreme-react/popup";
import { callAPI, callApiGet } from "../../../components/Axios";
import notify from "devextreme/ui/notify";
import { post } from "axios";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

class UserProcess extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isTableLoading: false,
      gridData: [],
      addRowVisible: false,
      newName: "",
      newSurname: "",
      newEmail: "",
      newGSM: "",
      newPhoneNumber: "",
      newInternalNumber: "",
      newDepartment: "",
      newJob: "",
      newRole: "",
      newManager: "",
      newPermissionPackage: [],
      newIsManager: false,
      selectedIsGM: false,
      newIsGM: false,
      newPhoto: "",
      newCompany: undefined,
      newUserPool: [],
      detailData: [],
      companyDetailsData: [],
      selectedIsManager: false,
      selectedIsChangeUser: false,
      selectedSearchable: false,
      selectedIsActive: false,
      gridData1: undefined,
      editRowVisible: false,
      detailData: [],

      oldUserID: undefined,
      oldName: undefined,
      oldSurname: undefined,
      oldEmail: undefined,
      oldGSM: undefined,
      oldPhoneNumber: undefined,
      oldInternalNumber: undefined,
      oldDepartment: undefined,
      oldJob: undefined,
      oldRole: undefined,
      oldIsManager: undefined,
      oldManager: undefined,
      oldPermissonPackage: undefined,
      oldIsGM: undefined,
      oldIsActive: undefined,
      oldSearchable: undefined,
      oldIsChangeUser: undefined,
      oldIsUserPool: undefined,
      oldGroup: undefined,
      oldCompanyID: undefined,
      gruops:undefined,
      searchText: undefined,
      newDomainName : "",
    };
    this.getDataUser = this.getDataUser.bind(this);
    this.handleNewIsGMCheckBox = this.handleNewIsGMCheckBox.bind(this);
    this.handleNewIsManagerCheckBox =
      this.handleNewIsManagerCheckBox.bind(this);
    // this.refreshDataGrid = this.refreshDataGrid.bind(this);
    this.getRef = this.getRef.bind(this);
    this.handleSelectedIsActiveCheckBox =
      this.handleSelectedIsActiveCheckBox.bind(this);
    this.handleSelectedIsSearch = this.handleSelectedIsSearch.bind(this);
    this.handleSelectedIsChangeUser =
      this.handleSelectedIsChangeUser.bind(this);
      this.handleSelectedIsGMCheckBox =
      this.handleSelectedIsGMCheckBox.bind(this);
    this.handleSelectedIsManagerCheckBox =
      this.handleSelectedIsManagerCheckBox.bind(this);
 
    
  }

  componentDidMount() {
    this.getDataUser("abcd");
     BB.FetchJSON("api/Yonetim/Group/GetAllGroup").then((data) => {
      this.setState({ gruops: data });
    });
  }
  // componentDidMount() {
  //   this._isMounted = true;
  //   if (window.location.hash.includes("#!/Yonetim/UserProcess")) {
  //    // this.getAllUsers();
  //   }
  // }
  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  //Functions

  getDetailsData() {
    callAPI(
      callApiGet,
      "/api/Yonetim/Users/GetDetail",
      {},
      (res) => {
        if (res.data) {
          this.setState({ detailData: res.data });
        }
      },
      true
    );
  }

  getCompanyDetails() {
    if (this.state.newCompany === undefined) {
      return notify(
        t("kurumalaninidoldurmanizgerekmektedir"),
        "warning",
        3000
      );
    } else {
      callAPI(
        callApiGet,
        "/api/Yonetim/Users/GetCompanyDetails?CompanyID=" +
          this.state.newCompany,
        {},
        (res) => {
          if (res.data) {
            this.setState({ companyDetailsData: res.data });
          }
        },
        true
      );
    }
  }
  getCompanyDetailsForUpdate() {
    if (this.state.oldCompanyID === undefined) {
      return notify(
        t("kurumalaninidoldurmanizgerekmektedir"),
        "warning",
        3000
      );
    } else {
      callAPI(
        callApiGet,
        "/api/Yonetim/Users/GetCompanyDetails?CompanyID=" +
          this.state.oldCompanyID,
        {},
        (res) => {
          if (res.data) {
            this.setState({ companyDetailsData: res.data });
          }
        },
        true
      );
    }
  }
  
  getAllUsers() {
    this.props.actions.isLoading(true);
    callAPI(
      callApiGet,
      "/api/Yonetim/Users/GetAllUser",
      {},
      (res) => {
        debugger;
        this.setState({ gridData1: res.data });
        this.props.actions.isLoading(false);
      },
      true
    );
  }

  // refreshDataGrid=(e)=> {
  //   let fakearray=[]
  //   e.map((item, i) => {
  //     this.dataGrid._instance.getDataSource()._items.push(item)
  //   });

  //   //this.dataGrid.instance.option('dataSource',e );
  //   //this.dataGrid._instance.reload();
  //  // dataGridInstance.option('dataSource', yourDataSource);
  // }

  getRef(ref) {
    this.dataGrid = ref;
  }

  async getDataUser(e) {

    this.setState({searchText:e})
    
    if(e.length===0){
      this.setState({ isTableLoading: true });
      await callAPI(
        callApiGet,
        "/api/Yonetim/Users/GetStarstwitUserForThreeWords?word=" + e,
        {},
        (res) => {
          if (res.data) {
            //this.refreshDataGrid(res.data)
            this.setState({ gridData: res.data });
          }
        },
        true
      );
      this.setState({ isTableLoading: false });
    }
   else if(e.length>2){
    this.setState({ isTableLoading: true });

    await callAPI(
      callApiGet,
      "/api/Yonetim/Users/GetStarstwitUserForThreeWords?word=" + e,
      {},
      (res) => {
        if (res.data) {
          //this.refreshDataGrid(res.data)
          this.setState({ gridData: res.data });
        }
      },
      true
    );
    this.setState({ isTableLoading: false });

  }
  else{
    notify(t("minimumuckaraktergirmelisiniz"), "warning", 2000);

  }
  }

  addSelectedPoolUsers2 = (e) => {
    this.setState({
      newUserPool: e.value,
    });
  };

  addNewUser() {
    let { newName, newSurname, newEmail, newGSM, newDepartment, newRole } =
      this.state;

    if (
      newName === "" ||
      newName === undefined ||
      newSurname === "" ||
      newSurname === undefined ||
      newEmail === "" ||
      newEmail === undefined ||
      newGSM === "" ||
      newGSM === undefined ||
      newDepartment === "" ||
      newDepartment === undefined ||
      newRole === "" ||
      newRole === undefined
    ) {
      notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
    } else {
      if (
        String(this.state.newRole) === "a6957f7c-53cc-e811-80f6-005056b839bb"
      ) {
        //destek rolü

        if (this.state.newUserPool.length === 0) {
          return notify(
            t("destekrolundekikullanicilaricinhavuzalanizorunludur"),
            "warning",
            3000
          );
        }
      }

      // kişi kendisinin yöneticisi olmaması için
      var name = this.state.newName.toUpperCase();
      var surname = this.state.newSurname.toUpperCase();
      var userName = name + " " + surname;
      var managers = this.state.detailData.Managers;
      var managerName = "";

      managers.forEach((element) => {
        if (this.state.newManager === element.ID) {
          managerName = element.Name;
        }
      });

      if (managerName === userName) {
        return notify(
          t("secilenkisikendisininyoneticisiolamaz"),
          "warning",
          2000
        );
      }

      let user = {
        Name: this.state.newName,
        SurName: this.state.newSurname,
        Email: this.state.newEmail,
        Phone: this.state.newPhoneNumber,
        InternalNumber: this.state.newInternalNumber
          ? this.state.newInternalNumber
          : 0,
        MobilPhone: this.state.newGSM,
        CompanyDepartmentID: this.state.newDepartment,
        CompanyDepartmentJobID: this.state.newJob,
        ManagerID: this.state.newManager,
        RoleID: this.state.newRole,
        CompanyID: this.state.newCompany,
        Photo: null,
        //IsActive: true,
        LangID: 0,
        UserName: this.state.newEmail,
        Password: "1111",

        IsActive: this.state.selectedIsActive,
        IsGM: this.state.newIsGM,
        AllowSearchTask: this.state.selectedSearchable,
        AllowChangeUser: this.state.selectedIsChangeUser,
          IsManager: this.state.newIsManager,
          DomainName: this.state.newDomainName,
      };

      let permissionArr = [];
      let userPoolArr = [];

      this.state.newPermissionPackage.map((item, i) => {
        let permissionData = this.state.detailData.PermissionPackages.filter(
          (x) => x.ID == item
        )[0];
        permissionArr.push(permissionData);
      });

      this.state.newUserPool.map((item, i) => {
        let poolData = this.state.detailData.Pools.filter(
          (x) => x.ID == item
        )[0];
        userPoolArr.push(poolData);
      });

      this.props.actions.isLoading(true);

      let entity = {
        User: user,
        RolePackages: permissionArr,
        UserPools: userPoolArr,
      };

      const formData = new FormData();
      formData.append("file", this.state.newPhoto);
      formData.append("jsonText", JSON.stringify(entity));

      if (this.state.selectedGroup !== undefined) {
        formData.append("userGroup", this.state.selectedGroup);
      }

      post("api/Yonetim/Users/Add", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.data.MessageStatus === true) {
            this.clrState();
            this.getAllUsers();

            this.props.actions.isLoading(false);
            this.setState({ addRowVisible: false });
            notify(t("islemBasarili"), "success", 1000);
          } else {
            this.clrState();
            this.props.actions.isLoading(false);
            notify(t("islemBasarisiz") + res.data.Message, "warning", 1000);
          }
        })
        .catch((ex) => {
          this.props.actions.isLoading(false);
        });
    }
  } 
   CheckPermissionForTransferPool =(poolID,userID)=>{
    callAPI(
   callApiGet,
   'api/Yonetim/Users/CheckPermissionForTransferPool?poolID='+poolID+"&userID=" +userID,
   {}
   ,
    (res) => {
     if (res?.data >0) {
       BB.askYesNo( res.data,t("adetsecilenkullanicininhavuzundasorumluoldugukapanmistaleplervardir"), t("tamam"), t("vazgec")).then(ask => {
         if (ask === true) {

         }})
     

     }
   },
   true
 );
 }

  //HandleEvents
  handleSelectedIsActiveCheckBox(e) {
    if (this.state.oldIsActive) {
      this.setState({ oldIsActive: false });
    } else {
      this.setState({ oldIsActive: true });
    }
  }
  handleSelectedIsSearch(e) {
    if (this.state.oldSearchable) {
      this.setState({ oldSearchable: false });
    } else {
      this.setState({ oldSearchable: true });
    }
  }
  handleSelectedIsChangeUser(e) {
    if (this.state.oldIsChangeUser) {
      this.setState({ oldIsChangeUser: false });
    } else {
      this.setState({ oldIsChangeUser: true });
    }
  }

  handleNewIsGMCheckBox(e) {
    if (this.state.oldIsGM) {
      this.setState({ oldIsGM: false });
    } else {
      this.setState({ oldIsGM: true });
    }
  }
  handleNewIsManagerCheckBox(e) {
    if (this.state.oldIsManager) {
      this.setState({ oldIsManager: false });
    } else {
      this.setState({ oldIsManager: true });
    }
  }

  setMultiSelectChange = (event) => {
    this.setState({
      oldPermissonPackage: event.value,
    });
  };
  setMultiSelectChange3 = (event) => {
    this.setState({
      oldManager: event.value.ID,
    });
  };
  
setMultiSelectChange2 = (event) => {
    "previousValue"
    //CheckPermissionForTransferPool
    this.CheckPermissionForTransferPool(event.previousValue[0],this.state.oldUserID)
    this.setState({
      oldIsUserPool: event.value,
    });
  };
  handleSelectedIsGMCheckBox(e) {
    this.setState({ oldIsGM: !this.state.oldIsGM });
  }

  handleSelectedIsManagerCheckBox(e) {
    this.setState({ oldIsManager: !this.state.oldIsManager });
  }

  handleSelectedIsActiveCheckBox(e) {
    this.setState({ oldIsActive: !this.state.oldIsActive });
  }

  handleSelectedIsSearch(e) {
    this.setState({ oldSearchable: !this.state.oldSearchable });
  }

  handleSelectedIsChangeUser(e) {
    this.setState({ oldIsChangeUser: !this.state.oldIsChangeUser });
  }
  handleChange(id, value) {
    if (id === "newCompany") {
      this.setState({ newCompany: value });
      this.getCompanyDetails();
    }
    if (id === "newName") {
      this.setState({ newName: value });
    }
    if (id === "newSurname") {
      this.setState({ newSurname: value });
    }
    if (id === "newEmail") {
      this.setState({ newEmail: value });
    }
    if (id === "newGSM") {
      this.setState({ newGSM: value });
    }
    if (id === "newPhoneNumber") {
      this.setState({ newPhoneNumber: value });
    }
    if (id === "newInternalNumber") {
      this.setState({ newInternalNumber: value });
    }
    if (id === "newDepartment") {
      this.setState({ newDepartment: value });
    }
    if (id === "newJob") {
      this.setState({ newJob: value });
    }
    if (id === "newRole") {
      this.setState({ newRole: value });
    }
    if (id === "newManager") {
      this.setState({ newManager: value });
    }
    if (id === "newIsManager") {
      this.setState({ newIsManager: value });
    }
    if (id === "oldCompanyID") {
      this.setState({ oldCompanyID: value });
      this.getCompanyDetailsForUpdate();
    }
    if(id==="oldDepartment"){
      this.setState({ oldDepartment: value });
    }
    if(id==="oldJob"){
      this.setState({ oldJob: value });
    }
    if(id==="oldRole"){
      this.setState({ oldRole: value });
    }
    if(id==="oldGroup"){
      this.setState({ oldGroup: value });
    }
    if(id==="oldManager"){
      this.setState({ oldManager: value });
    }
    if(id==="oldName"){
      this.setState({ oldName: value });
    }
    if(id==="oldSurname"){
      this.setState({ oldSurname: value });
    }
    if(id==="oldEmail"){
      this.setState({ oldEmail: value });
    }
    if(id==="oldPhoneNumber"){
      this.setState({ oldPhoneNumber: value });
    }
    if(id==="oldGSM"){
      this.setState({ oldGSM: value });
    }
    if(id==="oldInternalNumber"){
      this.setState({ oldInternalNumber: value });
    }
      if (id === "newDomainName") {
          this.setState({ newDomainName: value });
      }
  }


  setUser() {
    if (
      this.state.oldName === "" ||
      this.state.oldName === undefined ||
      this.state.oldSurname === "" ||
      this.state.oldSurname === undefined ||
      this.state.oldEmail === "" ||
      this.state.oldEmail === undefined ||
      this.state.oldGSM === "" ||
      this.state.oldGSM === undefined ||
      this.state.oldDepartment === "" ||
      this.state.oldDepartment === undefined ||
      this.state.oldRole === "" ||
      this.state.oldRole === undefined
    ) {
      notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
    } else {
      if (
        String(this.state.oldRole) ===
        "a6957f7c-53cc-e811-80f6-005056b839bb"
      ) 
      {
        //destek rolü
        //D45342AB-14D1-E811-80F6-005056B839BB Son Kullanıcı 
        debugger
        if (this.state.oldIsUserPool.length === 0) {
          return notify(
            t("destekrolundekikullanicilaricinhavuzalanizorunludur"),
            "warning",
            3000
          );
        }
      }
      if( String(this.state.oldRole) ===
      "d45342ab-14d1-e811-80f6-005056b839bb"){
       if(this.state.oldIsUserPool.length !== 0){
        return notify(
          t("sonkullanicirolundekikullanicilaricinhavuzalanibosolmalidir"),
          "warning",
          3000
        );
       }
      }
      // kişi kendisinin yöneticisi olmaması için
      var name = this.state.oldName.toUpperCase();
      var surname = this.state.oldSurname.toUpperCase();
      var userName = name + " " + surname;
      var managers = this.state.detailData.Managers;
      var managerName = "";

      managers.forEach((element) => {
        if (this.state.oldManager === element.ID) {
          managerName = element.Name;
        }
      });
      if (managerName === userName) {
        return notify(
          t("secilenkisikendisininyoneticisiolamaz"),
          "warning",
          2000
        );
      }

      this.props.actions.isLoading(true);

      let user = {
        ID: this.state.oldUserID,
        CompanyID: this.state.oldCompanyID,
        Name: this.state.oldName,
        SurName: this.state.oldSurname,
        Email: this.state.oldEmail,
        Phone: this.state.oldPhoneNumber,
        InternalNumber: this.state.oldInternalNumber
          ? this.state.oldInternalNumber
          : 0,
        MobilPhone: this.state.oldGSM,
        CompanyDepartmentID: this.state.oldDepartment,
        CompanyDepartmentJobID: this.state.oldJob,
        ManagerID: this.state.oldManager,
        RoleID: this.state.oldRole,
        IsManager: this.state.oldIsManager,
        IsGM: this.state.oldIsGM,
        IsActive: this.state.oldIsActive,
        AllowSearchTask: this.state.oldSearchable,
        AllowChangeUser: this.state.oldIsChangeUser,
      };

      let permissionArr = [];
      let userPoolArr = [];

      this.state.oldPermissonPackage.map((item, i) => {
        let permissionData = this.state.detailData.PermissionPackages.filter(
          (x) => x.ID == item
        )[0];
        permissionArr.push(permissionData);
      });

      this.state.oldIsUserPool.map((item, i) => {
        let poolData = this.state.detailData.Pools.filter((x) => x.ID == item)[0];
        userPoolArr.push(poolData);
      });

      let entity = {
        User: user,
        RolePackages: permissionArr,
        UserPools: userPoolArr,
      };

      const formData = new FormData();
      formData.append("jsonText", JSON.stringify(entity));

      formData.append("userGroup", this.state.oldGroup);
      if (this.state.selectedGroup !== undefined) {
        formData.append("userGroup", this.state.oldGroup);
      }

      post("api/Yonetim/Users/Add", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.MessageStatus === true) {
            this.setState(
              {
                editRowVisible: false,
                addRowVisible: false,
                oldUserID: undefined,
                oldName: undefined,
                oldSurname: undefined,
                oldEmail: undefined,
                oldGSM: undefined,
                oldPhoneNumber: undefined,
                oldInternalNumber: undefined,
                oldDepartment: undefined,
                oldJob: undefined,
                oldRole: undefined,
                oldManager: undefined,
                oldPermissionPackage: undefined,
                oldIsManager: undefined,
                oldIsGM: undefined,
                oldPhoto: undefined,
                oldGroup: undefined,
              oldIsUserPool:undefined
              },
              //this.getDatas()
            );
            this.props.actions.isLoading(false);
          //  searchText
            notify(t("islemBasarili"), "success", 1000);
            this.getDataUser(this.state.searchText);
          } else {
            this.setState(
              {
                editRowVisible: false,
                addRowVisible: false,
                oldUserID: undefined,
                oldName: undefined,
                oldSurname: undefined,
                oldEmail: undefined,
                oldGSM: undefined,
                oldPhoneNumber: undefined,
                oldInternalNumber: undefined,
                oldDepartment: undefined,
                oldJob: undefined,
                oldRole: undefined,
                oldManager: undefined,
                oldPermissionPackage: undefined,
                oldIsManager: undefined,
                oldIsGM: undefined,
                oldPhoto: undefined,
                oldGroup: undefined,
              oldIsUserPool:undefined

              },
              
            );
            this.props.actions.isLoading(false);
            notify(t("islemBasarisiz") + res.data.Message, "warning", 1000);
          }
        })
        .catch((ex) => {
          this.props.actions.isLoading(false);
        });
    }
  }
clrData(id){
if(id==="oldManager"){
  this.setState({oldManager:undefined})
}
}

  setRow(cellData) {

    let newArr = [];
    cellData.data.PermissionPackages.map((item, i) => {
      newArr.push(item.ID);
    });

    let newArr2 = [];
    cellData.data.UserPools.map((item, i) => {
      newArr2.push(item.PoolID);
    });
    this.setState({
      editRowVisible: true,
      oldUserID: cellData.data.ID,
      oldName: cellData.data.Name,
      oldSurname: cellData.data.SurName,
      oldEmail: cellData.data.Email,
      oldGSM: cellData.data.MobilPhone,
      oldPhoneNumber: cellData.data.Phone,
      oldInternalNumber: cellData.data.InternalNumber,
      oldDepartment: cellData.data.CompanyDepartmentID,
      oldJob: cellData.data.CompanyDepartmentJobID,
      oldRole: cellData.data.RoleID,
      oldIsManager: cellData.data.IsManager,
      oldManager: cellData.data.ManagerID,
      oldPermissonPackage: newArr,
      oldIsGM: cellData.data.IsGM,
      oldIsActive: cellData.data.IsActive,
      oldSearchable: cellData.data.AllowSearchTask,
      oldIsChangeUser: cellData.data.AllowChangeUser,
      oldIsUserPool: newArr2,
      oldGroup: cellData.data.groupUser.length > 0 ? cellData.data.groupUser[0].ID:undefined
    });

    // BB.FetchJSON(
    //   "api/Yonetim/Users/GetSelectedJobs?departmentID=" +
    //     this.state.selectedDepartment +
    //     "&companyID=" +
    //     this.state.companyID +
    //     "&jobID=" +
    //     this.state.selectedJob
    // ).then((data) => {
    //   this.setState({ jobs: data });
    // });

    BB.FetchJSON("api/Yonetim/Users/GetUserGroup?UID=" + cellData.data.ID).then(
      (data) => {
        if (data && data.length > 0) {
          this.setState({ oldGroup: data[0].GroupID });
        } else {
          this.setState({ oldGroup: undefined });
        }
      }
    );
  }

  renderButtonsForUpdate = (cellData) => {
    return (
      <div
        style={{
          backgroundColor: "#3495eb",
          color: "#000",
          cursor: "pointer",
          border: "solid 0px #000",
          borderRadius: 10,
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 2,
          paddingBottom: 2,
          //, visibility:e.data.IconVisible
        }}
        onClick={() => {
          this.getDetailsData();
          callAPI(
            callApiGet,
            "/api/Yonetim/Users/GetCompanyDetails?CompanyID=" +
              cellData.data.CompanyID,
            {},
            (res) => {
              if (res.data) {
                this.setState({ companyDetailsData: res.data });
              }
            },
            true
          );
          let newArr = [];
          cellData.data.PermissionPackages.map((item, i) => {
            newArr.push(item.ID);
          });

          let newArr2 = [];
          cellData.data.UserPools.map((item, i) => {
            newArr2.push(item.PoolID);
          });
          //oldCompany
          this.setState({
            editRowVisible: true,
            oldUserID: cellData.data.ID,
            oldName: cellData.data.Name,
            oldSurname: cellData.data.SurName,
            oldEmail: cellData.data.Email,
            oldGSM: cellData.data.MobilPhone,
            oldPhoneNumber: cellData.data.Phone,
            oldInternalNumber: cellData.data.InternalNumber,
            oldDepartment: cellData.data.CompanyDepartmentID,
            oldJob: cellData.data.CompanyDepartmentJobID,
            oldRole: cellData.data.RoleID,
            oldIsManager: cellData.data.IsManager,
            oldManager: cellData.data.ManagerID,
            oldPermissonPackage: newArr,
            oldIsGM: cellData.data.IsGM,
            oldIsActive: cellData.data.IsActive,
            oldSearchable: cellData.data.AllowSearchTask,
            oldIsChangeUser: cellData.data.AllowChangeUser,
            oldIsUserPool: newArr2,
            oldCompanyID: cellData.data.CompanyID,
            oldGroup: cellData.data.groupUser.length > 0 ? cellData.data.groupUser[0].ID:undefined
          });

          this.setState({ editRowVisible: true });

         
        }}
      >
        <i
          className="fa fa-pencil"
          aria-hidden="true"
          style={{ marginTop: 5 }}
        ></i>{" "}
        {t("düzenle")}
      </div>
    );
  };
  //Render
  render() {
    return (
      <div className="animated fadeIn">
        <Col>
          <Card>
            <CardBody>
              <Button
                color={"success"}
                outline
                onClick={() =>
                  this.setState(
                    {
                      addRowVisible: true,
                      newName: "",
                      newSurname: "",
                      newEmail: "",
                      newGSM: "",
                      newPhoneNumber: "",
                      newInternalNumber: "",
                      newDepartment: "",
                      newJob: "",
                      newRole: "",
                      newManager: "",
                      newPermissionPackage: [],
                      newIsManager: false,

                      newIsGM: false,
                      newPhoto: "",
                      newUserPool: [],
                    },
                    this.getDetailsData()
                  )
                }
                style={{
                  float: "left",
                  position: "absolute",
                  zIndex: 1,
                  left: 80,
                }}
                type="button"
              >
                {t("kullaniciEkleme")}
              </Button>

              <DataGrid
                id="gridContainer"
                ref={this.getRef}
                //onOptionChanged={this.onOptionChanged()}
                dataSource={this.state.gridData}
                showRowLines={true}
                showColumnLines={false}
                showBorders={false}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="dxTaskTrackingSettingse2"
                />
                <ColumnChooser
                  enabled={true}
                  mode="dragAndDrop"
                  allowSearch={true}
                  allowHiding={true}
                  showInColumnChooser={true}
                />

                <Paging defaultPageSize={15} />

                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[10, 15, 20, 25]}
                  showNavigationButtons={true}
                />

                <Sorting mode="multiple" />

                <FilterRow visible={true} showAllText={"Tümünü Seç"} />

                <FilterPanel visible={true} />

                <HeaderFilter visible={true} />

              

                <Export enabled={true} fileName="Talepler" />

                

                <SearchPanel
                  visible={true}
                  width={100}
                  onTextChange={this.getDataUser}
                />

             

                <Column
                  dataField="Name"
                  caption={t("adı")}
                  minWidth={80}
                  allowFiltering={true}
                />
                <Column
                  dataField="SurName"
                  caption={t("soyadi")}
                  minWidth={80}
                  allowFiltering={true}
                />
                <Column
                  dataField="Departmanı"
                  caption={t("departmani")}
                  minWidth={80}
                  allowFiltering={true}
                />
                <Column
                  dataField={"Ünvan"}
                  caption={t("unvan")}
                  minWidth={80}
                  allowFiltering={true}
                />
                <Column
                  dataField={"Rolü"}
                  caption={t("rolu")}
                  minWidth={80}
                  allowFiltering={true}
                />
                <Column
                  dataField={"Yönetici"}
                  caption={t("yonetici")}
                  minWidth={60}
                  allowFiltering={true}
                />
                <Column
                  dataField={"GenelYetkili"}
                  caption={t("genelyetkili")}
                  minWidth={60}
                  allowFiltering={true}
                />
                <Column
                  dataField={"IsActive"}
                  caption={t("aktiflik")}
                  allowFiltering={true}
                  minWidth={60}
                />
                <Column
                  dataField={"CompanyName"}
                  caption={t("kurumadi")}
                  allowFiltering={true}
                  minWidth={60}
                />
                <Column
                  dataField="İşlemler"
                  caption={t("islemler")}
                  width={90}
                  cellRender={(cellData) =>
                    this.renderButtonsForUpdate(cellData)
                  }
                />
              </DataGrid>
            </CardBody>
          </Card>
        </Col>

        {/*Kullanıcı Ekleme, Popup*/}
        <Popup
          visible={this.state.addRowVisible}
          dragEnabled={false}
          showTitle={false}
          title={t("kullaniciEkleme")}
          minWidth={metrics.popupMinWidth}
          maxWidth={metrics.popupAutoWidth}
          maxHeight={metrics.popupMinHeight}
        >
          <ScrollView>
            <Row>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel("newName", t("adizorunlu"), "text", this)}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel("newSurname", t("soyadizorunlu"), "text", this)}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel("newEmail", t("epostazorunlu"), "email", this)}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel("newPhoneNumber", t("telefon"), "number", this)}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel("newGSM", t("ceptelefonuzorunlu"), "number", this)}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.InputWithLabel(
                  "newInternalNumber",
                  t("dahili"),
                  "number",
                  this
                )}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "newCompany",
                  t("kurum"),
                  this,
                  this.state.detailData.Companies
                )}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "newDepartment",
                  t("departmanzorunlu"),
                  this,
                  this.state.companyDetailsData.Departmans
                )}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "newJob",
                  t("gorevzorunlu"),
                  this,
                  this.state.companyDetailsData.Jobs
                )}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "newRole",
                  t("roluzorunlu"),
                  this,
                  this.state.detailData.Permission
                )}
              </Col>



              
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "newManager",
                  t("yoneticisi"),
                  this,
                  this.state.detailData.Managers
                )}
              </Col>

              <Col lg="6" sm={12}>
                <div>
                  <strong>{t("havuz")}</strong>

                  <TagBox
                    items={this.state.detailData.Pools}
                    //value={this.state.selectedIsUserPools}
                    showSelectionControls={true}
                    maxDisplayedTags={3}
                    displayExpr="Name"
                    valueExpr="ID"
                    id="addSelectedPoolUsers"
                    selectAllMode="allPages"
                    onValueChanged={this.addSelectedPoolUsers2}
                    // disabled={this.state.isLastUser}
                  />
                </div>
              </Col>

              <Col lg="6" sm={12}>
                <div>
                  <strong>{t("yetkipaketleri")}</strong>

                  <TagBox
                    items={this.state.detailData.PermissionPackages}
                    showSelectionControls={true}
                    maxDisplayedTags={3}
                    displayExpr="Name"
                    valueExpr="ID"
                    id="addSelectedRoleList"
                    selectAllMode="allPages"
                    onValueChanged={this.addMultiSelectChange}
                  />
                </div>
              </Col>

              <Col lg="6" sm={12}>
                {BB.FileWithLabel("newPhoto", t("kullanicifotografi"), this)}
              </Col>
                        <Col lg="6" sm={12}>
                            {" "}
                            {BB.InputWithLabel("newDomainName", t("domainName"), "text", this)}
                        </Col>
              <Col lg="6" sm={12}></Col>

              <Col lg="12" sm={12}>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="newIsManagerCheckBox"
                    //value={this.state.newIsManager}
                    onChange={this.handleNewIsManagerCheckBox}
                  />

                  <label
                    className="form-check-label"
                    htmlFor="newIsManagerCheckBox"
                  >
                    {t("yetkili")}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsGMCheckBox"
                    // value={this.state.selectedIsGM}
                    // checked={this.state.selectedIsGM}
                    onChange={this.handleNewIsGMCheckBox}
                  />

                  <label
                    className="form-check-label"
                    htmlFor="selectedIsGMCheckBox"
                  >
                    {t("genelyetkili")}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsActiveCheckBox"
                    //value={this.state.selectedIsActive}
                    //checked={this.state.selectedIsActive}
                    onChange={this.handleSelectedIsActiveCheckBox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsActiveCheckBox"
                  >
                    {t("aktifkullanici")}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedSearchable"
                    //value={this.state.selectedSearchable}
                    //checked={this.state.selectedSearchable}
                    onChange={this.handleSelectedIsSearch}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedSearchable"
                  >
                    {t("taleparayabilsin")}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsChangeUser"
                    // value={this.state.selectedIsChangeUser}
                    //checked={this.state.selectedIsChangeUser}
                    onChange={this.handleSelectedIsChangeUser}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsChangeUser"
                  >
                    {t("kullanicidegistirebilsin")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
              <Button
                color={"info"}
                onClick={() => this.setState({ addRowVisible: false })}
              >
                {t("vazgec")}
              </Button>
              <Button
                onClick={() => this.addNewUser()}
                color={"success"}
                style={{ marginLeft: 20, marginRight: 0 }}
              >
                {t("kaydet")}
              </Button>
            </Row>
          </ScrollView>
        </Popup>




        {/*Kullanıcı Düzenleme, Popup*/}
        <Popup
          visible={this.state.editRowVisible}
          dragEnabled={false}
          showTitle={false}
          title={t("kullaniciduzenleme")}
          minWidth={metrics.popupMinWidth}
          maxWidth={metrics.popupAutoWidth}
          maxHeight={metrics.popupMinHeight}
        >
          <ScrollView>
            <Row>
              <Col lg="6" sm={12}>
                {BB.InputWithLabel("oldName", t("adizorunlu"), "text", this)}
              </Col>
              <Col lg="6" sm={12}>
                {BB.InputWithLabel("oldSurname", t("soyadizorunlu"), "text", this)}
              </Col>
              <Col lg="6" sm={12}>
                {BB.InputWithLabel("oldEmail", t("epostazorunlu"), "email", this)}
              </Col>
              <Col lg="6" sm={12}>
                {BB.InputWithLabel("oldPhoneNumber", t("telefon"), "text", this)}
              </Col>
              <Col lg="6" sm={12}>
                {BB.InputWithLabel("oldGSM", t("ceptelefonuzorunlu"), "text", this)}
              </Col>

              <Col lg="6" sm={12}>
                {BB.InputWithLabel(
                  "oldInternalNumber",
                  t("dahili"),
                  "number",
                  this
                )}
              </Col>
              <Col lg="6" sm={12}>
                {" "}
                {BB.DxDropDownWithJSON(
                  "oldCompanyID",
                  t("kurum"),
                  this,
                  this.state.detailData.Companies
                )}
              </Col>
              <Col lg="6" sm={12}>
                {BB.DxDropDownWithJSON(
                  "oldDepartment",
                  t("departmanzorunlu"),
                  this,
                  this.state.companyDetailsData.Departmans
                )}
              </Col>

              <Col lg="6" sm={12}>
                {BB.DxDropDownWithJSON(
                  "oldJob",
                  t("görev"),
                  this,
                  this.state.companyDetailsData.Jobs        
                          )}
              </Col>
              <Col lg="6" sm={12}>
                {BB.DxDropDownWithJSON(
                  "oldRole",
                  t("roluzorunlu"),
                  this,
                  this.state.detailData.Permission
                )}
              </Col>
              <Col lg="6" sm={12}>
                {BB.DxDropDownWithJSON(
                  "oldGroup",
                  t("grubu"),
                  this,
                  this.state.gruops
                )}
              </Col>
 
         

               <Col lg="6" sm={12}>
                {BB.DxDropDownWithJSONForManager(
                  "oldManager",
                  t("yoneticisi"),
                  this,
                  this.state.detailData.Managers
                )}
              </Col> 


              <Col lg="6" sm={12}>
                <div>
                  <strong>{t("yetkipaketleri")}</strong>

                  <TagBox
                    items={this.state.detailData.PermissionPackages}
                    value={this.state.oldPermissonPackage}
                    showSelectionControls={true}
                    maxDisplayedTags={3}
                    displayExpr="Name"
                    valueExpr="ID"
                    id="addSelectedRoleList"
                    selectAllMode="allPages"
                    onValueChanged={this.setMultiSelectChange}
                  />
                </div>
              </Col>
              <Col lg="6" sm={12}>
                <div>
                  <strong>{t("havuz")}</strong>

                  <TagBox
                    items={this.state.detailData.Pools}
                    value={this.state.oldIsUserPool}
                    showSelectionControls={true}
                    maxDisplayedTags={3}
                    displayExpr="Name"
                    valueExpr="ID"
                    id="addSelectedPoolUsers"
                    selectAllMode="allPages"
                    onValueChanged={this.setMultiSelectChange2}
                    disabled={this.state.isLastUser}
                  />
                </div>
              </Col>

              <Col lg="12" sm={12}>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsManagerCheckBox"
                    value={this.state.oldIsManager}
                    checked={this.state.oldIsManager}
                    onChange={this.handleSelectedIsManagerCheckBox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsManagerCheckBox"
                  >
                    {t("yetkili")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsGMCheckBox"
                    value={this.state.oldIsGM}
                    checked={this.state.oldIsGM}
                    onChange={this.handleSelectedIsGMCheckBox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsGMCheckBox"
                  >
                    {t("genelyetkili")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsActiveCheckBox"
                    value={this.state.oldIsActive}
                    checked={this.state.oldIsActive}
                    onChange={this.handleSelectedIsActiveCheckBox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsActiveCheckBox"
                  >
                    {t("aktifkullanici")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedSearchable"
                    value={this.state.oldSearchable}
                    checked={this.state.oldSearchable}
                    onChange={this.handleSelectedIsSearch}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedSearchable"
                  >
                    {t("taleparayabilsin")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="selectedIsChangeUser"
                    value={this.state.oldIsChangeUser}
                    checked={this.state.oldIsChangeUser}
                    onChange={this.handleSelectedIsChangeUser}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="selectedIsChangeUser"
                  >
                    {t("kullanicidegistirebilsin")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
              <Button
                color={"info"}
                onClick={() => this.setState({ editRowVisible: false })}
              >
                {t("vazgec")}
              </Button>
              <Button
                onClick={() => this.setUser()}
                color={"success"}
                style={{ marginLeft: 20, marginRight: 0 }}
              >
                {t("kaydet")}
              </Button>
            </Row>
          </ScrollView>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserProcess)
);
