import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DataGrid, {Column, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import {post} from "axios";
import {Popup} from 'devextreme-react/popup';
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CompanyDepartment extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         redirect: false,
         redirectUrl: '',
         companyID: this.props.location.state ? this.props.location.state.CompanyID : null,
         departmentID: this.props.location.state ? this.props.location.state.DepartmentID : null,
         addDepartmentVisible: false,
         departmentName: '',
         editDepartmentVisible: false,
         editID: ''
      }
      let { t } = this.props;
   }

   componentDidMount()
   {
      if (this.state.companyID !== null)
      {
         this.props.actions.isLoading(true);
         let url = 'api/Yonetim/Companies/GetDataDepartment?ID=' + this.state.companyID;
         BB.FetchJSON(url).then(res =>
         {
            this.props.actions.isLoading(false);
            this.setState({gridData: res.Data})
         });
      }
      else
      {
         this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})
      }

   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginRight: 5}}
               color={'info'}
               outline
               onClick={() => this.setState({
                  editDepartmentVisible: true,
                  editID: cellData.data.ID,
                  departmentName: cellData.data.Name,
               })}
            >{t("düzenle")}</Button>

            <Button
               color={'info'}
               outline
               className='mr-1'
               onClick={() =>
               {
                  this.setState({
                     departmentID: cellData.data.ID,
                     redirect: true,
                     redirectUrl: '/Yonetim/CompaniesDepartmentJobs'
                  });
               }}
            >{t("unvanlar")}
            </Button>
         </div>
      );
   }

   getData()
   {

      let url = 'api/Yonetim/Companies/GetDataDepartment?ID=' + this.state.companyID;
      BB.FetchJSON(url).then(res =>
      {
         this.props.actions.isLoading(false);
         this.setState({gridData: res.Data})
      });
   }


   add()
   {
      if (this.state.departmentName === undefined || this.state.departmentName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            Name: this.state.departmentName,
            CompanyID: this.state.companyID
         }

         const formData = new FormData();

         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', null)

         post('api/Yonetim/Companies/AddDepartment', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addDepartmentVisible: false,
                  departmentName: ''
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("departmanbasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  addDepartmentVisible: false,
                  departmentName: ''
               });
               notify(t("departmaneklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   edit()
   {
      if (this.state.departmentName === undefined || this.state.departmentName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);

         let entity = {
            Name: this.state.departmentName,
            CompanyID: this.state.companyID
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', this.state.editID)
         post('api/Yonetim/Companies/AddDepartment', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({editDepartmentVisible: false, selectedName: ''}, this.getData());
               this.props.actions.isLoading(false);
               notify(t("departmanbasarilibirsekildeduzenlendi"), "success", 1000);
            }
            else
            {
               this.setState({editDepartmentVisible: false, selectedName: ''});
               notify(t("departmanduzenlemeislemibasarisiz") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   render()
   {
      const {gridData, redirectUrl, departmentID, companyID} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {DepartmentID: departmentID, CompanyID: companyID}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("geri")}</Button>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({addDepartmentVisible: true})}
                           style={{float: 'left', position: 'absolute', zIndex: 1, marginLeft: 60}}
                           type="button">{t("departmanekle")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Column
                              dataField="Name"
                              caption={t("departmanadi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={300}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>

               </Col>
               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.addDepartmentVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("departmanekle")}
                  width={metrics.popupMinWidth}
                  height={220}
               >
                  <form>

                     {BB.InputWithLabel('departmentName', t("departmanadi"), 'text', this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addDepartmentVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
               <Popup
                  visible={this.state.editDepartmentVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title="Departman Düzenle"
                  width={metrics.popupMinWidth}
                  height={220}
               >

                  {BB.InputWithLabel('departmentName',t("departmanadi"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({
                           editDepartmentVisible: false,
                           editID: '',
                           departmentName: ''
                        })}>
                        {t("kapat")}
                     </Button>
                     <Button
                        onClick={() => this.edit()}
                        color={'primary'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </Popup>
            </Row>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(CompanyDepartment)
);
