import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Popup} from "devextreme-react/popup";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

let backArr = [];

class StatusManagement extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: undefined,
         statusList: [],
         groups: [],

         editRowVisible: false,
         addRowVisible: false,

         selectedName: undefined,
         selectedIsSLA: false,
         selectedID: undefined,
         selectedParentID: null,
         selectedIsActive: true,
         selectedChangeTaskStatus: false,
         selectedGroup: undefined,
         isJobStatus: false,
         selectedFinishJob: false,
      }

      this.getData();
   }

   getData(parentID)
   {
      BB.FetchJSON('api/Yonetim/Group/GetAllGroup').then(groups =>
      {
         BB.FetchJSON('api/Yonetim/Status/GetData?ID=' + parentID).then(data =>
         {
            BB.FetchJSON('api/TaskDetail/GetTaskStatus').then(status =>
            {
               this.setState({
                  statusList: status,
                  gridData: data.Data,
                  selectedParentID: parentID,
                  groups: groups
               });
            });
         });
      });

      if (parentID === "b0cd6623-b950-eb11-a2cb-005056b81088")
      {
         this.setState({isJobStatus: true});
      }
      else
      {
         this.setState({isJobStatus: false});
      }
   }

   add()
   {
      if (this.state.selectedName === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         BB.FetchJSON('api/Yonetim/Status/Add' +
            '?Name=' + this.state.selectedName +
            '&ForSLA=' + this.state.selectedIsSLA +
            '&EditID=null' +
            '&ParentID=' + this.state.selectedParentID +
            '&ChangeTaskStatus=' + this.state.selectedChangeTaskStatus +
            '&TaskStatusID=' + this.state.selectedStatus +
            '&GroupID=' + this.state.selectedGroup +
            '&isFinishJob=' + this.state.selectedFinishJob
         ).then(res =>
         {
            if (res.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedIsSLA: null,
               });
               this.getData(this.state.selectedParentID);
               notify(t("durumolusturuldu"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedIsSLA: null
               });
               notify(t("durumolusturmahata") + res.Message, "warning", 1000);
            }
         });
      }
   }

   edit(IsActive)
   {
      if (this.state.selectedName === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         BB.FetchJSON('api/Yonetim/Status/Add?Name=' + this.state.selectedName +
            '&ForSLA=' + this.state.selectedIsSLA +
            '&IsActive=' + this.state.selectedIsActive +
            '&EditID=' + this.state.selectedID +
            '&ParentID=' + this.state.selectedParentID +
            '&ChangeTaskStatus=' + this.state.selectedChangeTaskStatus +
            '&TaskStatusID=' + this.state.selectedStatus +
            '&GroupID=' + this.state.selectedGroup +
            '&isFinishJob=' + this.state.selectedFinishJob
         ).then(res =>
         {
            if (res.MessageStatus === true)
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedIsSLA: null,
                  selectedID: undefined
               });

               this.getData(this.state.selectedParentID);

               notify(t("durumbasariyladuzenlendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  editRowVisible: false,
                  addRowVisible: false,
                  selectedName: '',
                  selectedIsSLA: null,
                  selectedID: undefined
               });

               notify(t("durumduzenlemehata") + res.Message, "warning", 1000);
            }
         });
      }
   }

   handleChange(id, value)
   {

      if (id === 'selectedIsActive')
      {
         this.setState({[id]: !this.state.selectedIsActive});
      }
      else
      {
         if (id === 'selectedIsSLA')
         {
            this.setState({[id]: !this.state.selectedIsSLA});
         }
         else
         {
            if (id === 'selectedChangeTaskStatus')
            {
               this.setState({[id]: !this.state[id]});
            }
            else
            {
               if (id === 'selectedFinishJob')
               {
                  this.setState({[id]: !this.state[id]});
               }
               else
               {
                  this.setState({[id]: value});
               }
            }
         }
      }
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({selectedID: cellData.data.Name, selectedIsSLA: cellData.data.ForSLA});

                  backArr.push(cellData.data.ID);

                  this.setState({backName: cellData.data.Name});

                  this.getData(cellData.data.ID);
               }}>
               {t("altdurumlar")}
            </Button>

            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {

                  BB.FetchJSON('api/Yonetim/Status/GetStatusGroupID?StatusID=' + cellData.data.ID)
                  .then(res =>
                  {
                     this.setState({
                        editRowVisible: true,
                        selectedID: cellData.data.ID,
                        selectedName: cellData.data.Name,
                        selectedIsSLA: cellData.data.ForSLA,
                        selectedIsActive: cellData.data.IsActive,
                        selectedChangeTaskStatus: cellData.data.IsChangeTaskStatus,
                        selectedStatus: cellData.data.ChangingTaskStatusID === '00000000-0000-0000-0000-000000000000' ? null : cellData.data.ChangingTaskStatusID,
                        selectedFinishJob: cellData.data.IsFinishJob,
                        selectedGroup: res.length > 0 ? res[0].GroupID : null
                     });
                  })
               }}
            >
               {t("düzenle")}
            </Button>
         </div>
      );
   }

   render()
   {
      return (
         <>
            <Col>
               <Card>
                  <CardBody>


                  
                     <Button

                           color={'primary'}
                           outline
                           onClick={() =>
                           {
                              let backID = null;

                              backID = backArr[backArr.length - 2];

                              backArr.pop();

                              this.getData(backID);
                           }}
                        style={{float: 'left', position: 'absolute', zIndex: 1}}
                        type="button"
                     >
                             {t("geri")}
                        

                     </Button>
                     
                     

                    
                        <Button                           
                        color={'success'}
                        outline
                        onClick={() => this.setState({
                           addRowVisible: true,
                           selectedIsActive: false,
                           selectedChangeTaskStatus: false,
                           selectedStatus: undefined,
                        })}
                           style={{float: 'left', position: 'absolute', zIndex: 1, left: 80}}
                           type="button">
                          {t("yeni")} </Button>
                        
                     


                     <DataGrid
                        id="gridContainer"
                        dataSource={this.state.gridData}
                        showRowLines={true}
                        showColumnLines={false}
                        showBorders={false}
                     >
                        <Paging
                           defaultPageSize={10}/>
                        <Pager
                           showPageSizeSelector={true}
                           allowedPageSizes={[5, 10, 20, 50]}
                           showNavigationButtons={true}
                        />
                        <SearchPanel visible={true} width={100}/>
                        <Export enabled={true} fileName="Durum Listesi"/>

                        <Column
                           dataField="Name"
                           caption={t("adı")}
                           minWidth={200}
                           allowFiltering={true}/>
                        <Column
                           dataField="IsActive"
                           caption={t("aktiflik")}
                           minWidth={200}
                           allowFiltering={true}/>

                        <Column
                           dataField="İşlemler"
                           caption={t("islemler")}
                           width={250}
                           cellRender={this.actionButton}
                        />
                     </DataGrid>
                  </CardBody>
               </Card>
            </Col>

            {/*Yeni Satır Ekleme Popup*/}
            <Popup
               visible={this.state.addRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title={t("yenidurumtanimi")}
               width={metrics.popupMinWidth}
               height={530}
            >
               {BB.InputWithLabel('selectedName', t("durumtanimi"), 'text', this)}
               {BB.CheckBoxWithLabel('selectedIsSLA', t("sladurumu"), this)}
               {BB.CheckBoxWithLabel('selectedFinishJob', t("gorevsonlandirsin"), this, !this.state.isJobStatus)}
               {BB.CheckBoxWithLabel('selectedIsActive', t("aktiflik"), this)}
               {BB.CheckBoxWithLabel('selectedChangeTaskStatus', t("talepdurumudegissinmi"), this, this.state.isJobStatus)}
               {BB.DxDropDownWithJSON('selectedStatus', t("talepdurmunundegistirileceğistatu"), this, this.state.statusList, !this.state.selectedChangeTaskStatus)}
               {BB.DxDropDownWithJSON('selectedGroup', t("goruntulenecegigrubuseciniz"), this, this.state.groups)}
               <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                  <Button
                     color={'info'}
                     onClick={() => this.setState({addRowVisible: false})}>
                     Vazgeç
                  </Button>
                  <Button
                     onClick={() => this.add()}
                     color={'success'}
                     style={{marginLeft: 20, marginRight: 0}}>
                     Oluştur
                  </Button>
               </Row>
            </Popup>

            {/*Düzenle Popup*/}
            <Popup
               visible={this.state.editRowVisible}
               dragEnabled={false}
               showTitle={true}
               showCloseButton={false}
               title="Kategori Düzenle"
               width={metrics.popupMinWidth}
               height={560}
            >
               {BB.InputWithLabel('selectedName', t("durumtanimi"), 'text', this)}
               {BB.CheckBoxWithLabel('selectedIsSLA', t("sladurumu"), this)}
               {BB.CheckBoxWithLabel('selectedFinishJob', t("gorevsonlandirsin"), this, !this.state.isJobStatus)}
               {BB.CheckBoxWithLabel('selectedIsActive', t("aktiflik"), this)}
               {BB.CheckBoxWithLabel('selectedChangeTaskStatus', t("talepdurumudegissinmi"), this, this.state.isJobStatus)}
               {BB.DxDropDownWithJSON('selectedStatus', t("talepdurmunundegistirileceğistatu"), this, this.state.statusList, !this.state.selectedChangeTaskStatus)}
               {BB.DxDropDownWithJSON('selectedGroup', t("goruntulenecegigrubuseciniz"), this, this.state.groups)}
               <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                  <Button
                     color={'info'}
                     onClick={() => this.setState({
                        editRowVisible: false,
                        selectedID: '',
                        selectedName: '',
                     })}>
                     {t("kapat")}
                  </Button>

                  <Button
                     onClick={() => this.edit()}
                     color={'primary'}
                     style={{marginLeft: 20, marginRight: 0}}>
                     {t("kaydet")}
                  </Button>
               </Row>
            </Popup>
         </>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(StatusManagement)
);
