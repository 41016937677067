import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  ListGroup,
  ListGroupItem,
  Badge,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap";
import DataGrid, {
  Column,
  Export,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import { Redirect } from "react-router-dom";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import { connect } from "react-redux";
import metrics, { StatusList } from "../../../common/metrics";
import PoolCategoriesFilter from "../../../components/custom/PoolCategoriesFilter/PoolCategoriesFilter";
import { data } from "jquery";
import { t } from "i18next";
import {
   callAPI,
   callApiGet
} from "../../../components/Axios";
import { withTranslation } from "react-i18next";

class Pool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: undefined,
      editRowVisible: false,
      editPoolVisible: false,

      editID: "",
      selectedName: "",
      selectedIsActive: true,
      selectedMasterPoolID: null,

      redirect: false,
      poolID: "",
      treeViewData: [],
      selectedPoolCategories: [],
      categoryTerm: "",
      filteredCategory: [],
      selectedFilterCategory: "",
      selectedEmployee: undefined,
      modalOpen: false,
      selectedCategoryName: undefined,
      selectedCategory: [],
      poolCategories: [],
      selectedDeleteCategory: [],
      poolTerm: "",
    };
    this.treeView = null;
    let { t } = this.props;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    callAPI(
      callApiGet,
      "api/Yonetim/Pools/GetData",
      {},
      async (res) => {
        const data = res?.data;
        this.setState({ gridData: data });
        this.props.actions.isLoading(false);
      },
      true
    );
  }

  getCategoryName() {
    this.props.actions.isLoading(true);
    BB.FetchJSON(
      "/api/Yonetim/Pools/GetCategoryByPoolID?poolID=" + this.state.editID
    ).then((res) => this.setState({ poolCategories: res }));

  }

  handleChange(id, value) {
    this.setState({ [id]: value });
  }

  add() {
    if (
      this.state.selectedName === undefined ||
      this.state.selectedName === ""
    ) {
      notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
    } else {
      this.props.actions.isLoading(true);

      let entity = {
        Name: this.state.selectedName,
        StatusID: this.state.selectedIsActive
          ? StatusList.Aktif
          : StatusList.Pasif,
      };


      const formData = new FormData();
      formData.append("jsonText", JSON.stringify(entity));
      formData.append("MasterPoolID", this.state.selectedMasterPoolID);

      post("api/Yonetim/Pools/AddPool", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.data.MessageStatus === true) {
          this.setState(
            {
              editRowVisible: false,
              editPoolVisible: false,
              selectedName: "",
              selectedMasterPoolID: null,
            },
            this.getData()
          );
          this.props.actions.isLoading(false);
          notify(t("havuzolusturuldu"), "success", 1000);
        } else {
          this.setState({
            editRowVisible: false,
            editPoolVisible: false,
            selectedName: "",
            selectedMasterPoolID: null,
          });
          notify(t("havuzolusturmadahata") + res.Message, "warning", 1000);
          this.props.actions.isLoading(false);
        }
      });
    }
  }
  deleteCategory(ID) {
    let entity = {
      Name: this.state.selectedName,
      StatusID: this.state.selectedIsActive
        ? StatusList.Aktif
        : StatusList.Pasif,
    };

    let arr = [];

    arr.push({
      PoolID: this.state.editID,
      CategoryID: ID,
    });

    const formData = new FormData();
    formData.append("jsonText", JSON.stringify(entity));
    formData.append("categoryText", JSON.stringify(arr));
    formData.append("EditID", this.state.editID);

    post("api/Yonetim/Pools/DeletePoolCategory", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => {
      this.setState(
        { editRowVisible: false, selectedCategory: [] },
        this.getData()
      );
      this.getCategoryName();

      this.props.actions.isLoading(false);
      //notify('Havuz Başarıyla Düzenlendi!', "success", 1000);
    });
  }

  edit() {
    //BB.FetchJSON('/api/Yonetim/Pools/GetCategoryByPoolID?poolID='+this.state.editID).then(res => this.setState({poolCategories : res}))

    if (
      this.state.selectedName === undefined ||
      this.state.selectedName === ""
    ) {
      notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
    } else {
      this.props.actions.isLoading(true);
      let arr = [];
      let entity = {
        Name: this.state.selectedName,
        StatusID: this.state.selectedIsActive
          ? StatusList.Aktif
          : StatusList.Pasif,
      };

      /*   for (let i = 0; i < this.state.selectedPoolCategories.length; i++)
         {
            arr.push({
               PoolID: this.state.editID,
               CategoryID: this.state.selectedPoolCategories[i]
            });
         } */
      for (let i = 0; i < this.state.selectedCategory.length; i++) {
        arr.push({
          PoolID: this.state.editID,
          CategoryID: this.state.selectedCategory[i],
        });
      }

      const formData = new FormData();

      formData.append("jsonText", JSON.stringify(entity));
      formData.append("categoryText", JSON.stringify(arr));
      //formData.append('categoryText', this.state.selectedCategory);
      formData.append("MasterPoolID", this.state.selectedMasterPoolID);
      formData.append("EditID", this.state.editID);

      post("api/Yonetim/Pools/AddPool", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.data.MessageStatus === true) {
          this.setState(
            { editRowVisible: false, selectedCategory: [] },
            this.getData()
          );
          this.getCategoryName();

          this.props.actions.isLoading(false);
          notify(t("havuzbasariyladuzenlendi"), "success", 1000);
        } else {
          this.setState({
            editRowVisible: false,
            editPoolVisible: false,
            selectedName: "",
          });
          notify(t("havuzduzenlemedehata") + res.Message, "warning", 1000);
          this.props.actions.isLoading(false);
        }
      });
    }
  }

  activePool(ID) {
    this.props.actions.isLoading(true);

    callAPI(
      callApiGet,
      "api/Yonetim/Pools/SetActivePool?PoolID=" + ID,
      {},
      async (res) => {
        const result = res?.data;

        if (result.MessageStatus === true) {
          this.setState(
            { editRowVisible: false, editPoolVisible: false, selectedName: "" },
            this.getData()
          );
          this.props.actions.isLoading(false);
          notify(t("havuzbasariylaaktifealindi"), "success", 1000);
        } else {
          this.setState({
            editRowVisible: false,
            editPoolVisible: false,
            selectedName: "",
          });
          notify(t("havuzdurumdegistirmedehata"), "warning", 2000);
          this.props.actions.isLoading(false);
        }
      },
      true
    );
  }

  disablePool(ID) {
    this.props.actions.isLoading(true);

    callAPI(
      callApiGet,
      "api/Yonetim/Pools/SetDisablePool?PoolID=" + ID,
      {},
      async (res) => {
        const result = res?.data;
        if (result.MessageStatus === true) {
          this.setState(
            { editRowVisible: false, editPoolVisible: false, selectedName: "" },
            this.getData()
          );
          this.props.actions.isLoading(false);
          notify(t("havuzbasariylaaktifealindi"), "success", 1000);
        } else {
          this.setState({
            editRowVisible: false,
            editPoolVisible: false,
            selectedName: "",
          });
          notify(
            t("havuzdurumdegistirmedehatahavuzabaglikategori"),
            "warning",
            2000
          );
          this.props.actions.isLoading(false);
        }
      },
      true
    );
  }

  delete(ID) {
    this.props.actions.isLoading(true);

    callAPI(
      callApiGet,
      "api/Yonetim/Pools/DeletePool?PoolID=" + ID,
      {},
      async (res) => {
        const result = res?.data;
        if (result.MessageStatus === true) {
          this.setState(
            { editRowVisible: false, editPoolVisible: false, selectedName: "" },
            this.getData()
          );
          this.props.actions.isLoading(false);
          notify(t("havuzbasariylasilindi"), "success", 1000);
        } else {
          this.setState({
            editRowVisible: false,
            editPoolVisible: false,
            selectedName: "",
          });
          notify(
            t("havuzsilmehata"),
            "warning",
            2000
          );
          this.props.actions.isLoading(false);
        }
      },
      true
    );
  }

  actionButton = (cellData) => {
    return (
      <div>
        <Button
          color={"info"}
          outline
          onClick={() => {
            this.setState({ poolID: cellData.data.ID, redirect: true });
          }}
        >
          {t("kullanicilar")}
        </Button>

        <Button
          style={{ marginLeft: 5 }}
          color={"info"}
          outline
          onClick={() => {
            let arr = [];
            let arr2 = [];

            if (cellData.data.PoolCategory.length > 0) {
              for (let i = 0; i < cellData.data.PoolCategory.length; i++) {
                arr.push(cellData.data.PoolCategory[i].CategoryID);
              }
            }

            BB.FetchJSON("/api/Yonetim/Pools/GetCategories").then((res) =>
              this.setState({ treeViewData: res })
            );

            this.setState({
              editPoolVisible: true,
              editID: cellData.data.ID,
              selectedName: cellData.data.Name,
              selectedIsActive: cellData.data.Status === "Aktif",
              selectedMasterPool: cellData.data.Level,
              selectedPoolCategories: arr,
            });

            BB.FetchJSON(
              "/api/Yonetim/Pools/GetCategoryByPoolID?poolID=" +
                cellData.data.ID
            )
              .then(
                (res) => this.setState({ poolCategories: res }),
                this.props.actions.isLoading(true)
              )
              .then((res) => {
                if (res !== null) {
                  this.props.actions.isLoading(false);
                }
              });
            //this.props.actions.isLoading(false);
          }}
        >
          {t("düzenle")}
        </Button>

        <Button
          style={{ marginLeft: 5 }}
          color={"info"}
          outline
          onClick={() => {
            BB.askYesNo(
              t("Onaylıyormusunuz?"),
              t("sectiginizhavuzaktifolacaktır"),
              t("evet"),
              t("hayır")
            ).then((res) => {
              if (res) {
                this.activePool(cellData.data.ID);
              }
            });
          }}
        >
          {t("aktif")}
        </Button>
        <Button
          style={{ marginLeft: 5 }}
          color={"info"}
          outline
          onClick={() => {
            BB.askYesNo(
              t("Onaylıyormusunuz?"),
              t("sectiginizhavuzpasifolacaktir"),
              t("evet"),
              t("hayır")
            ).then((res) => {
              if (res) {
                this.disablePool(cellData.data.ID);
              }
            });
          }}
        >
          {t("pasif")}
        </Button>
      </div>
    );
  };

  render() {
    const onChangeCategoryInput = (e) => {
      this.setState({ poolTerm: e.target.value });
    };

    let filteredPoolList = this.state.poolCategories.filter((poolcategory) => {
      return (
        poolcategory.Name.toLowerCase().indexOf(
          this.state.poolTerm.toLowerCase()
        ) !== -1
      );
    });

    if (this.state.redirect === true) {
      return (
        <Redirect
          to={{
            pathname: "/Yonetim/PoolUser",
            state: { PoolID: this.state.poolID },
          }}
        />
      );
    } else {
      return (
        <div className="animated fadeIn">
          <Col>
            <Card>
              <CardBody>
                <Button
                  color={"success"}
                  outline
                  onClick={() => this.setState({ editRowVisible: true })}
                  style={{ float: "left", position: "absolute", zIndex: 1 }}
                  type="button"
                >
                  {t("yeni")}
                </Button>

                <DataGrid
                  id="gridContainer"
                  dataSource={this.state.gridData}
                  showRowLines={true}
                  showColumnLines={true}
                  showBorders={false}
                  allowColumnResizing={true}
                  columnResizingMode={"nextColumn"}
                  allowColumnReordering={true}
                  onCellHoverChanged={(e) => null}
                >
                  <SearchPanel visible={true} width={100} />
                  <Export enabled={true} fileName="Havuz Listesi" />
                  <Paging pageSize={15} />

                  <Column
                    dataField="Name"
                    caption={t("havuztanimi")}
                    minWidth={200}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"Status"}
                    caption={t("durum")}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"Level"}
                    caption={t("anahavuz")}
                    minWidth={80}
                  />
                  <Column
                    dataField="İşlemler"
                    caption={t("islemler")}
                    width={310}
                    cellRender={this.actionButton}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </Col>

          {/*Yeni Satır Ekleme Popup*/}
          <Modal
            isOpen={this.state.editRowVisible}
            dragEnabled={false}
            showTitle={true}
            showCloseButton={false}
            title=""
            width={metrics.popupMinWidth}
            height={320}
          >
            <ModalHeader>{t("yenihavuztanimi")}</ModalHeader>
            <ModalBody>
              <form>
                {BB.InputWithLabel("selectedName", t("tanim"), "text", this)}
                {BB.DxDropDownWithJSON(
                  "selectedMasterPoolID",
                  t("talepHavuuzu"),
                  this,
                  this.state.gridData
                )}

                <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
                  <Button
                    color={"info"}
                    onClick={() => this.setState({ editRowVisible: false })}
                  >
                    {t("vazgec")}
                  </Button>
                  <Button
                    onClick={() => this.add()}
                    color={"success"}
                    style={{ marginLeft: 20, marginRight: 0 }}
                  >
                    {t("olustur")}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>

          {/*Düzenle Popup*/}
          <Modal
            isOpen={this.state.editPoolVisible}
            dragEnabled={false}
            showTitle={true}
            showCloseButton={false}
            title=""
            width={metrics.popupMinWidth}
            height={550}
          >
            <ModalHeader>{t("havuzduzenle")}</ModalHeader>
            <ModalBody>
              {BB.InputWithLabel("selectedName", t("tanim"), "text", this)}
              {/* {BB.DxDropDownWithJSON('selectedMasterPoolID', 'Talep Havuzu', this, this.state.gridData)} */}
              {/* {BB.DxMultipleDropdown('selectedPoolCategories', 'Kategori', this, this.state.treeViewData)} */}
              <InputGroup>
                <Input
                  className="border border-dark shadow-sm"
                  style={{ border: "5px" }}
                  type="text"
                  autoFocus={true}
                  name={this.state.poolTerm}
                  onChange={onChangeCategoryInput}
                  placeholder={t("aradiginizKategoriyiYaziniz")}
                />
              </InputGroup>
              <br></br>
              <FormGroup>
                <ListGroup style={{ overflowY: "scroll", height: "350px" }}>
                  {filteredPoolList.map((filter) => (
                    <ListGroupItem>
                      {filter.Name}
                      <Button
                        color="danger"
                        onClick={() => this.deleteCategory(filter.ID)}
                        className="ml-2"
                      >
                        X
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </FormGroup>

              <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
                <PoolCategoriesFilter
                  state={this.state}
                  event={this}
                  edit={this.edit}
                />
                <Button
                  color={"info"}
                  onClick={() =>
                    this.setState({
                      editPoolVisible: false,
                      editID: "",
                      selectedName: "",
                      selectedIsActive: false,
                      selectedMasterPoolID: null,
                    })
                  }
                >
                  {t("kapat")}
                </Button>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Pool)
);
