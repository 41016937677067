import React, {Component} from 'react';
import {CardBody, Col, Row,} from 'reactstrap';
import {withTranslation} from "react-i18next";
import * as BB from "../../../../components/BBComponent";
import {Redirect, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import {AppSwitch} from "@coreui/react";
import {CustomCard, LabelGroup} from "../../../../components/custom";

class OwnTask extends Component
{
   constructor(props)
   {
      super(props);

      this.state = {
         collapse: true,
         fadeIn: true,
         data: [],
         timeout: 50,
         currentTask: [],
         talepEden: '',
         firma: '',
         departman: '',
         gorev: '',
         kategori: '',
         aciklama: '',
         aciliyet: '',
         etki: '',
         showOwnTask: false,
         showDetail: false,
         showAll: false,
      };
   }

   handleChange = (key, value) =>
   {
      if (key === 'redirect')
      {
         this.props.history.push('/TaskDetail?' + value)
      }
   }

   render()
   {
      let {showAll} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: '/TaskDetail', state: {taskID: this.state.taskID, ownTask: true}}}/>
      }
      else
      {
         const {t} = this.props;

         return (
            <>
               <Row>
                  <Col xs="12" sm="12">
                     <CustomCard cardStyle='card-dashboard-calendar pb-0'>
                        <LabelGroup title={t('taleplerim')}
                                    description={'Açmış olduğunuz talepleriniz burada listelenmektedir'}/>

                        <div className="card-header-actions" style={{position: 'absolute', right: 200, marginTop: -50}}>
                           <p style={{position: 'absolute', right: 40, width: 175}}>{t('tumunugoruntule')}</p>
                           <AppSwitch
                              checked={showAll}
                              className={'float-right mb-0'}
                              label
                              color={'info'}
                              size={'sm'}
                              onChange={e =>
                              {
                                 this.setState({showAll: !showAll});
                              }}/>
                        </div>

                        <div className="card-header-actions" style={{marginTop: -50}}>
                           <p style={{position: 'absolute', right: 65,}}>{t('detayligorunum')}</p>
                           <AppSwitch
                              checked={this.state.showDetail}
                              className={'float-right mb-0'}
                              label
                              color={'info'}
                              size={'sm'}
                              onChange={e => this.setState({showDetail: !this.state.showDetail})}/>
                        </div>
                        <CardBody>
                           {BB.DxDataGridForOwnTask('api/Pool/GetOwnTask?AllData=' + showAll, this, this.props.loginUser, {
                              hata: t('hata')
                              , onayVer: t('onayVer')
                              , iptal: t('iptal')
                              , vazgec: t('vazgec')
                              , devral: t('devral')
                              , eminMisiniz: t('eminMisiniz')
                              , talepDevralindi: t('talepDevralindi')
                              , talebiDevralacaksiniz: t('talebiDevralacaksiniz')
                              , onayAciklamasiGiriniz: t('onayAciklamasiGiriniz')
                              , iptalAciklamasiGiriniz: t('iptalAciklamasiGiriniz')
                              , onayBasariylaVerilmistir: t('onayBasariylaVerilmistir')
                              , talepBasariylaIptalEdilmistir: t('talepBasariylaIptalEdilmistir')
                              , seciniz: t('seciniz')
                           }, this.state.showDetail)}
                        </CardBody>
                     </CustomCard>
                  </Col>
               </Row>
            </>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {
      loginUser: state.loginReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OwnTask)))

