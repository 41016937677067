import React, {PureComponent} from 'react';
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Row from "reactstrap/es/Row";
import {ReactFormBuilder, ReactFormGenerator} from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import Col from "reactstrap/es/Col";
import CardFooter from "reactstrap/es/CardFooter";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import * as BB from '../../../components/BBComponent'
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class FormDesign extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         taskCategories: [],

         selectedID: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.ID : null : null,
         selectedName: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.Name : null : null,
         selectedForm: this.props.location.state ? this.props.location.state.selectedForm ? JSON.parse(this.props.location.state.selectedForm.JsonForm) : [] : [],
         selectedCategoryID: this.props.location.state ? this.props.location.state.selectedForm ? this.props.location.state.selectedForm.CategoryID : undefined : undefined,

         redirect: false,
         selectedIsRequired: this.props.location.state.selectedForm ? this.props.location.state.selectedForm.IsRequired: false,
         previewVisible: false,
      }
      this.getCategories();
   }


   getCategories = () =>
   {
      BB.FetchJSON('/api/Yonetim/CustomForm/GetAllCategories').then(data => this.setState({taskCategories: data}));
   }


   getData = () => new Promise(resolve =>
   {
      resolve(this.state.selectedForm.task_data);
   })


   handleChange(id, value)
   {
      if (id === 'selectedIsRequired')
      {
         this.setState({[id]: !this.state.selectedIsRequired});
      }
      else
      {
         this.setState({[id]: value});
      }
   }

   
   
   saveForm()
   {
      let formData = new FormData();
      let {selectedCategoryID, selectedForm, selectedID, selectedName, selectedIsRequired} = this.state;

      // {this.state.selectedForm.task_data.map((item, i) =>{
      //    if(item.element ==="Dropdown"){
      //       item.options.map((item2,k)=>{
      //          item2.value = item2.text
   
      //       })
      //    }
      // })}
      

      formData.append('Name', selectedName);
      formData.append('EditID', selectedID);
      formData.append('CatID', selectedCategoryID);
      formData.append('IsRequired', selectedIsRequired);
      formData.append('JsonBody', JSON.stringify(selectedForm));
 
      post('api/Yonetim/CustomForm/AddOrUpdateCustomForm', formData, {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         if (res.data === true)
         {
            this.setState({redirect: true});
            notify(t("basarili"), 'success', 2000);
         }
         else
         {
            notify(t("hata"), 'error', 2000);
         }
      });
   }
   render()
   {


      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: '/Yonetim/FormList'}}/>
      }
      else
      {
         return (
            <Row>
               <Col xl={12}>
                  <Card>
                     <CardBody>
                        <Row>
                           <Col xl={2}>
                              <Button
                                 color={'success'}
                                 outline
                                 block
                                 onClick={() => this.setState({redirect: true})}
                                 style={{float: 'left', position: 'absolute', zIndex: 1, width: 100}}
                                 type="button">
                                 {t("geri")}
                              </Button>

                              <Card style={{marginTop: 45}}>
                                 <CardBody>
                                    {BB.InputWithLabel('selectedName', t("formtanimi"), 'text', this)}
                                    {BB.DxDropDownWithJSONWithPlaceHolder('selectedCategoryID', t("kategoriseciniz"), this, this.state.taskCategories, t("LütfenSeçiniz"))}
                                    {BB.CheckBoxWithLabelFormDesign('selectedIsRequired', t("zorunluolsunmu"), this,false,this.state.selectedIsRequired)}
                                 </CardBody>
                              </Card>
                           </Col>
                           <Col xl={10}>
                              <ReactFormBuilder
                                 onLoad={this.getData}
                                 onPost={(e) => this.setState({selectedForm: e})}
                              />
                           </Col>
                        </Row>
                     </CardBody>
                     <CardFooter>
                        <Button
                           style={{marginRight: 10}}
                           color={'success'}
                           onClick={() =>
                              this.setState({previewVisible: true})}
                        >
                           {t("önizleme")}
                        </Button>

                        <Button
                           color={'info'}
                           onClick={() => this.saveForm()}
                        >
                           {t("kaydet")}
                        </Button>

                     </CardFooter>
                  </Card>
               </Col>
               <Modal isOpen={this.state.previewVisible}>
                  <ModalHeader>{t("formonizlemesi")}</ModalHeader>
                  <ModalBody>
                     <ReactFormGenerator
                        read_only={true}
                        form_method="POST"
                        hide_actions={true}
                        data={this.state.selectedForm.task_data}
                     />
                     <div className="modal-footer">
                        <Button
                           outline
                           color={'success'}
                           onClick={() => this.setState({previewVisible: false})}>
                           {t("kapat")}
                        </Button>
                     </div>
                  </ModalBody>
               </Modal>
            </Row>
         );
      }
   }
}

export default withTranslation()(FormDesign);
