import {Col, Row,} from 'reactstrap';
import React from "react";

const CustomParameters = () =>
{
   return (
      <>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Talep No</Col>
            <Col style={{textAlign: 'end'}}>{' {TaskNumber}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Bildirim Tipi</Col>
            <Col style={{textAlign: 'end'}}>{' {NotificationType}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Öncelik Derecesi</Col>
            <Col style={{textAlign: 'end'}}>{' {DegreeOfUrgency}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Öncelik Açıklaması</Col>
            <Col style={{textAlign: 'end'}}>{' {UrgencyDescription}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Kategori</Col>
            <Col style={{textAlign: 'end'}}>{' {Category}'}</Col>
         </Row>
         
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Durum</Col>
            <Col style={{textAlign: 'end'}}>{' {Status}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Kapatma Tarihi</Col>
            <Col style={{textAlign: 'end'}}>{' {ClosingDate}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Sorumlu Kullanıcı</Col>
            <Col style={{textAlign: 'end'}}>{' {ResponsibleUser}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Onaylayan Yönetici</Col>
            <Col style={{textAlign: 'end'}}>{' {ConfirmingManager}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Onaylanma Tarihi</Col>
            <Col style={{textAlign: 'end'}}>{' {ApprovalDate}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Bitirme Tarihi</Col>
            <Col style={{textAlign: 'end'}}>{' {TerminationDate}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Bitirme Durumu</Col>
            <Col style={{textAlign: 'end'}}>{' {TerminationPatameter}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Bitirme Mesajı</Col>
            <Col style={{textAlign: 'end'}}>{' {TerminationDescription}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>İş Tekrarı</Col>
            <Col style={{textAlign: 'end'}}>{' {MainTask}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Açıklama</Col>
            <Col style={{textAlign: 'end'}}>{' {Description}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Talep Tipi</Col>
            <Col style={{textAlign: 'end'}}>{' {TaskType}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Havuz</Col>
            <Col style={{textAlign: 'end'}}>{' {Pool}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Talebi Açan Kişi</Col>
            <Col style={{textAlign: 'end'}}>{' {CreateUser}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>İşlem Durum</Col>
            <Col style={{textAlign: 'end'}}>{' {WorkStatus}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Etki</Col>
            <Col style={{textAlign: 'end'}}>{' {Effect}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Açıklama</Col>
            <Col style={{textAlign: 'end'}}>{' {TaskLog}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Kullanıcı Adı</Col>
            <Col style={{textAlign: 'end'}}>{' {Username}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Şifre</Col>
            <Col style={{textAlign: 'end'}}>{' {Password}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Talep Detay Adresi</Col>
            <Col style={{textAlign: 'end'}}>{' {Url}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Adı Soyadı</Col>
            <Col style={{textAlign: 'end'}}>{' {FullName}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Email</Col>
            <Col style={{textAlign: 'end'}}>{' {Email}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>T.Açan Firması</Col>
            <Col style={{textAlign: 'end'}}>{' {CreateUserCompany}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>T.Açan Departmanı</Col>
            <Col style={{textAlign: 'end'}}>{' {CompanyDepartment}'}</Col>
         </Row>

         <hr/>

         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Görev Açıklaması</Col>
            <Col style={{textAlign: 'end'}}>{' {JobDescription}'}</Col>
         </Row>
         <Row className='d-flex justify-content-between'>
            <Col className='font-weight-bold'>Görev Durumu</Col>
            <Col style={{textAlign: 'end'}}>{' {Status}'}</Col>
         </Row>
          <Row className='d-flex justify-content-between'>
              <Col className='font-weight-bold'>Anket URL Bilgisi</Col>
              <Col style={{textAlign: 'end'}}>{' {anketUrl}'}</Col>
          </Row>
      </>
   );
}
export default CustomParameters;
