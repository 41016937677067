import React, {PureComponent} from 'react';
import {List} from "../../../custom";
import {bindActionCreators} from "redux";
import * as mainActions from "../../../../redux/actions/mainActions";
import {connect} from "react-redux";
import {ModalHeader,ModalBody,Modal,Button, Col, Row} from "reactstrap";

import {
   callAPI,
   callApiGet,
   callApiPost,
   callApiPut
} from "../../../Axios";

class Notice extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         Message: [],
         Popups:[],
         informationModal:false
      };
   }

   componentDidMount()
   {
      this.getMessageData();
   }

   getMessageData()
   {
      callAPI(
         callApiGet,
         `api/Main/GetPopupMessage`,
         {},
         async (res) => {
            if (res?.data) {
                this.setState({ Message:  res?.data?.Message })
                const visible = getCookie("popupVisible") == null ? false : getCookie("popupVisible") ; // localStorage.getItem("popupVisible") == null ? false : localStorage.getItem("popupVisible");
                if(res?.data?.Popups.length>0 && !visible){
                  this.setState({ Popups:  res?.data?.Popups,informationModal:true  })
                }
                
            }
         },
         true
   );   
   }

   render()
   {
      const t = this.props.useTranslation;
      return (
         <>
            <List
               title={t('duyuru')}
               description={t('talepYönetimSistemiÜzerindenYapılanTümDuyurularaBuradanUlaşabilirsiniz')}
               data={this.state.Message}
               noData={t('Herhangibirveriyok')}
            />

            <Modal
               centered={true}
               isOpen={this.state.informationModal}
            >
               <ModalHeader>
                  Bilgilendirme
               </ModalHeader>
               <ModalBody className='pt-0'>
                  <div style={{marginTop: 10}}>
                     <Row>
                        <Col xl={12}>
                        <div className='popup-block'>
                                 {
                                    this.state.Popups.map((item, inx) => (
                                       <div key={inx}>
                                          <h6>{item.Title}</h6>
                                          <p>{item.Message}</p>
                                       </div>
                                    ))
                                 }
                              </div>
                        </Col>
                     </Row>
                     <Row>
                        <Col>
                        <Button
                              color={'danger'}
                              className='float-right m-1'
                              onClick={()=>this.setState({informationModal:false})}
                            >
                              Kapat
                           </Button>
                        <Button
                              color={'info'}
                              className='float-right m-1'
                              onClick={()=>{this.setState({informationModal:false}); setCookie("popupVisible",true,30)}}
                            >
                              Bir Daha Gösterme
                           </Button>
                          
                        </Col>
                     </Row>
                  </div>
               </ModalBody>
            </Modal>
         </>
      );
      
   }
}

function setCookie(name,value,days) {
   var expires = "";
   if (days) {
       var date = new Date();
       date.setTime(date.getTime() + (days*24*60*60*1000));
       expires = "; expires=" + date.toUTCString();
   }
   document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
   var nameEQ = name + "=";
   var ca = document.cookie.split(';');
   for(var i=0;i < ca.length;i++) {
       var c = ca[i];
       while (c.charAt(0)==' ') c = c.substring(1,c.length);
       if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
   }
   return null;
}

function mapStateToProps(state)
{
   return {
      popupMessage: state.popupMessageReducer
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         getPopupMessage: bindActionCreators(mainActions.getPopupMessage, dispatch)
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
