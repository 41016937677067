import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {post} from "axios";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {TagBox} from 'devextreme-react';
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as BB from '../../../components/BBComponent'
import {connect} from "react-redux";
import metrics from "../../../common/metrics";
import {Popup} from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import * as loadingActions from "../../../redux/actions/loadingActions";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

const DATA = [{ID: "_blank", Name: "Yeni Sekmede Aç"}, {ID: "_self", Name: "Olduğu Sayfada Aç"}]

class Sss extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         sssID: '',
         redirect: false,
         redirectUrl: '',
         addVisible: false,
         editVisible: false,
         isLink: false,
         isActive: false,
         roleList: [],
         targetList: DATA,
         editID: '',
         addSelectedRoleList: [],
         editSelectedRoleList: [],
         document: [],
         toogle: false
      }
      this.onValueChanged = this.onValueChanged.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleFile = this.handleFile.bind(this);
   }

   componentDidMount()
   {
      this.getDatas();
      this.props.actions.isLoading(false);
   }

   getDatas()
   {
      let URL = 'api/Yonetim/AdminSSS/GetData';
      BB.FetchJSON(URL).then(data =>
      {
         this.setState({gridData: data.Data})
      });
      let url = `api/Yonetim/AdminSSS/Add`;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({roleList: res.ViewBag.Roles});
      });
   }

   getData(ID)
   {
      let URL = `api/Yonetim/AdminSSS/Edit?ID=${ID}`;
      BB.FetchJSON(URL).then(res =>
      {
         this.findCommonElement(res.ViewBag.Roles, res.Data.Roles)
         this.setState({
            editSssData: res.Data,
            editTitle: res.Data.Title,
            editOrderNumber: res.Data.OrderNumber,
            editContent: res.Data.Body,
            editIsLink: res.Data.IsLink,
            editIsActive: res.Data.IsActive,
            editRoleList: res.ViewBag.Roles,
            editSelectedTargetID: res.Data.LinkTargetType,
            editDocument: res.Data.Documents
         })
      });
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginRight: 10}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({editID: cellData.data.ID, editVisible: true}, this.getData(cellData.data.ID));
               }}>
               {t("düzenle")}
            </Button>
            <Button
               color={'primary'}
               outline
               onClick={() => this.delete(cellData.data.ID)}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   delete(ID)
   {
      BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.props.actions.isLoading(true);
            const formData = new FormData();
            formData.append('ID', ID);
            post('api/Yonetim/AdminSSS/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.getDatas();
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {
                  this.getDatas();
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });
         }
      })
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   handleSelectChange(e)
   {
      const {id} = e.target
      if (id === 'editIsActive')
      {
         this.setState({editIsActive: !this.state.editIsActive})
      }
      else
      {
         if (id === 'editIsLink')
         {
            this.setState({editIsLink: !this.state.editIsLink})
         }
         else
         {
            if (id === 'isLink')
            {
               this.setState({isLink: !this.state.isLink})
            }
            else
            {
               if (id === 'isActive')
               {
                  this.setState({isActive: !this.state.isActive})
               }
            }
         }
      }
   }

   handleFile = (e) =>
   {
      const {id} = e.target
      if (id === 'editSelectedSssFiles')
      {
         this.setState({editSelectedSssFiles: e.target.files[0]})
      }
      else
      {
         if (id === 'selectedSssFiles')
         {
            this.setState({selectedSssFiles: e.target.files[0]})
         }
      }
   }

   onValueChanged(e)
   {
      const newValues = e.value;
      const {id} = e.element;
      if (id === 'addSelectedRoleList')
      {
         this.setState({addSelectedRoleList: newValues})
      }
      else
      {
         if (id === 'editSelectedRoleList')
         {
            this.setState({editSelectedRoleList: newValues})
         }
      }

   }

   handleSubmit = (e) =>
   {
      const {title, orderNumber, content, addSelectedRoleList, selectedSssFiles, isLink, isActive, selectedTargetID} = this.state;
      if (orderNumber === '' || orderNumber === undefined || title === undefined || title === '' || addSelectedRoleList.length == 0)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            Title: title,
            Body: content,
            OrderNumber: orderNumber,
            IsLink: isLink,
            IsActive: isActive,
            LinkTargetType: selectedTargetID
         }
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('file', selectedSssFiles);
         formData.append('RoleIDs', addSelectedRoleList);
         post('api/Yonetim/AdminSSS/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  OrderNumber: '',
                  isLink: false,
                  isActive: false,
                  title: '',
                  orderNumber: '',
                  content: '',
                  addSelectedRoleList: [],
                  selectedSssFiles: '',
                  editSelectedSssFiles: '',
                  selectedTargetID: '',
                  addVisible: false
               });
               this.props.actions.isLoading(false);
               this.getDatas();
               notify(t("eklemebasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               notify(t("eklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }
   handleEditSubmit = (e) =>
   {
      const {editTitle, editOrderNumber, editContent, editSelectedRoleList, editSelectedSssFiles, editIsLink, editIsActive, editSelectedTargetID} = this.state;
      if (editOrderNumber === '' || editOrderNumber === undefined || editTitle === undefined || editTitle === '' || editSelectedRoleList.length == 0)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            Title: editTitle,
            Body: editContent,
            OrderNumber: editOrderNumber,
            IsLink: editIsLink,
            IsActive: editIsActive,
            LinkTargetType: editSelectedTargetID
         }
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('file', editSelectedSssFiles);
         formData.append('RoleIDs', editSelectedRoleList);
         formData.append('EditID', this.state.editID);
         post('api/Yonetim/AdminSSS/Edit', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.getDatas();
               this.props.actions.isLoading(false);
               this.setState({editVisible: false})
               notify(t("duzenlemeislemibasarili"), "success", 1000);
            }
            else
            {
               notify(t("duzenlemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }

   findCommonElement(array1, array2)
   {
      var data = [];
      for (let i = 0; i < array1.length; i++)
      {
         for (let j = 0; j < array2.length; j++)
         {
            if (array1[i].ID === array2[j].RoleID)
            {
               data.push(array1[i].ID)
            }
         }
      }
      this.setState({editSelectedRoleList: data, toogle: true})
   }

   actionButtonDocument = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'light'}
               onClick={() =>
               {
                  this.downloadSssDocument(cellData.data)
               }}>
               <i className='icon-cloud-download icon-delete'></i>
            </Button>
            <Button
               style={{marginLeft: 5}}
               color={'light'}
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizdokumansilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.deleteDocument(cellData.data.ID);
                     }
                  })
               }}>
               <i className='icon-trash icon-delete'></i>
            </Button>
         </div>
      );
   }

   downloadSssDocument(data)
   {
      var anchor = document.createElement('a');
      anchor.href = data.FilePath;
      anchor.target = '_blank';
      anchor.download = data.DocumentTitle;
      anchor.click();
   }

   deleteDocument(ID)
   {
      this.props.actions.isLoading(false);
      let url = 'api/Yonetim/AdminSSS/DeleteDocument?ID=' + ID;
      BB.FetchJSON(url).then(data =>
      {
         if (data.MessageStatus === true)
         {
            this.props.actions.isLoading(false);
            this.setState({editVisible: false})
            notify(t("dokumanbasariylasilindi"), "success", 1000);
         }
         else
         {
            notify(t("dokumansilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   render()
   {
      const {gridData, redirectUrl, sssID, isActive, isLink, editSelectedRoleList, toogle, editIsActive, editIsLink, editDocument} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {SssID: sssID}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({
                              addVisible: true
                           })}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="SSS Listesi"/>
                           <Column
                              dataField="Title"
                              caption={t("baslik")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Body"
                              caption={t("açıklama")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="IsActive"
                              caption={t("aktifpasif")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={150}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               {/*SSS ekleme, Popup*/}
               <Popup
                  visible={this.state.addVisible}
                  dragEnabled={false}
                  showTitle={false}
                  title={t("sssekleme")}
                  minWidth={metrics.popupMinWidth}
                  maxWidth={metrics.popupAutoWidth}
                  height={500}
               >
                  <ScrollView>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col lg='6'>
                              {BB.InputWithLabel('title', t("baslik"), 'text', this)}
                           </Col>
                           <Col lg='6'>
                              {BB.InputWithLabel('orderNumber', t("siranumarasi"), 'number', this)}
                           </Col>
                           <Col lg='12'>
                              {BB.TextAreaWithLabel('content', t("icerik"), t("icerik"), 4, this)}
                           </Col>
                           <Col lg='6'>
                              {BB.DxDropDownWithJSON('selectedTargetID', t("linksayfasi"), this, this.state.targetList)}
                           </Col>
                           <Col lg='6'>
                              <label><strong>{t("yetkigrubu")}</strong></label>
                              <TagBox
                                 items={this.state.roleList}
                                 showSelectionControls={true}
                                 maxDisplayedTags={3}
                                 displayExpr="Name"
                                 valueExpr="ID"
                                 id='addSelectedRoleList'
                                 selectAllMode="allPages"
                                 onValueChanged={this.onValueChanged}
                              />
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="isLink"
                                        onChange={this.handleSelectChange} value={isLink} checked={isLink}/>
                                 <Label className="form-check-label" check htmlFor="isLink">Link</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="isActive"
                                        onChange={this.handleSelectChange} value={isActive} checked={isActive}/>
                                 <Label className="form-check-label" check htmlFor="isActive">{t("aktifpasif")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='3' style={{paddingTop: 10}}>
                              <FormGroup>
                                 <Label htmlFor='selectedSssFiles'>
                                    <strong>{t("dosyaekle")}</strong>
                                 </Label>
                                 <Input
                                    type='file'
                                    id='selectedSssFiles'
                                    onChange={this.handleFile}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                           <Button
                              color={'info'}
                              onClick={() => this.setState({addVisible: false})}>
                              {t("vazgec")}
                           </Button>
                           <Button
                              color={'success'}
                              type='submit'
                              style={{marginLeft: 20, marginRight: 0}}>
                              {t("kaydet")}
                           </Button>
                        </Row>
                     </Form>
                  </ScrollView>
               </Popup>
               {/*SSS düzenleme, Popup*/}
               <Popup
                  visible={this.state.editVisible}
                  dragEnabled={false}
                  showTitle={false}
                  title={t("sssduzenleme")}
                  minWidth={metrics.popupMinWidth}
                  maxWidth={metrics.popupAutoWidth}
                  maxHeight={metrics.popupMaxHeight}
               >
                  <ScrollView>
                     <Form onSubmit={this.handleEditSubmit}>
                        <Row>
                           <Col lg='6'>
                              {BB.InputWithLabel('editTitle', t("baslik"), 'text', this)}
                           </Col>
                           <Col lg='6'>
                              {BB.InputWithLabel('editOrderNumber', t("siranumarasi"), 'number', this)}
                           </Col>
                           <Col lg='12'>
                              {BB.TextAreaWithLabel('editContent', t("icerik"), t("icerik"), 4, this)}
                           </Col>
                           <Col lg='6'>
                              {BB.DxDropDownWithJSON('editSelectedTargetID', t("linksayfasi"), this, this.state.targetList)}
                           </Col>

                           <Col lg='6'>
                              <label><strong>{t("yetkigrubu")}</strong></label>
                              {
                                 toogle ?
                                    <TagBox
                                       items={this.state.roleList}
                                       defaultValue={editSelectedRoleList}
                                       showSelectionControls={true}
                                       maxDisplayedTags={3}
                                       displayExpr="Name"
                                       id='editSelectedRoleList'
                                       valueExpr="ID"
                                       selectAllMode="allPages"
                                       onValueChanged={this.onValueChanged}
                                    />
                                    : null
                              }
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="editIsLink"
                                        onChange={this.handleSelectChange} value={editIsLink} checked={editIsLink}/>
                                 <Label className="form-check-label" check htmlFor="editIsLink">Link</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="editIsActive"
                                        onChange={this.handleSelectChange} value={editIsActive} checked={editIsActive}/>
                                 <Label className="form-check-label" check htmlFor="editIsActive">{t("aktifpasif")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='3' style={{paddingTop: 10}}>
                              <FormGroup>
                                 <Label htmlFor='editSelectedSssFiles'>
                                    <strong>{t("dosyaekle")}</strong>
                                 </Label>
                                 <Input
                                    type='file'
                                    id='editSelectedSssFiles'
                                    onChange={this.handleFile}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col lg='12' style={{marginRight: 0, marginTop: 5, marginBottom: 10}}>
                              <Button
                                 color={'success'}
                                 className='float-right'
                                 type='submit'
                                 style={{marginLeft: 10, marginRight: 5}}>
                                 {t("kaydet")}
                              </Button>
                              <Button
                                 color={'info'}
                                 className='float-right'
                                 onClick={() => this.setState({
                                    editVisible: false,
                                    editSelectedRoleList: [],
                                    toogle: false
                                 })}>
                                 {t("vazgec")}
                              </Button>

                           </Col>
                        </Row>
                     </Form>
                     <Row>
                        <Col lg='12'>
                           <Card>
                              <CardHeader>{t("baglıdokumanlar")}</CardHeader>
                              <CardBody>
                                 <DataGrid
                                    dataSource={editDocument}
                                    showRowLines={true}
                                    showColumnLines={false}
                                    showBorders={false}
                                 >
                                    <Paging
                                       defaultPageSize={10}/>
                                    <Pager
                                       showPageSizeSelector={true}
                                       allowedPageSizes={[5, 10, 20, 50]}
                                       showNavigationButtons={true}
                                    />
                                    <Column
                                       dataField="DocumentTitle"
                                       caption={t("dokumanbasligi")}
                                       minWidth={220}
                                       allowFiltering={true}/>
                                    <Column
                                       dataField="İşlemler"
                                       caption={t("islemler")}
                                       width={250}
                                       cellRender={this.actionButtonDocument}
                                    />
                                 </DataGrid>
                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </ScrollView>
               </Popup>
            </Row>

         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Sss));

