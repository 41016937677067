import React from "react";
import './styles.scss';
import * as BB from '../../BBComponent';
import {Label} from "reactstrap";

const CategoryCustomControl = ({customElementsData, event}) =>
{
   if (customElementsData !== null)
   {
      return (
         <>
            {customElementsData.map((item, i) =>
            {
               // Aciklama = 0,
               if (item.CustomControlType === 0)
               {
                  return <div key={i}>
                     <Label htmlFor={item.ID} style={{color: 'brown'}}>
                        <strong color={'brown'}>{item.Value}</strong>
                        <br/>
                     </Label>
                     <br/>
                  </div>
               }

               // Tarih = 1,
               if (item.CustomControlType === 1)
               {
                  return <div
                     key={i}>{BB.DateSelectWithLabel(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, event)}</div>
               }

               // DosyaYukleme = 2,
               if (item.CustomControlType === 2)
               { //FileWithLabel
                  return <div
                     key={i}>{BB.FileWithLabelByResolveFile(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, event)}</div>
               }

               // DosyaIndirme = 3,
               if (item.CustomControlType === 3)
               {
                  let itemName = item.Value.split('/')[item.Value.split('/').length - 1];
                  let url = item.Value.split('build')[1];

                  return <div key={i}>
                     {/* item.Document.FilePath.split('_')[item.Document.FilePath.split('_').length - 1].toString() */}
                     <a href={url} download={itemName.split('_')[itemName.split('_').length - 1].toString()}>{itemName.split('_')[itemName.split('_').length - 1].toString()}</a>
                     <br/>
                     <br/>
                  </div>   
               }

               // MetinGirisi = 4,
               if (item.CustomControlType === 4)
               {
                 
                  return <div
                  
                     key={i}>{BB.InputWithLabel(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, 'text', event, '')}</div>
               }

               // CokluMetinGirisi = 5,
               if (item.CustomControlType === 5)
               {
                  return <div
                     key={i}>{BB.TextAreaWithLabel(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, '', 4, event)}</div>
               }

               // CheckBox = 6,
               if (item.CustomControlType === 6)
               {
                  return <div
                     key={i}>{BB.CheckBoxWithLabel(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, event)}</div>
               }

               // IzinSecenek = 7,
               // if (item.CustomControlType === 7)
               // {
               //    return <div key={i}>{BB.InputWithLabel('ssss', item.Value, 'text', event, '')}</div>;
               // }

               // DropDown = 8
               if (item.CustomControlType === 8)
               {


                   return <div
                       key={i}>{BB.DxDropDownWithJSON(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, event, item.DropdownDatasource)}</div>

                  //return <div
                  //   key={i}>{BB.DxDropDownWithURL(item.ID, item.IsRequired === true ? item.Value + '*' : item.Value, event, 'api/Yonetim/Parameter/GetData?ID=' + item.DropDownParentID)}</div>

               }
            })}
         </>)
   }
}

export default CategoryCustomControl;
