import React, { PureComponent } from 'react';
import * as BB from "../../../BBComponent";
import notify from "devextreme/ui/notify";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import * as mainActions from "../../../../redux/actions/mainActions";
import * as taskActions from "../../../../redux/actions/taskActions";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getTaskAssigned } from "../../../../redux/actions/getTaskAssignedActions";
import { data } from 'jquery';
import TableTaskAssigned from '../../../custom/TableTaskAssigned/TableTaskAssigned';

class TaskAssignedRequests extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            getLast10TaskList: [],
            redirect: false,
            taskID: undefined
        };
    }

    componentDidMount() {
        this.props.actions.getTaskAssigned();
    }

    handleDetail(id) {
        this.props.props.history.push('/TaskDetail?' + id)
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {
                    this.props.watcherTasks !== null && (
                        <TableTaskAssigned
                            title={t("görevAtananTalepler")}
                            description={t("görevAtananTaleplerAciklama")}
                            data={this.props.watcherTasks}
                            t={t}
                            handleDetail={(id) => this.handleDetail(id)}
                            
                        />
                    )
                }

            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        watcherTasks: state.getTaskAssignedReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            getTaskAssigned: bindActionCreators(getTaskAssigned, dispatch),
        }
    }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskAssignedRequests)))
