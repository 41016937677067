import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, InputGroup, ListGroup, ListGroupItem, Label } from 'reactstrap';
import { FormGroup } from "semantic-ui-react";
import * as BB from '../../BBComponent';

const UserSelect = ({ visible, event , t}) => {
    
    const companyFilter = (e) => {
        event.setState({ companySelectToolListVisible: true });
        var companies = event.state.taskCompanies;
        if (event.state.companySelectTool.length > 2) {
            //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

            //   

            var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(event.state.companySelectTool.toLowerCase()));

            event.setState({ taskCompaniesTemp: filteredCompanies });
            //})
        } else {
            let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(event.state.companySelectTool.toLowerCase())).slice(0,30);
            let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: "Daha fazla Firma için en az 3 karakter girmelisiniz...", ParentID: null };
            tempCompanies = tempCompanies.concat(itemdef);
            event.setState({ taskCompaniesTemp: tempCompanies });
        }

    }
    const onClickFilter = (filter) => {
        if (filter.ID !== '') {
            event.setState({ companySelectTool: filter.Name })
            event.handleChange('selectedCreateUserCompanyID', filter.ID)
            event.setState({ companySelectToolListVisible: false });
        }
    }
    return (
        <Modal isOpen={visible}>
            <ModalHeader>{t("kullaniciDegistir")}</ModalHeader>
            <ModalBody>
                <FormGroup>

                    <Label>
                        <strong>{t("firmaListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={event.state.companySelectTool} type="text" autoFocus={true} name={'selectedCreateUserCompanyID'} onChange={(e) => { event.setState({ companySelectTool: e.target.value }, companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px', display: event.state.companySelectToolListVisible ? 'block' : 'none' }}>
                        {
                            event.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === event.state.selectedCreateUserCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={() => onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {/* {BB.DxSelectBoxWithJSON('selectedCreateUserCompanyID', 'Kurum Seçiniz', event, event.state.taskCompanies)}*/}
                    {BB.DxDropDownWithJSON('selectedEmployee', t("kullaniciSeciniz"), event, event.state.taskCreateUserList)}
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color={'danger'} style={{ float: 'right' }} onClick={() => { event.setState({ userSelectVisible: false }); event.setState({ taskCompaniesTemp: [], companySelectToolListVisible: false, companySelectTool:''  });  }}>
                    {t('kapat')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default UserSelect;

{/**/
}
