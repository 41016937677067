import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import * as BB from "../../../../components/BBComponent";
import { callAPI, callApiGet } from "../../../../components/Axios";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class TaskCustomControl extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         customControlID: '',
         redirect: false,
         redirectUrl: ''
      }
   }

   componentDidMount()
   {
      BB.FetchJSON('api/Yonetim/CustomControls/GetData').then(res =>
      {
         this.setState({gridData: res.Data});
         this.props.actions.isLoading(false);
      })
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/CustomControls/GetData').then(res =>
      {
         this.setState({gridData: res.Data});
         this.props.actions.isLoading(false);
      })
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               color={'info'}
               className='mr-1'
               outline
               onClick={() =>
               {
                  this.setState({
                     customControlID: cellData.data.ID,
                     redirect: true,
                     redirectUrl: '/Yonetim/CustomControlEdit'
                  });
               }}>
               {t("düzenle")}
            </Button>
            <Button
               color={'info'}
               className='mr-1'
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizozelalansilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}
            > {t("sil")}
            </Button>
         </div>
      );
   }

   delete(ID)
   {
      this.props.actions.isLoading(false);
      let url = `api/Yonetim/CustomControls/Delete?ID=` + ID;
      BB.FetchJSON(url).then(res =>
      {
         if (res.MessageStatus === true)
         {
            this.getData()
            this.props.actions.isLoading(false);
            notify(t("ozelalanbasariylasilinmis"), "success", 1000);
         }
         else
         {
            notify(t("ozelalansilmeislemindehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   render()
   {
      const {gridData, redirectUrl, customControlID} = this.state;

      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {CustomControlID: customControlID}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({redirect: true, redirectUrl: '/Yonetim/CustomControlAdd'})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>
                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={true}
                           showBorders={false}
                           allowColumnResizing={true}
                           columnResizingMode={'nextColumn'}
                           allowColumnReordering={true}
                           onCellHoverChanged={(e) => null}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Kategori Listesi"/>
                           <Column
                              dataField="Category"
                              caption={t("kategori")}
                             //minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="CustomControlType"
                              caption={t("kontroltipi")}
                              //minWidth={200}
                              allowFiltering={true}/>
                               <Column
                             dataField="ParamName"
                              caption={t("parametre")}
                              //minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Value"
                              caption={t("deger")}
                             // minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="OrderNumber"
                              caption={t("sira")}
                              //minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="IsRequired"
                              caption={t("zorunluluk")}
                              allowFiltering={true}/>
                           <Column
                              dataField="IsActive"
                              caption={t("aktifpasif")}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              //width={150}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskCustomControl));
