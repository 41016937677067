import React from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import * as BB from "../../../../../components/BBComponent";
import List from "devextreme-react/list";
import 'semantic-ui-css/semantic.min.css'

const Watcher = ({ event, t }) => {
   return (
      <Modal isOpen={event.state.visibleWatcher} centered={true} size={'l'}>
         <ModalHeader toggle={() => event.setState({ selectedWatcherUserList: [], visibleWatcher: false })}>
            {t("ekbilgilendirme")}
         </ModalHeader>
         <ModalBody>
            <Row>
               <Col lg={8}>
                  {BB.DxMultipleDropdown('selectedWatcherUserID', t("kullaniciSeciniz"), event, event.state.watcherList)}
               </Col>
               <Col lg={4}>
                  <Button
                     color={'light'}
                     block
                     style={{ marginTop: 27, float: 'right' }}
                     onClick={() => {
                        let selectedUser = [];
                        let AllUser = event.state.watcherList;
                        let addingList = [];

                        event.state.selectedWatcherUserID.map((item, i) => {
                           selectedUser.push(AllUser.filter(x => x.ID == item)[0])
                        });

                        selectedUser.map(sUser => {
                           let hasList = false;

                           event.state.selectedWatcherUserList.map(item => {
                              if (item.ID === sUser.ID) {
                                 hasList = true;
                              }
                           })

                           if (hasList === false) {
                              addingList.push(sUser);
                           }
                        })

                        event.setState({ selectedWatcherUserList: event.state.selectedWatcherUserList.concat(addingList), })
                     }}
                  >
                     {t("listeyeEkle")}
                  </Button>
               </Col>
            </Row>

            <Col>
               <List
                  keyExpr={"ID"}
                  displayExpr={"Name"}
                  dataSource={event.state.selectedWatcherUserList}
                  height={110}
                  allowItemDeleting={true}
                  itemDeleteMode={"static"}
                  showSelectionControls={true}
               />
            </Col>
            <Col>
               <Row>
                  <Col lg={8}>
                     {BB.DxMultipleDropdown('selectedWatcherGroupsID', t("izleyiciGrubuSeciniz"), event, event.state.oldGroupUsers)}
                  </Col>
                  <Col lg={4}>
                     <Button
                        color={'light'}
                        block
                        style={{ marginTop: 27, float: 'right' }}
                        onClick={() => {
                           // event.GetWatcherGroupUsers(event.state.selectedWatcherGroups.concat(addingList));
                           event.GetWatcherGroupUsers(event.state.selectedWatcherGroupsID);
                        }}
                     >
                        {t("listeyeEkle")}
                     </Button>
                  </Col>
               </Row>
               <List
                  keyExpr={"ID"}
                  displayExpr={"Name"}
                  dataSource={event.state.selectedWatcherGroups}
                  height={110}
                  allowItemDeleting={false}
                  itemDeleteMode={"static"}
                  showSelectionControls={true}
               />
            </Col>
         </ModalBody>

         <ModalFooter>
            <Col>
               {BB.CheckBoxWithLabel('isDepartmanNotification', t('departmanabildir'), event)}
            </Col>
            <Col>
               <Button
                  color={'info'}
                  onClick={() => {
                     event.setState({
                        selectedWatcherUserList: [],
                        isDepartmanNotification: false,
                        selectedWatcherUserID: [],
                        selectedWatcherGroupsID: [],
                        selectedWatcherGroups: []
                     })
                     try {
                        event.treeView.instance.unselectAll();
                     }
                     catch (e) {

                     }
                  }}
               >
                  {t("listetemizle")}
               </Button>
               <Button
                  style={{ float: 'right' }}
                  color={'success'}
                  onClick={() => event.setState({ visibleWatcher: false, })}
               >
                  {t("kaydet")}
               </Button>
            </Col>
         </ModalFooter>
      </Modal>
   )
}
export default Watcher;
