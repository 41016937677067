import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from './index'
import thunk from 'redux-thunk';

function loadFromLocalStorage()
{
   try
   {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
         return undefined
      } else {
         localStorage.setItem('storageState', JSON.stringify(true));
      }
      return JSON.parse(serializedState);
   }
   catch (e)
   {
      return undefined;
   }
}


const persistedState = loadFromLocalStorage();

export default function configureStore()
{
   return createStore(
      rootReducer,
      persistedState,
      compose(
         applyMiddleware(thunk),
         window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
      )
   );
}


