import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap'
import DataGrid, {Column, Editing, Pager, Paging, SearchPanel, Selection, Texts} from "devextreme-react/data-grid";
import CustomInput from '../../../components/admin/CustomInput/CustomInput'
import CustomButton from '../../../components/admin/CustomButton/CustomButton'
import {post} from "axios";
import Swal from 'sweetalert2';
import notify from "devextreme/ui/notify";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import * as BB from "../../../components/BBComponent";
import {Redirect} from 'react-router-dom';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CompanyAdd extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         kurum: '',
         telefon: '',
         adres: '',
         toogle: false,
         gridData: undefined,
         changeGridData: [],
         changeGridMultiData: [],
         selectedDepartment: [],
         taskList: [],
         info: {
            error: '',

         },
         addDepartmentVisible: false,
         departmentName: '',
         redirectUrl: '',
         redirect: false,
         confirmationType: [],
         selectedConfirmationTypeID: '7515e172-5907-e911-80f7-005056b839bb',
         useSurvey: [],
         selectedUseSurveyID: '7b685298-5907-e911-80f7-005056b839bb',

      }
      let { t } = this.props;
      this.handleChangeInput = this.handleChangeInput.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTask = this.handleTask.bind(this);
      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.onSelectionMultiChanged = this.onSelectionMultiChanged.bind(this);
      this.onRowRemoving = this.logEvent.bind(this, 'RowRemoving');
      this.onRowRemoved = this.logEvent.bind(this, 'RowRemoved');
   }

   componentDidMount()
   {
      BB.FetchJSON('api/Common/GetDepartment').then(res =>
      {
         this.setState({gridData: res});
      })
      BB.FetchJSON('api/Yonetim/Companies/GetSurveyConfirmation').then(res =>
      {
         this.setState({useSurvey: res.Survery, confirmationType: res.Confirmations})
      })

   }

   logEvent(eventName)
   {
      this.setState((state) =>
      {
         return {events: [eventName].concat(state.events)};
      });
   }

   handleChangeInput = (e) =>
   {
      const {name, value} = e.target;
      this.setState({[name]: value})
   }
   handleSubmit = (e) =>
   {
      const {kurum, telefon, adres} = this.state;
      if (kurum === '' || telefon === '' || adres === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {

         const formData = new FormData();
         let entity = {
            Name: this.state.kurum,
            Address: this.state.adres,
            Phone: this.state.telefon,
            IsActive: this.state.toogle,
            UseSurveyID: this.state.selectedUseSurveyID,
            ConfirmationTypeID: this.state.selectedConfirmationTypeID
         }

         let departmans = this.state.taskList;
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('selectedData', JSON.stringify(departmans));
         post('api/Yonetim/Companies/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  kurum: '',
                  adres: '',
                  telefon: ''
               });
               this.props.actions.isLoading(false);
               notify(t("firmabasarilibirsekildeeklendi"), "success", 1000);
               this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})
            }
            else
            {
               this.setState({
                  addDepartmentVisible: false,
                  //editPoolVisible: false,
                  departmentName: ''
               });
               notify(t("firmaeklemedehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }

   onSelectionChanged({selectedRowsData})
   {
      const data = selectedRowsData[0];
      this.setState({selectedDepartment: data})
      let url = 'api/Common/GetJobsForDepartment?ID=' + data.ID;

      BB.FetchJSON(url).then(res =>
      {
         this.setState({changeGridData: res})
      });

      /*axios.get(`api/Common/GetJobsForDepartment?ID=`+data.ID)
      .then(res => {
        const data = res.data;
        this.setState({ changeGridData:data});
      })*/

   }

   onSelectionMultiChanged({selectedRowsData})
   {
      this.selectionChangedBySelectBox = false;
      this.setState({changeGridMultiData: selectedRowsData})
   }

   handleTask()
   {
      let selectedItemsJob = this.state.changeGridMultiData;
      let selectedDepartment = this.state.selectedDepartment;
      let task = this.state.taskList;
      if (selectedItemsJob.length > 0)
      {
         let data = [];
         for (let i = 0; i < selectedItemsJob["length"]; i++)
         {
            let a = selectedItemsJob[i]["ID"];
            let b = selectedItemsJob[i]["text"];

            let o =
               {
                  'depID': selectedDepartment.ID,
                  'depText': selectedDepartment.text,
                  'jobID': a,
                  'jobText': b
               };
            data.push(o)
            let c = task.filter((item) => item.jobID === data[i]['jobID']);
            if (c.length > 0)
            {
               Swal.fire({
                  type: 'error',
                  title: t("zateneklenmis"),
                  text: b + t("listedezatenbulunmaktadir")
               })
            }
            else
            {
               let joined = task.concat(data);
               this.setState({taskList: joined})
            }
         }

      }
      else
      {
         Swal.fire({
            icon: 'error',
            text: t("oncelistedensecimyapmalisiniz"),
            showCancelButton: false
         })
      }
   }

   handleChange(id, value)
   {
      if (id === 'toogle')
      {
         this.setState({toogle: !this.state.toogle});
      }
      else
      {
         this.setState({[id]: value});
      }
   }


   render()
   {
      const {
         adres,
         kurum,
         telefon,
         toogle,
         gridData,
         changeGridData,
         taskList,
         redirect,
         redirectUrl,
         useSurvey,
         confirmationType
      } = this.state;

      if (redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl}}/>
      }
      return (
         <Row>
            <Col sm='12' md='12' lg='12'>
               <Card>
                  <CardHeader>{t("firmaolustur")}</CardHeader>
                  <CardBody>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col lg='6' sm='12'>
                              <CustomInput type='text' label={t("kurumadi")} placeholder="" name="kurum"
                                           handleChange={this.handleChangeInput} value={kurum} rq='*'/>
                           </Col>
                           <Col lg='6' sm='12'>
                              <CustomInput type='text' label={t("telefon")} placeholder="" name="telefon"
                                           handleChange={this.handleChangeInput} value={telefon} rq='*'/>
                           </Col>
                           <Col lg='12' sm='12'>
                              <CustomInput type='textarea' label={t("adres")} placeholder="" name="adres"
                                           handleChange={this.handleChangeInput} value={adres} rq='*'/>
                           </Col>
                        </Row>
                        <Row>
                           <Col lg='3' sm='12' className='mb-3'>
                              {BB.DxDropDownWithJSON('selectedConfirmationTypeID', '', this, confirmationType)}
                           </Col>
                           <Col lg='3' sm='12' className='mb-3'>
                              {BB.DxDropDownWithJSON('selectedUseSurveyID', '', this, useSurvey)}
                           </Col>
                        </Row>
                        <Row>
                           <Col>{BB.CheckBoxWithLabel('toogle', t("aktifolarakkaydetmekicintiklayiniz"), this, false)}</Col>
                        </Row>
                        <DataGrid
                           id="taskListGridData"
                           dataSource={taskList}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                           onRowRemoving={this.onRowRemoving}
                           onRowRemoved={this.onRowRemoved}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <Editing mode="row" allowDeleting={true}>
                              <Texts
                                 deleteRow={t("sil")}
                                 confirmDeleteMessage={t("silmekistediginizeeminmisiniz")}
                              />
                           </Editing>
                           <Column
                              dataField="depText"
                              caption={t("departmantanimi")}
                              minWidth={220}
                              allowFiltering={true}/>
                           <Column
                              dataField="jobText"
                              caption={t("gorevtanimi")}
                              minWidth={220}
                              allowFiltering={true}/>
                        </DataGrid>
                        <div>
                           <CustomButton color='success' cs='float-right  mt-10' type='submit'>{t("firmayikaydet")}</CustomButton>
                        </div>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
            <Col sm='12' md='12' lg='12'>
               <Row>
                  <Col sm='12' md='12' lg='6'>
                     <Card>
                        <CardHeader>
                           {t("departmanlar")}
                        </CardHeader>
                        <CardBody className='card-body-height'>
                           <DataGrid
                              id="gridContainer"
                              dataSource={gridData}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                              onSelectionChanged={this.onSelectionChanged}
                              hoverStateEnabled={true}
                              selection={{mode: 'single'}}
                              keyExpr="ID"
                              onRowRemoving={this.onRowRemoving}
                              onRowRemoved={this.onRowRemoved}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <Editing mode="row" allowAdding={true}>
                                 <Texts
                                    deleteRow='Sil'
                                    confirmDeleteMessage={t("silmekistediginizeeminmisiniz")}
                                    width={300}
                                    height={300}
                                 />
                              </Editing>
                              <SearchPanel visible={true} width={100}/>
                              <Column
                                 dataField="text"
                                 caption={t("departmentlistesi")}
                                 minWidth={220}
                                 allowFiltering={true}/>
                           </DataGrid>
                        </CardBody>
                     </Card>
                  </Col>
                  <Col sm='12' md='12' lg='6'>
                     <Card>
                        <CardHeader>
                           {t("gorevler")}
                        </CardHeader>
                        <CardBody className='card-body-height'>
                           <DataGrid
                              id="gridContainer"
                              dataSource={changeGridData}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                              hoverStateEnabled={true}
                              onSelectionChanged={this.onSelectionMultiChanged}
                              keyExpr="ID"
                              ref={ref => this.dataGrid = ref}
                           >
                              <Selection mode="multiple"/>
                              <Paging pageSize={10}/>
                              <Editing
                                 mode="row"
                                 allowAdding={true}
                                 showTitle={true}
                                 width={300}
                                 height={300}
                              />
                              <SearchPanel visible={true} width={100}/>
                              <Column
                                 dataField="text"
                                 caption={t("gorevlistesi")}
                                 minWidth={220}
                                 allowFiltering={true}/>
                           </DataGrid>
                           <CustomButton color='light' cs='float-right secilenButton' handleClick={this.handleTask}>{t('secilengorevlerikaydet')}</CustomButton>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Col>
         </Row>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(CompanyAdd)
);
