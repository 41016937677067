import React, {Component} from 'react';
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row,Label, Input, InputGroup, ListGroup, ListGroupItem} from 'reactstrap';
import { FormGroup } from "semantic-ui-react";
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import ScrollView from 'devextreme-react/scroll-view';
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CategoryAuthority extends Component
{
   constructor(props)
   {
         // Global bir değişken tanımlanacak "PageAccesID = Token içerisinde olan menü ID var ise sayfaya devam et yoksa defaulta yönlendir . "
         //Global metoda da yazılabilir . 
      super(props);
      this.state = {
         treeData: [],
         roleCategoryDatas: [],
         roles: [],
         companies: [],
         selectedCategoryID: undefined,
         selectedRoleCategoryID: undefined,
         addRoleCategoryRowVisible: false,
         selectedCompanyID: undefined,
         selectedRolePackageGroupID: undefined,
         selectedUserID: undefined,
         usersByCompanies:[],
         companySelectToolListVisible:false,
         companySelectTool:'',
         taskCompaniesTemp: [],
      }
   }

   componentDidMount()
   {
      this.getDatas();

      this.props.actions.isLoading(false);
   }
  
   getDatas()
   {
      let urlTree = 'api/Yonetim/RoleCategories/GetCategories/';

      BB.FetchJSON(urlTree).then(data =>
      {
         this.setState({
            treeData: data.Data.Categories,
            companies: data.Data.Companies,
            roles: data.Data.Roles,
            isLoading: false
         })
      });
   }

   actionRoleCategory(cellData)
   {
      return (
         <div>
            <Button
               color={'primary'}
               outline
               onClick={() => this.deleteRoleCategory(cellData)}>
               Sil
            </Button>
         </div>
      );
   }

   getRoleCategory()
   {
      let urlCategoryRolePackage = 'api/Yonetim/RoleCategories/GetData?id=' + this.state.selectedCategoryID;

      BB.FetchJSON(urlCategoryRolePackage).then(data =>
      {
         this.setState({roleCategoryDatas: data.Data, isLoading: false})
      });
   }

   getUsersByCompany(){
      let usersByCompany = '/api/Yonetim/RoleCategories/GetUsers?companyID=' + this.state.selectedCompanyID + '&categoryID=' + this.state.selectedCategoryID;
      BB.FetchJSON(usersByCompany).then(data =>
         {
            this.setState({usersByCompanies: data, isLoading: false})
         });
   }
   onClickFilter  (filter)  {
      if (filter.ID !== '') {
          this.setState({ companySelectTool: filter.Name })
          this.handleChange('selectedCompanyID', filter.ID)
          this.setState({ companySelectToolListVisible: false });
      }
  }
  companyFilter  (e){
   this.setState({ companySelectToolListVisible: true });
   var companies = this.state.companies
   if (this.state.companySelectTool.length > 2) {
       //getFilterCategories(event.state.selectedEmployee, catTerm).then(data => {

       //

       var filteredCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase()));

       this.setState({ taskCompaniesTemp: filteredCompanies });
       //})
   } else {
       let tempCompanies = companies.filter(x => x.Name.toLowerCase().includes(this.state.companySelectTool.toLowerCase())).slice(0,30);
       let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: t("dahafazlafirmaicinenazuckaraktergirmelisiniz"), ParentID: null };
       tempCompanies = tempCompanies.concat(itemdef);
       this.setState({ taskCompaniesTemp: tempCompanies });
   }

}
   addRoleCategory()
   {
      this.setState(
         {
            addRoleCategoryRowVisible: true
         });

      if (this.state.selectedUserID === '' || this.state.selectedUserID === undefined ||
         this.state.selectedCompanyID === '' || this.state.selectedCompanyID === undefined 
      )
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else if (this.state.selectedCategoryID === '' || this.state.selectedCategoryID === undefined)
     {         notify(t("talepkategorisialaninidoldurunuz"), "warning", 2000);
   }
      else
      {
         this.props.actions.isLoading(true);
      }


      let entity = {
         RoleID: this.state.selectedRolePackageGroupID,
         UserID: this.state.selectedUserID,
         CompanyID: this.state.selectedCompanyID,
         CategoryID: this.state.selectedCategoryID
      }

      const formData = new FormData();
      formData.append('jsonText', JSON.stringify(entity));

      post('api/Yonetim/RoleCategories/Add', formData, {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         if (res.data.MessageStatus === true)
         {
            this.setState({
               selectedRoleCategoryID: undefined,
               addRoleCategoryRowVisible: false,
               selectedCompanyID: undefined,
               selectedRolePackageGroupID: undefined,
               selectedUserID: undefined,
            }, this.getRoleCategory());
            this.props.actions.isLoading(false);
            notify(t("islemBasarili"), "success", 1000);
         }
         else
         {
            this.setState({
               selectedRoleCategoryID: undefined,
               addRoleCategoryRowVisible: false,
               selectedCompanyID: undefined,
               selectedRolePackageGroupID: undefined,
               selectedUserID: undefined,
            }, this.getRoleCategory());
            this.props.actions.isLoading(false);
            notify(t("islemBasarisiz") + res.Message, "warning", 1000);
         }
      })
      .catch(ex =>
      {
         this.props.actions.isLoading(false);
      });
   }

   deleteRoleCategory(cellData)
   {
      BB.askYesNo(t("onaylıyorMusunuz?"), t("sectiginizmesajsilinecektir"), t("evet"), t("hayır")).then(res =>
      {
         if (res)
         {
            this.state.selectedRoleCategoryID = cellData.data.ID;

            this.props.actions.isLoading(true);

            const formData = new FormData();

            formData.append('id', this.state.selectedRoleCategoryID);

            post('api/Yonetim/RoleCategories/Delete', formData, {
               headers: {
                  'content-type': 'multipart/form-data'
               },
            })
            .then(res =>
            {
               if (res.data.MessageStatus === true)
               {
                  this.setState({
                     selectedRoleCategoryID: undefined,
                     addRoleCategoryRowVisible: false
                  }, this.getRoleCategory());
                  this.props.actions.isLoading(false);
                  notify(t("kayitsilindi"), "success", 1000);
               }
               else
               {

                  this.setState({
                     selectedRoleCategoryID: undefined,
                     addRoleCategoryRowVisible: false
                  }, this.getRoleCategory());
                  notify(t("kayitsilinemedi") + res.Message, "warning", 1000);
                  this.props.actions.isLoading(false);
               }
            })
            .catch(ex =>
            {
               this.props.actions.isLoading(false);
            });

         }
      })
   }

   handleChange(id, value)
   {
      if (id === "selectedCategoryID")
      {
         this.setState({[id]: value});
         this.getRoleCategory();
      }
      // else if (id==="selectedCompanyID"){
      //    this.setState({[id]: value});

      //    this.getUsersByCompany();

      // }
     else if (id === 'selectedCompanyID')
      {
         BB.FetchJSON('api/Yonetim/Pools/GetCompanyUsers?CompanyID=' + value).then(data =>
         {
            this.setState({usersByCompanies: data, isLoading: false})
            this.setState({[id]: value});

         })
      }
   
      else
      {

         this.setState({[id]: value});
      }

   }

   render()
   {
      return (
         <div className="animated fadeIn">
            <Col>
               <Card>
                  <CardBody>
                     <Row>
                        <Col lg="4">
                           <Row>
                              <Col lg="12" style={{marginLeft: 10, height: '78vh'}}>
                                 <ScrollView width='100%' height='100%'>
                                    {BB.DxTreeViewToAll('selectedCategoryID', '', this, this.state.treeData)}
                                 </ScrollView>
                              </Col>
                           </Row>
                        </Col>
                        <Col lg={8}>
                           <Button
                              color={'success'}
                              outline
                              onClick={() => this.setState({
                                 addRoleCategoryRowVisible: true
                              })}
                              style={{float: 'left', position: 'absolute', zIndex: 1}}
                              type="button">{t("yeni")}</Button>
                           <DataGrid
                              id="gridContainer"
                              dataSource={this.state.roleCategoryDatas}
                              showRowLines={true}
                              showColumnLines={false}
                              showBorders={false}
                           >
                              <Paging
                                 defaultPageSize={10}/>
                              <Pager
                                 showPageSizeSelector={true}
                                 allowedPageSizes={[5, 10, 20, 50]}
                                 showNavigationButtons={true}
                              />
                              <SearchPanel visible={true} width={100}/>
                              <Export enabled={true} fileName=""/>
                              <Paging pageSize={3}/>

                              <Column
                                 dataField="CategoryName"
                                 caption={t("kategori")}
                                 minWidth={100}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'CompanyName'}
                                 caption={t("firma")}
                                 minWidth={80}
                                 allowFiltering={true}/>
                              <Column
                                 dataField={'UserName'}
                                 caption={t("kullanıcı")}
                                 minWidth={80}/>
                              <Column
                                 dataField={'RoleName'}
                                 caption={t("yetkigrubu")}
                                 minWidth={80}/>
                              <Column
                                 dataField="İşlemler"
                                 caption={t("islemler")}
                                 width={200}
                                 cellRender={(cellData) => this.actionRoleCategory(cellData)}
                              />
                           </DataGrid>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Col>

            {/*Kategori Yetki Ekleme, Popup*/}
            <Modal
               isOpen={this.state.addRoleCategoryRowVisible}
               dragEnabled={false}
               showTitle={true}
               title={t("yetkigrubu")}
               width={metrics.popupMinWidth}
               height={300}
               centered={true}
            >
               <ModalBody>
               <FormGroup>
                    <Label>
                        <strong>{t("firmaListesi")}</strong>
                    </Label>
                    <InputGroup>
                        <Input className="border border-dark shadow-sm" style={{ border: '5px', marginBottom: 15 }} value={this.state.companySelectTool} type="text" autoFocus={true} name={'selectedCreateUserCompanyID'} onChange={(e) => { this.setState({ companySelectTool: e.target.value }, this.companyFilter); }} placeholder={t("aradiginizFirmayiYaziniz")} />
                    </InputGroup>
                    <ListGroup style={{ overflowY: 'scroll', height: '120px', display: this.state.companySelectToolListVisible ? 'block' : 'none' }}>
                        {
                            this.state.taskCompaniesTemp.map(filter => (
                                <ListGroupItem active={filter.ID === this.state.selectedCreateUserCompanyID && filter.ID !== '' ? true : false} disabled={filter.ID === ''} onClick={()=>this.onClickFilter(filter)} key={filter.ID}>{filter.Name}</ListGroupItem>
                            ))
                        }
                    </ListGroup>
                    {/* {BB.DxSelectBoxWithJSON('selectedCreateUserCompanyID', 'Kurum Seçiniz', event, event.state.taskCompanies)}*/}
                    {BB.DxDropDownWithURL2('selectedUserID', t("kullanıcı"), this, this.state.usersByCompanies,t("lutfenSeciniz"))}

                </FormGroup>
                  {/* {BB.DxDropDownWithJSON('selectedCompanyID', 'Firma', this, this.state.companies)} */}
                  {/* {BB.DxDropDownWithURL('selectedUserID', 'Kullanıcı', this, '/api/Yonetim/RoleCategories/GetUsers?companyID=' + this.state.selectedCompanyID + '&categoryID=' + this.state.selectedCategoryID)} */}
                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                     <Button
                        color={'info'}
                        onClick={() => this.setState({addRoleCategoryRowVisible: false})}>
                        Vazgeç
                     </Button>
                     <Button
                        onClick={() => this.addRoleCategory()}
                        color={'success'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        Kaydet
                     </Button>
                  </Row>
               </ModalBody>
            </Modal>

         </div>
      );
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(CategoryAuthority)
);
