import React, {Component} from 'react';
import * as loadingActions from "../../../redux/actions/loadingActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import axios, {post} from "axios";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

let oldParentIDList = [];

class TaskCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gridData: undefined,
            editRowVisible: false,
            addRowVisible: false,
            selectedName: '',
            selectedApprovalRequired: false,
            selectedSLA: 0,
            selectedCategoryType: undefined,
            selectedConfirmationType: undefined,
            selectedMaxLevel: 0,
            selectedParentID: '',
            selectedOrder: 0,
            redirect: false,
            selectedID: '',
            selectedCategoryTypeID: '',
            selectedConfirmationTypeID: '',
            backButtonVisible: 'block',
            parentIDIndex: -1,
            selectedColor: '',
            selectedActivityStatus: true,
            selectedFilreRequirement:undefined
        }
    }

    componentDidMount() {
        this.getData();

    }

    goBack() {
        if (this.state.parentIDIndex > -1) {
            this.state.parentIDIndex = this.state.parentIDIndex - 1;
            this.state.selectedParentID = oldParentIDList[this.state.parentIDIndex];
            oldParentIDList.pop(oldParentIDList[oldParentIDList.length - 1]);
            this.getData(this.state.selectedParentID)
        } else {
            if (this.state.parentIDIndex === -1) {
                this.state.parentIDIndex = -1;
                this.state.selectedParentID = undefined;
                oldParentIDList = [];
                this.getData();
            }
        }
    }

    getData(parentID) {
        let visibleBackButton = parentID != undefined ? 'block' : 'none';
        this.setState({backButtonVisible: visibleBackButton});
        let url = 'api/Yonetim/Categories/GetData?ParentID=' + parentID;

        BB.FetchJSON(url).then(data => {
            this.setState({gridData: data.Data});
            this.props.actions.isLoading(false);
        });
    }

    add() {
        if (this.state.selectedName === undefined || this.state.selectedName === '') {
            notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
        } else {
            this.props.actions.isLoading(true);

            let entity = {
                Name: this.state.selectedName,
                SLA: this.state.selectedSLA,
                ParameterID: this.state.selectedCategoryType,
                ApprovalRequired: this.state.selectedApprovalRequired,
                MaxLevel: this.state.selectedMaxLevel,
                OrderNumber: this.state.selectedOrder,
                ConfirmationTypeID: this.state.selectedConfirmationType,
                ParentID: this.state.selectedParentID,
                Color: this.state.selectedColor,
                IsActive: this.state.selectedActivityStatus,
                File_Attachement_Required:this.state.selectedFilreRequirement

            }

            const formData = new FormData();
            formData.append('jsonText', JSON.stringify(entity));
            formData.append('EditID', null)

            post('api/Yonetim/Categories/Add', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
            })
                .then(res => {
                    if (res.data.MessageStatus === true) {
                        this.setState({
                            editRowVisible: false,
                            addRowVisible: false,
                            selectedName: '',
                            selectedMasterPoolID: null
                        }, this.getData(this.state.selectedParentID));
                        this.props.actions.isLoading(false);
                        notify(t("kategoriolusturuldu"), "success", 1000);
                    } else {
                        this.setState({
                            editRowVisible: false,
                            addRowVisible: false,
                            selectedName: '',
                            selectedMasterPoolID: null
                        });
                        notify(t("kategoriolusturmahata") + res.Message, "warning", 1000);
                        this.props.actions.isLoading(false);
                    }
                });
        }
    }

    edit() {
        if (this.state.selectedName === undefined || this.state.selectedName === '') {
            notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
        } else {
            this.props.actions.isLoading(true);

            let entity = {
                ID: this.state.selectedID,
                Name: this.state.selectedName,
                SLA: this.state.selectedSLA,
                ParameterID: this.state.selectedCategoryTypeID,
                ApprovalRequired: this.state.selectedApprovalRequired,
                MaxLevel: this.state.selectedMaxLevel,
                OrderNumber: this.state.selectedOrder,
                ConfirmationTypeID: this.state.selectedConfirmationTypeID,
                ParentID: this.state.selectedParentID,
                Color: this.state.selectedColor,
                IsActive: this.state.selectedActivityStatus,
                File_Attachement_Required:this.state.selectedFilreRequirement
            }

            const formData = new FormData();
            formData.append('jsonText', JSON.stringify(entity));
            formData.append('EditID', this.state.selectedID)

            post('api/Yonetim/Categories/Add', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
            })
                .then(res => {
                    if (res.data.MessageStatus === true) {
                        this.setState({
                            editRowVisible: false,
                            editPoolVisible: false,
                            selectedName: ''
                        }, this.getData(this.state.selectedParentID));
                        this.props.actions.isLoading(false);
                        notify(t("kategoriguncellendi"), "success", 1000);
                    } else {
                        this.setState({editRowVisible: false, editPoolVisible: false, selectedName: ''});
                        notify(t("kategoriguncellemedehata") + res.Message, "warning", 1000);
                        this.props.actions.isLoading(false);
                    }
                });
        }
    }

    handleChange = (id, value) => {
        this.setState({[id]: value});

        if (id === 'selectedApprovalRequired') {
            this.setState({selectedApprovalRequired: !this.state.selectedApprovalRequired})
        } else if (id === 'selectedActivityStatus') {
            this.setState({selectedActivityStatus: !this.state.selectedActivityStatus})
        }
        else if (id ==='selectedFilreRequirement'){

            if(this.state.selectedFilreRequirement === undefined){
                this.setState({selectedFilreRequirement: false})

            }
            this.setState({selectedFilreRequirement: !this.state.selectedFilreRequirement})
        }

    }

    handleDeleteCategory(id) {
        const url = (action) => {
            debugger
            return `api/Yonetim/Categories/${action}?categoryID=${id}`
        }
        // const url = `api/Yonetim/Categories/Delete?categoryID=${id}`

        BB.askYesNo(
            t("kategorisilme"),
            t("secilmiskategoriyisilmekistediginizeeminmisiniz"),
            t("evet"),
            t("hayır")).then((res) =>
        {
            if (res === true)
            {
                BB.FetchJSON(url("FindCateforUsage")).then(result =>
                {
                    if (result.Data === true) {
                        BB.FetchJSON(url("Delete")).then(result => {
                            this.getData();
                        })
                    } else {
                        BB.askYesNo(
                            t("kategoriinaktifetme"),
                            t("secilmiskategorininaktifkullanimivardirsectiginizkategoriyiinaktifetmekistermisiniz"),
                            t("evet"),
                            t("hayır")).then((res) =>
                        {
                            if(res === true) {
                                BB.FetchJSON(url("Update")).then(result => {
                                    this.getData();
                                })
                            }
                        })
                    }
                })
            }
        })
    }

    actionButton = (cellData) => {
        return (
            <div>
                <Button
                    color={'info'}
                    outline
                    onClick={() => {
                        this.setState({
                            selectedID: cellData.data.ID,
                            redirect: true,
                            selectedParentID: cellData.data.ID
                        });
                        this.getData(cellData.data.ID);
                        oldParentIDList.push(cellData.data.ID);
                        this.state.parentIDIndex = this.state.parentIDIndex + 1;
                    }}>
                    {t("altkategoriler")}
                </Button>

                <Button
                    style={{marginLeft: 5}}
                    color={'info'}
                    outline
                    onClick={() => this.setState({
                        editRowVisible: true,
                        selectedID: cellData.data.ID,
                        selectedName: cellData.data.Name,
                        selectedSLA: cellData.data.SLA,
                        selectedApprovalRequired: cellData.data.ApprovalRequired,
                        selectedCategoryTypeID: cellData.data.Parametre,
                        selectedConfirmationTypeID: cellData.data.CategoryParametre,
                        selectedMaxLevel: cellData.data.MaxLevel,
                        selectedColor: cellData.data.Color,
                        selectedActivityStatus: cellData.data.IsActive,
                        selectedFilreRequirement: cellData.data.File_Attachement_Required
                    })}
                >{t("düzenle")}</Button>

                <Button
                    style={{marginLeft: 5}}
                    color={'danger'}
                    outline
                    onClick={() => this.handleDeleteCategory(cellData.data.ID)}
                >{t("sil")}</Button>
            </div>
        );
    }

    colorRender(e) {
        if (e.data.Color) {
            return (
                <div
                    style={{
                        padding: '1px 1px',
                        borderRadius: 20,
                        fontSize: 12,
                        width: 63,
                        backgroundColor: e.data.Color,
                        color: 'black',
                        borderColor: 'black',
                        borderWidth: 1,
                    }}
                    className='ml-0 btn  aciliyetButton mr-0'>{e.data.Color}</div>
            );
        } else {
            return (
                <div style={{backgroundColor: '#fff'}}
                     className='ml-0 btn  aciliyetButton mr-0'>{e.data.Color}</div>
            );
        }
    }

    render() {
        return (
            <div className="animated fadeIn">
                <Col>
                    <Card>
                        <CardBody>

                            <Button
                                color={'success'}
                                outline

                                onClick={() => this.setState({addRowVisible: true})}

                                style={{
                                    float: 'left',
                                    position: 'absolute',
                                    zIndex: 1,
                                    marginLeft: 60,
                                   

                                }} 
                                type="button"


                                >{t("yeni")}</Button>

                            <Button
                                color={'primary'}
                                outline

                                onClick={() => this.goBack()}

                                style={{float: 'left', position: 'absolute', zIndex: 1,                                    display: this.state.backButtonVisible
                            }}
                                
                                type="button"

                                >{t("geri")}</Button>


                            <DataGrid
                                id="gridContainer"
                                dataSource={this.state.gridData}
                                showRowLines={true}
                                showColumnLines={false}
                                showBorders={false}
                            >
                                <Paging
                                    defaultPageSize={10}/>
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[5, 10, 20, 50]}
                                    showNavigationButtons={true}
                                />
                                <SearchPanel visible={true} width={100}/>
                                <Export enabled={true} fileName="Kategori Listesi"/>

                                <Column
                                    dataField="Name"
                                    defaultSortOrder="asc"
                                    caption={t("adı")}
                                    minWidth={200}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'SLA'}
                                    caption={t("sla")}
                                    minWidth={80}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'ParametreName'}
                                    caption={t("taleptipi")}
                                    minWidth={80}
                                    allowFiltering={true}/>

                                <Column
                                    dataField={'ApprovalRequired'}
                                    caption={t("onayatabi")}
                                    minWidth={80}/>

                                <Column
                                    dataField={'MaxLevel'}
                                    caption={t("onayseviyesi")}
                                    minWidth={80}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'ParentID'}
                                    caption="Parent"
                                    minWidth={80}
                                    visible={false}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'CategoryParametre'}
                                    caption={t("taleptipi")}
                                    minWidth={80}
                                    visible={false}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'Color'}
                                    caption="Renk"
                                    minWidth={80}
                                    cellRender={this.colorRender}
                                />
                                <Column
                                    dataField={'Parametre'}
                                    caption={t("taleptipi")}
                                    minWidth={80}
                                    visible={false}
                                    allowFiltering={true}/>
                                <Column
                                    dataField={'IsActive'}
                                    caption={t("aktiflikdurumu")}
                                    allowFiltering={false}/>
                                       <Column
                                    dataField={'File_Attachement_Required'}
                                    caption={t("zorunludosya")}
                                    allowFiltering={false}/>
                                <Column
                                    dataField="İşlemler"
                                    caption={t("islemler")}
                                    width={250}
                                    cellRender={this.actionButton}
                                />
                            </DataGrid>
                        </CardBody>
                    </Card>
                </Col>

                {/*Yeni Satır Ekleme Popup*/}
                <Modal
                    isOpen={this.state.addRowVisible}
                    dragEnabled={false}
                    showTitle={true}
                    showCloseButton={false}
                >
                    <ModalHeader>
                        Yeni Kategori Tanımı
                    </ModalHeader>
                    <ModalBody>
                        <form>

                            {BB.InputWithLabel('selectedName', t("kategoriadi"), 'text', this)}
                            {BB.DxDropDownWithURL('selectedCategoryType', t("kategoritip"), this, "/api/Yonetim/Categories/GetCategoryType", t("kategoritip"))}
                            {BB.DxDropDownWithURL('selectedConfirmationType', t("onaytip"), this, "/api/Yonetim/Categories/GetConfirmationType", t("onaytip"))}
                            {BB.InputWithLabel('selectedSLA', t("sla"), 'number', this)}
                            {BB.CheckBoxWithLabel('selectedApprovalRequired', t("onayatabi"), this)}
                            
                            {BB.CheckBoxWithLabel('selectedFilreRequirement', t("dosyaeklemezorunlumu"), this)}

                            {this.state.selectedApprovalRequired === true && BB.InputWithLabel('selectedMaxLevel', t("onayseviyesi"), 'number', this, '', 0)}

                            {BB.InputWithLabel('selectedOrder', t("sirano"), 'number', this)}

                            {BB.InputWithLabel('selectedColor', t("kategorirengi"), 'text', this)}

                            {BB.ColorSelectWithLabel('selectedColor', t("kategorirengi"), this)}

                            <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                                <Button
                                    color={'info'}
                                    onClick={() => this.setState({addRowVisible: false})}>
                                    {t("vazgec")}
                                </Button>
                                <Button
                                    onClick={() => this.add()}
                                    color={'success'}
                                    style={{marginLeft: 20, marginRight: 0}}>
                                    {t("olustur")}
                                </Button>
                            </Row>
                        </form>
                    </ModalBody>

                </Modal>

                {/*Düzenle Popup*/}
                <Modal
                    isOpen={this.state.editRowVisible}
                    dragEnabled={false}
                    showTitle={true}
                    showCloseButton={false}
                    title=""
                >
                    <ModalHeader>
                        {t("kategoriduzenle")}
                    </ModalHeader>
                    <ModalBody>
                        {BB.InputWithLabel('selectedName',  t("kategoriadi"), 'text', this)}
                        {BB.DxDropDownWithURL('selectedCategoryTypeID', t("kategoritip"), this, "/api/Yonetim/Categories/GetCategoryType", t("kategoritip"))}
                        {BB.DxDropDownWithURL('selectedConfirmationTypeID', t("onaytip"), this, "/api/Yonetim/Categories/GetConfirmationType", t("onaytip"))}
                        {BB.InputWithLabel('selectedSLA', t("sla"), 'number', this)}
                        {BB.CheckBoxWithLabel('selectedApprovalRequired', t("onayatabi"), this)}
                        {BB.CheckBoxWithLabel('selectedActivityStatus', 'Aktiflik Durumu', this)}
                        {BB.CheckBoxWithLabel('selectedFilreRequirement', t("dosyaeklemezorunlumu"), this)}
                        {this.state.selectedApprovalRequired === true && BB.InputWithLabel('selectedMaxLevel', 'Onay Seviyesi', 'number', this, '', 0)}

                        {BB.InputWithLabel('selectedOrder', t("sirano"), 'number', this)}

                        {BB.ColorSelectWithLabel('selectedColor', t("kategorirengi"), this)}

                        <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                            <Button
                                color={'info'}
                                onClick={() => this.setState({
                                    editRowVisible: false,
                                    selectedID: '',
                                    selectedName: '',
                                })}>
                                {t("kapat")}
                            </Button>

                            <Button
                                onClick={() => this.edit()}
                                color={'primary'}
                                style={{marginLeft: 20, marginRight: 0}}>
                                {t("kaydet")}
                            </Button>
                        </Row>
                    </ModalBody>

                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
        }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskCategory));
