import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {post} from "axios";
import {Popup} from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as BB from "../../../components/BBComponent";
import * as loadingActions from "../../../redux/actions/loadingActions";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class Popups extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         redirect: false,
         redirectUrl: '',
         addPopupVisible: false,
         departmentName: '',
         editID: '',
         messageType: [
            {ID: 'Popup', Name: 'Popup'},
            {ID: 'Message', Name: 'Message'},
         ],
         selectedSssFiles: undefined
      }
   }

   componentDidMount()
   {
      BB.FetchJSON('api/Yonetim/Popup/GetData').then(res =>
      {
         this.setState({gridData: res.Data});
         this.props.actions.isLoading(false);
      })
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizpopupsilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   delete(ID)
   {
      this.props.actions.isLoading(true);
      fetch('api/Yonetim/Popup/Delete?ID=' + ID, {method: 'GET'})
      .then((response) => response.json())
      .then((res) =>
      {
         if (res.MessageStatus === true)
         {
            this.getData()
            this.props.actions.isLoading(false);
            notify(t("popupbasariylesilindi"), "success", 1000);
         }
         else
         {
            notify(t("popupsilmehata"), "warning", 2000);
            this.props.actions.isLoading(false);
         }
      });
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/Popup/GetData').then(res =>
      {
         this.setState({gridData: res.Data});
         this.props.actions.isLoading(false);
      })
   }

   add()
   {
      let {selectedSssFiles} = this.state;

      if (this.state.MessageType === undefined || this.state.MessageType === '' || this.state.title === '' || this.state.title === undefined)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            PopupMessageType: this.state.MessageType,
            Title: this.state.title,
            Message: this.state.message,
            StartDate: this.state.selectedStartDate,
            EndDate: this.state.selectedClosedDate,
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('file', selectedSssFiles);
         post('api/Yonetim/Popup/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addPopupVisible: false,
                  title: '',
                  MessageType: '',
                  selectedStartDate: '',
                  selectedClosedDate: '',
                  message: ''
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("popupeklemebasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  addPopupVisible: false
               });
               notify(t("popupeklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   handleFile = (e) =>
   {
      this.setState({selectedSssFiles: e.target.files[0]})
   }

   render()
   {
      const {gridData, redirectUrl, MessageType} = this.state;

      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({addPopupVisible: true})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Anket Listesi"/>
                           <Column
                              dataField="Title"
                              caption={t("baslik")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="PopupMessageType"
                              caption={t("popuptipi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Message"
                              caption={t("mesaj")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Url"
                              caption={t("resim")}
                              minWidth={200}

                              allowFiltering={true}/>
                           <Column
                              dataField="StartDate"
                              caption={t("başlangıçtarihi")}
                              minWidth={200}
                              dataType='date'
                              allowFiltering={true}/>
                           <Column
                              dataField="EndDate"
                              caption={t("bitiştarihi")}
                              dataType='date'
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={80}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.addPopupVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("popupekle")}
                  width={metrics.popupMinWidth}
                  height={710}
               >
                  <form>
                     {BB.DxDropDownWithJSON('MessageType', t("messajtipi"), this, this.state.messageType)}
                     {BB.InputWithLabel('title', t("baslik"), 'text', this)}
                     {BB.DateSelectWithLabel('selectedStartDate', t("başlangıçtarihi"), this, t("lutfenSeciniz"))}
                     {BB.DateSelectWithLabel('selectedClosedDate', t("bitiştarihi"), this, t("lutfenSeciniz"))}
                     {BB.TextAreaWithLabel('message', t("mesaj"), t("mesaj"), 5, this)}
                     {MessageType === "Message" &&
                     <Col lg='12' style={{paddingTop: 10}}>
                        <FormGroup>
                           <Label htmlFor='selectedSssFiles'>
                              <strong>{t("dosyaekle")}</strong>
                           </Label>
                           <Input
                              type='file'
                              id='selectedSssFiles'
                              onChange={this.handleFile}
                           />
                        </FormGroup>
                     </Col>
                     }

                     {/*Tahmini sonlandırma tarihi*/}
                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addPopupVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
            </Row>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Popups));
