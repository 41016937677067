export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SET_TASK_TRACKING_DATA = 'SET_TASK_TRACKING_DATA';
export const SET_POOL_DATA = 'SET_POOL_DATA';
export const SET_OWN_TASK_DATA = 'SET_OWN_TASK_DATA';
export const SET_OWN_ACTIVTY_DATA = 'SET_OWN_ACTIVTY_DATA';
// export const SET_OWN_CONFIRM_DATA = 'SET_OWN_CONFIRM_DATA';
export const SET_OWN_SURVEYS = 'SET_OWN_SURVEYS';
export const SET_MENUS = 'SET_MENUS';
export const SET_LOADING = 'SET_LOADING';
export const SET_POOL_COUNT = 'SET_POOL_COUNT';
export const SET_DEPARTMENT_TASK = 'SET_DEPARTMENT_TASK';
export const SET_PARENT_CATEGORIES = 'SET_PARENT_CATEGORIES';
export const SET_CHILD_CATEGORIES = 'SET_CHILD_CATEGORIES';
export const SET_MAIN_COMPANIES = 'SET_MAIN_COMPANIES';
export const SET_OWN_TASK_COUNT = 'SET_OWN_TASK_COUNT';
export const SET_SLA_TASK_COUNT = 'SET_SLA_TASK_COUNT';
export const SET_OWN_CONFIRM_TASK_COUNT = 'SET_OWN_CONFIRM_TASK_COUNT';
export const SET_MY_TASK_CREATE_TEMPLATE = 'SET_MY_TASK_CREATE_TEMPLATE';
export const SET_POPUP_MESSAGE = 'SET_POPUP_MESSAGE';
export const GET_WATCHER_TASKS = 'GET_WATCHER_TASKS';
export const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES';
export const GET_TASK_ASSIGNED = 'GET_TASK_ASSIGNED';
