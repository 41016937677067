import * as BB from "../../../../../components/BBComponent";
import Swal from "sweetalert2";
import metrics from "../../../../../common/metrics";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import TaskModel from "../../../../../models/Task";
import { htmlToText } from "html-to-text";
import {
   callAPI,
   callApiGet,
   callApiPost,
   callApiPut
} from "../../../../../components/Axios";
export const getParentCategories = (UserID, ParentID, event) => new Promise((resolve) => {

   let url = UserID !== null && '/api/Task/GetCategories?UserID=' + UserID + '&ParentID=' + ParentID;

   event.setState({ parentCategoryLoading: true })
   callAPI(
      callApiGet,
      url,
      {},
      async (res) => {
         event.setState({ taskCategories: res?.data, parentCategoryLoading: false })
         resolve(res?.data)
      },
      true
   );

   // BB.FetchJSON(url).then(data =>
   // {
   //    event.setState({taskCategories: data, parentCategoryLoading: false})
   //    resolve(data)
   // });
});

export const getDepartmentUser = (event) => new Promise((resolve) => {
   BB.FetchJSON('/api/OwnConfirmTask/GetDepartmentUser').then(data => {
      event.setState({ departmentUsers: data });
   });
});

export const getCategoriesEffect = (CategoryID, event) => new Promise((resolve) => {

   let url = '/api/Task/GetCategoryType?id=' + CategoryID;

   BB.FetchJSON(url).then(data => {
      if (data.length > 0) {
         event.setState({ effectList: data, showEffect: true })
         resolve(data)
      }
      else {
         event.setState({ effectList: [], showEffect: false })
         resolve(data)
      }
   });
});

export const getCompanies = () => new Promise((resolve) => {
   let url = '/api/Task/GetCompanies';

   BB.FetchJSON(url).then(data => {
      resolve(data)
   });
});

export const getMainTaskList = (event, UserID) => new Promise((resolve) => {






   if (UserID) {

      callAPI(
         callApiGet,
         '/api/Task/GetMainTaskList?UserID=' + UserID,
         {},
         async (res) => {
            event.setState({ mainTaskList: res.data })
         },
         true
      );




      // let url = 
      // BB.FetchJSON(url).then(data =>
      // {
      //    event.setState({mainTaskList: data});
      // });





   }
   else {
      callAPI(
         callApiGet,
         '/api/Task/GetMainTaskList?UserID=null',
         {},
         async (res) => {
            event.setState({ mainTaskList: res.data })
         },
         true
      );

   }






});

export const getCustomElements = (CategoryID, event) => {
   let customElement = '/api/Task/GetCustomElements?id=' + CategoryID;
   let customForm = '/api/Yonetim/CustomForm/GetCustomForm?CatID=' + CategoryID;
   let customSheet = '/api/Yonetim/CustomSheet/GetCustomSheet?CatID=' + CategoryID;
   let fileRequirement = '/api/Task/GetCategoriesRequiremnet/?CategoryID=' + CategoryID;

   // BB.FetchTEXT(customElement).then(data =>
   // {
   //    if (data)
   //    {
   //       let customControls = JSON.parse(data);
   //       let sortedCustomControls = customControls.sort(function (a, b)
   //       {
   //          return a.OrderNumber - b.OrderNumber;
   //       });

   //       event.setState({customElements: sortedCustomControls})
   //    }
   //    else
   //    {
   //       event.setState({customElements: null})
   //    }
   // });

   callAPI(
      callApiGet,
      fileRequirement,
      {},
      (res) => {
         if (res.data) {

            //let customControls = JSON.parse(res.data);


            event.setState({ fileRequirement: res.data })
         }
         else {
            event.setState({ fileRequirement: res.data })
         }
      },

   );
   callAPI(
      callApiGet,
      customElement,
      {},
      (res) => {
         if (res.data) {
            //let customControls = JSON.parse(res.data);
            let customControls = res.data;
            let sortedCustomControls = customControls.sort(function (a, b) {
               return a.OrderNumber - b.OrderNumber;
            });

            event.setState({ customElements: sortedCustomControls })
         }
         else {
            event.setState({ customElements: null })
         }
      },

   );

   callAPI(
      callApiGet,
      customForm,
      {},
      (res) => {
         if (res.data && res.data.length > 0) {
            event.setState({
               message: res.data[0].Name + ' doldurmak için ',
               selectedFormID: res.data[0].ID,
               selectedForm: JSON.parse(res.data[0].JsonForm).task_data,
               selectedFormName: res.data[0].Name,
               selectedFormRequired: res.data[0].IsRequired,
            });
         }
         else {
            event.setState({
               message: '',
               selectedForm: [],
               selectedFormName: undefined,
               selectedFormID: undefined,
               selectedFormRequired: false
            });
         }
      },

   );

   callAPI(
      callApiGet,
      customSheet,
      {},
      (res) => {
         if (res.data && res.data.length > 0) {
            event.setState({
               message2: res.data[0].Name + ' doldurmak için ',
               selectedSheetID: res.data[0].ID,
               selectedSheet: JSON.parse(res.data[0].JsonForm),
               selectedSheetName: res.data[0].Name
            });
         }
         else {
            event.setState({ message: '', selectedSheet: [], selectedSheetName: undefined, selectedSheetID: undefined });

         }
      },

   );


   // BB.FetchJSON(customSheet).then(data =>
   // {
   //    if (data && data.length > 0)
   //    {
   //       event.setState({
   //          message2: data[0].Name + ' doldurmak için ',
   //          selectedSheetID: data[0].ID,
   //          selectedSheet: JSON.parse(data[0].JsonForm),
   //          selectedSheetName: data[0].Name
   //       });
   //    }
   //    else
   //    {
   //       event.setState({message: '', selectedSheet: [], selectedSheetName: undefined, selectedSheetID: undefined});
   //    }
   // });

   getCategoryConfirmations(CategoryID, event);
}

export const getCategoryConfirmations = (CategoryID, event) => {
   let url1 = '/api/Task/GetCategoryConfirmations?categoryID=' + CategoryID;

   if (event.state.selectedEmployee !== undefined && event.state.selectedEmployee !== '') {
      url1 = url1 + '&userID=' + event.state.selectedEmployee + '&companyID=' + event.state.selectedCompanyName;
   }


   BB.FetchTEXT(url1).then(data => {
      event.setState({ categoryConfirmations: data });
   });

}

export const getShowExpectedEndDate = (event) => {
   BB.FetchJSON('api/Main/ShowExpectedEndDate').then(res => {
      event.setState({ showExpectedEndDate: res });
   })
}

export const getWatcherUserList = (event) => {
   BB.FetchJSON('/api/User/GetUserList?CompanyID=' + event.props.loginuser.CompanyID).then(res => {
      event.setState({ watcherList: res });
   })
}

export const saveTemplate = (e, event, t,isAcil) => {
   e.preventDefault();
   event.checkValidations().then(validation => {
      if (validation) {
         const formData = new FormData();
         Swal.fire({
            title: t("sablonaKisaAdVerin"),
            text: t("sablonDescription"),
            input: 'text',
            inputAttributes: {
               autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: t("kaydet"),
            cancelButtonText: t("iptal"),
            showLoaderOnConfirm: true,
            onClose: () => {
               event.props.actions.isLoading(false);
            },
            preConfirm: (text) => {
               if (text) {
                  let newState = event.state;
                  newState.selectedDueClosedDate = null;
                  newState.selectedTaskDescription = metrics.htmlDescription;
                  newState.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
                  newState.UrgencyDescription = isAcil ? event.state.selectedDegreeOfUrencyDescription : null;

                  let jsonTemplate = {
                     Name: text,
                     TemplateJson: JSON.stringify(newState),
                     IsActive: true
                  };

                

                  formData.append('jsonTemplate', JSON.stringify(jsonTemplate));


                  post('api/Task/AddMyTaskCreateTemplate', formData, {
                     headers: {
                        'content-type': 'multipart/form-data'
                     },
                  })
                     .then(res => {
                        if (res.data === true) {
                           event.props.actions.isLoading(false);
                           notify(t("sablonBasariylaKaydedildi"), "success", 3000);
                        }
                        else {
                           event.props.actions.isLoading(false);
                           notify('Şablon Kaydedilemedi! : ' + res.Message, "warning", 3000);
                        }
                     })
                     .catch(ex => {
                        event.props.actions.isLoading(false);
                     });
               }
               else {
                  notify('Şablon kısa adı boş olamaz', 'error', 2000);
               }
            },
            allowOutsideClick: () => !Swal.isLoading()
         });
      }
   });
}

export const saveTask = (e, event,isAcil,t) => {
   e.preventDefault();

   event.checkValidations().then(validation => {
      if (validation) {
         let item = new TaskModel();
         let selectedWatcherUserIDs = [];
         let selectedWatcherGroupIDs = [];

         event.state.selectedWatcherUserList.map(item => {
            selectedWatcherUserIDs.push(item.ID);
         });

         event.state.selectedWatcherGroups.map(item => {
            selectedWatcherGroupIDs.push(item.ID);
         });

         item.CategoryID = event.state.selectedCategory;
         item.CreateDate = new Date();
         item.DegreeOfUrgency = event.state.selectedDegreeOfUrency;
         item.Description = htmlToText(metrics.htmlDescription, { tags: { 'img': { format: 'skip' } } }).replaceAll('//', '/');
         item.NotificationType = event.state.selectedNotificationType;
         item.TaskNumber = 0;
         item.TaskTypeID = 'fb3a1946-fecd-e811-80f6-005056b839bb';
         item.UrgencyDescription = isAcil ? metrics.selectedDegreeOfUrencyDescription : null;
         item.CreateUserID = event.state.selectedEmployee;
         item.IsDepartmanNotification = false;
         item.ExpectedEndDate = event.state.selectedDueClosedDate;
         item.MailNotification = event.state.selectedMailNotifications;
         item.WatcherUsers = selectedWatcherUserIDs;
         item.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
         item.HTMLDescription = metrics.htmlDescription;
         item.EffectID = event.state.selectedEffect;
         item.WatcherGroups = event.state.selectedWatcherGroupsID;

         event.state.selectedMainTaskID !== undefined ? item.MainTaskID = event.state.selectedMainTaskID : item.MainTaskID = undefined;

         // if(event.state.selectedCustomControl !== null){
         //    event.state.selectedCustomControl.map((item2, i) =>
         //    {
         //       let customControlFrm ={
         //          ID : item2.ID,
         //          Value : toString(item2.Value)
         //       }
         //    })
         // }



         let formData = new FormData();
         formData.append('jsonText', JSON.stringify(item));

         if (event.state.selectedFormID !== undefined) {
            formData.append('customFormID', event.state.selectedFormID);
            formData.append('customFormValues', JSON.stringify(event.state.answer_data));
         }

         if (event.state.selectedSheetID !== undefined) {
            formData.append('customSheetID', event.state.selectedSheetID);
            formData.append('customSheetValues', JSON.stringify(event.state.rows));
         }

         event.state.selectedTaskFiles.map((item, i) => {
            formData.append('files', item)
         })

         if (event.state.selectedTaskFiles.length === 0 && event.state.fileRequirement) {
            return notify(t("kategoriyeBagliZorunluDosyaYuklemesiYapmanizGerek"), "warning", 5000);

         }
         if(event.state.selectedFormRequired && event.state.answer_data.length == 0){
            return notify(t("gerekliFormVeyaSayfalariDoldurmanizGerekmektedir"), "warning", 5000);
         }
         event.props.actions.isLoading(true);
         
         post('api/Task/taskCreate', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
            .then(res => {
               event.props.actions.isLoading(false);
               if (res.data["Issuccess"]) {
                  let ths = event;

                  notify(res.data["ErrMsg"], "success", 2000);

                  setTimeout(function () {
                     ths.props.actions.getAllTaskInPool();

                     ths.setState({
                        selectedNotificationType: 0,
                        selectedCompanyName: undefined,
                        selectedEmployee: undefined,
                        selectedCategory: undefined,
                        selectedDegreeOfUrency: undefined,
                        selectedEffect: undefined,
                        selectedMainTaskID: undefined,
                        selectedTaskDescription: undefined,
                        selectedDueClosedDate: undefined,
                        selectedTaskFiles: [],
                        isDepartmanNotification: false,
                        categoryConfirmations: '',
                        selectedFormRequired: false,
                        answer_data:[]
                     });

                     ths.props.history.push('/Main');

                  }, 2000);
               }
               else {
                  notify(res.data["ErrMsg"], "error", 2000);
                  event.props.actions.isLoading(false);
               }
            });
      }
   });
}
