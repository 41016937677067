import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import { Button, Row, Label, ListGroup, ListGroupItem, Col } from "reactstrap";
import notify from "devextreme/ui/notify";

import {
  callAPI,
  callApiGet,
  callApiPost,
} from "../../../../../components/Axios";

var deletedDocuments1 = [];

const TaskUpdateDescription = ({ t, event }) => {

  const deleteOldFile = (fileName) => {
    //taskDescDocuments
    let docName = event.state.taskDescDocumentsName;

    for (let i = 0; i < event.state.updatedTaskDocuments.length; i++) {
      let currentDocument = event.state.updatedTaskDocuments[i];

      if (currentDocument.DocumentType === "2") {
        if (currentDocument.FilePath.includes(fileName)) {

          deletedDocuments1.push(currentDocument);
          var index = docName.indexOf(fileName);
          delete docName[index];
        }
      }
    }
    event.setState({
      taskDescDocumentsName: docName,
      deletedDocuments: deletedDocuments1,
    });
  };


  const updateComment = (e) => {
    e.preventDefault();

    event.props.actions.isLoading(true);

    // if (event.state.selectedCommentDescription === '')
    // {
    //     if (event.state.selectedTaskFiles.length < 1) {
    //         notify((event.languages.dosyaEklemeZorunludur + " veya " + event.languages.aciklamaAlaniZorunludur), "error", 1500);

    //         event.props.actions.isLoading(false);
    //         return;
    //     }
    // }
    const url = `api/TaskDetail/UpdateTaskDescription`;

    const formData = new FormData();
    //
    let viewClientNow = false;


    if (
      event.state.tempViewClient === false &&
      event.state.updateViewClient === "on"
    ) {
      viewClientNow = true;
    } else if (
      event.state.tempViewClient === true &&
      event.state.updateViewClient === "on"
    ) {
      viewClientNow = false;
    } else if (
      event.state.tempViewClient === true &&
      event.state.updateViewClient === true
    ) {
      viewClientNow = true;
    } else {
    }

    let upddateByTaskDescription = {
      ID: event.state.selectedTaskLogId,
      Description: event.state.updatedDescription,
      ViewClient: viewClientNow,
      Documents: event.state.deletedDocuments,
    };

    formData.append("jsonText", JSON.stringify(upddateByTaskDescription));
    for (let i = 0; i < event.state.selectedTaskFiles.length; i++) {
      formData.append("file", event.state.selectedTaskFiles[i]);
    }
    // formData.append('ShareOther', event.state.selectedShareOther);
    // formData.append('ShareList', event.state.selectedShareOtherMails);

    // for (let i = 0; i < event.state.selectedTaskFiles.length; i++)
    // {
    //     formData.append('file', event.state.selectedTaskFiles[i])
    // }

    const config = { headers: { "content-type": "multipart/form-data" } };

    callAPI(
      callApiPost,
      url,
      formData,
      async (res) => {
        if (res.data.IsSuccess) {
          notify(t("acıklamaBasarıylaGuncellendi"), "success", 2000);

          event.setState({
            visibleAciklamaGüncelle: false,
            updatedDescription: "",
            updatedTaskDocuments: [],
            selectedTaskFiles: [],
            taskDescDocumentsName: [],
            selectedTaskLogId: undefined,
              deletedDocuments :[],
            ComponentFileUpload: [],
          });

          // Array.from(document.querySelectorAll("input")).forEach(
          //     input => (input.value = "")
          // );

          event.getData(event.state.taskID);
          event.props.actions.isLoading(false);
        } else {
          event.props.actions.isLoading(false);

          notify(t("acıklamaGuncellenirkenHata"), "error", 2000);
        }
      },
      true
    );
  };
 

  return (
    <Modal
      size={"lg"}
      centered={true}
      isOpen={event.state.visibleAciklamaGüncelle}
      style={{ zIndex: 1 }}
    >
      <ModalHeader>{t("aciklamaGüncelle")}</ModalHeader>
      <ModalBody>
        <form onSubmit={(e) => updateComment(e)}>
          {BB.TextAreaWithLabel(
            "updatedDescription",
            t("zorunluAciklama"),
            "",
            5,
            event
          )}

          <Row>
            <Col>
              {BB.MultipleFileWithLabel2(
                "selectedTaskFiles",
                t("yeniEkDosya"),
                event,
                false
              )}
              <ListGroup
                style={{
                  overflowY: "scroll",
                  height: "100px",
                  width: "320px",
                  display: event.state.fileUploaderVisible,
                }}
              >
                {event.state.selectedTaskFiles.map((filter) => (
                  <ListGroupItem style={{ padding: 6 }} key={filter.name}>
                    {filter.name}
                    {
                      <Label
                        color="danger"
                        onClick={() => event.deleteSelectedFile(filter.name)}
                        className="ml-2"
                        style={{ cursor: "pointer" }}
                      >
                        x
                      </Label>
                    }
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
            <Col>
              <Label>
                <strong>{t("eskiDosyalar")}</strong>
              </Label>

              {
                <div>
                  <ListGroup
                    style={{
                      overflowY: "scroll",
                      height: "100px",
                      width: "320px",
                    }}
                  >
                    {event.state.taskDescDocumentsName.map((filter) => (
                      <ListGroupItem style={{ padding: 6 }} key={filter}>
                        {filter}
                        {
                          <Label
                            color="danger"
                            onClick={() => deleteOldFile(filter)}
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                          >
                            x
                          </Label>
                        }
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              }
            </Col>
          </Row>

          {event.state.taskCreator ? null : (
            <div>
              {BB.CheckBoxWithLabel(
                "updateViewClient",
                t("talepSahibiIlePaylas"),
                event,
                event.state.taskCreator
              )}
            </div>
          )}

          {BB.CheckBoxWithLabel(
            "selectedShareOther",
            t("ekbilgilendirmemesajı"),
            event
          )}
          {event.state.selectedShareOther === true
            ? BB.DxDropDownWithJSON(
                "selectedShareOtherMails",
                t("bilgilendirilecekkisiler"),
                event,
                event.state.poolUsers
              )
            : null}

          <Row style={{ float: "right", marginRight: 5 }}>
            <Button
              color={"info"}
              onClick={() =>
                event.setState({
                  visibleAciklamaGüncelle: false,
                  selectedTaskLogId: undefined,
                })
              }
            >
              {t("vazgec")}
            </Button>

            <Button
              color={"success"}
              style={{ marginLeft: 20, marginRight: 15 }}
              type={"submit"}
            >
              {t("guncelle")}
            </Button>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default TaskUpdateDescription;
//this.setState({ selectedShareOwner: !this.state.selectedShareOwner });
