import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";


const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Render
                ths={this}
            />
        );
    }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export const Render = (ths) => {
    const { t } = useTranslation();
    const event = ths.ths;

    return (
        <>
            {
               /*
                    <span><a href="https://www.bb.com.tr">B&B</a> &copy; 2020 {t('bbBilisim')}.</span>
                    <span className="ml-auto">Powered by <a href="https://www.bb.com.tr">{t('talepYonetimSistemi')}</a></span>
               */
            }
        
            <span className="ml-auto"> &copy; {new Date().getFullYear()} Tüm Hakları Saklıdır.<a className='footer-link' href="https://www.bb.com.tr" target='_blank'> B&B Bilişim Bilgisayar </a></span>
             <br></br>
            
            
           
            
           


            
     
        </>
    );
};


export default DefaultFooter;
