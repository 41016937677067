import {combineReducers} from "redux";
import loginReducer from "./loginReducer";
import {myTaskCreateTemplatesReducer, ownTaskReducer, taskReducer,} from "./taskReducer";
import {poolCountReducer, poolReducer} from "./poolReducer";
import activityReducer from "./activityReducer";
import surveyReducer from "./surveyReducer";
import menuReducer from "./menuReducer";
import loadingReducer from "./loadingReducer";
import {childCategoryReducer, parentCategoryReducer} from './categoryReducer'
import mainCompanyReducer from "./mainCompanyReducer";
import {ownConfirmTaskCountReducer, ownTaskCountReducer, popupMessageReducer, slaTaskCountReducer} from "./mainReducer";
import getWatcherTasksReducer from "./getWatcherTasksReducer";
import getTaskAssignedReducer from "./getTaskAssignedReducer";

const appReducer = combineReducers({
   loginReducer,
   taskReducer,
   ownTaskReducer,
   // ownConfirmReducer,
   poolReducer,
   activityReducer,
   surveyReducer,
   menuReducer,
   loadingReducer,
   poolCountReducer,
   parentCategoryReducer,
   childCategoryReducer,
   mainCompanyReducer,
   slaTaskCountReducer,
   ownTaskCountReducer,
   ownConfirmTaskCountReducer,
   myTaskCreateTemplatesReducer,
   popupMessageReducer,
   getWatcherTasksReducer,
   getTaskAssignedReducer
})

const rootReducer = (state, action) =>
{
   if (action.type === 'USER_LOGGED_OUT')
   {
      state = undefined;
   }

   return appReducer(state, action);
};

export default rootReducer;
