import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import DataGrid, {Column, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import {post} from "axios";
import {Popup} from 'devextreme-react/popup';
import * as BB from "../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class CompanyDepartmentJobs extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         redirect: false,
         redirectUrl: '',
         departmentID: this.props.location.state ? this.props.location.state.DepartmentID : null,
         companyID: this.props.location.state ? this.props.location.state.CompanyID : null,
         addDepartmentJobsVisible: false,
         editDepartmentJobsVisible: false,
         editID: '',
         departmentJobsName: ''
      }
   }

   componentDidMount()
   {
      if (this.state.departmentID !== null)
      {
         this.props.actions.isLoading(true);
         let url = 'api/Yonetim/Companies/GetDataDepartmentJob?ID=' + this.state.departmentID;
         BB.FetchJSON(url).then(res =>
         {
            this.setState({gridData: res.Data});
            this.props.actions.isLoading(false);
         });
      }
      else
      {
         this.setState({redirect: true, redirectUrl: '/Yonetim/Companies'})
      }
   }

   add()
   {
      if (this.state.departmentJobsName === undefined || this.state.departmentJobsName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            Name: this.state.departmentJobsName,
            CompanyDepartmentID: this.state.departmentID
         }

         const formData = new FormData();

         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', null)

         post('api/Yonetim/Companies/AddDepartmentJob', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addDepartmentJobsVisible: false,
                  //editPoolVisible: false,
                  departmentJobsName: ''
               }, this.getData());
               this.props.actions.isLoading(false);
               notify(t("unvanbasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               this.setState({
                  addDepartmentJobsVisible: false,
                  //editPoolVisible: false,
                  departmentJobsName: ''
               });
               notify(t("unvaneklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   edit()
   {
      if (this.state.departmentJobsName === undefined || this.state.departmentJobsName === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            Name: this.state.departmentJobsName,
            CompanyDepartmentID: this.state.departmentID
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', this.state.editID)
         post('api/Yonetim/Companies/AddDepartmentJob', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({editDepartmentJobsVisible: false, departmentJobsName: ''}, this.getData());
               this.props.actions.isLoading(false);
               notify(t("guncellemebasarili"), "success", 1000);
            }
            else
            {
               this.setState({editDepartmentJobsVisible: false, departmentJobsName: ''});
               notify(t("guncellemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginLeft: 5}}
               color={'info'}
               outline
               className='mr-1'
               onClick={() => this.setState({
                  editDepartmentJobsVisible: true,
                  editID: cellData.data.ID,
                  departmentJobsName: cellData.data.Name,
               })}
            >{t("düzenle")}</Button>
         </div>
      );
   }

   getData()
   {
      /* ESKİ YAPI
      axios.get(`api/Yonetim/Companies/GetDataDepartmentJob?ID=` + this.state.departmentID)
      .then(res => {
      const data = res.data.Data;
      this.setState({ gridData:data});

      })
      */
      let url = 'api/Yonetim/Companies/GetDataDepartmentJob?ID=' + this.state.departmentID;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({gridData: res.Data})
         this.props.actions.isLoading(false);
      });
   }

   render()
   {
      const {gridData, redirectUrl, companyID} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {CompanyID: companyID}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({redirect: true, redirectUrl: '/Yonetim/Departments'})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("geri")}</Button>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({addDepartmentJobsVisible: true})}
                           style={{float: 'left', position: 'absolute', zIndex: 1, marginLeft: 60}}
                           type="button">{t("unvanekle")}
                        </Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Column
                              dataField="Name"
                              caption={t("unvanadi")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={300}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>

               </Col>

               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.addDepartmentJobsVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("unvanekle")}
                  width={metrics.popupMinWidth}
                  height={220}
               >
                  <form>

                     {BB.InputWithLabel('departmentJobsName', t("unvanadi"), 'text', this)}

                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addDepartmentJobsVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
               <Popup
                  visible={this.state.editDepartmentJobsVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("unvanduzenle")}
                  width={metrics.popupMinWidth}
                  height={220}
               >

                  {BB.InputWithLabel('departmentJobsName', t("unvanadi"), 'text', this)}

                  <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>

                     <Button
                        color={'info'}
                        onClick={() => this.setState({
                           editDepartmentJobsVisible: false,
                           editID: '',
                           departmentJobsName: ''
                        })}>
                        {t("kapat")}
                     </Button>

                     <Button
                        onClick={() => this.edit()}
                        color={'primary'}
                        style={{marginLeft: 20, marginRight: 0}}>
                        {t("kaydet")}
                     </Button>
                  </Row>
               </Popup>
            </Row>
         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(CompanyDepartmentJobs)
);
