import React, { Component, Suspense } from "react";
import * as router from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
 
} from "reactstrap";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
import routes from "../navigation/routes";
import { bindActionCreators } from "redux";
import * as loginUserActions from "../redux/actions/loginUserActions";
import * as surveysActions from "../redux/actions/surveysActions";
import { connect } from "react-redux";
import * as poolActions from "../redux/actions/poolActions";
import { LoadPanel } from "devextreme-react/load-panel";
import metrics from "../common/metrics";
import * as BB from "../components/BBComponent";
import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";

import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import logo from "../assets/info.png";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import * as loadingActions from "../redux/actions/loadingActions";
import * as mainActions from "../redux/actions/mainActions";
import ReactHtmlParser from "react-html-parser";
import jwt_decode from "jwt-decode";
import { callAPI, callApiGet } from "../components/Axios";
import { t } from "i18next";

let Subdomain = undefined;
let props = undefined;
let answers = [];
const taskIDArray = [];

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      menus: undefined,
      getPopupMessage: [],
      getMessage: [],
      popupVisible: false,
      editRowVisible: false,
      QuestionsAll: [],
      gridData: undefined,
      selectedTaskID: "",
      selectedTaskSurveyID: "",
      selectedTaskDescription: "",
      elemOfArray: 0,
      modalState: localStorage.getItem("modalVisible") || false,
      modalVisible: false,
      activeIndex: 0,
      selectedLoginUserID: undefined,
      loginUser: null,
      surveyCount: 0,
      hubConnection: null,
      connection:null,
    };

    let { t } = this.props;

    //  this.languages = {
    //    lutfenBekleyiniz:t("lutfenBekleyiniz")
    //  }


    var token = localStorage.getItem("token");
    if (token) {
      let user = jwt_decode(token);
      if (user) {
       this.state.loginUser=user;
       if(window.location.hash.includes("#!/TaskDetail")){
        
       }
       else if (window.location.hash.includes("#!/TaskPool")) {

       }
       else if (window.location.hash != "#!/main") {
         window.location.href = "#!/main";
      }
          this.changeSelectionPriority =
          this.changeSelectionPriority.bind(this);
          this.answer = this.answer.bind(this);
          this.goToIndex = this.goToIndex.bind(this);
          this.onExiting = this.onExiting.bind(this);
          this.onExited = this.onExited.bind(this);
          this.getMenus();
          this.getSurveys();

          metrics.defaultLayout = this;
        
      } 
    }

 
  }

  //componentDidMount() {}

  getMenus() {
    if (this.state.loginUser != undefined) {
      if (
        this.state.loginUser.Menus != null &&
        this.state.loginUser.Menus.length > 0
      ) {
        let menus = JSON.parse(this.state.loginUser.Menus);
        let arr = { items: [] };

        if (menus.length > 0) {
          for (const item of menus[0].children) {
            arr.items.push(item);
          }
        }

        let ths = this;
        setTimeout(function () {
          ths.setState({ menus: arr });
        }, 50);
      } else {
        window.location.href = "#!/login";
        localStorage.clear();
        notify(
          "Oturum süreniz dolmuştur. Lütfen yeniden oturum açınız.",
          "error",
          3000
        );
      }
    } else {
      window.location.href = "#!/login";
      localStorage.clear();
      notify(
        "Oturum süreniz dolmuştur. Lütfen yeniden oturum açınız.",
        "error",
        3000
      );
    }
  }

  componentDidMount() {


    //const hubConnection = new HubConnection('https://localhost:5001/tysHub');

  // this.setState({ hubConnection }, () => {
  //   this.state.hubConnection
  //     .start()
  // });
  // this.state.hubConnection.on('ReceiveMessage', (loginUserId, message,taskID) => {

  // });


    props = this.props;
    // this.getPopupMessageData();
    //this.takeSurveyAndAnswers(this.state.elemOfArray);
    //this.refreshSession();
  }

  // takeSurveyAndAnswers(number) {

  //    this.getSurveys(number).then(() => {
  //       //    this.setState({ gridData: this.props.ownSurvey[number] });

  //       //    if (this.state.gridData) {
  //       //       this.setState({
  //       //          selectedTaskID: this.state.gridData.TaskNumber.toString(),
  //       //          selectedTaskDescription: this.state.gridData.Description,
  //       //          selectedTaskSurveyID: this.state.gridData.ID
  //       //       })

  //       //       this.answer(this.state.gridData.ID);

  //       //       setTimeout(() => {

  //       //          if (this.checkIsDelayed(this.state.selectedTaskID)) {
  //       //             this.setState({ editRowVisible: false });

  //       //          }
  //       //          else {
  //       //             this.setState({ editRowVisible: true })
  //       //          }
  //       //       }, 2000)

  //       //    };
  //    })
  // }

  getSurveys(number) {
    if (this.state.loginUser != undefined) {
      let url = "/api/TaskSurvey/GetData";
      callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          if (res?.data) {
            if (!res || !res.data || res.data.length < 1) return;

            const result = res?.data;
            this.setState({ gridData: result[0] });

            if (this.state.gridData) {
              this.setState({
                selectedTaskID: this.state.gridData.TaskNumber.toString(),
                selectedTaskDescription: this.state.gridData.Description,
                selectedTaskSurveyID: this.state.gridData.ID,
              });
              console.log("this.state.gridData.ID ",this.state.gridData.ID);
              this.answer(this.state.gridData.ID);

              setTimeout(() => {
                if (this.checkIsDelayed(this.state.selectedTaskID)) {
                  this.setState({ editRowVisible: false });
                } else {
                  this.setState({ editRowVisible: true });
                }
              }, 2000);
            }
          }
        },
        true
      );
    }
  }






  //  createHubConnection =async () => {
  //   const hubCn = new HubConnectionBuilder().withUrl("http://localhost:5001/tysHub").build()
  //   try {
  //   await hubCn.start();
  //   setHubConnection(hubCn)
  //   hubConnection.on("ReceiveMessage",(loginUserID, message,taskId)=>{
  //     if(loginUserID === this.state.loginUser.ID){
  //        //Send Notification 
  //     }
 
  //   } )}
  //   catch (e) {
    
  //   }
  //   }
    




  loading() {
    return (
      <div className="animated fadeIn pt-1 text-center center">
        {t("yukleniyor")}
      </div>
    );
  }

  answer(ID) {

    if (this.state.loginUser != undefined) {
      let url = "api/TaskSurvey/TaskAnswer?ID=" + ID;
      callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          if (res?.data) {
            if (!res || !res.data || res.data.length < 1) return;

            var questions = [];
            console.log("res.data.Survey.SurveyQuestion ",res.data.Survey.SurveyQuestion);
      for (var i = 0; i < res.data.Survey.SurveyQuestion.length; i++) {
  
        questions.push({
          Question: res.data.Survey.SurveyQuestion[i].Question,
          Answer1: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
          Answer2: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
          Answer3: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
          Answer4: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
        });
      }
      this.setState({ QuestionsAll: questions });
    
          }
        },
        true
      );
    }


    // BB.FetchJSON("api/TaskSurvey/TaskAnswer?ID=" + ID).then((data) => {


    //   var questions = [];

    //   for (var i = 0; i < data.Survey.SurveyQuestion.length; i++) {
    //     /*  answers.push({
    //             q: i,
    //             a: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option
    //          }); */
    //     questions.push({
    //       Question: data.Survey.SurveyQuestion[i].Question,
    //       Answer1: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
    //       Answer2: data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
    //       Answer3: data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
    //       Answer4: data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
    //     });
    //   }
    //   this.setState({ QuestionsAll: questions });
    // });
  }

  changeSelectionPriority(e) {
    let q = e.target.id.slice(0, 1);
    let a = e.target.value;

    answers.map((item, index) => {
      if (q == item.q) {
        answers.splice(index, 1);
      }
    });
    answers.push({
      q: parseInt(q),
      a: a,
    });
  }

  answerSave() {
    const formData = new FormData();
debugger
    let answersLenght = JSON.stringify(answers).split("},{");
    let questionsAll = this.state.QuestionsAll;
    let lenght1 = answersLenght.length;
    let lenght2 = questionsAll.length;
  
    if (lenght1 === lenght2) {
      formData.append("jsonText", JSON.stringify(answers));
      formData.append("TaskSurveyID", this.state.selectedTaskSurveyID);
      formData.append("TaskID", this.state.selectedTaskID);

      if (answers.length == 0) {
        notify("Anket cevapları boş geçilemez!", "warning", 4000);
      }
      this.props.actions.isLoading(true);

      post("api/TaskSurvey/TaskAnswer", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then((res) => {

        if (res.data.MessageStatus === true) {
          this.setState({
            editRowVisible: false,
          });
          this.props.actions.isLoading(false);
          notify("Anket Tamamlandı!", "success", 1000);
          answers = [];
        } else {
          this.setState({
            editRowVisible: false,
          });
          notify("Anket Oluşturma Hata! : " + res.data.Message, "error", 1000);
          this.props.actions.isLoading(false);
        }
      });
    } else {
      return notify(
        "Tüm soruları cevaplamanız gerekmektedir !!! ",
        "warning",
        1000
      );
    }
  }

  

  async saveToLocalStorage(id) {
    taskIDArray.push(id);
    localStorage.setItem("SurveyTaskID", JSON.stringify(taskIDArray));
    await this.setState({
      editRowVisible: false,
      elemOfArray: this.state.elemOfArray + 1,
    });
  }

  checkIsDelayed(id) {
    const taskId = JSON.parse(localStorage.getItem("SurveyTaskID")) || [];
    if (taskId.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  handleModalVisible = () => {
    localStorage.setItem("modalVisible", true);
    this.setState({ modalVisible: false });
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  render() {
    metrics.redirectURL =
      this.props.location.pathname + this.props.location.search;
    let isLoading = this.props.loading.isLoading;
    let { t } = this.props;

    if (
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === null
    ) {
      return <Redirect push to="/login" />;
    } //Login User Dolu
    else {
      return (
        
        <div className="app">
          
          <LoadPanel
            message={t("lutfenBekleyiniz")}
            position={"center"}
            shadingColor="rgba(0,0,0,0.4)"
            visible={isLoading}
            showIndicator={true}
            shading={true}
            closeOnOutsideClick={false}
          />
          <AppHeader fixed>
            <Suspense fallback={this.loading()}>
              <DefaultHeader {...this.props} onLogout={(e) => signOut()} />
            </Suspense>
          </AppHeader>
          <div className="app-body">
            <AppSidebar display="lg" fixed minimized>
              {/*<AppSidebar display="lg" fixed minimized>*/}
              <AppSidebarHeader />
              <AppSidebarForm />
              <Suspense fallback={this.loading()}>
                <AppSidebarNav
                  navConfig={this.state.menus}
                  {...this.props}
                  router={router}
                />
              </Suspense>
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
            <main className="main" style={{ paddingTop: 15 }}>
              <Container fluid>
                <Suspense fallback={this.loading()}>
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                    {/* <Redirect from="/" to="/login" /> */}
                  </Switch>
                </Suspense>
              </Container>
            </main>
            <AppAside fixed>
              <Suspense fallback={this.loading()}>
                <DefaultAside {...this.props} />
              </Suspense>
            </AppAside>
          </div>
          <AppFooter>
            <Suspense fallback={this.loading()}>
              <DefaultFooter />
            </Suspense>
          </AppFooter>

          <Modal isOpen={this.state.editRowVisible} centered={true}>
            <ModalHeader>
              {`${this.state.selectedTaskID}`} {t("numaralitalepAnketi")}
            </ModalHeader>
            <ModalHeader>
              <div>Talep Açıklaması</div>
              <p style={{ fontWeight: "lighter" }}>
                {this.state.selectedTaskDescription
                  ? ReactHtmlParser(this.state.selectedTaskDescription)
                  : "Yükleniyor..."}
              </p>
            </ModalHeader>
            <ModalBody>
              <div className="survey-list">
                {this.state.QuestionsAll.map((item, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <span className="survey-index">{index + 1}</span>
                        {item.Question}
                      </p>
                      <div className="rating">
                        <input
                          type="radio"
                          id={`${index}-star4`}
                          name={`${index}-rating`}
                          value={item.Answer1}
                          onClick={this.changeSelectionPriority}
                        />
                        <label
                          className="star"
                          htmlFor={`${index}-star4`}
                          title={item.Answer1}
                          aria-hidden="true"
                        >
                          {" "}
                        </label>
                        <input
                          type="radio"
                          id={`${index}-star3`}
                          name={`${index}-rating`}
                          value={item.Answer2}
                          onClick={this.changeSelectionPriority}
                        />
                        <label
                          className="star"
                          htmlFor={`${index}-star3`}
                          title={item.Answer2}
                          aria-hidden="true"
                        >
                          {" "}
                        </label>
                        <input
                          type="radio"
                          id={`${index}-star2`}
                          name={`${index}-rating`}
                          value={item.Answer3}
                          onClick={this.changeSelectionPriority}
                        />
                        <label
                          className="star"
                          htmlFor={`${index}-star2`}
                          title={item.Answer3}
                          aria-hidden="true"
                        >
                          {" "}
                        </label>
                        <input
                          type="radio"
                          id={`${index}-star1`}
                          name={`${index}-rating`}
                          value={item.Answer4}
                          onClick={this.changeSelectionPriority}
                        />
                        <label
                          className="star"
                          htmlFor={`${index}-star1`}
                          title={item.Answer4}
                          aria-hidden="true"
                        >
                          {" "}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </ModalBody>
            <ModalFooter>
              {/* { <Button
                        onClick={() => this.saveToLocalStorage(this.state.selectedTaskID)}
                        color={'secondary'}
                        style={{ marginLeft: 15, marginRight: 0 }}>
                        Sonra Tamamla
                     </Button> } */}
              <Button
                onClick={() => this.answerSave()}
                color={"primary"}
                style={{ marginLeft: 15, marginRight: 0 }}
              >
                Kaydet
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
}

export const signOut = () => {
  try {
    localStorage.clear();
    metrics.redirectURL = undefined;
    props.history.push("/login");
  } catch (error) {}
};

function mapStateToProps(state) {
  return {
    loginuser: state.loginReducer,
    menus: state.menuReducer,
    ownsurvey: state.surveyReducer,
    loading: {
      isLoading: state.loadingReducer,
    },
    popupmessage: state.popupMessageReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearLoginUser: bindActionCreators(
        loginUserActions.clearLoginUser,
        dispatch
      ),
      getAllTaskInPool: bindActionCreators(poolActions.getPoolCount, dispatch),
      getSurveys: bindActionCreators(surveysActions.getAllSurveys, dispatch),
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      getPopupMessage: bindActionCreators(
        mainActions.getPopupMessage,
        dispatch
      ),
    },
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);
