import * as actionTypes from './actionTypes'
import * as models from "../../models/LoginUserModel";

export function setLoginUser(User)
{
   return {type: actionTypes.SET_LOGIN_USER, payload: User}
}

export function clrLoginUser(User)
{
   return {type: 'USER_LOGGED_OUT', payload: User}
}

export function checkLoginUser(userEmail, userPass, isLdapLogin)
{
   return function (dispatch)
   {
      let url = 'api/Login/checkUserLogin?userName=' + userEmail + "&userPassword=" + userPass + "&isLdapLogin=" + isLdapLogin;
      return fetch(url)
      .then(res => res.json())
      .then(result =>
      {
        
         if (result.ID === '' || result.ID === null || result.ID === undefined)
         {
            alert('Kullanıcı adı & şifreniz hatalıdır');
         }
         else
         {
            dispatch(setLoginUser(result))
         }
      });
   }
}

export function checkLoginUser3rdPartApp(Username, Password, CompanyName)
{
   return function (dispatch)
   {
      let url = 'api/Login/Login3rdPartApp?Username=' + Username + "&Password=" + Password + "&CompanyName=" + CompanyName;

      return fetch(url)
      .then(res => res.json())
      .then(result =>
      {
         if (result.ID === '' || result.ID === null || result.ID === undefined)
         {
            alert('Kullanıcı adı & şifreniz hatalıdır');
         }
         else
         {
            dispatch(setLoginUser(result))
         }
      });
   }
}

export function clearLoginUser()
{
   return function (dispatch)
   {
      return dispatch(clrLoginUser(models.LoginUser))
   }
}
