import * as actionTypes from '../actions/actionTypes'

export function poolReducer(state = [], action)
{
   switch (action.type)
   {
      case actionTypes.SET_POOL_DATA:
         return action.payload
      default:
         return state
   }
}

export function poolCountReducer(state = 0, action)
{
   switch (action.type)
   {
      case actionTypes.SET_POOL_COUNT:
         return action.payload
      default:
         return state
   }
}
