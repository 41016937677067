import React, { Component } from "react";
import {
  Button,
  CardBody,
  Col,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Input,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { withTranslation } from "react-i18next";
import * as BB from "../../../../components/BBComponent";
import notify from "devextreme/ui/notify";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as poolActions from "../../../../redux/actions/poolActions";
import { connect } from "react-redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import { CustomCard, LabelGroup } from "../../../../components/custom"
import metrics from "../../../../common/metrics";
import TextBox from "devextreme-react/text-box";
import CategoryCustomControl from "../../../../components/custom/CategoryCustomControl/CategoryCustomControl";
import Watcher from "./Modals/Watcher";
import FormModal from "./Modals/Form";
import SheetModal from "./Modals/Sheet";
import UserSelect from "../../../../components/custom/UserSelect/UserSelect";
import CategorySelect from "../../../../components/custom/CategorySelect/CategorySelect";
import * as functions from "./Functions/index";
import CategoryFilter from "../../../../components/custom/CategoryFilter/CategoryFilter"
import jwt_decode from 'jwt-decode';
import {
  Dx2HtmlEditor,
  TextAreaWithMetrics,
} from "../../../../components/BBComponent";
import axios from "axios";
import {
  callAPI,
  callApiGet,
  callApiPost,
  callApiPut
} from "../../../../components/Axios";

let isTemplate = false;
let isDelete = false;
let htmlDescription = "";





class TaskCreate extends Component {
  constructor(props) {
    super(props);
    let user = null;
    var token = localStorage.getItem("token");

    if (token) {
      user = jwt_decode(token);
    }

    this.state = {
      taskCategories: [],
      mainTaskList: [],
      effectList: [],
      userList: [],
      departmentUsers: [],
      CustomControlForms: [],

      ComponentFileUpload :[],
      selectedFormID: undefined,
      selectedForm: [],
      selectedFormName: undefined,

      selectedSheetID: undefined,
      selectedSheet: [],
      selectedSheetName: undefined,

      answer_data: [],
      previewVisible: false,
      preview2Visible: false,
      redirect: false,
      selectedFormRequired:false,
      selectedNotificationType: 0,
      selectedCompanyID: this.props.CompanyID,
      selectedCompanyName: undefined,
      fileUploaderVisible :"none",
      selectedEmployee: user.ID,
      selectedEmployeeName:
        user.Name +
        " " +
        user.SurName +
        " - (" +
        user.CompanyName +
        ")",
      selectedEmployeeSurname: user.SurName,
      selectedEmployeeJobName: user.JobName,
      selectedEmployeeCompanyName: user.CompanyName,
      selectedEmployeeDepartment: user.Department,
      selectedEmployeeEmail: user.Email,

      selectedCreateUserCompanyID: undefined,

      selectedCategory: undefined,
      selectedDegreeOfUrency: 0,
      selectedEffect: undefined,
      selectedMainTaskID: undefined,
      selectedTaskDescription: undefined,
      selectedDueClosedDate: undefined,
      selectedTaskFiles: [],
      selectedCustomControl: [],
      isDepartmanNotification: false,
      categoryConfirmations: "",
      showEffect: false,
      showEmployees: false,
      showExpectedEndDate: false,
      showRepeatJobNumbers: false,
      repeatJob: false,
      customElements: [],
      isMailNotification: false,
      selectedMailNotifications: "",
      message: "",
      message2: "",
      rows: [],
      selectedWatcherUserList: [],
      selectedWatcherUserID: [],
      visibleWatcher: false,
      watcherList: [],
      companySelectTool: '',

      selectCategory: false,

      selectedParentCategory: undefined,

      selectedChildCategory1: undefined,
      selectedChildCategory2: undefined,
      selectedChildCategory3: undefined,
      selectedChildCategory4: undefined,
      selectedChildCategory5: undefined,
      selectedChildCategory6: undefined,

      childCategory1Arr: [],
      childCategory2Arr: [],
      childCategory3Arr: [],
      childCategory4Arr: [],
      childCategory5Arr: [],
      childCategory6Arr: [],

      parentCategoryLoading: false,
      childCategory1Loading: false,
      childCategory2Loading: false,
      childCategory3Loading: false,
      childCategory4Loading: false,
      childCategory5Loading: false,
      childCategory6Loading: false,

      selectedCategoryName: undefined,
      userSelectVisible: false,
      modalOpen: false,
      categoryTerm: "",
      filteredCategory: [],
      selectedFilterCategory: "",
      taskCompanies: [],
      taskCompaniesTemp: [],
      companySelectToolListVisible: false,
      loginUser: user,
      categoryPath: '',
      favoriCategory:[],
      isFavori:true,    
      fileRequirement:false,
      oldGroupUsers: [],
      selectedWatcherGroups: [],
      selectedWatcherGroupsID:[]
    };

    this.htmlRef = React.createRef();
    this.customElement = React.createRef();
    this.treeView = React.createRef();

    this.getCompanies();


    functions.getMainTaskList(this);
    functions.getDepartmentUser(this);
    this.GetWatcherGroups();

    this.getParentCategories(user.ID, null);



  }

  deleteSelectedFile=(name)=>{

    let newFiles = [];
    this.state.selectedTaskFiles.map((item, i) => {

      if(item.name !== name ){
       newFiles.push(item);
      }
    });
    this.setState({ selectedTaskFiles: newFiles });
    this.setState({ ComponentFileUpload: newFiles });

  }


  getCompanies = () => {
    callAPI(
      callApiGet,
      `/api/Task/GetCompanies`,
      {},
      async (res) => {
        if (res?.data) {

          this.setState({ taskCompanies: res?.data });
          let tempCompanies = res?.data.slice(0, 10);
          let itemdef = { Checked: false, Description: null, Expanded: false, ID: "", Name: "Daha Fazla Firma için En az 3 karakter Girmelisiniz...", ParentID: null };
          tempCompanies = tempCompanies.concat(itemdef);
          this.setState({ taskCompaniesTemp: tempCompanies });

          this.props.actions.isLoading(false)
        }
      },
      true
    );
  };

  getParentCategories = (UserID, ParentID) => {
    let url = UserID !== null && '/api/Task/GetCategories?UserID=' + UserID + '&ParentID=' + ParentID;

    this.setState({ parentCategoryLoading: true })
    
    callAPI(
      callApiGet,
      url,
      {},
      async (res) => {
        if (res?.data) {

          this.setState({ taskCategories: res?.data, parentCategoryLoading: false })

          this.props.actions.isLoading(false)
        }
      },
      true
    );
  };


  GetWatcherGroups() {
    callAPI(
      callApiGet,
      `api/User/GetWatcherGroupsByUserId`,
      {},
      async (res) => {
        if (res?.data) {
          this.setState({
            oldGroupUsers: res?.data
          });
        }
      },
      true
    );
  }

  GetWatcherGroupUsers(group) {
    callAPI(
      callApiGet,
      `api/User/GetGroupUsersByGroupId`,
      {watchersGroupID: JSON.stringify(group)},
      async (res) => {
       if(res?.data != null){
        this.setState({selectedWatcherGroups:res?.data});
       }
      },
      true
    );
  }

  componentDidMount() {



    this.props.actions.isLoading(false);
    functions.getShowExpectedEndDate(this);
    functions.getWatcherUserList(this);

  }

  handleChange = (state, value) => {
     
    let currentSelectedCustomControl = this.state.selectedCustomControl;

    let isCustomControl = this.state.customElements.filter(
      (x) => x.ID == state
    );


    if (isCustomControl.length > 0) {

      let hasAlready = currentSelectedCustomControl.filter(
        (x) => x.ID == state
      );


      if (hasAlready.length > 0) {

        let currentIndex = currentSelectedCustomControl.indexOf(hasAlready);
        currentSelectedCustomControl.splice(currentIndex, 1);

        if (isCustomControl[0].CustomControlType === 2) {
          let item = { ID: state, Value: value };
          currentSelectedCustomControl.push(item);
        } else {
          let item = { ID: state, Value: value };
          currentSelectedCustomControl.push(item);
        }
      } 
      
      else {
        //Burada File To Json yapmamız lazım 
        if (isCustomControl[0].CustomControlType === 2) {
          
          let item = { ID: state, Value: value };
      

          currentSelectedCustomControl.push(item);
        } else {
          let item = { ID: state, Value: value };
   
          currentSelectedCustomControl.push(item);
        }
      }

      this.setState({ selectedCustomControl: currentSelectedCustomControl });
    }
  
    if (state === "selectedCompanyName") {
      this.setState({
        showEmployees: true,
        selectedCompanyName: value,
        selectedEmployee: undefined,
        selectedCategory: undefined,
        selectedDegreeOfUrency: undefined,
        selectedEffect: undefined,
        selectedMainTaskID: undefined,
      });
    }
    if (state === "selectedEmployee") {
      let user = this.state.taskCreateUserList.filter((x) => x.ID == value)[0];

      functions.getParentCategories(user.ID, null, this);

      functions.getMainTaskList(this, value);

      this.setState({
        selectedEmployee: value,
        userSelectVisible: false,
        selectedEmployeeName: user.Name,
        selectedEmployeeSurname: user.Surname,
        selectedEmployeeJobName: user.JobName,
        selectedEmployeeCompanyName: user.CompanyName,
        selectedEmployeeDepartment: user.DepartmentName,
        selectedEmployeeEmail: user.Email,
        selectedParentCategory: undefined,
        selectedChildCategory1: undefined,
        selectedChildCategory2: undefined,
        selectedChildCategory3: undefined,
        selectedChildCategory4: undefined,
        selectedChildCategory5: undefined,
        childCategory1Arr: [],
        childCategory2Arr: [],
        childCategory3Arr: [],
        childCategory4Arr: [],
        childCategory5Arr: [],
        selectedCategory: undefined,
        selectedCategoryName: "",
      });

      if (value !== this.state.loginUser.ID) {
        this.setState({ selectedNotificationType: undefined });
      }

      if (
        this.state.taskCategories.filter(
          (x) => x.ID == this.state.selectedCategory
        ).length > 0
      ) {
        this.setState({ selectedCategory: undefined });
      }

      if (
        this.state.selectedCategory !== "" &&
        this.state.selectedCategory !== undefined
      ) {
        functions.getCategoryConfirmations(this.state.selectedCategory, this);
      }
    }
    if (state === "selectedCategory") {

      this.setState({ selectedCategory: value });

      if (value) {
        functions.getCustomElements(value, this);
        functions.getCategoriesEffect(value, this);
      }
    }
    if (state === "repeatJob") {
      this.setState({
        repeatJob: !this.state.repeatJob,
        showRepeatJobNumbers: !this.state.repeatJob,
      });
    }
    if (state === "selectedMainTaskID") {
      this.setState({ selectedMainTaskID: value });
    }
    if (state === "selectedTaskDescription") {
      this.setState({ selectedTaskDescription: value });
    }
    if (state === "selectedCreateUserCompanyID") {

      var url = "/api/User/GetUserList?CompanyID=" + value;
      callAPI(
        callApiGet,
        url,
        {},
        async (res) => {
          if (res?.data) {
  
            this.setState({
              selectedCreateUserCompanyID: value,
              selectedEmployee: undefined,
              taskCreateUserList: res?.data,
            });
          }
        },
        true
      );
    }
    if (state === "selectedDueClosedDate") {
      this.setState({ selectedDueClosedDate: value });
    }
    if (state === "selectedEffect") {
      this.setState({ selectedEffect: value });

    }
    if (state === "selectedTaskFiles") {
      this.setState({ selectedTaskFiles: value });
    }
    if (state === "selectedTaskFilesState") {
      this.setState({ fileUploaderVisible: value });
    }
    if (state === "selectedDegreeOfUrency") {
      value !== 0
        ? this.setState({ isAcil: true })
        : this.setState({ isAcil: false });
      this.setState({ selectedDegreeOfUrency: value });
    }
    // if (state === 'selectedDegreeOfUrencyDescription')
    // {
    //    this.setState({selectedDegreeOfUrencyDescription: value});
    // }
    if (state === "isDepartmanNotification") {
      let selectedDepUser = [];

      if (!this.state.isDepartmanNotification) {
        this.state.departmentUsers.map((item) => {
          if (item.ID !== this.state.loginUser.ID) {
            selectedDepUser.push(item);
          }
        });

        this.setState({ selectedWatcherUserList: selectedDepUser });
      }

      this.setState({
        isDepartmanNotification: !this.state.isDepartmanNotification,
        selectedWatcherUserList: selectedDepUser,
        selectedCompanyID: undefined,
      });
    }
    if (state === "isMailNotification") {
      this.setState({ isMailNotification: !this.state.isMailNotification });
    }
    if (state === "selectedMailNotifications") {
      this.setState({ selectedMailNotifications: value });
    }
    if (state === "selectedWatcherUserID") {
      this.setState({ selectedWatcherUserID: value });
    } else {
      this.setState({ [state]: value });
    }


  };

  checkValidations = () =>
    new Promise((resolve) => {
      let { t } = this.props;

      if (
        metrics.htmlDescription === undefined ||
        metrics.htmlDescription === "<p></p>" ||
        metrics.htmlDescription === ""
      ) {
        this.props.actions.isLoading(false);
        notify(t("aciklamaAlaniZorunludur"), "error", 1000);
        resolve(false);
      }
      if (this.state.selectedCategory === undefined) {
        
        this.props.actions.isLoading(false);
        notify(t("kategoriAlaniZorunludur"), "error", 1000);
        resolve(false);
      }
      
      if (
        (metrics.selectedDegreeOfUrencyDescription === undefined ||
          metrics.selectedDegreeOfUrencyDescription === "") &&
        this.state.isAcil
      ) {
        this.props.actions.isLoading(false);
        notify(t("aciliyetAciklamaAlaniZorunludur"), "error", 1000);
        resolve(false);
      }

      if (this.state.customElements.length > 0) {

        this.state.customElements.map((item, i) => {
    
          let currentElement = this.state.selectedCustomControl.filter(
           
            (x) => x.ID == item.ID,
           
          );

          if (
            item.CustomControlType === 1 ||
            item.CustomControlType === 2 ||
            item.CustomControlType === 4 ||
            item.CustomControlType === 5 ||
            item.CustomControlType === 6 ||
            item.CustomControlType === 8
          ) {

            //IsRequired
            if(item.IsRequired){
              if (currentElement.length === 0) {
              
                notify(t("ozelAlanZorunlu"), "error", 1000);
                resolve(false);
              
              }
            }
            
            
      

          }
        });
      }

      if (
        this.selectedFormRequired ===true &&
        this.state.selectedForm.length > 0 &&
        this.state.answer_data.length === 0
      ) {
        notify(
          t("asagidabulunanformudoldurmaniziricaederiz"),
          "error",
          1000
        );
        resolve(false);
      }

      if (this.state.selectedSheet.length > 0 && this.state.rows.length === 0) {
        notify(
          t("asagidabulunantabloyudoldurmaniziricaederiz"),
          "error",
          1000
        );
        resolve(false);
      }

      resolve(true);
    });

  saveCustomFormValues(values) {
    this.setState({ previewVisible: false, answer_data: values });
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState((state) => {
      const rows = state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    });
  };

  render() {
    // var token = localStorage.getItem("token");
    // var user = jwt_decode(token);
    const { t } = this.props;
    const {
      categoryConfirmations,
      message,
      selectedFormRequired,
      isAcil,
      customElements,
      showExpectedEndDate,
      message2,
      showEffect,
      effectList,
      mainTaskList,
      childCategory1Arr,
      childCategory2Arr,
      childCategory3Arr,
      childCategory4Arr,
      childCategory5Arr,
      selectedParentCategory,
      selectedEmployeeName,
      selectedEmployeeSurname,
      selectedEmployeeJobName,
      selectedEmployeeCompanyName,
      selectedEmployeeDepartment,
      selectedEmployeeEmail,
    } = this.state;

    const event = this;
    const AciliyetData = [
      { ID: 0, Name: t("düşük") },
      { ID: 1, Name: t("orta") },
      { ID: 2, Name: t("yüksek") },
    ];
    let disable =
      this.state.selectedEmployee !== this.state.loginUser.ID &&
      event.state.loginUser.Role !== "d45342ab-14d1-e811-80f6-005056b839bb";
    // let { Name, Surname, JobName, CompanyName, Department, Email } =
    //   this.props.loginuser;
    let size = window.innerWidth >= 400 ? 2 : 3;
    // selectedDegreeOfUrency isAcil
    return (
      <>
        <form onSubmit={(e) => functions.saveTask(e, event,isAcil,t)}>
          <Row>
            <Col xs="0" sm="2"></Col>
            <Col xs="0" sm="8">
              <CustomCard cardStyle="card-dashboard-calendar pb-0">
                <LabelGroup title={t("talepoluştur")} description={""} />

                <CardBody>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg={8}>
                          <Row>
                            <Col lg={10} xs={10}>
                              <TextBox
                                placeholder={t("lütfenseçiniz")}
                                readOnly={true}
                                value={selectedEmployeeName}
                                // style={{marginTop: 25, width: 685, float: 'left'}}
                                style={{
                                  marginTop: 25,
                                  width: "100%",
                                  float: "left",
                                }}
                              />
                            </Col>

                            {event.state.loginUser.Role === "a6957f7c-53cc-e811-80f6-005056b839bb" && this.state.loginUser.AllowChangeUser && (
                              <Col lg={size} xs={size} className="px-0">
                                <a
                                  style={{
                                    color: "darkblue",
                                    cursor: "pointer",
                                    float: "left",
                                    marginTop: 35,
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      userSelectVisible:
                                        !this.state.userSelectVisible,
                                    });
                                  }}
                                >
                                  <u>{t("degistir")}</u>
                                </a>
                              </Col>
                            )}

                            <UserSelect
                              event={this}
                              visible={this.state.userSelectVisible}
                              t={t}
                            />
                          </Row>
                        </Col>
                        <Col lg={4}>
                          {BB.DxDropDownWithJSONClrButton(
                            "selectedMainTaskID",
                            t("iştekrarıno"),
                            event,
                            mainTaskList
                          )}
                        </Col>
                      </Row>

                      <Row
                        style={{
                          marginTop: -5,
                          marginBottom: 15,
                          fontSize: "12px",
                        }}
                      >
                        <Col>
                          <a className="text-muted">{t("işUnvani")}</a> :{" "}
                          {this.state.selectedEmployeeJobName} {"  |  "}
                          <a className="text-muted">{t("departman")}</a> :{" "}
                          {this.state.selectedEmployeeDepartment} {"  |  "}
                          <a className="text-muted">{t("eposta")}</a> :{" "}
                          {this.state.selectedEmployeeEmail}
                        </Col>
                      </Row>

                      <Row className="d-flex justify-content-between align-items-center">
                        <Col
                          lg={6}
                          onClick={() =>
                            this.setState({ selectCategory: true })
                          }
                        >
                          <CategorySelect state={this.state} event={this} t={t} />
                        </Col>
                        <Col
                          lg={2}
                          style={{ marginTop: "3px", padding: "2px" }}
                        >
                          <CategoryFilter state={this.state} event={this} t={t} />
                        </Col>

                        <Col lg={4}>
                          {BB.DegreeOfUrencyButtons(
                            "selectedDegreeOfUrency",
                            t("aciliyetderecesi"),
                            event,
                            t("düşük"),
                            t("orta"),
                            t("yüksek"),
                            this.state.selectedDegreeOfUrency
                          )}
                        </Col>
                      </Row>

                      {showExpectedEndDate === true && (
                        <Row>
                          <Col></Col>
                          <Col></Col>
                          <Col>
                            {BB.DateSelectWithLabel(
                              "selectedDueClosedDate",
                              t("tahminikapanmatarihi"),
                              event,
                              t("lütfenseçiniz")
                            )}
                          </Col>
                        </Row>
                      )}

                      <CategoryCustomControl
                        customElementsData={customElements}
                        event={this}
                      />
                  {this.state.selectedFormID && (
                  <div>
                    <br />
                    {this.state.message}
                    <a
                      style={{ cursor: "pointer", color: "cornflowerblue" }}
                      onClick={() => event.setState({ previewVisible: true })}
                    >
                      
                          {t("burayaTiklayiniz")}
                    </a>
                    
                  </div>
                  
                )}
                <br />
                
                      {BB.DxHtmlEditor(
                        "selectedTaskDescription",
                        t("açıklamazorunlu")
                      )}

                      {isAcil === true &&
                        BB.TextAreaWithMetrics(
                          "selectedDegreeOfUrencyDescription",
                          t("aciliyetaçıklaması"),
                          t("aciliyethakkındabirşeyleryaz"),
                          1,
                          event
                        )}

                      {showEffect &&
                        BB.DxDropDownWithJSON(
                          "selectedEffect",
                          t("etki"),
                          event,
                          effectList
                        )}

                      <Row>
                        <Col>
                          {BB.MultipleFileWithLabel2(
                            "selectedTaskFiles",
                            t("ekdosya"),
                            event,
                            t("dosyaSec"),
                            true,
                            
                          )} 
                          <ListGroup style={{ overflowY: "scroll", height: "100px",width:"320px" ,display: this.state.fileUploaderVisible}}>
                          {this.state.selectedTaskFiles.map((filter) => (
                            <ListGroupItem style={{padding:6}}>
                              {filter.name}
                              { <Label
                                color="danger"
                                onClick={() => this.deleteSelectedFile(filter.name)}
                                className="ml-2"
                                style={{cursor:"pointer"}}
                              >
                                x
                              </Label> }
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                        </Col>
                        <Col></Col>
                        <Col>
                          <div
                            style={{ float: "right", marginRight: 30 }}
                            onClick={() =>
                              this.setState({ visibleWatcher: true })
                            }
                          >
                            <Label style={{ cursor: "pointer" }}>
                              <strong>
                                <u>
                                  {this.state.selectedWatcherUserList
                                    ? t("ekbilgilendirme") +
                                    " (" +(
                                    this.state.selectedWatcherUserList
                                      .length + this.state.selectedWatcherGroups.length)+
                                    ")"
                                    : t("ekbilgilendirme") + " (0)"}
                                </u>
                              </strong>
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Button
                            onClick={(e) => functions.saveTemplate(e, this,t,isAcil)}
                            type={"submit"}
                            style={{
                              float: "left",
                              marginRight: 5,
                              backgroundColor: "#72f4cb",
                              width:"75%"
                            }}
                            size="ls"
                            className="save-template-button"
                          >
                            {t("sablonOlarakKaydet")}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type={"submit"}
                            style={{
                              float: "right",
                              backgroundColor: "#37C19A",
                              width: "75%",
                            }}
                            size="ls"
                          >
                            {t("olustur")}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                {categoryConfirmations && (
                  <div
                    contentEditable={false}
                    dangerouslySetInnerHTML={{ __html: categoryConfirmations }}
                  />
                )}

              

                {this.state.selectedSheetID && (
                  <div>
                    <br />
                    {this.state.message2}
                    <a
                      style={{ cursor: "pointer", color: "cornflowerblue" }}
                      onClick={() => event.setState({ preview2Visible: true })}
                    >
                      {t("burayaTiklayiniz")}
                    </a>
                  </div>
                )}
              </CustomCard>
            </Col>
            <Col xs="0" sm="2"></Col>
          </Row>
        </form>

        <Watcher event={this} t={t} />

        <SheetModal event={this} t={t} />

        <FormModal event={this} t={t} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginuser: state.loginReducer,
    menus: state.menuReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAllTaskInPool: bindActionCreators(poolActions.getPoolCount, dispatch),
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskCreate))
);
