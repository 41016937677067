import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap'
import './CustomInput.scss'

const CustomInput = ({label, placeholder, type, name, handleChange, value, rq}) =>
{
   return (
      <FormGroup>
         <Label htmlFor={name} className='input-label'>{label}<span className='required'>{rq}</span></Label>
         <Input type={type} id={name} name={name} placeholder={placeholder} onChange={handleChange} value={value}/>
      </FormGroup>
   )
}
export default CustomInput;
