import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {post} from "axios";
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import notify from "devextreme/ui/notify";
import {Popup} from 'devextreme-react/popup';
import {bindActionCreators} from "redux";
import * as BB from '../../../components/BBComponent'
import {connect} from "react-redux";

import * as loadingActions from "../../../redux/actions/loadingActions";
import metrics from "../../../common/metrics";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class EffectManagement extends Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         gridData: [],
         categoryList: [],
         addPopupVisible: false,
         editPopupVisible: false,
         editID: '',
         editData: [],
         redirect: false,
         redirectUrl: ''
      }
   }

   componentDidMount()
   {
      this.props.actions.isLoading(true);
      this.getDatas();
   }

   getDatas()
   {
      let URL = 'api/Yonetim/Effect/GetData';
      BB.FetchJSON(URL).then(data =>
      {
         this.setState({gridData: data.Data})
         this.props.actions.isLoading(false);
      });
      let URL1 = 'api/Yonetim/Effect/CategoryList';
      BB.FetchJSON(URL1).then(res =>
      {
         this.setState({categoryList: res.ViewBag})
         this.props.actions.isLoading(false);
      });
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               style={{marginRight: 10}}
               color={'info'}
               outline
               onClick={() =>
               {
                  this.setState({editID: cellData.data.ID, editPopupVisible: true}, this.getEditData(cellData.data.ID));
               }}>
               {t("düzenle")}
            </Button>
         </div>
      );
   }

   getEditData(ID)
   {
      let url = `api/Yonetim/Effect/Edit?ID=` + ID;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({
            editName: res.Data[0].Name,
            editContent: res.Data[0].Content,
            editSelectedCategory: res.Data[0].CategoryID
         });
      });
   }

   add()
   {
      if (this.state.name === undefined || this.state.name === '' || this.state.content === '' || this.state.content === undefined || this.state.selectedCategory === '')
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            Name: this.state.name,
            Content: this.state.content,
            CategoryID: this.state.selectedCategory
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', null);
         post('api/Yonetim/Effect/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  addPopupVisible: false,
                  name: '',
                  content: '',
                  selectedCategory: ''
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  addPopupVisible: false
               });
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   edit()
   {
      if (this.state.editName === undefined || this.state.editName === '' || this.state.editContent === '' ||
         this.state.editContent === undefined || this.state.editSelectedCategory === '' || this.state.editSelectedCategory === undefined)
      {
         notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         let entity = {
            Name: this.state.editName,
            Content: this.state.editContent,
            CategoryID: this.state.editSelectedCategory
         }
         const formData = new FormData();
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('EditID', this.state.editID);
         post('api/Yonetim/Effect/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  editPopupVisible: false,
                  editName: '',
                  editContent: '',
                  editSelectedCategory: '',
                  editID: ''
               }, this.getDatas());
               this.props.actions.isLoading(false);
               notify(t("islemBasarili"), "success", 1000);
            }
            else
            {
               this.setState({
                  editPopupVisible: false
               });
               notify(t("islemBasarisiz") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
   }

   render()
   {
      const {gridData, redirectUrl} = this.state;
      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({
                              addPopupVisible: true
                           })}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName=""/>
                           <Column
                              dataField="Name"
                              caption={t("baslik")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Content"
                              caption={t("açıklama")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Category"
                              caption={t("kategori")}
                              minWidth={200}
                              allowFiltering={true}/>
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={100}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>

               </Col>
               {/*Yeni Satır Ekleme Popup*/}
               <Popup
                  visible={this.state.addPopupVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("etkiyontemietkiekle")}
                  width={metrics.popupMinWidth}
                  height={450}
               >
                  <form>
                     {BB.InputWithLabel('name', t("etkituru"), 'text', this)}
                     {BB.DxDropDownWithJSON('selectedCategory', t("kategori"), this, this.state.categoryList)}
                     {BB.TextAreaWithLabel('content', t("icerik"), t("icerik"), 5, this)}
                     {/*Tahmini sonlandırma tarihi*/}
                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({addPopupVisible: false})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.add()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
               {/*Yeni Satır Edit Popup*/}
               <Popup
                  visible={this.state.editPopupVisible}
                  dragEnabled={false}
                  showTitle={true}
                  showCloseButton={false}
                  title={t("etkiyontemietkiekle")}
                  width={metrics.popupMinWidth}
                  height={450}
               >
                  <form>
                     {BB.InputWithLabel('editName', t("etkituru"), 'text', this)}
                     {BB.DxDropDownWithJSON('editSelectedCategory',  t("kategori"), this, this.state.categoryList)}
                     {BB.TextAreaWithLabel('editContent', t("icerik"), t("icerik"), 5, this)}
                     {/*Tahmini sonlandırma tarihi*/}
                     <Row style={{float: 'right', marginRight: 0, marginTop: 10}}>
                        <Button
                           color={'info'}
                           onClick={() => this.setState({editPopupVisible: false, editID: ''})}>
                           {t("vazgec")}
                        </Button>
                        <Button
                           onClick={() => this.edit()}
                           color={'success'}
                           style={{marginLeft: 20, marginRight: 0}}>
                           {t("olustur")}
                        </Button>
                     </Row>
                  </form>
               </Popup>
            </Row>

         );
      }
   }
}

function mapStateToProps(state)
{
   return {}
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withTranslation()(
   connect(mapStateToProps, mapDispatchToProps)(EffectManagement)
);

