import * as actionTypes from './actionTypes'
import * as BB from "../../components/BBComponent";

export function addParentCategories(Categories)
{
   return {type: actionTypes.SET_PARENT_CATEGORIES, payload: Categories}
}

export function addChildCategories(Categories)
{
   return {type: actionTypes.SET_CHILD_CATEGORIES, payload: Categories}
}

export function addFilteredCategories(Categories) {
   return {type: actionTypes.SET_FILTER_CATEGORIES, payload: Categories}
}

export function getParentCategories(UserID, ParentID)
{
   return function (dispatch)
   {
      let url = UserID !== null && '/api/Task/GetCategories?UserID=' + UserID + '&ParentID=' + ParentID;

      BB.FetchJSON(url).then(data =>
      {
         dispatch(addParentCategories(data))
      });
   }
}

export function getChildCategories(UserID, ParentID)
{
   return function (dispatch)
   {
      let url = UserID !== null && '/api/Task/GetCategories?UserID=' + UserID + '&ParentID=' + ParentID;

      BB.FetchJSON(url).then(data =>
      {
         dispatch(addChildCategories(data))
      });
   }
}

export function getFilterCategories(UserID, catTerm) {

   return function(dispatch) {
      let url = UserID !== null && '/api/Task/GetCategoriesTree?UserID=' + UserID +  '&keywords=' + catTerm;
      BB.FetchJSON(url).then(data =>
         {
            dispatch(addFilteredCategories(data));
            
         });
   }
   
}
