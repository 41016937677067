import * as actionTypes from './actionTypes'

export function addAllActivities(AllActivities)
{
   return {type: actionTypes.SET_OWN_ACTIVTY_DATA, payload: AllActivities}
}

export function getAllActivities()
{
   return function (dispatch)
   {

      let url = 'api/Activity/GetData';

      return fetch(url)
         .then(res => res.json())
         .then(result =>
         {
           // localStorage.setItem('loginTime', new Date());
            dispatch(addAllActivities(result))
         });
   }
}

