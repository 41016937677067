import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";
import * as BB from '../../../components/BBComponent'
import DataGrid, {Column, Export, Pager, Paging, SearchPanel} from "devextreme-react/data-grid";
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Redirect} from "react-router-dom";
import notify from "devextreme/ui/notify";
import ReactDataGrid from "react-data-grid";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class SheetList extends PureComponent
{
   constructor(props)
   {
      super(props);

      this.state = {
         gridData: [],
         selectedForm: undefined,
         previewVisible: false,
         rows: []
      }

      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/CustomSheet/GetCustomSheet')
      .then(data =>
      {
         this.setState({gridData: data});
      })
   }

   delete(ID)
   {
      BB.FetchJSON('api/Yonetim/CustomSheet/DeleteCustomSheet?EditID=' + ID)
      .then(data =>
      {
         if (data)
         {
            this.getData();
            notify(t("Basarılı!"), 'success', 2000);
         }
         else
         {
            notify(t("ilgiliformtaleptekullanilmistir"), 'error', 2000);
         }
      })
   }

   actionButton = (cellData) =>
   {
      return (
         <div>
            <Button
               color={'info'}
               className='mr-1'
               outline
               onClick={() => this.setState({
                  selectedForm: cellData.data,
                  redirect: true,
                  redirectUrl: '/Yonetim/SheetDesing'
               })}>

               {t("düzenle")}
            </Button>
            <Button
               color={'info'}
               className='mr-1'
               outline
               onClick={() =>
               {
                  let data = JSON.parse(cellData.data.JsonForm);
                  this.setState({previewVisible: true, selectedForm: data})
               }}>
               {t("goruntule")}
            </Button>
            <Button
               color={'info'}
               className='mr-1'
               outline
               onClick={() =>
               {
                  BB.askYesNo(t('onaylıyormusunuz?'), t("sectiginizozelformsilinecektir"), t("evet"), t("hayır")).then(res =>
                  {
                     if (res)
                     {
                        this.delete(cellData.data.ID);
                     }
                  })
               }}>
               {t("sil")}
            </Button>
         </div>
      );
   }

   render()
   {
      const {redirectUrl, selectedForm} = this.state;

      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: redirectUrl, state: {selectedForm: selectedForm}}}/>
      }
      else
      {
         return (
            <Row>
               <Col md='12' sm='12' lg='12'>
                  <Card>
                     <CardBody>
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({redirect: true, redirectUrl: '/Yonetim/SheetDesing'})}
                           style={{float: 'left', position: 'absolute', zIndex: 1}}
                           type="button">{t("yeni")}</Button>

                        <DataGrid
                           id="gridContainer"
                           dataSource={this.state.gridData}
                           showRowLines={true}
                           showColumnLines={false}
                           showBorders={false}
                        >
                           <Paging
                              defaultPageSize={10}/>
                           <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20, 50]}
                              showNavigationButtons={true}
                           />
                           <SearchPanel visible={true} width={100}/>
                           <Export enabled={true} fileName="Kategori Listesi"/>
                           <Column
                              dataField="CategoryName"
                              caption={t("kategori")}
                              width={'20%'}
                              allowFiltering={true}/>
                           <Column
                              dataField="Name"
                              caption={t("formtanimi")}
                              width={'20%'}
                              allowFiltering={true}/>
                           <Column
                              dataField='CreateDate'
                              caption={t("olusturmazamani")}
                              dataType='datetime'
                              width={'20%'}
                           />
                           <Column
                              dataField='UpdateDate'
                              caption={t("songuncelleme")}
                              dataType='datetime'
                              width={'20%'}
                           />
                           <Column
                               dataField='IsRequired'
                               caption={t("zorunluluk")}
                               width={'20%'}
                           />
                           <Column
                              dataField="Düzenle"
                              caption={t("islemler")}
                              width={250}
                              cellRender={this.actionButton}
                           />
                        </DataGrid>
                     </CardBody>
                  </Card>
               </Col>

               <Modal isOpen={this.state.previewVisible} size={'xl'}>
                  <ModalHeader>{t("tabloonizlemesi")}</ModalHeader>
                  <ModalBody style={{marginRight: 40}}>


                     <ReactDataGrid
                        columns={this.state.selectedForm}
                        rowGetter={i => this.state.rows[i]}
                        rowsCount={0}
                        enableCellSelect={false}
                     />

                     <div className="modal-footer">
                        <Button
                           color={'success'}
                           outline
                           onClick={() => this.setState({previewVisible: false})}>{t("kapat")}
                        </Button>
                     </div>
                  </ModalBody>
               </Modal>
            </Row>
         );
      }
   }
}

export default withTranslation()(SheetList);
