import * as actionTypes from './actionTypes'
import * as BB from "../../components/BBComponent";

export function addSLATaskCount(data)
{
   return {type: actionTypes.SET_SLA_TASK_COUNT, payload: data}
}

export function addMyTaskCount(data)
{
   return {type: actionTypes.SET_OWN_TASK_COUNT, payload: data}
}

// export function addOwnConfirmTaskCount(data)
// {
//    return {type: actionTypes.SET_OWN_CONFIRM_TASK_COUNT, payload: data}
// }

export function addPopupMessage(data)
{
   return {type: actionTypes.SET_POPUP_MESSAGE, payload: data}
}

// export function getSLATaskCount()
// {
//    return function (dispatch)
//    {
//       let url = 'api/Main/GetMyTaskCount';

//       BB.FetchJSON(url).then(data =>
//       {
//          dispatch(addSLATaskCount(data))
//       });
//    }
// }

export function getOwnCreatedTaskCount()
{
   return function (dispatch)
   {
      let url = 'api/Main/GetMyOwnTaskCount';

      BB.FetchJSON(url).then(data =>
      {
         dispatch(addMyTaskCount(data))
      });
   }
}

// export function getOwnConfirmTaskCount()
// {
//    return function (dispatch)
//    {
//       let url = 'api/Main/GetConfirmTaskCount';

//       BB.FetchJSON(url).then(data =>
//       {
//          dispatch(addOwnConfirmTaskCount(data))
//       });
//    }
// }

export function getPopupMessage()
{
   return function (dispatch)
   {
      let url = 'api/Main/GetPopupMessage';

      BB.FetchJSON(url).then(data =>
      {
         dispatch(addPopupMessage(data))
        
      });
      
   }
}
