import React, {PureComponent} from 'react';
import {Button, Col, Row} from "reactstrap";
import * as BB from '../../../BBComponent';
import Modal from "reactstrap/es/Modal";
import ModalBody from "reactstrap/es/ModalBody";
import ModalHeader from "reactstrap/es/ModalHeader";
import {CustomButton} from "../../../custom";
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import {Chrono} from "react-chrono";
import Back from "../../../../assets/left-arrow.png";
import {bindActionCreators} from "redux";
import * as poolActions from "../../../../redux/actions/poolActions";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import {connect} from "react-redux";
import TableTaskTabs from "../../../custom/TableTaskTabs/TableTaskTabs";
import {htmlToText} from "html-to-text";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import {  callAPI,
   callApiGet,
   callApiPost,
   callApiPut } from "../../../../components/Axios";

class Jobs extends PureComponent
{
   constructor()
   {
      super();
      this.state = {
         showModal: false,
         poolTask: [],
         myJobs: [],
         myJobs1: [],
         selectedTaskID: undefined,
         selectedResponsibleUserID: undefined,
         selectedDescription: undefined,
         selectedStartDate: new Date(),
         selectedEndDate: new Date(),
         selectedStatusID: undefined,
         jobStatus: [],
         companyUsers: [],
         redirect: false,
         taskID: this.props ? this.props.taskID ? this.props.taskID : undefined : undefined,
         showDetail: false,
         showAddDetail: false,
         jobDetails: [],
         visibleViewer: false,
         selectedJobID: undefined,
         favoriCategory:[],
         selectedCategoryName:undefined,
         selectedCategory:undefined,
      isFavori:true,    
      selectedCommentDescription:''
      };
      this.getFavCategory();
      //this.getJobStatus();
   }

   componentDidMount()
   {
     //this.getFavCategory();
   }

   getCompanyUsers(TaskID)
   {
      BB.FetchJSON('api/Main/GetCompanyUsers?TaskID=' + TaskID)
      .then(companyUsers =>
      {
         this.setState({companyUsers: companyUsers});
      })
   }
   getFavCategory(){
      let url2 = "/api/Task/GetFavoriCategoriesNoCustomControl";
      callAPI(
         callApiGet,
         url2,
         {},
          (favcategory) => {
            this.setState({favoriCategory:favcategory.data   ,   visibleViewer: false,
               selectedJobDescription: '',
               selectedJobStatus: null,
               showModal: false,
               selectedResponsibleUserID: undefined,
               selectedStatusID: undefined,
               selectedDescription: ''})
         },
         true
       );
   }

   getData(JobID, isFinish)
   {
      let url = 'api/Main/GetMyJobs'

      if (this.props.taskID)
      {
         this.handleChange('selectedTaskID', this.props.taskID)
         url += '?TaskID=' + this.props.taskID;
      }
      let url2 = "/api/Task/GetFavoriCategoriesNoCustomControl";

      BB.FetchJSON(url)
      .then(GetMyJobs =>
      {
         
         BB.FetchJSON('api/Main/GetPoolTasks')
        
         .then(poolTasks =>

         {
            BB.FetchJSON(url2)
         .then(favcategory =>
         {
            let arr = [];
            if (poolTasks && poolTasks.length > 0)
            {
               for (let i = 0; i < poolTasks.length; i++)
               {
                  let a = htmlToText(poolTasks[i].Description.substr(0, 50)).replaceAll('//', '/');
                  let item = {
                     ID: poolTasks[i].ID,
                     Name: poolTasks[i].TaskNumber + ' - ' + a,
                  };

                  if (this.props.taskID)
                  {
                     arr.push({
                        ID: this.props.taskID,
                        Name: 'Seçili Talep',
                     })
                  }

                  arr.push(item);
               }
            }
            this.setState({
               poolTask: arr,
               myJobs: GetMyJobs.data,
               myJobs1: GetMyJobs.data1,
               favoriCategory:favcategory,
               visibleViewer: false,
               selectedJobDescription: '',
               selectedJobStatus: null,
               showModal: false,
               selectedResponsibleUserID: undefined,
               selectedStatusID: undefined,
               selectedDescription: ''
            });

            if (isFinish)
            {
               this.setState({showDetail: false});
            }
         })
      })
   })
      if (JobID)
      {
         this.getJobDetails(JobID)
      }
   }

   getJobStatus = () =>
   {
      BB.FetchJSON('api/Main/GetJobStatus').then(data =>
      {
         this.setState({jobStatus: data})
      });
   }

   getJobDetails(JobID)
   {
      let myJob = this.state.myJobs.filter(x => x.ID === JobID);
      let Job = myJob.length > 0 ? myJob : this.state.myJobs1.filter(x => x.ID === JobID);

      BB.FetchJSON('api/Main/GetMyJobDetail?JobID=' + JobID)
      .then(res =>
      {
         let arr = [];

         if (res && res.length > 0)
         {
            let item = {
               title: 'Görev durumu ' + Job[0].Name,
               cardTitle: 'Tarih : ' + new Date(Job[0].CreateDate).toLocaleDateString() + ' ' + new Date(Job[0].CreateDate).toLocaleTimeString(),
               cardSubtitle: "",
               cardDetailedText: Job[0].Description
            };

            arr.push(item)

            for (let i = 0; i < res.length; i++)
            {
               let item = {
                  title: 'Görev durumu ' + res[i].Name,
                  cardTitle: 'Tarih : ' + new Date(res[i].CreateDate).toLocaleDateString() + ' ' + new Date(res[i].CreateDate).toLocaleTimeString(),
                  cardSubtitle: "Toplam Geçen zaman " + res[i].ElapsedTime,
                  cardDetailedText: res[i].Description
               };

               arr.push(item);

               if (i === res.length - 1)
               {
                  this.setState({showDetail: true, jobDetails: arr, selectedJobID: JobID});
               }
            }
         }
         else
         {
            let item = {
               title: 'Görev durumu ' + Job[0].Name,
               cardTitle: 'Tarih : ' + new Date(Job[0].CreateDate).toLocaleDateString() + ' ' + new Date(Job[0].CreateDate).toLocaleTimeString(),
               cardSubtitle: "",
               cardDetailedText: Job[0].Description
            };

            arr.push(item)

            this.setState({showDetail: true, jobDetails: arr, selectedJobID: JobID});
         }
      })
   }

   handleChange(key, value)
   {
      if (key === 'selectedTaskID')
      {
         this.getCompanyUsers(value);
      }
      this.setState({[key]: value});

      
      if (key === 'selectedCommentDescription') {
         this.setState({ selectedCommentDescription: value })
     }
   }

   handleChangeSelect = () =>
   {
      let myJob = this.state.myJobs.filter(x => x.ID === this.state.selectedJobID);
      let selectedJob = myJob.length > 0 ? myJob[0] : this.state.myJobs1.filter(x => x.ID === this.state.selectedJobID)[0];
      let jobStatus = this.state.jobStatus.filter(x => x.ID === this.state.selectedJobStatus)[0];

      let title = jobStatus.IsFinishJob === true ?
         'Görev Sonlandırma'
         :
         'Görev Hareket ekleme';

      let desc = jobStatus.IsFinishJob === true ?
         selectedJob.TaskNumber + ' Numaralı talebe bağlı görev sonlandırılacaktır ?'
         :
         selectedJob.TaskNumber + ' Numaralı talebe bağlı göreve hareket eklemek istediğinize emin misiniz ?';

      BB.askYesNo(
         title,
         desc,
         'Evet',
         'Hayır')
      .then(res =>
      {
         if (res)
         {
            this.props.actions.isLoading(true);
            BB.FetchJSON('api/Main/UpdateJob?JobID=' + this.state.selectedJobID + '&Description=' + this.state.selectedJobDescription + '&StatusID=' + jobStatus.ID)
            .then(res =>
            {
               this.props.actions.isLoading(false);
               notify('Başarıyla güncellendi!', 'success', 3000);
               this.getData(this.state.selectedJobID, jobStatus.IsFinishJob);
            })
         }
      })
   }

   addJob()
   {

      let {
         selectedTaskID,
         selectedResponsibleUserID,
         selectedDescription,
         selectedStartDate,
         selectedEndDate,
         selectedStatusID
      } = this.state;

      if (selectedTaskID && selectedDescription && selectedStartDate && selectedEndDate && selectedStatusID)
      {

         let date1 = selectedStartDate.toLocaleDateString();
         let newSelectedStartDate = date1.split('.')[2] + '-' + date1.split('.')[1] + '-' + date1.split('.')[0] + ' ' + selectedStartDate.toLocaleTimeString();

         let date2 = selectedEndDate.toLocaleDateString();
         let newSelectedEndDate = date2.split('.')[2] + '-' + date2.split('.')[1] + '-' + date2.split('.')[0] + ' ' + selectedEndDate.toLocaleTimeString();

         let url = 'api/Main/AddJob?' +
            'TaskID=' + selectedTaskID + '&' +
            'ResponsibleUserID=' + selectedResponsibleUserID + '&' +
            'Description=' + selectedDescription + '&' +
            'strDate=' + newSelectedStartDate + '&' +
            'endDate=' + newSelectedEndDate + '&' +
            'StatusID=' + selectedStatusID;

         this.props.actions.isLoading(true);

         BB.FetchJSON(url)
         .then(r =>
         {
            this.props.actions.isLoading(false);
            notify('Görev başarıyla eklendi', 'success', 2000);
            this.getData();
         })

         this.setState({
            selectedTaskID: undefined,
            selectedResponsibleUserID: undefined,
            selectedStatusID: undefined,
            selectedDescription: undefined,
            showModal: false
         })
      }
      else
      {
         notify('Lütfen zorunlu alanları doldurunuz!', 'warning', 2000);
      }
   }

   redirectToTask(taskID)
   {
      this.setState({redirect: true, taskID: taskID});
   }

   closeModal()
   {
      this.setState({
         selectedTaskID: undefined,
         selectedResponsibleUserID: undefined,
         selectedStatusID: undefined,
         selectedDescription: undefined,
         showModal: false
      })
   }

   render()
   {
      const { t } = this.props;

      if (this.state.redirect === true)
      {
         return <Redirect to={{pathname: '/TaskDetail', state: {taskID: this.state.taskID}}}/>
      }
      else
      {
         return (
            <>
               <TableTaskTabs
                  title='GÖREVLER'
                  description='Taleplerinizle ilgili görevler oluşturun.'
                  data={this.state.myJobs}
                  data1={this.state.myJobs1}
                  type='select'
                  buttonVisible={true}
                  selectData={this.state.jobStatus}
                  minHeight={!this.props.isTaskDetail}
                  isTaskDetail={this.props.isTaskDetail}
                  handleChange={this.handleChangeSelect}
                  handleClick={() => this.setState({showModal: true})}
                  props={this}
                  event={this}
                  t={t}
                  handleDetail={(id) =>
                  {
                     this.getJobDetails(id)
                  }}
                  taskID={this.props.taskID}
               />
                          {/* <CategoryFilter state={this.state} event={this} /> */}

               <Modal
                  size={'lg'}
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.showModal}
               >
                  <ModalHeader toggle={() => this.closeModal()}>
                     Talep Oluştur
                  </ModalHeader>
                  <ModalBody>
                  <Col>
                     
        
          {BB.DxDropDownWithJSONFav('selectedCategory', 'Kategori', this, this.state.favoriCategory)} 
  
          
          
          {BB.TextAreaWithLabel2('selectedCommentDescription', "Açıklama", '', 5, this)}

          <button 
           className='ml-2 btn btn-success taskButton2 mb-1 mr-2' 
           
           >{t("olustur")}</button>
           
                     </Col>
                  </ModalBody>
               </Modal>

               <Modal
                  size={'l'}
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.showDetail}
               >
                  <ModalHeader
                     toggle={() => this.setState({showDetail: !this.state.showDetail, visibleViewer: false,})}>
                     {this.state.visibleViewer &&
                     (
                        <img onClick={() => this.setState({visibleViewer: false})} src={Back}
                             style={{height: 20, cursor: 'pointer', marginRight: 10}}/>
                     )
                     }
                     {this.state.visibleViewer ? 'Hareket Ekle' : 'Görev Hareketleri'}
                  </ModalHeader>
                  <ModalBody className='pt-0'>
                     {this.state.visibleViewer === true ?
                        (
                           <Col className='mt-2'>
                              <Row style={{display: 'contents'}}>
                                 {BB.DxDropDownWithJSON('selectedJobStatus', 'Görev Durumu', this, this.state.jobStatus,)}

                              </Row>
                              <Row style={{display: 'contents'}}>
                                 {BB.TextArea('selectedJobDescription', 'Açıklama', 'Görev hareketi hakkında açıklama giriniz', '4', this)}

                              </Row>
                              <Row>
                                 <Button
                                    block
                                    className='float-right'
                                    onClick={() =>
                                    {
                                       this.handleChangeSelect();
                                    }}
                                    color={'success'}
                                 >
                                    {'Kaydet'}
                                 </Button>
                              </Row>
                           </Col>
                        )
                        :
                        (
                           <div>
                              <div>
                                 <div className="ml-auto show float-right mr-2 mt-2">
                                    <CustomButton icon='icon-plus' style={{position: 'absolute', top: -48, right: 60}}
                                                  handleClick={() => this.setState({visibleViewer: true})}/>
                                 </div>
                              </div>
                              <div>
                                 <div className="ml-auto show float-right mr-2 mt-2">
                                    <CustomButton icon="icon-close" style={{position: 'absolute', top: -48, right: 10}}
                                                  handleClick={() => this.setState({showDetail: !this.state.showDetail})}/>
                                 </div>
                              </div>
                              <Chrono items={this.state.jobDetails} mode="VERTICAL_ALTERNATING"/>
                           </div>
                        )
                     }
                  </ModalBody>
               </Modal>
            </>
         );
      }
   }
}



function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(null, mapDispatchToProps)(Jobs)));
//export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FastTaskCreate)))

