import React, {PureComponent} from 'react';
import * as BB from "../../../components/BBComponent";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import Col from "reactstrap/es/Col";
import CardFooter from "reactstrap/es/CardFooter";
import {Button} from "reactstrap";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

class Error extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: []
      }
      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Yonetim/TaskError/GetTaskErrors')
      .then(res =>
      {
         if (res)
         {
            this.setState({gridData: res});
         }
      })
   }

   clearAllError()
   {
      BB.askYesNo(t("tümhatamesajlarinitemizlemekistediginizeeminmisiniz"), t("hatamesajlarinisil"), t("evet"), t("vazgec"))
      .then(e =>
      {
         if (e === true)
         {
            BB.FetchJSON('api/Yonetim/TaskError/DeleteTaskErrors')
            .then(res =>
            {
               if (res)
               {
                  this.getData();
               }
            })
         }
      })
   }

   render()
   {
      return (
         <Col>
            <Card>
               <CardHeader>
                  {t("sistemhatalari")}
               </CardHeader>
               <CardBody>
                  {BB.DxDataGridForErrors(this.state.gridData)}
               </CardBody>
            </Card>
            <CardFooter>
               <Button
                  color={'primary'}
                  onClick={() => this.clearAllError()}
               >
                  {t("hataloglarinitemizle")}
               </Button>
            </CardFooter>
         </Col>
      );
   }
}

export default withTranslation()(Error);
