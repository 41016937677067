import React from "react";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {Button, Row, 
    CardBody,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    ListGroup,
    ListGroupItem} from "reactstrap";
import notify from "devextreme/ui/notify";
import { callAPI, callApiGet, callApiPost } from "../../../../../components/Axios";

const TaskAddDescription = ({t, event}) =>
{
    const addComment = (e) => {
        e.preventDefault();

        event.props.actions.isLoading(true);

        if (event.state.selectedCommentDescription === '')
        {
            if (event.state.selectedTaskFiles.length < 1) {
                notify((event.languages.dosyaEklemeZorunludur + " veya " + event.languages.aciklamaAlaniZorunludur), "error", 1500);

                event.props.actions.isLoading(false);
                return;
            }
        }

        const url = `api/TaskDetail/addcomment`;

        const formData = new FormData();

        formData.append('TaskId', event.state.taskID);
        formData.append('Description', event.state.selectedCommentDescription);
        formData.append('ShareOwner', event.state.selectedShareOwner);
        formData.append('ShareOther', event.state.selectedShareOther);
        formData.append('ShareList', event.state.selectedShareOtherMails);

        for (let i = 0; i < event.state.selectedTaskFiles.length; i++)
        {
            formData.append('file', event.state.selectedTaskFiles[i])
        }

        const config = {headers: {'content-type': 'multipart/form-data'}};



        callAPI(
            callApiPost,
            url,
            formData,
            async (res) => {
               
                if (res.data)
                {
                    notify(t("aciklamabasarilieklendi"), "success", 2000);
    
                    event.setState({
                        visibleAciklamaEkle: false,
                        selectedCommentDescription: '',
                        selectedTaskFiles: [],
                        selectedShareOwner: false,
                        selectedShareOtherMails: [],
                        selectedShareOther: false,
                        fileUploaderVisible :"none",
                        ComponentFileUpload:[]
                    });
    
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
    
                    event.getData();
                   event.props.actions.isLoading(false);
    
                }
                else
                {               event.props.actions.isLoading(false);
    
                    notify(res.data["ErrMsg"], "error", 2000);
                }
              
        
            },true) 




       

    }

    return (
        <Modal
            centered={true}
            isOpen={event.state.visibleAciklamaEkle}
            style={{zIndex: 1}}
        >
            <ModalHeader>{t('talebeAciklamaEkle')}</ModalHeader>
            <ModalBody>
                <form onSubmit={(e) => addComment(e)}>
                    {BB.TextAreaWithLabel('selectedCommentDescription', t('zorunluAciklama'), '', 5, event)}

                    {/* {BB.MultipleFileWithLabel('selectedCommentFiles', t('ekdosya'), event, false)} */}
                    {BB.MultipleFileWithLabel2(
                            "selectedTaskFiles",
                            t("ekdosya"),
                            event,
                            t("sec")
                            
                          )} 
                          <ListGroup style={{ overflowY: "scroll", height: "100px",width:"320px" ,display: event.state.fileUploaderVisible}}>
                          {event.state.selectedTaskFiles.map((filter) => (
                            <ListGroupItem style={{padding:6}} key={filter.name}>
                              {filter.name}
                              { <Label
                                color="danger"
                                 onClick={() => event.deleteSelectedFile(filter.name)}
                                className="ml-2"
                                style={{cursor:"pointer"}}
                              >
                                x
                              </Label> }
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                    
                    {event.state.taskCreator ? null : <div>{BB.CheckBoxWithLabel('selectedShareOwner', t('talepSahibiIlePaylas'), event,event.state.taskCreator)}</div> }
                    
                    {BB.CheckBoxWithLabel('selectedShareOther', t('ekbilgilendirmemesajı'), event)}
                    {event.state.selectedShareOther === true ? BB.DxDropDownWithJSON('selectedShareOtherMails', t('bilgilendirilecekkisiler'), event, event.state.poolUsers) : null}

                    <Row style={{float: 'right', marginRight: 5}}>
                        <Button
                            color={'info'}
                            onClick={() => event.setState({visibleAciklamaEkle: false})}>
                            {t('vazgec')}
                        </Button>
                        <Button
                            color={'success'}
                            style={{marginLeft: 20, marginRight: 15}}
                            type={'submit'}>
                            {t('ekle')}
                        </Button>
                    </Row>
                </form>
            </ModalBody>
        </Modal>

        
    )
}

export default TaskAddDescription;
