import React from "react";
import {CustomCard, Empty, LabelGroup} from "../index";

import './styles.scss'

const List = ({title, description, data, buttonVisible, handleClick,noData}) =>
{
   return (
      <CustomCard cardStyle='card-dashboard-calendar pb-0'>
         <LabelGroup title={title} description={description} buttonVisible={buttonVisible} handleClick={handleClick}/>
         {
            data.length > 0 ? (
               <table className="table  m-b-0 transcations mt-2">
                  <tbody>
                  {
                     data.map((item, inx) => (
                        <tr key={inx}>

                           <td>
                              <div className="d-flex align-middle ml-3">
                                 <div className="d-inline-block">
                                    <h6 className="mb-1">{item.Title}</h6>
                                    <p className="mb-0 tx-13 text-muted">{item.Message}</p>
                                    {item.LoginPageImageDocuments ? item.LoginPageImageDocuments.length > 0 ?
                                       <a
                                          style={{height: 10}}
                                          href={item.LoginPageImageDocuments[0].FilePath}
                                          download>
                                          <p
                                             style={{cursor: "pointer", color: "cornflowerblue"}}
                                          >
                                             İlişkili ({item.LoginPageImageDocuments.length}) Dökümanı Görüntüle
                                          </p>
                                       </a>
                                       : null : null}

                                 </div>
                              </div>
                           </td>
                           <td className="text-right">
                              <div className="d-inline-block">
                                 <p className="mb-0 tx-11 text-muted">Duyuru Tarihi</p>

                                 <h6 className="mb-2 tx-15 font-weight-semibold">
                                    {new Date(item.StartDate).toLocaleDateString()}
                                 </h6>
                              </div>
                           </td>
                        </tr>
                     ))
                  }
                  </tbody>
               </table>
            ) : (
               <Empty title={noData}/>
            )
         }
      </CustomCard>
   )
}

export default List;
