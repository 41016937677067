import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import React from "react";
import * as BB from "../BBComponent";
import notify from "devextreme/ui/notify";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import jwt_decode from "jwt-decode";
import {withTranslation} from "react-i18next";
import { t } from "i18next";

import * as loadingActions from "../../redux/actions/loadingActions";
import {
  callAPI,
  callApiGet,
  callApiPost,
  callApiPut,
} from "../../components/Axios";

class swr extends React.Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    let user = null;
    if (token) {
      user = jwt_decode(token);
    }
   this.state = {
      Notifications: [],
      hubConnection: null,
      connection: null,
      loginUser: user,
      notifications: [],
    };
    //this.getData();

    

    var url = window.location.origin + "/tysHub";
    const newConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
   
    this.setState({ connection: newConnection });

    if (newConnection) {
      newConnection.start().then((result) => {
        newConnection.on(
          "ReceiveMessage",
          (loginUserId, message, taskID, taskNumber, taskLogID, logType) => {
            let notificationss = this.state.Notifications;
            if (this.state.loginUser.ID === loginUserId) {
              let NotificationBody = taskNumber + t("numarali");
              let BackgrondColor = "#000";
              let TaskID = taskID;
              let Icon = "fa fa-align-justify";
              let TaskLogID = taskLogID;

              if (logType === 0) {
                NotificationBody += t("talep") + " " + t("tarafinizaDevredilmistir");
                BackgrondColor = "#E7E7E3";
              }
              if (logType === 1) {
                NotificationBody += t("taleb") + " " + t("aciklamaEklenmistir");
                BackgrondColor = "#ECA900";
              }
              if (logType === 2) {
                NotificationBody += t("taleb") + " " + t("aciliyetiDegistirilmistir");
                BackgrondColor = "#E7E7E3";
              }
              if (logType === 3) {
                NotificationBody += t("taleb") + " " + t("durumuDegistirilmistir");
                BackgrondColor = "#E7E7E3";
              }
              if (logType === 4) {
                NotificationBody +=t("taleb") + " " + t("cozumlenmistir");
                BackgrondColor = "#E7E7E3";
              }
              if (logType === 7) {
                NotificationBody += t("talep") + " " + t("ekonayiniziBeklemektedir");
                BackgrondColor = "#E7E7E3";
              }

              //  if (logType === 4) {
              //   NotificationBody += "iniz Çözümlenmiştir .";
              //   BackgrondColor = "#E7E7E3";
              // }

              notificationss.push({
                NotificationBody,
                BackgrondColor,
                TaskID,
                Icon,
                TaskLogID,
              });
              this.setState({ Notifications: notificationss });
              // if (data.data.length === i + 1)
              // {
              //    ths.setState({Notifications: Notifications})
              // }
            }
          }
        );
      });
    } else {
    }
  }
  componentDidMount() {
    this.getData();
  }

readAllNotification(){

                let Notifications = [];
                 let formData = new FormData();
                  formData.append("jsontext", JSON.stringify(this.state.Notifications));
                  callAPI(
                    callApiPost,
                    "api/Common/ReadMyAllNotification",
                    formData,
                    {},
                    (res) => {
                      if (res===true)
                      {
                        
                        Notifications = [];
                        this.setState({ Notifications: [] });
                        return  notify(t("buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur"), "warning", 2000);
                      }
                     else{
                      return  notify(t("buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur"), "error", 2000);

                     }

                     
                    },
                    true
                  );
               

              }




  getData() {
    const endpoint = "api/Common/GetMyNotification2";
    let ths = this;

    callAPI(
      callApiGet,
      endpoint,
      {},
      (data) => {
        let Notifications = [];

        if (data.data && data.data.length > 0) {
          data.data.map((item, i) => {
            let NotificationBody = item.TaskNumber + t("numarali");
            let BackgrondColor = "#000";
            let TaskID = item.TaskID;
            let Icon = "fa fa-align-justify";
            let TaskLogID = item.ID;

            if (item.LogType === 0) {
              NotificationBody += t("talep") + " " + t("tarafinizaDevredilmistir");
              BackgrondColor = "#E7E7E3";
            }
            if (item.LogType === 1) {
              NotificationBody += t("taleb") + t("aciklamaEklenmistir");
              BackgrondColor = "#ECA900";
            }
            if (item.LogType === 2) {
              NotificationBody += t("taleb") + t("aciliyetiDegistirilmistir");
              BackgrondColor = "#E7E7E3";
            }
            if (item.LogType === 3) {
              NotificationBody += t("taleb") + t("durumuDegistirilmistir");
              BackgrondColor = "#E7E7E3";
            }
             if (item.LogType === 4) {
               NotificationBody += t("taleb") + t("cozumlenmistir");
               BackgrondColor = "#E7E7E3";
             }
             if (item.LogType === 7) {
              NotificationBody += t("talep") + " " + t("ekonayiniziBeklemektedir");
              BackgrondColor = "#E7E7E3";
            }
            
            Notifications.push({
              NotificationBody,
              BackgrondColor,
              TaskID,
              Icon,
              TaskLogID,
            });

            if (data.data.length === i + 1) {
              ths.setState({ Notifications: Notifications });
            }
          });
        } else {
          ths.setState({ Notifications: [] });
        }
      },
      true
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let props = this.props;
      const {t} = this.props;
      let { Notifications } = this.state;
    let formData = new FormData();
    formData.append("jsontext", JSON.stringify(this.state.Notifications));
    return (
      <UncontrolledDropdown nav direction="down">
        <DropdownToggle nav style={{ marginTop: 5 }}>
          <div className="nav-link">
            <i className="icon-bell" />
            {Notifications && Notifications.length > 0 ? (
              <Badge pill color="danger">
                {Notifications.length}
              </Badge>
            ) : null}
          </div>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>{t("bildirimler")}</strong>
          </DropdownItem>

          <div
            style={
              Notifications.length > 5
                ? { overflowY: "scroll", height: 300 }
                : {}
            }
          >
            {Notifications.map((item, i) => {
              return (
                <DropdownItem
                  onClick={() => {
                    callAPI(
                      callApiGet,
                      "api/Common/ReadMyNotification?TaskLogID=" +
                        item.TaskLogID,
                      {},
                      (res) => {
                          var newNotifications=[]
                           newNotifications = Notifications.filter((elem) => elem.TaskLogID !== item.TaskLogID);
                           this.setState({Notifications:newNotifications}) 

                        

                        props.props.history.push("/TaskDetail?" + item.TaskID);
                      },
                      true
                    );
                    // BB.FetchJSON('api/Common/ReadMyNotification?TaskLogID=' + item.TaskLogID).then((res) =>
                    // {
                    //    props.props.history.push('/TaskDetail?' + item.TaskID)
                    // });
                  }}
                  key={i}
                  tag="div"
                >
                  {item.NotificationBody}
                </DropdownItem>
              );
            })}
          </div>

          <DropdownItem
            disabled={Notifications.length === 0}
            onClick={() =>
              BB.askYesNo(
                t("eminMisiniz"),
                t("tumbildirimlerokunduokarakisaretlenecektir"),
                t("evet"),
                t("iptal")
              ).then((res) => {
                if (res === true) {

                  callAPI(
                    callApiPost,
                    "api/Common/ReadMyAllNotification",
                    formData,
                    
                    (res) => {
                      
                      if (res.data===true)
                      {
                        
                        Notifications = [];
                        this.setState({ Notifications: [] });
                        return  notify(t("basarili"), "success", 2000);
                      }
                     else{
                      return  notify(t("ufakbirhatailekarsilasildi"), "error", 2000);

                     }

                     
                    },
                    true
                  );
                  
                }



              })
            }
            tag="div"
            className="text-center"
          >
            <strong>{t("tumunuOkundoOlarakIsaretle")}</strong>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}
export default withTranslation()(swr)