import React, {Component} from 'react';
import ReactCardFlip from 'react-card-flip'
import {Col, Row} from 'reactstrap';
import './material-dashboard-pre.css'
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from "redux";
import * as loginUserActions from "../../../redux/actions/loginUserActions";
import * as menuActions from "../../../redux/actions/menuActions";
import * as poolActions from "../../../redux/actions/poolActions";
import {connect} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {post} from "axios";

class CustomDashboard extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         flip: true,
         dashboardData: [],
         dashboardItems: [],
         CustomDashboardItemParts: []
      }

      this.checkLoginUser();
      this.getData();
   }

   checkLoginUser()
   {
      if (this.props.loginUser.ID === undefined)
      {
         let s = this.props.history.location.search;

         if (s !== "")
         {
            let lst = s.replace('?', '').split('&');

            if (lst.length > 0)
            {
               let username = lst[0].split('=')[1];
               let password = lst[1].split('=')[1];
               let companyname = lst[2].split('=')[1];

               this.props.actions.checkLoginUser3rdPartApp(username, password, companyname).then((res) =>
               {
                  if (this.props.loginUser)
                  {
                     this.createTimer();
                  }
               });
            }
         }
      }
      else
      {
         this.createTimer();
      }
   }

   createTimer()
   {
      let ths = this;

      post('api/CustomDashboards/getCustomDashBoard', new FormData(), {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         if (res.data && res.data.CustomDashboards && res.data.CustomDashboards.length > 0)
         {
            setInterval(function ()
            {
               if (!ths.state.flip === false)
               {
                  ths.getData();
               }

               ths.setState({flip: !ths.state.flip})

            }, res.data.CustomDashboards[0].FlipInterval);
         }
      });
   }

   getData()
   {
      let formData = new FormData();

      if (this.state.CustomDashboardItemParts)
      {
         formData.append('jsonText', JSON.stringify(this.state.CustomDashboardItemParts));
      }
      else
      {
         formData.append('jsonText', null);
      }

      post('api/CustomDashboards/getCustomDashBoard', formData, {
         headers: {
            'content-type': 'multipart/form-data'
         },
      })
      .then(res =>
      {
         this.setState({

            dashboardData: res.data.CustomDashboards[0],
            CustomDashboardItemParts: res.data.CustomDashboardItemParts
         });
      });
   }

   render()
   {
      if (this.state.dashboardData && this.state.dashboardData.Title)
      {
         return (
            <Render ths={this}/>
         );
      }
      else
      {
         return (
            <div style={{height: '100vh', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
               <div className="alert alert-warning" role="alert">
                  Kullanıcıya atanmış dashboard bulunamadı..
               </div>
            </div>);
      }
   }

}

export const Render = (ths) =>
{
   const {t} = useTranslation();
   const state = ths.ths.state;

   return (
      <div className='wrapper'>
         <Col style={{alignContent: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 20}}>
            <h3>{state.dashboardData.Title}</h3>
         </Col>
         <div className='content'>
            <div className='container-fluid'>
               <div className="row" style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>

                  {state.dashboardData.CustomDasboardItem.map(function (item, i)
                  {
                     let totalRow = item.ItemsDetail.length;
                     let higherThan = item.ChangeBackground ? Number.parseInt(item.Value) < item.OptimumValue ? 'green' : Number.parseInt(item.Value) > item.OptimumValue ? 'red' : '#87CEEB' : '#87CEEB';
                     let lowerThan = item.ChangeBackground ? Number.parseInt(item.Value) > item.OptimumValue ? 'green' : Number.parseInt(item.Value) < item.OptimumValue ? 'red' : '#87CEEB' : '#87CEEB';

                     return <Col xs='12' sm='12' md='6' lg='4' xl='3' key={i}>
                        <div className="card card-product" data-count="4">

                           <ReactCardFlip
                              isFlipped={item.Value !== '0' ? state.flip : null}
                              flipDirection="vertical">

                              <div className="card-image" data-header-animation="false">
                                 <div id="box1" className="box parent">
                                    <div id="box1count" className="boxCount child"
                                         style={{backgroundColor: item.HigherThan === true ? higherThan : lowerThan}}>

                                       <div className='text-center'>
                                          <p style={{
                                             fontSize: 30,
                                             alignContent: 'center',
                                             justifyContent: 'center',
                                             whiteSpace: 'pre-wrap'
                                          }}>
                                             {item.Value}
                                          </p>
                                       </div>


                                    </div>
                                 </div>
                              </div>


                              <div className="card-image" data-header-animation="false">
                                 <div id="box1" className="box parent">
                                    <div id="box1count" className="boxCount child"
                                         style={{backgroundColor: item.HigherThan === true ? higherThan : lowerThan}}>

                                       <div className='text-center' style={{marginTop: 0, transform: 180,}}>
                                          <p style={{textAlign: 'flext-start', fontSize: 20, whiteSpace: 'pre-wrap'}}>
                                             {
                                                item.ItemsDetail.map(function (item, i)
                                                {
                                                   return <div>{item.Text + ' ' + item.Value + '\n'}</div>;
                                                })
                                             }
                                          </p>
                                       </div>

                                    </div>
                                 </div>
                              </div>

                           </ReactCardFlip>


                           <div className="card-content" style={{height: 46}}>
                              <h4 className="card-title" style={{marginTop: 16}}>
                                 {item.SubTitle}
                              </h4>
                           </div>
                        </div>
                     </Col>
                  })}
               </div>
            </div>
         </div>
         <Row style={{marginTop: 50}}>
            <div style={{position: 'absolute', bottom: 20, left: 80}}>
               <h5>Son güncelleme
                  : {new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()}</h5>
            </div>
            <div style={{position: 'absolute', bottom: 20, right: 80}}>
               <h5>© {new Date().getFullYear()} {' '} {t('bbBilisim')}</h5>
            </div>
         </Row>
      </div>
   );
};

function mapStateToProps(state)
{
   return {
      loginUser: state.loginReducer,
      menus: state.menuReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         checkLoginuser: bindActionCreators(loginUserActions.checkLoginUser, dispatch),
         checkLoginUser3rdPartApp: bindActionCreators(loginUserActions.checkLoginUser3rdPartApp, dispatch),
         getMenu: bindActionCreators(menuActions.getMenus, dispatch),
         getPoolCount: bindActionCreators(poolActions.getPoolCount, dispatch),
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDashboard);


