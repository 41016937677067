import React, { Component } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import DataGrid, {
  Column,
  Export,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import * as BB from "../../../components/BBComponent";
import { Popup } from "devextreme-react/popup";
import { post } from "axios";
import notify from "devextreme/ui/notify";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import { connect } from "react-redux";
import ScrollView from "devextreme-react/scroll-view";
import { Redirect } from "react-router-dom";
import metrics from "../../../common/metrics";
import TagBox from "devextreme-react/tag-box";
import PoolUser from "../Pool/PoolUser";
import { callAPI, callApiGet, callApiPost } from "../../../components/Axios";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

class CompanyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: undefined,
      departmans: [],
      jobs: [],
      permissions: [],
      managers: [],
      permissionPackages: [],
      gruops: [],

      editRowVisible: false,
      addRowVisible: false,

      newName: undefined,
      newSurname: undefined,
      newEmail: undefined,
      newGSM: undefined,
      newPhoneNumber: undefined,
      newInternalNumber: undefined,
      newDepartment: undefined,
      newJob: undefined,
      newRole: undefined,
      newManager: undefined,
      newPermissionPackage: [],
      newIsManager: false,
      newIsGM: false,
      newPhoto: undefined,
      newUserPool: [],
      newDomainName: undefined,
      selectedGroup: undefined,
      selectedUserID: undefined,
      selectedName: undefined,
      selectedSurname: undefined,
      selectedEmail: undefined,
      selectedGSM: undefined,
      selectedPhoneNumber: undefined,
      selectedInternalNumber: undefined,
      selectedDepartment: undefined,
      selectedJob: undefined,
      selectedRole: undefined,
      selectedManager: undefined,
      selectedPermissionPackage: undefined,
      selectedIsManager: false,
      selectedIsGM: false,
      selectedIsActive: false,
      selectedDomainName:undefined,
      companyID: this.props.location.state
        ? this.props.location.state.CompanyID
        : null,
      redirect: false,
      redirectUrl: "",
      UserPools: [],
      selectedSearchable: false,
      selectedIsChangeUser: false,
      selectedIsUserPools: undefined,
      isLastUser:false
    };
    let { t } = this.props;

    this.handleNewIsGMCheckBox = this.handleNewIsGMCheckBox.bind(this);
    this.handleNewIsManagerCheckBox =
      this.handleNewIsManagerCheckBox.bind(this);
    this.handleSelectedIsGMCheckBox =
      this.handleSelectedIsGMCheckBox.bind(this);
    this.handleSelectedIsManagerCheckBox =
      this.handleSelectedIsManagerCheckBox.bind(this);
    this.handleSelectedIsActiveCheckBox =
      this.handleSelectedIsActiveCheckBox.bind(this);
    this.handleSelectedIsSearch = this.handleSelectedIsSearch.bind(this);
    this.handleSelectedIsChangeUser =
      this.handleSelectedIsChangeUser.bind(this);

    this.getDatas();
  }

  getDatas() {
    BB.FetchJSON(
      "api/Yonetim/Users/GetData?companyID=" + this.state.companyID
    ).then((data) => {
      this.setState({
        gridData: data,
        isLoading: false,
        // UserPools:data.UserPools
      });
    });

    BB.FetchJSON(
      "api/Yonetim/Users/GetDetailDatas?companyID=" + this.state.companyID
    ).then((data) => {
      this.setState({
        departmans: data.Departmans /*jobs: data.Jobs,*/,
        permissions: data.Permission,
        managers: data.Managers,
        permissionPackages: data.PermissionPackages,
        isLoading: false,
        UserPools: data.Pools,
      });
    });

    BB.FetchJSON("api/Yonetim/Group/GetAllGroup").then((data) => {
      this.setState({ gruops: data });
    });

    this.props.actions.isLoading(false);
  }


  CheckPermissionForTransferPool =(poolID,userID)=>{
     callAPI(
    callApiGet,
    'api/Yonetim/Users/CheckPermissionForTransferPool?poolID='+poolID+"&userID=" +userID,
    {}
    ,
     (res) => {
      if (res?.data >0) {
        BB.askYesNo( res.data,t("adetsecilenkullanicininhavuzundasorumluoldugukapanmistaleplervardir"), t("tamam"), t("vazgec")).then(ask => {
          if (ask === true) {

          }})
      

      }
    },
    true
  );
  }
 



  
  actionButton(cellData) {
    return (
      <div>
        <Button color={"primary"} outline onClick={() => this.setRow(cellData)}>
          {t("düzenle")}
        </Button>

        <Button
          style={{ marginLeft: 5 }}
          color={"primary"}
          outline
          onClick={() => {
            this.setState({
              selectedUserID: cellData.data.ID,
              redirect: true,
              redirectUrl: "/Yonetim/CompanyUserCategory",
            });
          }}
        >
          {t("kategori")}
        </Button>
      </div>
    );
  }

  handleChange(id, value) {
     
if((id==="selectedRole" || id==="newRole")&&String(value)==="d45342ab-14d1-e811-80f6-005056b839bb"){
this.setState({isLastUser:true})
}
else{
   this.setState({isLastUser:false})

}




    if (id === "newPhoto") {
      this.setState({ newPhoto: value.target.files[0] });
    } else {
      if (id === "newDepartment" || id === "selectedDepartment") {
        fetch(
          "api/Yonetim/Users/GetJobs?departmentID=" +
            value +
            "&companyID=" +
            this.state.companyID,
          { method: "GET" }
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({ jobs: data });
          });

        this.setState({ [id]: value });
      } else {
        if (id == "newName" || id == "newSurname") {
          this.setState({ [id]: value.toUpperCase() });
        } else {
          this.setState({ [id]: value });
        }
      }
    }
  }

  setMultiSelectChange = (event) => {
   

    this.setState({
      selectedPermissionPackage: event.value,
    });
  };

  setMultiSelectChange2 = (event) => {
    "previousValue"
    //CheckPermissionForTransferPool
    this.CheckPermissionForTransferPool(event.previousValue[0],this.state.selectedUserID)
    this.setState({
      selectedIsUserPools: event.value,
    });
  };

  addMultiSelectChange = (e) => {
    
    this.setState({
      newPermissionPackage: e.value,

    });
  };

  addSelectedPoolUsers2 = (e) => {
    this.setState({
      newUserPool: e.value,
    });
  };
  handleNewIsGMCheckBox(e) {
    this.setState({ newIsGM: !this.state.newIsGM });
  }

  handleNewIsManagerCheckBox(e) {
    this.setState({ newIsManager: !this.state.newIsManager });
  }

  handleSelectedIsGMCheckBox(e) {
    this.setState({ selectedIsGM: !this.state.selectedIsGM });
  }

  handleSelectedIsManagerCheckBox(e) {
    this.setState({ selectedIsManager: !this.state.selectedIsManager });
  }

  handleSelectedIsActiveCheckBox(e) {
    this.setState({ selectedIsActive: !this.state.selectedIsActive });
  }

  handleSelectedIsSearch(e) {
    this.setState({ selectedSearchable: !this.state.selectedSearchable });
  }

  handleSelectedIsChangeUser(e) {
    this.setState({ selectedIsChangeUser: !this.state.selectedIsChangeUser });
  }

    setRow(cellData) {
        console.log("deneme", cellData)
    let newArr = [];
    cellData.data.PermissionPackages.map((item, i) => {
      newArr.push(item.ID);
    });

    let newArr2 = [];
    cellData.data.UserPools.map((item, i) => {
      newArr2.push(item.PoolID);
    });

    this.setState({
      editRowVisible: true,
      selectedUserID: cellData.data.ID,
      selectedName: cellData.data.Name,
      selectedSurname: cellData.data.SurName,
      selectedEmail: cellData.data.Email,
      selectedGSM: cellData.data.MobilPhone,
      selectedPhoneNumber: cellData.data.Phone,
      selectedInternalNumber: cellData.data.InternalNumber,
      selectedDepartment: cellData.data.CompanyDepartmentID,
      selectedJob: cellData.data.CompanyDepartmentJobID,
      selectedRole: cellData.data.RoleID,
      selectedIsManager: cellData.data.IsManager,
      selectedManager: cellData.data.ManagerID,
      selectedPermissionPackage: newArr,
      selectedIsGM: cellData.data.IsGM,
      selectedIsActive: cellData.data.IsActive,
      selectedSearchable: cellData.data.AllowSearchTask,
      selectedIsChangeUser: cellData.data.AllowChangeUser,
      selectedIsUserPools: newArr2,
      selectedDomainName:cellData.data.DomainName,
    });

    BB.FetchJSON(
      "api/Yonetim/Users/GetSelectedJobs?departmentID=" +
        this.state.selectedDepartment +
        "&companyID=" +
        this.state.companyID +
        "&jobID=" +
        this.state.selectedJob
    ).then((data) => {
      this.setState({ jobs: data });
    });

    BB.FetchJSON("api/Yonetim/Users/GetUserGroup?UID=" + cellData.data.ID).then(
      (data) => {
        if (data && data.length > 0) {
          this.setState({ selectedGroup: data[0].GroupID });
        } else {
          this.setState({ selectedGroup: undefined });
        }
      }
    );
  }

  addNewUser() {
    let { newName, newSurname, newEmail, newGSM, newDepartment, newRole } =
      this.state;
  
    if (
      newName === "" ||
      newName === undefined ||
      newSurname === "" ||
      newSurname === undefined ||
      newEmail === "" ||
      newEmail === undefined ||
      newGSM === "" ||
      newGSM === undefined ||
      newDepartment === "" ||
      newDepartment === undefined ||
      newRole === "" ||
      newRole === undefined
    ) {
      notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
    } else {

      if (
         String(this.state.newRole) ===
         "a6957f7c-53cc-e811-80f6-005056b839bb"
       ) {
         //destek rolü
         
         if (this.state.newUserPool.length === 0) {
           return notify(
             t("destekrolundekikullanicilaricinhavuzalanizorunludur"),
             "warning",
             3000
           );
         }
       }



      // kişi kendisinin yöneticisi olmaması için
      var name = this.state.newName.toUpperCase();
      var surname = this.state.newSurname.toUpperCase();
      var userName = name + " " + surname;
      var managers = this.state.managers;
      var managerName = "";

      managers.forEach((element) => {
        if (this.state.newManager === element.ID) {
          managerName = element.Name;
        }
      });

      if (managerName === userName) {
        return notify(
          t("secilenkisikendisininyoneticisiolamaz"),
          "warning",
          2000
        );
      }

      let user = {
        Name: this.state.newName,
        SurName: this.state.newSurname,
        Email: this.state.newEmail,
        Phone: this.state.newPhoneNumber,
        InternalNumber: this.state.newInternalNumber
          ? this.state.newInternalNumber
          : 0,
        MobilPhone: this.state.newGSM,
        CompanyDepartmentID: this.state.newDepartment,
        CompanyDepartmentJobID: this.state.newJob,
        ManagerID: this.state.newManager,
        RoleID: this.state.newRole,
        CompanyID: this.state.companyID,
        Photo: null,
        IsManager: this.state.newIsManager,
        IsGM: this.state.newIsGM,
        IsActive: true,
        LangID: 0,
        UserName: this.state.newEmail,
        Password: "1111",
        AllowSearchTask: this.state.selectedSearchable,
        AllowChangeUser: this.state.selectedIsChangeUser,
        DomainName: this.state.newDomainName,
      };

      let permissionArr = [];
      let userPoolArr = [];

      this.state.newPermissionPackage.map((item, i) => {
        let permissionData = this.state.permissionPackages.filter(
          (x) => x.ID == item
        )[0];
        permissionArr.push(permissionData);
      });

      this.state.newUserPool.map((item, i) => {
        let poolData = this.state.UserPools.filter((x) => x.ID == item)[0];
        userPoolArr.push(poolData);
      });

      this.props.actions.isLoading(true);

      let entity = {
        User: user,
        RolePackages: permissionArr,
        UserPools: userPoolArr,
      };

      const formData = new FormData();
      formData.append("file", this.state.newPhoto);
      formData.append("jsonText", JSON.stringify(entity));

      if (this.state.selectedGroup !== undefined) {
        formData.append("userGroup", this.state.selectedGroup);
      }

      post("api/Yonetim/Users/Add", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.data.MessageStatus === true) {
            this.clrState();
            this.props.actions.isLoading(false);
            notify(t("islemBasarili"), "success", 1000);
          } else {
            this.clrState();
            this.props.actions.isLoading(false);
            notify(t("islemBasarisiz") + res.data.Message, "warning", 1000);
          }
        })
        .catch((ex) => {
          this.props.actions.isLoading(false);
        });
    }
  }

  clrState() {
    this.setState(
      {
        editRowVisible: false,
        addRowVisible: false,
        newName: undefined,
        newSurname: undefined,
        newEmail: undefined,
        newGSM: undefined,
        newPhoneNumber: undefined,
        newInternalNumber: undefined,
        newDepartment: undefined,
        newJob: undefined,
        newRole: undefined,
        newManager: undefined,
        newPermissionPackage: [],
        newIsManager: undefined,
        newIsGM: undefined,
        newPhoto: undefined,
        selectedGroup: undefined,
        selectedIsUserPools:undefined,
        newDomainName: undefined
      },
      this.getDatas()
    );
  }

  setUser() {
    if (
      this.state.selectedName === "" ||
      this.state.selectedName === undefined ||
      this.state.selectedSurname === "" ||
      this.state.selectedSurname === undefined ||
      this.state.selectedEmail === "" ||
      this.state.selectedEmail === undefined ||
      this.state.selectedGSM === "" ||
      this.state.selectedGSM === undefined ||
      this.state.selectedDepartment === "" ||
      this.state.selectedDepartment === undefined ||
      this.state.selectedRole === "" ||
      this.state.selectedRole === undefined
    ) {
      notify(t("lutfenZorunluAlanlarıDoldurunuz"), "warning", 2000);
    } else {
      if (
        String(this.state.selectedRole) ===
        "a6957f7c-53cc-e811-80f6-005056b839bb"
      ) 
      {
        //destek rolü
        //D45342AB-14D1-E811-80F6-005056B839BB Son Kullanıcı 
        if (this.state.selectedIsUserPools.length === 0) {
          return notify(
            t("destekrolundekikullanicilaricinhavuzalanizorunludur"),
            "warning",
            3000
          );
        }
      }
      if( String(this.state.selectedRole) ===
      "d45342ab-14d1-e811-80f6-005056b839bb"){
       if(this.state.selectedIsUserPools.length !== 0){
        return notify(
          t("sonkullanicirolundekikullanicilaricinhavuzalanibosolmalidir"),
          "warning",
          3000
        );
       }
      }
      // kişi kendisinin yöneticisi olmaması için
      var name = this.state.selectedName.toUpperCase();
      var surname = this.state.selectedSurname.toUpperCase();
      var userName = name + " " + surname;
      var managers = this.state.managers;
      var managerName = "";

      managers.forEach((element) => {
        if (this.state.selectedManager === element.ID) {
          managerName = element.Name;
        }
      });
      if (managerName === userName) {
        return notify(
          t("secilenkisikendisininyoneticisiolamaz"),
          "warning",
          2000
        );
      }

      this.props.actions.isLoading(true);

      let user = {
        ID: this.state.selectedUserID,
        CompanyID: this.state.companyID,
        Name: this.state.selectedName,
        SurName: this.state.selectedSurname,
        Email: this.state.selectedEmail,
        Phone: this.state.selectedPhoneNumber,
        InternalNumber: this.state.selectedInternalNumber
          ? this.state.selectedInternalNumber
          : 0,
        MobilPhone: this.state.selectedGSM,
        CompanyDepartmentID: this.state.selectedDepartment,
        CompanyDepartmentJobID: this.state.selectedJob,
        ManagerID: this.state.selectedManager,
        RoleID: this.state.selectedRole,
        IsManager: this.state.selectedIsManager,
        IsGM: this.state.selectedIsGM,
        IsActive: this.state.selectedIsActive,
        AllowSearchTask: this.state.selectedSearchable,
        AllowChangeUser: this.state.selectedIsChangeUser,
        DomainName:this.state.selectedDomainName
      };

      let permissionArr = [];
      let userPoolArr = [];

      this.state.selectedPermissionPackage.map((item, i) => {
        let permissionData = this.state.permissionPackages.filter(
          (x) => x.ID == item
        )[0];
        permissionArr.push(permissionData);
      });

      this.state.selectedIsUserPools.map((item, i) => {
        let poolData = this.state.UserPools.filter((x) => x.ID == item)[0];
        userPoolArr.push(poolData);
      });

      let entity = {
        User: user,
        RolePackages: permissionArr,
        UserPools: userPoolArr,
      };

      const formData = new FormData();
      formData.append("jsonText", JSON.stringify(entity));

      if (this.state.selectedGroup !== undefined) {
        formData.append("userGroup", this.state.selectedGroup);
      }

      post("api/Yonetim/Users/Add", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.data.MessageStatus === true) {
            this.setState(
              {
                editRowVisible: false,
                addRowVisible: false,
                selectedUserID: undefined,
                selectedName: undefined,
                selectedSurname: undefined,
                selectedEmail: undefined,
                selectedGSM: undefined,
                selectedPhoneNumber: undefined,
                selectedInternalNumber: undefined,
                selectedDepartment: undefined,
                selectedJob: undefined,
                selectedRole: undefined,
                selectedManager: undefined,
                selectedPermissionPackage: undefined,
                selectedIsManager: undefined,
                selectedIsGM: undefined,
                selectedPhoto: undefined,
                selectedGroup: undefined,
                selectedIsUserPools:undefined,
                selectedDomainName:undefined
              },
              this.getDatas()
            );
            this.props.actions.isLoading(false);
            notify(t("islemBasarili"), "success", 1000);
          } else {
            this.setState(
              {
                editRowVisible: false,
                addRowVisible: false,
                selectedUserID: undefined,
                selectedName: undefined,
                selectedSurname: undefined,
                selectedEmail: undefined,
                selectedGSM: undefined,
                selectedPhoneNumber: undefined,
                selectedInternalNumber: undefined,
                selectedDepartment: undefined,
                selectedJob: undefined,
                selectedRole: undefined,
                selectedManager: undefined,
                selectedPermissionPackage: undefined,
                selectedIsManager: undefined,
                selectedIsGM: undefined,
                selectedPhoto: undefined,
                selectedGroup: undefined,
                selectedIsUserPools:undefined,
                selectedDomainName:undefined

              },
              this.getDatas()
            );
            this.props.actions.isLoading(false);
            notify(t("islemBasarisiz") + res.data.Message, "warning", 1000);
          }
        })
        .catch((ex) => {
          this.props.actions.isLoading(false);
        });
    }
  }

  render() {
    const { selectedUserID, redirectUrl } = this.state;

    if (this.state.redirect === true) {
      return (
        <Redirect
          to={{
            pathname: redirectUrl,
            state: { UserID: selectedUserID, companyID: this.state.companyID },
          }}
        />
      );
    } else {
      return (
        <div className="animated fadeIn">
          <Col>
            <Card>
              <CardBody>
                <Button
                  color={"success"}
                  outline
                  onClick={() =>
                    this.setState({
                      redirect: true,
                      redirectUrl: "/Yonetim/Companies",
                    })
                  }
                  style={{ float: "left", position: "absolute", zIndex: 1 }}
                  type="button"
                >
                  {t('geri')}
                </Button>

                <Button
                  color={"success"}
                  outline
                  onClick={() =>
                    this.setState({
                      addRowVisible: true,
                      newName: "",
                      newSurname: "",
                      newEmail: "",
                      newGSM: "",
                      newPhoneNumber: "",
                      newInternalNumber: "",
                      newDepartment: "",
                      newJob: "",
                      newRole: "",
                      newManager: "",
                      newPermissionPackage: [],
                      newIsManager: false,
                      newIsGM: false,
                      newPhoto: "",
                      newUserPool:[],
                    })
                  }
                  style={{
                    float: "left",
                    position: "absolute",
                    zIndex: 1,
                    left: 80,
                  }}
                  type="button"
                >
                  {t("kullaniciEkleme")}
                </Button>

                <DataGrid
                  id="gridContainer"
                  dataSource={this.state.gridData}
                  showRowLines={true}
                  showColumnLines={false}
                  showBorders={false}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20, 50]}
                    showNavigationButtons={true}
                  />
                  <FilterRow visible={true} />
                  <SearchPanel visible={true} width={100} />
                  <Export enabled={true} fileName="" />
                  <Column
                    dataField="Name"
                    caption={t("adı")}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField="SurName"
                    caption={t('soyadi')}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField="Departmanı"
                    caption={t("departman")}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"Ünvan"}
                    caption={t("unvan")}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"Rolü"}
                    caption={t("rolu")}
                    minWidth={80}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"Yönetici"}
                    caption={t("yonetici")}
                    minWidth={60}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"GenelYetkili"}
                    caption={t("genelyetkili")}
                    minWidth={60}
                    allowFiltering={true}
                  />
                  <Column
                    dataField={"IsActive"}
                    caption={t("aktiflik")}
                    allowFiltering={true}
                    minWidth={60}
                  />
                  <Column
                    dataField="İşlemler"
                    caption={t("islemler")}
                    width={200}
                    cellRender={(cellData) => this.actionButton(cellData)}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </Col>

          {/*Kullanıcı Ekleme, Popup*/}
          <Popup
            visible={this.state.addRowVisible}
            dragEnabled={false}
            showTitle={false}
            title={t("kullaniciEkleme")}
            minWidth={metrics.popupMinWidth}
            maxWidth={metrics.popupAutoWidth}
            maxHeight={metrics.popupMinHeight}
          >
            <ScrollView>
              <Row>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel("newName", t("adizorunlu"), "text", this)}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel("newSurname", t("soyadizorunlu"), "text", this)}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel("newEmail", t("epostazorunlu"), "email", this)}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel(
                    "newPhoneNumber",
                    t("telefon"),
                    "number",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel("newGSM", t("ceptelefonuzorunlu"), "number", this)}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.InputWithLabel(
                    "newInternalNumber",
                    t("dahili"),
                    "number",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.DxDropDownWithJSON(
                    "newDepartment",
                    t("departmanzorunlu"),
                    this,
                    this.state.departmans
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.DxDropDownWithJSON(
                    "newJob",
                    t("gorevzorunlu"),
                    this,
                    this.state.jobs
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.DxDropDownWithJSON(
                    "newRole",
                    t("roluzorunlu"),
                    this,
                    this.state.permissions
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {" "}
                  {BB.DxDropDownWithJSON(
                    "newManager",
                    t("yoneticisi"),
                    this,
                    this.state.managers
                  )}
                </Col>

                <Col lg="6" sm={12}>
                  <div>
                    <strong>{t("havuz")}</strong>

                    <TagBox
                      items={this.state.UserPools}
                      //value={this.state.selectedIsUserPools}
                      showSelectionControls={true}
                      maxDisplayedTags={3}
                      displayExpr="Name"
                      valueExpr="ID"
                      id="addSelectedPoolUsers"
                      selectAllMode="allPages"
                      onValueChanged={this.addSelectedPoolUsers2}
                      disabled={this.state.isLastUser}
                    />
                  </div>
                </Col>

                <Col lg="6" sm={12}>
                  <div>
                    <strong>{t("yetkipaketleri")}</strong>

                    <TagBox
                      items={this.state.permissionPackages}
                      showSelectionControls={true}
                      maxDisplayedTags={3}
                      displayExpr="Name"
                      valueExpr="ID"
                      id="addSelectedRoleList"
                      selectAllMode="allPages"
                      onValueChanged={this.addMultiSelectChange}
                    />
                  </div>
                </Col>

                <Col lg="6" sm={12}>
                  {BB.FileWithLabel("newPhoto", "Kullanıcı Fotoğrafı", this)}
                          </Col>
                          <Col lg="6" sm={12}>
                              {" "}
                              {BB.InputWithLabel("newDomainName", t("domainName"), "text", this)}
                          </Col>
                <Col lg="6" sm={12}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="newIsManagerCheckBox"
                      value={this.state.newIsManager}
                      onChange={this.handleNewIsManagerCheckBox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="newIsManagerCheckBox"
                    >
                      {t("yetkili")}
                    </label>
                  </div>
                </Col>
                <Col lg="6" sm={12}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="newIsGMCheckBox"
                      value={this.state.newIsGM}
                      onChange={this.handleNewIsGMCheckBox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="newIsGMCheckBox"
                    >
                      {t("genelyetkili")}
                    </label>
                  </div>
                </Col>
              </Row>
              <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
                <Button
                  color={"info"}
                  onClick={() => this.setState({ addRowVisible: false })}
                >
                  {t("vazgec")}
                </Button>
                <Button
                  onClick={() => this.addNewUser()}
                  color={"success"}
                  style={{ marginLeft: 20, marginRight: 0 }}
                >
                  {t("kaydet")}
                </Button>
              </Row>
            </ScrollView>
          </Popup>





          {/*Kullanıcı Düzenleme, Popup*/}
          <Popup
            visible={this.state.editRowVisible}
            dragEnabled={false}
            showTitle={false}
            title={t("kullaniciduzenleme")}
            minWidth={metrics.popupMinWidth}
            maxWidth={metrics.popupAutoWidth}
            maxHeight={metrics.popupMinHeight}
          >
            <ScrollView>
              <Row>
                <Col lg="6" sm={12}>
                  {BB.InputWithLabel("selectedName", t("adizorunlu"), "text", this)}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.InputWithLabel(
                    "selectedSurname",
                    t("soyadizorunlu"),
                    "text",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.InputWithLabel(
                    "selectedEmail",
                    t("epostazorunlu"),
                    "email",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.InputWithLabel(
                    "selectedPhoneNumber",
                    t("telefon"),
                    "number",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.InputWithLabel(
                    "selectedGSM",
                    t("ceptelefonuzorunlu"),
                    "number",
                    this
                  )}
                </Col>

                <Col lg="6" sm={12}>
                  {BB.InputWithLabel(
                    "selectedInternalNumber",
                    t("dahili"),
                    "number",
                    this
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.DxDropDownWithJSON(
                    "selectedDepartment",
                    t("departmanzorunlu"),
                    this,
                    this.state.departmans
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.DxDropDownWithJSON(
                    "selectedJob",
                    t("görev"),
                    this,
                    this.state.jobs
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.DxDropDownWithJSON(
                    "selectedRole",
                    t("roluzorunlu"),
                    this,
                    this.state.permissions
                  )}
                </Col>

                <Col lg="6" sm={12}>
                  {BB.DxDropDownWithJSON(
                    "selectedGroup",
                    t("grubu"),
                    this,
                    this.state.gruops
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  {BB.DxDropDownWithJSON(
                    "selectedManager",
                    t("yoneticisi"),
                    this,
                    this.state.managers
                  )}
                </Col>
                <Col lg="6" sm={12}>
                  <div>
                    <strong>{t("yetkipaketleri")}</strong>

                    <TagBox
                      items={this.state.permissionPackages}
                      value={this.state.selectedPermissionPackage}
                      showSelectionControls={true}
                      maxDisplayedTags={3}
                      displayExpr="Name"
                      valueExpr="ID"
                      id="addSelectedRoleList"
                      selectAllMode="allPages"
                      onValueChanged={this.setMultiSelectChange}
                    />
                  </div>
                </Col>
                <Col lg="6" sm={12}>
                  <div>
                    <strong>{t("havuz")}</strong>

                    <TagBox
                      items={this.state.UserPools}
                      value={this.state.selectedIsUserPools}
                      showSelectionControls={true}
                      maxDisplayedTags={3}
                      displayExpr="Name"
                      valueExpr="ID"
                      id="addSelectedPoolUsers"
                      selectAllMode="allPages"
                      onValueChanged={this.setMultiSelectChange2}
                      disabled={this.state.isLastUser}
                    />
                  </div>
                </Col>
                          <Col lg="6" sm={12}>
                              {BB.InputWithLabel("selectedDomainName", t("domainName"), "text", this)}
                          </Col>
                <Col lg="12" sm={12}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="selectedIsManagerCheckBox"
                      value={this.state.selectedIsManager}
                      checked={this.state.selectedIsManager}
                      onChange={this.handleSelectedIsManagerCheckBox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selectedIsManagerCheckBox"
                    >
                      {t("yetkili")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="selectedIsGMCheckBox"
                      value={this.state.selectedIsGM}
                      checked={this.state.selectedIsGM}
                      onChange={this.handleSelectedIsGMCheckBox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selectedIsGMCheckBox"
                    >
                      {t("genelyetkili")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="selectedIsActiveCheckBox"
                      value={this.state.selectedIsActive}
                      checked={this.state.selectedIsActive}
                      onChange={this.handleSelectedIsActiveCheckBox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selectedIsActiveCheckBox"
                    >
                      {t("aktifkullanici")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="selectedSearchable"
                      value={this.state.selectedSearchable}
                      checked={this.state.selectedSearchable}
                      onChange={this.handleSelectedIsSearch}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selectedSearchable"
                    >
                      {t("taleparayabilsin")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="selectedIsChangeUser"
                      value={this.state.selectedIsChangeUser}
                      checked={this.state.selectedIsChangeUser}
                      onChange={this.handleSelectedIsChangeUser}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="selectedIsChangeUser"
                    >
                      {t("kullanicidegistirebilsin")}
                    </label>
                  </div>
                </Col>
              </Row>
              <Row style={{ float: "right", marginRight: 0, marginTop: 10 }}>
                <Button
                  color={"info"}
                  onClick={() => this.setState({ editRowVisible: false })}
                >
                  {t("vazgec")}
                </Button>
                <Button
                  onClick={() => this.setUser()}
                  color={"success"}
                  style={{ marginLeft: 20, marginRight: 0 }}
                >
                  {t("kaydet")}
                </Button>
              </Row>
            </ScrollView>
          </Popup>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CompanyUser)
);
