import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {TagBox} from 'devextreme-react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../redux/actions/loadingActions";
import * as BB from "../../../components/BBComponent";
import {post} from "axios";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import { withTranslation } from 'react-i18next';

export const FileWithLabel = (id, text, event) =>
{
   return (
      <FormGroup>
         <Label htmlFor={id}>
            <strong>{text}</strong>
         </Label>
         <Input
            type='file'
            id={id}
            onChange={(e) => event.handleChange(id, e)}
         />
      </FormGroup>
   );
}

const DATA = [{ID: "_blank", Name: "Yeni Sekmede Aç"}, {ID: "_self", Name: "Olduğu Sayfada Aç"}]

class SssAdd extends React.Component
{
   constructor(props)
   {
      super(props)
      this.state = {
         roleList: [],
         selectedRoleList: [],
         isLink: false,
         isActive: false,
         orderNumber: '',
         targetList: DATA
      }
      this.onValueChanged = this.onValueChanged.bind(this);
      this.handleLinkChange = this.handleLinkChange.bind(this)
      this.handleActiveChange = this.handleActiveChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleFile = this.handleFile.bind(this)
   }

   componentDidMount()
   {
      let url = `api/Yonetim/AdminSSS/Add`;
      BB.FetchJSON(url).then(res =>
      {
         this.setState({roleList: res.ViewBag.Roles});
      });
   }

   //input ve textarea
   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   //Radio button
   handleLinkChange()
   {
      const {isLink} = this.state
      this.setState({isLink: !isLink})
   }

   handleActiveChange()
   {
      const {isActive} = this.state
      this.setState({isActive: !isActive})
   }

   handleFile = (e) =>
   {
      this.setState({selectedSssFiles: e.target.files[0]})
   }

   onValueChanged(e)
   {
      const newValues = e.value;
      this.setState({selectedRoleList: newValues})
   }

   handleSubmit = (e) =>
   {
      const {
         title,
         orderNumber,
         content,
         selectedRoleList,
         selectedSssFiles,
         isLink,
         isActive,
         selectedTargetID
      } = this.state;
      if (orderNumber === '' || orderNumber === undefined || title === undefined || title === '' || selectedRoleList.length == 0)
      {
         notify(t("zorunluAlanlariDoldurunuz"), "warning", 2000);
      }
      else
      {
         this.props.actions.isLoading(true);
         const formData = new FormData();
         let entity = {
            Title: title,
            Body: content,
            OrderNumber: orderNumber,
            IsLink: isLink,
            IsActive: isActive,
            LinkTargetType: selectedTargetID
         }
         formData.append('jsonText', JSON.stringify(entity));
         formData.append('file', selectedSssFiles);
         formData.append('RoleIDs', selectedRoleList);
         post('api/Yonetim/AdminSSS/Add', formData, {
            headers: {
               'content-type': 'multipart/form-data'
            },
         })
         .then(res =>
         {
            if (res.data.MessageStatus === true)
            {
               this.setState({
                  OrderNumber: '',
                  isLink: false,
                  isActive: false,
                  title: '',
                  orderNumber: '',
                  content: '',
                  selectedRoleList: [],
                  selectedSssFiles: '',
                  selectedTargetID: ''
               });
               this.props.actions.isLoading(false);
               notify(t("eklemebasarilibirsekildeeklendi"), "success", 1000);
            }
            else
            {
               notify(t("eklemeislemindehata") + res.Message, "warning", 1000);
               this.props.actions.isLoading(false);
            }
         });
      }
      e.preventDefault();
   }

   render()
   {
      const {isLink, isActive} = this.state;
      return (
         <Row>
            <Col>
               <Card>
                  <CardHeader>{t("sssekleme")}</CardHeader>
                  <CardBody>
                     <Form onSubmit={this.handleSubmit}>
                        <Row>
                           <Col lg='6'>
                              {BB.InputWithLabel('title', t("baslik"), 'text', this)}
                           </Col>
                           <Col lg='6'>
                              {BB.InputWithLabel('orderNumber', t("siranumarasi"), 'number', this)}
                           </Col>
                           <Col lg='12'>
                              {BB.TextAreaWithLabel('content', t("icerik"), t("icerik"), 5, this)}
                           </Col>
                           <Col lg='6'>
                              {BB.DxDropDownWithJSON('selectedTargetID', t("linksayfasi"), this, this.state.targetList)}
                           </Col>
                           <Col lg='6'>
                              <label><strong>{t("yetkigrubu")}</strong></label>
                              <TagBox
                                 items={this.state.roleList}
                                 showSelectionControls={true}
                                 maxDisplayedTags={3}
                                 displayExpr="Name"
                                 valueExpr="ID"
                                 selectAllMode="allPages"
                                 onValueChanged={this.onValueChanged}
                              />
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio"
                                        onChange={this.handleLinkChange} value={isLink} checked={isLink}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio">Link</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <FormGroup check inline className='mb-1 customRadioButton'>
                                 <Input className="form-check-input" type="checkbox" id="inline-radio1"
                                        onChange={this.handleActiveChange} value={isActive} checked={isActive}/>
                                 <Label className="form-check-label" check htmlFor="inline-radio1">{t("aktifpasif")}</Label>
                              </FormGroup>
                           </Col>
                           <Col lg='3' style={{paddingTop: 10}}>
                              <FormGroup>
                                 <Label htmlFor='selectedSssFiles'>
                                    <strong>{t("dosyaekle")}</strong>
                                 </Label>
                                 <Input
                                    type='file'
                                    id='selectedSssFiles'
                                    onChange={this.handleFile}
                                 />
                              </FormGroup>
                           </Col>
                           <Col lg='12'>
                              <Button color='success' className='float-right  mt-10' type='submit'>
                                 {t("ekle")}
                              </Button>
                           </Col>
                        </Row>
                     </Form>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      )
   }
}

function mapStateToProps(state)
{
   return {}
}


function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
      }
   }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SssAdd));

